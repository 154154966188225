var DlhSoft;
'undefined' === typeof DlhSoft && (DlhSoft = { assemblies: [], buildReleaseDate: new Date(1, 0, 1) });
DlhSoft.assemblies.push({
  assembly: 'DlhSoft.ProjectData.GanttChart.HTML.Controls',
  company: 'DlhSoft',
  product: 'Project Data Modern Library',
  version: '5.3.20.17',
  copyright: 'Copyright \u00a9 2012-2024 DlhSoft',
  title: 'Project Data Gantt Chart HTML Controls',
  description: 'Project Data Gantt Chart related HTML client components',
});
DlhSoft.buildReleaseDate = (function (d, O, V) {
  d = new Date(d, O - 1, V);
  return d > DlhSoft.buildReleaseDate ? d : DlhSoft.buildReleaseDate;
})(2024, 5, 9);
var _0x5c25 = 'Licensing{undefined{string{DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.{.{length{license{push{type{content{:{lastIndexOf{substr{buildReleaseDate{charCodeAt{pow{toUpperCase{DlhSoft Component Licensing Warning{http://DlhSoft.com/Purchase.aspx{?Assemblies={,{http://www.componentsource.com/features/dlhsoft{ (version {){This component is currently licensed to be used for testing purposes only{The component is part of this assembly{To purchase a standard license for a product that includes this assembly you can use the Purchase page{ownerDocument{div{createElement{style{position: absolute; left: 24px; top: 24px; width: 480px; display: none !important; z-index: 1; color: Black; background-color: #ffffc0; border: solid 1px Blue; overflow: auto{setAttribute{padding: 6px; padding-top: 8px; font-family: Arial; font-size: small{font-weight: bold; margin-bottom: 4px{appendChild{createTextNode{color: Red; margin-top: 2px; margin-bottom: 2px{margin-top: 4px{a{color: Blue; text-decoration: underline{href{target{_blank{Purchase license{margin-left: 4px; color: Blue; text-decoration: underline{javascript://{Continue trial{click{removeChild{addEventListener{\n\n{: {:\n'.split(
  '{'
);
void 0 == DlhSoft[_0x5c25[0]] &&
  (DlhSoft[_0x5c25[0]] = (function () {
    var d = _0x5c25[1],
      O = _0x5c25[2],
      V = [],
      ba = [],
      ca = _0x5c25[3],
      qa = function (r, P) {
        if (typeof r !== O || typeof P !== O) return !1;
        if (P == ca) return !0;
        var K = P[_0x5c25[11]](_0x5c25[10]);
        if (0 <= K) {
          var Y = P[_0x5c25[12]](0, K),
            H,
            J = Y[_0x5c25[11]](_0x5c25[10]);
          if (0 <= J)
            try {
              H = new Date(parseInt(Y[_0x5c25[12]](J + 1)));
            } catch (U) {}
          typeof H === d && (H = new Date(2013, 11, 31));
          if (DlhSoft[_0x5c25[13]] > H) return !1;
          Y = Y + _0x5c25[10] + r;
          for (i = H = 0; i < Y[_0x5c25[5]]; i++) (J = Y[_0x5c25[14]](i)), (H = (H << 5) - H + J), (H &= H);
          0 > H && (H = Math[_0x5c25[15]](2, 32) - H);
          Y = H.toString(16)[_0x5c25[16]]();
          K = P[_0x5c25[12]](K + 1);
          if (Y == K) return !0;
        }
        return !1;
      },
      r = function (r, P, K, Y, H) {
        var J = _0x5c25[17],
          U = _0x5c25[18],
          X =
            typeof ComponentSource === d || !ComponentSource
              ? U + _0x5c25[19] + Y + _0x5c25[20] + H
              : _0x5c25[21],
          V = Y + _0x5c25[22] + H + _0x5c25[23],
          O = _0x5c25[24],
          fa = _0x5c25[25],
          ca = _0x5c25[26];
        setTimeout(function () {
          var d = r[_0x5c25[27]],
            s = d[_0x5c25[29]](_0x5c25[28]);
          s[_0x5c25[32]](_0x5c25[30], _0x5c25[31]);
          var j = d[_0x5c25[29]](_0x5c25[28]);
          j[_0x5c25[32]](_0x5c25[30], _0x5c25[33]);
          var u = d[_0x5c25[29]](_0x5c25[28]);
          u[_0x5c25[32]](_0x5c25[30], _0x5c25[34]);
          j[_0x5c25[35]](u);
          u[_0x5c25[35]](d[_0x5c25[36]](J));
          j[_0x5c25[35]](d[_0x5c25[36]](O + _0x5c25[10]));
          u = d[_0x5c25[29]](_0x5c25[28]);
          u[_0x5c25[32]](_0x5c25[30], _0x5c25[37]);
          u[_0x5c25[35]](d[_0x5c25[36]](P));
          j[_0x5c25[35]](u);
          j[_0x5c25[35]](d[_0x5c25[36]](fa + _0x5c25[10]));
          u = d[_0x5c25[29]](_0x5c25[28]);
          u[_0x5c25[32]](_0x5c25[30], _0x5c25[37]);
          u[_0x5c25[35]](d[_0x5c25[36]](V));
          j[_0x5c25[35]](u);
          j[_0x5c25[35]](d[_0x5c25[36]](ca + _0x5c25[10]));
          u = d[_0x5c25[29]](_0x5c25[28]);
          u[_0x5c25[32]](_0x5c25[30], _0x5c25[38]);
          var k = d[_0x5c25[29]](_0x5c25[39]);
          k[_0x5c25[32]](_0x5c25[30], _0x5c25[40]);
          k[_0x5c25[32]](_0x5c25[41], X);
          k[_0x5c25[32]](_0x5c25[42], _0x5c25[43]);
          k[_0x5c25[35]](d[_0x5c25[36]](_0x5c25[44]));
          u[_0x5c25[35]](k);
          k = d[_0x5c25[29]](_0x5c25[39]);
          k[_0x5c25[32]](_0x5c25[30], _0x5c25[45]);
          k[_0x5c25[32]](_0x5c25[41], _0x5c25[46]);
          k[_0x5c25[35]](d[_0x5c25[36]](_0x5c25[47]));
          k[_0x5c25[50]](
            _0x5c25[48],
            function () {
              r[_0x5c25[49]](s);
              ba[_0x5c25[7]](K);
            },
            !1
          );
          u[_0x5c25[35]](k);
          j[_0x5c25[35]](u);
          s[_0x5c25[35]](j);
          r[_0x5c25[35]](s);
        }, 0);
      };
    return {
      setLicense: function (d, r) {
        V[_0x5c25[7]]({ type: d, content: r });
      },
      validate: function (T, P, K, Y, H, J, U) {
        for (var P = P + _0x5c25[4] + K, X = 0; X < ba[_0x5c25[5]]; X++) if (ba[X] == P) return;
        a: if (
          (typeof J !== O &&
            typeof U !== d &&
            (U[_0x5c25[6]] !== d && (J = U[_0x5c25[6]]), typeof U === O && (J = U)),
          typeof J !== O)
        )
          for (U = 0; U < V[_0x5c25[5]]; U++)
            if (((X = V[U]), X[_0x5c25[8]] == P && typeof X[_0x5c25[9]] === O)) {
              J = X[_0x5c25[9]];
              break a;
            }
        qa(P, J) ? (T[_0x5c25[6]] = J) : r(T, K, P, Y, H);
      },
    };
  })());
void 0 == DlhSoft.Controls && (DlhSoft.Controls = {});
void 0 == DlhSoft.Controls.GanttChartView &&
  (DlhSoft.Controls.GanttChartView = (function () {
    var d = 'undefined',
      O = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      V = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      ba = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      ca = new Date(259200000),
      qa = new Date(315576259200000),
      r = function (a, c, e, b, f) {
        a.internalEventListeners &&
          a.internalEventListeners.push({ object: c, event: e, handler: b, useCapture: f });
        return b;
      },
      T = function (a, c) {
        var e = document.createEvent('MouseEvents');
        e.initMouseEvent(
          c,
          true,
          true,
          document.defaultView,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          a
        );
        a.dispatchEvent(e);
      },
      P = function (a, c, e, b) {
        function f(c) {
          if (typeof t.x !== d && !(a.offsetWidth <= 0)) {
            var c = t.gridWidth + (c.clientX - t.x),
              b = a.offsetWidth - c - 3,
              f = Math.max(1, e.minGridWidth),
              g = Math.max(1, e.minChartWidth);
            if (c < f) {
              b = b - (f - c);
              c = f;
            } else if (b < g) {
              c = c - (g - b);
              b = g;
            }
            c < 1 && (c = 1);
            b < 1 && (b = 1);
            typeof G.percent !== d && delete G.percent;
            c = Math.ceil((c * 1000000) / a.offsetWidth) / 10000;
            G.style.width = c + '%';
            h.style.width = 100 - c + '%';
            e.gridWidth = G.offsetWidth + 'px';
            e.chartWidth = h.offsetWidth + 'px';
            La(t, G, e);
            typeof e.splitterPositionChangeHandler !== d &&
              setTimeout(function () {
                e.splitterPositionChangeHandler(G.offsetWidth, h.offsetWidth);
              }, 0);
          }
        }
        if (typeof a.ownerDocument.createElementNS === d || typeof a.ownerDocument.addEventListener === d)
          alert(
            'The required HTML5 features are not supported by the application host. Some features will be unavailable. Consider upgrading.'
          );
        else {
          var g = [
            'DlhSoft.Controls',
            'GanttChartView',
            'DlhSoft.ProjectData.GanttChart.HTML.Controls',
            '5',
            'validate',
            'Licensing',
          ];
          DlhSoft[g[5]][g[4]](a, g[0], g[1], g[2], g[3], b, e);
          a.isInitializing = true;
          if (typeof a.initializedItems !== d) {
            a.initializedItems.forEach(function (a) {
              a.gridItemContainer &&
                a.gridItemContainer.parentNode &&
                a.gridItemContainer.parentNode.removeChild(a.gridItemContainer);
              a.chartItemArea &&
                a.chartItemArea.parentNode &&
                a.chartItemArea.parentNode.removeChild(a.chartItemArea);
              delete a.ganttChartView;
              delete a.chartItem;
              delete a.chartItemArea;
              delete a.gridItem;
              delete a.gridItemContent;
              delete a.gridItemSelectionContainer;
              delete a.gridItemContainer;
              delete a.alternativeContentContainer;
              delete a.toggleButton;
              delete a.parent;
              delete a.children;
              delete a.selectionInput;
              delete a.contentInput;
              delete a.startInput;
              delete a.finishInput;
              delete a.milestoneInput;
              delete a.assignmentsContentInput;
              delete a.completedInput;
              delete a.dependsOf;
              delete a.touchMoveHandler;
              delete a.touchEndHandler;
            });
            typeof a.draggableItems !== d && delete a.draggableItems;
            typeof a.draggableDependencyItems !== d && delete a.draggableDependencyItems;
            typeof a.draggableOrderingItems !== d && delete a.draggableOrderingItems;
          }
          a.initializedItems = c.slice();
          a.internalEventListeners &&
            a.internalEventListeners.forEach(function (a) {
              a.object.removeEventListener(a.event, a.handler, a.useCapture);
            });
          a.internalEventListeners = [];
          typeof a.settings !== d &&
            typeof a.settings.toggleButtonAreaWidth !== d &&
            delete a.settings.toggleButtonAreaWidth;
          if (typeof a.items !== d) for (b = 0; b < a.items.length; b++) delete a.items[b].successors;
          typeof e !== 'object' && (e = {});
          J(c, e);
          H(e, c, a);
          Jc(a, c, e);
          b = e.columns;
          g = e.scales;
          Kc(b, a, e);
          ha(c, a);
          Sa(c, e, a);
          if (typeof e.visibilityFilter !== d) {
            Lc(c, e.visibilityFilter);
            for (var q = 0, o = 0; o < c.length; o++) {
              var m = c[o];
              if (typeof m.ganttChartItems !== d) {
                if (!m.isHidden) m.scheduleChartVisibilityIndex = q++;
                for (var n = 0; n < m.ganttChartItems.length; n++) {
                  var l = m.ganttChartItems[n];
                  if (m.isHidden) l.isHidden = true;
                  l.displayRowIndex = m.scheduleChartVisibilityIndex;
                }
              }
            }
          }
          var I = U(a, c, e),
            x = a.ownerDocument,
            q = x.createElement('div');
          q.setAttribute(
            'style',
            'font-family: ' +
              (e.classic ? 'Arial' : 'system-ui, Arial') +
              '; font-size: ' +
              (e.classic ? 'small' : '12px') +
              '; overflow: auto'
          );
          o = x.createElement('div');
          typeof e.containerClass !== d && o.setAttribute('class', e.containerClass);
          typeof e.containerStyle !== d && o.setAttribute('style', e.containerStyle);
          q.appendChild(o);
          m = x.createElement('div');
          m.setAttribute(
            'style',
            'border: solid 1px ' +
              e.border +
              '; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; overflow: auto'
          );
          o.appendChild(m);
          var G = x.createElement('div');
          G.setAttribute(
            'style',
            'overflow: auto; float: left; border-right: solid 1px ' +
              e.border +
              '; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; width: ' +
              e.gridWidth
          );
          e.isGridVisible && m.appendChild(G);
          var h = x.createElement('div');
          h.setAttribute('style', 'overflow: auto; float: right; width: ' + e.chartWidth);
          m.appendChild(h);
          var t = x.createElement('div');
          t.setAttribute(
            'style',
            'position: relative; opacity: 0; left: 0px; width: ' +
              e.splitterWidth * 2 +
              'px; height: 0px; border-left: solid ' +
              e.splitterWidth +
              'px ' +
              e.splitterBackground +
              '; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; cursor: ew-resize; -ms-touch-action: pinch-zoom; touch-action: auto'
          );
          t.addEventListener(
            'mouseover',
            r(
              a,
              t,
              'mouseover',
              function () {
                t.style.opacity = 1;
                t.isWaiting !== d && delete t.isWaiting;
              },
              true
            ),
            true
          );
          t.addEventListener(
            'mouseout',
            r(
              a,
              t,
              'mouseout',
              function () {
                if (typeof t.isWaiting === d) {
                  t.isWaiting = true;
                  setTimeout(function () {
                    if (typeof t.isWaiting !== d) {
                      delete t.isWaiting;
                      if (typeof t.x === d) t.style.opacity = 0;
                    }
                  }, 250);
                }
              },
              true
            ),
            true
          );
          t.addEventListener(
            'mousedown',
            r(
              a,
              t,
              'mousedown',
              function (a) {
                if (a.button == 0) {
                  t.style.opacity = 1;
                  t.x = a.clientX;
                  t.gridWidth = G.offsetWidth;
                }
              },
              true
            ),
            true
          );
          t.addEventListener(
            'touchstart',
            r(
              a,
              t,
              'touchstart',
              function (a) {
                a = a.touches[0];
                t.style.opacity = 1;
                t.x = a.clientX;
                t.gridWidth = G.offsetWidth;
              },
              true
            ),
            true
          );
          x.addEventListener(
            'mousemove',
            r(
              a,
              x,
              'mousemove',
              function (a) {
                f(a);
              },
              true
            ),
            true
          );
          x.addEventListener(
            'touchmove',
            r(
              a,
              x,
              'touchmove',
              function (a) {
                f(a.touches[0]);
              },
              true
            ),
            true
          );
          x.addEventListener(
            'mouseup',
            r(
              a,
              x,
              'mouseup',
              function () {
                if (typeof t.x !== d) {
                  delete t.x;
                  t.style.opacity = 0;
                }
                if (DlhSoft.Controls.ToolTip && (e.useUpdatingToolTips || e.useInlineToolTips)) {
                  if ((toolTip = a.toolTip)) {
                    toolTip.disable();
                    toolTip.hide();
                  }
                  if ((toolTip = a.resourceRoolTip)) {
                    toolTip.disable();
                    toolTip.hide();
                  }
                }
              },
              true
            ),
            true
          );
          x.addEventListener(
            'touchend',
            r(
              a,
              x,
              'touchend',
              function () {
                if (typeof t.x !== d) {
                  delete t.x;
                  t.style.opacity = 0;
                }
              },
              true
            ),
            true
          );
          e.isGridVisible && e.isSplitterEnabled && m.appendChild(t);
          o = x.createElement('div');
          o.setAttribute(
            'style',
            'float: right; background: ' +
              e.headerBackground +
              '; width: 0px; height: ' +
              e.headerHeight +
              'px'
          );
          G.appendChild(o);
          m = x.createElement('div');
          m.setAttribute(
            'style',
            'overflow: hidden; background: ' +
              e.headerBackground +
              '; border-bottom: solid 1px ' +
              e.border +
              '; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; height: ' +
              e.headerHeight +
              'px'
          );
          G.appendChild(m);
          n = x.createElement('div');
          n.setAttribute(
            'style',
            'float: right; background: ' +
              e.headerBackground +
              '; width: 0px; height: ' +
              e.headerHeight +
              'px'
          );
          h.appendChild(n);
          l = x.createElement('div');
          l.setAttribute(
            'style',
            'overflow: hidden; background: ' +
              e.headerBackground +
              '; border-bottom: solid 1px ' +
              e.border +
              '; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; height: ' +
              e.headerHeight +
              'px'
          );
          h.appendChild(l);
          var j = x.createElement('div');
          j.setAttribute(
            'style',
            'overflow-x: ' +
              (typeof e.isExport === d || !e.isExport ? 'scroll' : 'hidden') +
              '; overflow-y: ' +
              (typeof e.isExport === d || !e.isExport ? 'auto' : 'hidden') +
              '; height: ' +
              I
          );
          G.appendChild(j);
          var E = x.createElement('div');
          E.setAttribute(
            'style',
            'overflow-x: ' +
              (typeof e.isExport === d || !e.isExport ? 'scroll' : 'hidden') +
              '; overflow-y: ' +
              (typeof e.isExport === d || !e.isExport ? 'auto' : 'hidden') +
              '; height: ' +
              I
          );
          h.appendChild(E);
          var I = pb(b),
            k = x.createElement('div');
          k.setAttribute(
            'style',
            'background: ' +
              e.headerBackground +
              '; border-bottom: solid 1px ' +
              e.border +
              '; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; width: ' +
              I +
              'px; overflow: hidden; height: ' +
              e.headerHeight +
              'px'
          );
          m.appendChild(k);
          var p = x.createElement('div');
          p.setAttribute(
            'style',
            'float: left; width: ' +
              I +
              'px; overflow: auto' +
              (e.horizontalGridLines
                ? '; border-bottom: 1px solid ' +
                  e.horizontalGridLines +
                  '; margin-bottom: -1px; ; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box'
                : '')
          );
          j.appendChild(p);
          if (typeof e.extraSpaceHeight !== d) {
            var Ma = x.createElement('div');
            Ma.setAttribute(
              'style',
              'overflow: hidden; width: ' + I + 'px; height: ' + e.extraSpaceHeight + 'px'
            );
            j.appendChild(Ma);
          }
          var Q = F(e.timelineFinish, e),
            s = x.createElement('div');
          s.setAttribute(
            'style',
            'background: ' +
              e.headerBackground +
              '; border-bottom: solid 1px ' +
              e.border +
              '; -wekbit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; width: ' +
              Q +
              'px; height: ' +
              e.headerHeight +
              'px'
          );
          var y, v;
          if (e.extendTimelineToEntireWeeks) l.appendChild(s);
          else {
            I = x.createElement('div');
            l.appendChild(I);
            y = {
              workingWeekStart: e.visibleWeekStart,
              workingWeekFinish: e.visibleWeekFinish,
              workingDayStart: e.visibleDayStart,
              workingDayFinish: e.visibleDayFinish,
            };
            v = (M(e.timelineStart, e.intendedTimelineStart, e, y) / 3600000) * e.hourWidth;
            y = v + (M(e.intendedTimelineStart, e.intendedTimelineFinish, e, y) / 3600000) * e.hourWidth;
            I.setAttribute(
              'style',
              'overflow-x: hidden; width: ' + y + 'px; transform: translateX(-' + v + 'px);'
            );
            I.appendChild(s);
          }
          var u = x.createElement('div');
          u.setAttribute('style', 'float: left; overflow: hidden; width: ' + Q + 'px; height: 0px');
          if (e.extendTimelineToEntireWeeks) E.appendChild(u);
          else {
            I = x.createElement('div');
            E.appendChild(I);
            I.setAttribute(
              'style',
              'overflow-x: hidden; width: ' +
                y +
                'px; transform: translateX(-' +
                v +
                'px); clip-path: xywh(0 0 ' +
                y +
                'px 100%);'
            );
            I.appendChild(u);
          }
          var w = x.createElementNS('http://www.w3.org/2000/svg', 'svg');
          w.setAttribute('style', 'width: ' + Q + 'px; height: 0px');
          v = e.styleDefinitionTemplate(a);
          w.appendChild(v);
          w.chartAreaDefinitions = v;
          v = x.createElementNS('http://www.w3.org/2000/svg', 'g');
          w.appendChild(v);
          u.chartAreaAlternativeRows = v;
          v = function () {
            setTimeout(function () {
              try {
                var a = u.chartArea,
                  c = a.chartAreaDefinitions;
                a.removeChild(c);
                a.childNodes.length > 0 ? a.insertBefore(c, a.childNodes[0]) : a.appendChild(c);
              } catch (b) {}
            }, 0);
          };
          w.addEventListener('mousedown', r(a, w, 'mousedown', v, true), true);
          x.addEventListener('mouseup', r(a, x, 'mouseup', v, true), true);
          a.resetChartAreaDefinitions = v;
          u.appendChild(w);
          u.chartArea = w;
          if (typeof e.extraSpaceHeight !== d) {
            v = x.createElement('div');
            v.setAttribute(
              'style',
              'overflow: hidden; width: ' + Q + 'px; height: ' + e.extraSpaceHeight + 'px'
            );
            E.appendChild(v);
            E.chartExtraSpace = v;
          }
          var D, z, B, C;
          setTimeout(function () {
            z = screen.deviceXDPI;
            D = a.isInitialized ? -1 : a.offsetWidth;
            B = a.isInitialized ? -1 : a.offsetHeight;
            C = h.offsetHeight;
          }, 0);
          typeof a.splitterUpdateTimer !== d && clearInterval(a.splitterUpdateTimer);
          a.splitterUpdateTimer = setInterval(function () {
            try {
              if (typeof t.x === d && !(a.offsetWidth <= 0)) {
                var c = a.offsetWidth,
                  b = h.offsetHeight,
                  f = screen.deviceXDPI;
                if (c != D || f != z) {
                  var g = typeof G.percent !== d ? G.percent * c : G.offsetWidth,
                    o = h.offsetWidth,
                    m = Math.max(1, e.minGridWidth),
                    n = Math.max(1, e.minChartWidth);
                  if (g < m) {
                    o = o - (m - g);
                    g = m;
                  } else if (o < n) {
                    g = g - (n - o);
                    o = n;
                  }
                  g < 1 && (g = 1);
                  o < 1 && (o = 1);
                  var l = Math.ceil((g * 100) / c);
                  G.percent = l / 100;
                  G.style.width = l + '%';
                  h.style.width = 100 - l + '%';
                  e.gridWidth = G.offsetWidth + 'px';
                  e.chartWidth = h.offsetWidth + 'px';
                  La(t, G, e);
                  if (typeof e.splitterPositionChangeHandler !== d) {
                    var q = G.offsetWidth,
                      x = h.offsetWidth;
                    q > 0 &&
                      x > 0 &&
                      setTimeout(function () {
                        e.splitterPositionChangeHandler(q, x);
                      }, 0);
                  }
                  D = c;
                  B = b;
                  z = f;
                } else if (b != C) {
                  La(t, G, e);
                  C = b;
                }
              }
            } catch (I) {
              try {
                clearInterval(a.splitterUpdateTimer);
              } catch (j) {}
            }
          }, 100);
          typeof a.heightUpdateTimer !== d && clearInterval(a.heightUpdateTimer);
          a.heightUpdateTimer = setInterval(function () {
            try {
              if (!(a.clientHeight <= 0)) {
                var b = a.clientHeight;
                if (b != B) {
                  var f = U(a, c, e);
                  j.style.height = f;
                  E.style.height = f;
                  La(t, G, e);
                  if (E.style.height != 'auto') u.availableHeight = E.clientHeight;
                  za(u, Aa(c, e));
                  Ba(c, E, e);
                  B = b = a.clientHeight;
                }
              }
            } catch (d) {
              try {
                clearInterval(a.heightUpdateTimer);
              } catch (g) {}
            }
          }, 100);
          typeof a.scaleUpdateTimer !== d && clearInterval(a.scaleUpdateTimer);
          a.chartScale = 1;
          a.scaleUpdateTimer = setInterval(function () {
            try {
              var c = a.getItemsHeight(),
                b = a.gridContent.clientHeight / c;
              a.chartScale = b;
              a.chartContent.style.transform =
                'scaleY(' + b + ') translateY(-' + (c - a.gridContent.clientHeight) / 2 + 'px)';
            } catch (e) {}
          }, 1000);
          var A = function (b, f, g, o, m, n) {
            var l;
            if (
              e.mouseHandler ||
              (e.mouseMoveHandler && n == 'mousemove') ||
              (e.mouseDownHandler && n == 'mousedown') ||
              ((e.clickHandler || e.itemClickHandler || e.chartClickHandler || e.chartItemClickHandler) &&
                n == 'click') ||
              ((e.doubleClickHandler ||
                e.itemDoubleClickHandler ||
                e.chartDoubleClickHandler ||
                e.chartItemDoubleClickHandler) &&
                n == 'dblclick')
            ) {
              var q = b.which;
              !q && b.button && (b.button & 1 ? (q = 1) : b.button & 4 ? (q = 2) : b.button & 2 && (q = 3));
              l = m.getBoundingClientRect();
              var t = x.body,
                I = x.documentElement,
                m =
                  l.left +
                  (window.pageXOffset || I.scrollLeft || t.scrollLeft) -
                  (I.clientLeft || t.clientLeft || 0);
              l = Math.round(
                l.top + (window.pageYOffset || I.scrollTop || t.scrollTop) - (I.clientTop || t.clientTop || 0)
              );
              var m = Math.round(m),
                m = b.pageX - m,
                I = (b.pageY - l) / a.chartScale,
                h = (l = 0),
                G,
                ga;
              if (g)
                for (t = 0; t < c.length; t++) {
                  h = c[t];
                  if (h.isVisible && !(typeof h.isHidden !== d && h.isHidden)) {
                    h = h.itemTop + e.itemHeight;
                    if (I <= h) {
                      G = c[t];
                      break;
                    }
                  }
                }
              else if (f) {
                for (var j = e.scales, k = 0, E, t = 0; t < j.length; t++) {
                  E = j[t];
                  (typeof E.isHeaderVisible === d || E.isHeaderVisible) && k++;
                }
                var Mb = e.headerHeight;
                k > 0 && (Mb = e.headerHeight / k);
                for (t = 0; t < j.length; t++) {
                  E = j[t];
                  if (typeof E.isHeaderVisible === d || E.isHeaderVisible) {
                    k = Mb;
                    if (typeof E.headerHeight !== d) k = E.headerHeight;
                    h = h + k;
                    if (I <= h) {
                      G = E;
                      break;
                    }
                  }
                }
              }
              if (f) ga = Na(m, e);
              else {
                I = e.columns;
                for (t = 0; t < I.length; t++) {
                  h = I[t];
                  l = l + (h.width ? h.width : 0);
                  if (m <= l) {
                    ga = h;
                    break;
                  }
                }
              }
              e.mouseHandler && e.mouseHandler(n, g, f, G, ga, q, o, b);
              e.mouseMoveHandler && n == 'mousemove' && e.mouseMoveHandler(g, f, G, ga);
              e.mouseDownHandler && n == 'mousedown' && e.mouseDownHandler(g, f, G, ga, q);
              e.clickHandler && n == 'click' && e.clickHandler(g, f, G, ga);
              e.doubleClickHandler && n == 'dblclick' && e.doubleClickHandler(g, f, G, ga);
              e.itemClickHandler && n == 'click' && g && G && e.itemClickHandler(f, G, ga);
              e.itemDoubleClickHandler && n == 'dblclick' && g && G && e.itemDoubleClickHandler(f, G, ga);
              e.chartClickHandler && n == 'click' && f && e.chartClickHandler(g, G, ga);
              e.chartDoubleClickHandler && n == 'dblclick' && f && e.chartDoubleClickHandler(g, G, ga);
              e.chartItemClickHandler && n == 'click' && g && f && e.chartItemClickHandler(G, ga);
              e.chartItemDoubleClickHandler &&
                n == 'dblclick' &&
                g &&
                f &&
                e.chartItemDoubleClickHandler(G, ga);
            }
          };
          k.addEventListener(
            'mousemove',
            r(a, k, 'mousemove', function (a) {
              A(a, false, false, 0, k, 'mousemove');
            })
          );
          k.addEventListener(
            'mousedown',
            r(a, k, 'mousedown', function (a) {
              A(a, false, false, 1, k, 'mousedown');
            })
          );
          k.addEventListener(
            'click',
            r(a, k, 'click', function (a) {
              A(a, false, false, 1, k, 'click');
            })
          );
          k.addEventListener(
            'dblclick',
            r(a, k, 'dblclick', function (a) {
              A(a, false, false, 2, k, 'dblclick');
            })
          );
          p.addEventListener(
            'mousemove',
            r(a, p, 'mousemove', function (a) {
              A(a, false, true, 0, p, 'mousemove');
            })
          );
          p.addEventListener(
            'mousedown',
            r(a, p, 'mousedown', function (a) {
              A(a, false, true, 1, p, 'mousedown');
            })
          );
          p.addEventListener(
            'click',
            r(a, p, 'click', function (a) {
              A(a, false, true, 1, p, 'click');
            })
          );
          p.addEventListener(
            'dblclick',
            r(a, p, 'dblclick', function (a) {
              A(a, false, true, 2, p, 'dblclick');
            })
          );
          s.addEventListener(
            'mousemove',
            r(a, s, 'mousemove', function (a) {
              A(a, true, false, 0, s, 'mousemove');
            })
          );
          s.addEventListener(
            'mousedown',
            r(a, s, 'mousedown', function (a) {
              A(a, true, false, 1, s, 'mousedown');
            })
          );
          s.addEventListener(
            'click',
            r(a, s, 'click', function (a) {
              A(a, true, false, 1, s, 'click');
            })
          );
          s.addEventListener(
            'dblclick',
            r(a, s, 'dblclick', function (a) {
              A(a, true, false, 2, s, 'dblclick');
            })
          );
          u.addEventListener(
            'mousemove',
            r(a, u, 'mousemove', function (a) {
              A(a, true, true, 0, u, 'mousemove');
            })
          );
          u.addEventListener(
            'mousedown',
            r(a, u, 'mousedown', function (a) {
              A(a, true, true, 1, u, 'mousedown');
            })
          );
          u.addEventListener(
            'click',
            r(a, u, 'click', function (a) {
              A(a, true, true, 1, u, 'click');
            })
          );
          u.addEventListener(
            'dblclick',
            r(a, u, 'dblclick', function (a) {
              A(a, true, true, 2, u, 'dblclick');
            })
          );
          u.addEventListener(
            'touchmove',
            r(
              a,
              u,
              'touchmove',
              function (c) {
                a.draggingItem && c.preventDefault();
              },
              true
            ),
            true
          );
          p.container = j;
          u.container = E;
          k.container = m;
          s.container = l;
          a.gridContainer = G;
          a.chartContainer = h;
          a.gridContent = p;
          a.chartContent = u;
          a.gridContentContainer = j;
          a.chartContentContainer = E;
          a.gridHeader = k;
          a.chartHeader = s;
          a.gridHeaderContainer = m;
          a.chartHeaderContainer = l;
          a.splitter = t;
          E.isInitializing = true;
          v = X(c, e);
          Mc(a, q);
          if (E.style.height != 'auto') u.availableHeight = E.clientHeight;
          Nc(k, b, e, a);
          qb(s, w, g, e);
          Oc(w, c, e);
          Pc(p, u, w, c, b, v, e, a);
          La(t, G, e);
          E.isInitializing = false;
          Ba(c, E, e);
          E.scrollLeft = F(e.displayedTime, e);
          if (l.scrollLeft != E.scrollLeft) l.scrollLeft = E.scrollLeft;
          Qc(a, j, G, m, o, p, E, l, h, n, u, t, c, e);
          var L = false,
            ta = function (a, b) {
              typeof b === d && (b = 0);
              if (e.hourWidth != a) {
                L = true;
                var f = E.scrollLeft,
                  g = b,
                  o = e.hourWidth;
                e.hourWidth = a;
                typeof e.hourWidthChangeHandler !== d &&
                  setTimeout(function () {
                    e.hourWidthChangeHandler(e.hourWidth);
                  }, 0);
                Q = F(e.timelineFinish, e);
                s.style.width = Q + 'px';
                u.style.width = Q + 'px';
                w.style.width = Q + 'px';
                if (typeof E.chartExtraSpace !== d) E.chartExtraSpace.style.width = Q + 'px';
                var m;
                for (m = s.childNodes.length; m-- > 0; ) s.removeChild(s.childNodes[m]);
                var n = [];
                for (m = w.childNodes.length; m-- > 1; ) {
                  var l = w.childNodes[m];
                  if (l != u.chartAreaAlternativeRows) {
                    l.tag != 'Scale-Highlighting' &&
                      l.tag != 'Scale-Highlighting-CurrentTime' &&
                      l.tag != 'Scale-Separator' &&
                      l.tag != 'Scale-Separator-CurrentTime' &&
                      n.push(l);
                    w.removeChild(l);
                  }
                }
                qb(s, w, e.scales, e);
                for (m = 0; m < c.length; m++) {
                  l = c[m];
                  if (!e.isVirtualizing || (typeof l.isVirtuallyVisible !== d && l.isVirtuallyVisible)) {
                    ia(l.chartPredecessorItems, l, e);
                    ja(l.chartItem, l, e);
                  }
                }
                for (m = n.length; m-- > 0; ) w.appendChild(n[m]);
                za(u, Aa(c, e));
                E.scrollLeft = g * (a / o) - (g - f);
                setTimeout(function () {
                  L = false;
                }, 200);
              }
            };
          a.setHourWidth = ta;
          if (
            e.classic
              ? typeof e.isMouseWheelZoomEnabled === d || e.isMouseWheelZoomEnabled
              : e.isMouseWheelZoomEnabled
          ) {
            b = function (c) {
              c.preventDefault();
              c.stopPropagation();
              if (!L) {
                var b =
                    typeof c.wheelDelta !== d
                      ? c.wheelDelta >= 0
                        ? 1
                        : -1
                      : typeof c.detail !== d
                      ? -c.detail >= 0
                        ? 1
                        : -1
                      : 0,
                  e = a.settings,
                  f = e.hourWidth,
                  g =
                    typeof e.isMouseWheelZoomEnabledMinHourWidth !== d
                      ? e.isMouseWheelZoomEnabledMinHourWidth
                      : 1;
                if (b < 0) f = f / (1.2 * -b);
                else if (b > 0) {
                  f = f * 1.2 * b;
                  b =
                    typeof e.isMouseWheelZoomEnabledMaxHourWidth !== d
                      ? e.isMouseWheelZoomEnabledMaxHourWidth
                      : 20;
                  f > b && (f = b);
                }
                f < g && (f = g);
                ta(f, c.offsetX);
              }
            };
            w.addEventListener('mousewheel', r(a, w, 'mousewheel', b));
            w.addEventListener('DOMMouseScroll', r(a, w, 'DOMMouseScroll', b));
          }
          a.isInitializing = false;
          a.isInitialized = true;
          return a;
        }
      },
      K = function (a) {
        P(a, a.items, a.settings, a.license);
      },
      Y = function (a) {
        if (typeof a.dateTimePickerType === d) a.dateTimePickerType = 'text';
        if (typeof a.useDatePicker === d) a.useDatePicker = true;
        if (typeof a.useTimePicker === d) a.useTimePicker = true;
        if (typeof a.useResourceSelector === d) a.useResourceSelector = true;
        if (typeof a.useInlineToolTips === d) a.useInlineToolTips = true;
        if (typeof a.useInlineToolTipsForDependencies === d) a.useInlineToolTipsForDependencies = true;
        if (typeof a.useUpdatingToolTips === d) a.useUpdatingToolTips = true;
        if (typeof a.dateFormatter === d) a.dateFormatter = fa;
        if (typeof a.dateTimeFormatter === d) a.dateTimeFormatter = na;
        if (typeof a.dateTimeParser === d) a.dateTimeParser = s;
        if (typeof a.itemPropertyChangeHandler === d) a.itemPropertyChangeHandler = function () {};
        if (typeof a.target === d) a.target = 'Standard';
        if (typeof a.theme === d) a.theme = 'Modern';
        if (typeof a.isGridVisible === d)
          switch (a.target) {
            default:
              a.isGridVisible = true;
              break;
            case 'Phone':
              a.isGridVisible = false;
          }
        if (typeof a.interaction === d) a.interaction = a.target != 'Phone' ? 'Standard' : 'TouchEnabled';
        if (typeof a.isSplitterEnabled === d) a.isSplitterEnabled = true;
        if (typeof a.isReadOnly === d) a.isReadOnly = false;
        if (typeof a.isGridReadOnly === d) a.isGridReadOnly = false;
        if (typeof a.isChartReadOnly === d) a.isChartReadOnly = false;
        if (typeof a.isContentReadOnly === d) a.isContentReadOnly = false;
        if (typeof a.isAssignmentsContentReadOnly === d) a.isAssignmentsContentReadOnly = false;
        if (typeof a.isIndividualItemNonworkingTimeHighlighted === d)
          a.isIndividualItemNonworkingTimeHighlighted = false;
        if (typeof a.areTaskInterruptionsHighlighted === d) a.areTaskInterruptionsHighlighted = false;
        if (typeof a.areTaskDependencyConstraintsEnabled === d) a.areTaskDependencyConstraintsEnabled = false;
        if (typeof a.alwaysHandleInvalidDependencies === d) a.alwaysHandleInvalidDependencies = false;
        if (typeof a.selectionMode === d) a.selectionMode = 'Focus';
        if (typeof a.isVirtualizing === d) a.isVirtualizing = true;
      },
      H = function (a, c, e) {
        Y(a);
        if (typeof a.gridWidth === d) a.gridWidth = '35%';
        if (typeof a.chartWidth === d) a.chartWidth = a.isGridVisible ? '65%' : '100%';
        if (typeof a.minGridWidth === d) a.minGridWidth = 32;
        if (typeof a.minColumnWidth === d) a.minColumnWidth = 32;
        if (typeof a.minChartWidth === d) a.minChartWidth = 36;
        if (typeof a.border === d)
          switch (a.theme) {
            default:
              a.border = '#e0e0e0';
              break;
            case 'ModernBordered':
            case 'Aero':
              a.border = '#9a9a9a';
          }
        if (typeof a.splitterWidth === d) a.splitterWidth = 4;
        if (typeof a.splitterBackground === d) a.splitterBackground = a.border;
        if (typeof a.indentationLevelWidth === d) a.indentationLevelWidth = 16;
        if (typeof a.itemHeight === d) a.itemHeight = a.classic ? 21 : 28;
        if (typeof a.headerBackground === d) a.headerBackground = a.classic ? '#f4f4f4' : 'white';
        if (typeof a.headerHeight === d) a.headerHeight = (a.classic ? 21 : 31) * 2;
        if (typeof a.columns === d) a.columns = k(c, a);
        if (typeof a.toggleButtonStyle === d && a.toggleButtonClass == null)
          a.toggleButtonStyle = 'fill: Gray';
        if (typeof a.toggleButtonHoveringStyle === d && a.toggleButtonHoveringClass == null)
          a.toggleButtonHoveringStyle = 'fill: Black';
        if (typeof a.collapsedToggleButtonTemplate === d) a.collapsedToggleButtonTemplate = Nb(e, a);
        if (typeof a.expandedToggleButtonTemplate === d) a.expandedToggleButtonTemplate = Ob(e, a);
        if (typeof a.gridLines !== d) {
          if (typeof a.horizontalGridLines === d) a.horizontalGridLines = a.gridLines;
          if (typeof a.verticalGridLines === d) a.verticalGridLines = a.gridLines;
          if (typeof a.horizontalChartLines === d) a.horizontalChartLines = a.gridLines;
        }
        if (!a.classic && typeof a.verticalGridHeaderLines === d)
          a.verticalGridHeaderLines = a.verticalGridLines ? a.verticalGridLines : '#e0e0e0';
        if (typeof a.itemStyle === d && typeof a.horizontalGridLines !== d) {
          a.itemStyle =
            'border-top: solid 1px ' +
            a.horizontalGridLines +
            '; margin-top: -1px; -wekbit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box';
          if (typeof a.cellStyle === d)
            a.cellStyle =
              typeof a.verticalGridLines !== d
                ? 'border-right: solid 1px ' +
                  a.verticalGridLines +
                  '; height: ' +
                  a.itemHeight +
                  'px; padding-top: 2px; padding-left: 2px'
                : 'height: ' + a.itemHeight + 'px; padding-top: 2px';
        }
        if (typeof a.cellStyle === d && typeof a.verticalGridLines !== d)
          a.cellStyle =
            'border-right: solid 1px ' +
            a.verticalGridLines +
            '; height: ' +
            a.itemHeight +
            'px; padding-top: 3px; padding-left: 2px';
        if (!a.classic && typeof a.cellStyle === d) {
          a.cellStyle = 'padding-left: 2px;';
          if (typeof a.columnHeaderStyle === d) a.columnHeaderStyle = 'padding-left: 2px';
        }
        if (typeof a.selectedItemStyle === d && a.selectedItemClass == null)
          if (a.classic)
            switch (a.theme) {
              default:
                a.selectedItemStyle =
                  typeof a.horizontalGridLines !== d || typeof a.verticalGridLines === d
                    ? 'background-color: #f4f4f4; border-top: solid 1px ' +
                      (typeof a.horizontalGridLines !== d ? a.horizontalGridLines : 'White') +
                      '; margin-top: -1px; -wekbit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box'
                    : 'background-color: #f4f4f4';
                break;
              case 'Aero':
                a.selectedItemStyle =
                  typeof a.horizontalGridLines !== d || typeof a.verticalGridLines === d
                    ? 'background-color: LightBlue; border-top: solid 1px ' +
                      (typeof a.horizontalGridLines !== d ? a.horizontalGridLines : 'White') +
                      '; margin-top: -1px; -wekbit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box'
                    : 'background-color: LightBlue';
            }
          else a.selectedItemStyle = 'background-color: #8dd2ff60;';
        if (typeof a.summaryItemStyle === d && a.summaryItemClass == null)
          a.summaryItemStyle = 'font-weight: bold';
        if (typeof a.daysOfWeek === d) a.daysOfWeek = O;
        if (typeof a.months === d) a.months = V;
        if (typeof a.weekStartDay === d) a.weekStartDay = 0;
        if (typeof a.currentTime === d) a.currentTime = new Date();
        else if (typeof a.currentTime === 'string')
          try {
            a.currentTime = new Date(a.currentTime);
          } catch (b) {
            a.currentTime = new Date(parseInt(a.currentTime));
          }
        if (typeof a.displayedTime === 'string')
          try {
            a.displayedTime = new Date(a.displayedTime);
          } catch (f) {
            a.displayedTime = new Date(parseInt(a.displayedTime));
          }
        if (typeof a.timelineStart === 'string')
          try {
            a.timelineStart = new Date(a.timelineStart);
          } catch (g) {
            a.timelineStart = new Date(parseInt(a.timelineStart));
          }
        if (typeof a.timelineFinish === 'string')
          try {
            a.timelineFinish = new Date(a.timelineFinish);
          } catch (q) {
            a.timelineFinish = new Date(parseInt(a.timelineFinish));
          }
        if (!a.classic && typeof a.scales === d)
          a.scales = [
            {
              scaleType: 'NonworkingTime',
              isHeaderVisible: false,
              isHighlightingVisible: true,
              highlightingStyle:
                'stroke-width: 0; fill: ' +
                (a.nonworkingBackground ? a.nonworkingBackground : '#f8f8f8') +
                '; fill-opacity: 0.65',
            },
            {
              scaleType: 'Weeks',
              headerTextFormat: 'Date',
              headerStyle:
                'padding: 7px 5px; border-right: 1px solid ' +
                (a.headerBorder ? a.headerBorder : '#e8e8e8') +
                '; border-bottom: 1px solid ' +
                (a.headerBorder ? a.headerBorder : '#e8e8e8') +
                (a.headerForeground ? '; color: ' + a.headerForeground : ''),
              isSeparatorVisible: true,
              separatorStyle:
                'stroke: ' +
                (a.scaleSeparatorBorder ? a.scaleSeparatorBorder : '#c8bfe7') +
                '; stroke-width: 0.5px',
            },
            {
              scaleType: 'Days',
              headerTextFormat: 'DayOfWeekAbbreviation',
              headerStyle:
                'padding: 7px 5px; border-right: 1px solid ' +
                (a.headerBorder ? a.headerBorder : '#e8e8e8') +
                (a.headerForeground ? '; color: ' + a.headerForeground : ''),
            },
            {
              scaleType: 'CurrentTime',
              isHeaderVisible: false,
              isSeparatorVisible: true,
              separatorStyle:
                'stroke: ' +
                (a.currentTimeStroke ? a.currentTimeStroke : '#e31d3b') +
                '; stroke-width: 0.5px',
            },
          ];
        if (!a.classic) {
          if (typeof a.alternativeItemStyle === d) a.alternativeItemStyle = 'background-color: #8080800c;';
          if (typeof a.alternativeChartItemStyle === d) a.alternativeChartItemStyle = 'fill: #8080800c;';
        }
        if (!a.classic && typeof a.arrowSize === d) a.arrowSize = 1.5;
        if (typeof a.isRelativeToTimezone === d || a.isRelativeToTimezone) {
          a.currentTime = new Date(a.currentTime.valueOf() - a.currentTime.getTimezoneOffset() * 60000);
          if (typeof a.displayedTime !== d)
            a.displayedTime = new Date(
              a.displayedTime.valueOf() - a.displayedTime.getTimezoneOffset() * 60000
            );
          if (typeof a.timelineStart !== d)
            a.timelineStart = new Date(
              a.timelineStart.valueOf() - a.timelineStart.getTimezoneOffset() * 60000
            );
          if (typeof a.timelineFinish !== d)
            a.timelineFinish = new Date(
              a.timelineFinish.valueOf() - a.timelineFinish.getTimezoneOffset() * 60000
            );
          if (typeof a.scales !== d)
            for (c = 0; c < a.scales.length; c++) {
              var o = a.scales[c];
              if (typeof o.intervals !== d)
                for (var m = 0; m < o.intervals.length; m++) {
                  var n = o.intervals[m];
                  if (typeof n.start !== d)
                    n.start = new Date(n.start.valueOf() - n.start.getTimezoneOffset() * 60000);
                  if (typeof n.finish !== d)
                    n.finish = new Date(n.finish.valueOf() - n.finish.getTimezoneOffset() * 60000);
                }
            }
          a.isRelativeToTimezone = false;
        }
        if (typeof a.schedule !== d && a.schedule != null) {
          if (
            a.schedule != a.previousSchedule ||
            a.schedule.workingWeekStart != a.previousSchedule.workingWeekStart
          )
            a.workingWeekStart = a.schedule.workingWeekStart;
          if (
            a.schedule != a.previousSchedule ||
            a.schedule.workingWeekFinish != a.previousSchedule.workingWeekFinish
          )
            a.workingWeekFinish = a.schedule.workingWeekFinish;
          if (
            a.schedule != a.previousSchedule ||
            a.schedule.workingDayStart != a.previousSchedule.workingDayStart
          )
            a.visibleDayStart = a.schedule.workingDayStart;
          if (
            a.schedule != a.previousSchedule ||
            a.schedule.workingDayFinish != a.previousSchedule.workingDayFinish
          )
            a.visibleDayFinish = a.schedule.workingDayFinish;
          if (
            a.schedule != a.previousSchedule ||
            a.schedule.specialNonworkingDays != a.previousSchedule.specialNonworkingDays
          )
            a.specialNonworkingDays = a.schedule.specialNonworkingDays;
        }
        if (typeof a.extendTimelineToEntireWeeks === d) a.extendTimelineToEntireWeeks = true;
        a.intendedTimelineStart = a.timelineStart;
        a.intendedTimelineFinish = a.timelineFinish;
        a.timelineStart =
          typeof a.timelineStart === d
            ? new Date(Ca(a.currentTime, a.weekStartDay).valueOf() - 604800000)
            : Ca(a.timelineStart, a.weekStartDay);
        a.timelineFinish =
          typeof a.timelineFinish === d
            ? new Date(Ca(a.currentTime, a.weekStartDay).valueOf() + 32054400000)
            : rb(a.timelineFinish, a.weekStartDay);
        if (typeof a.intendedTimelineStart === d) a.intendedTimelineStart = a.timelineStart;
        if (typeof a.intendedTimelineFinish === d) a.intendedTimelineFinish = a.timelineFinish;
        if (
          !a.isExport &&
          typeof a.specialNonworkingDays !== d &&
          typeof a.specialNonworkingDays !== 'function'
        )
          for (c = 0; c < a.specialNonworkingDays.length; c++)
            a.specialNonworkingDays[c] = Z(
              new Date(
                a.specialNonworkingDays[c].valueOf() -
                  (a.specialNonworkingDays[c].getTimezoneOffset() < 0
                    ? a.specialNonworkingDays[c].getTimezoneOffset()
                    : 0) *
                    60000
              )
            );
        if (!a.isExport && typeof a.resourceSchedules !== d)
          for (o = 0; o < a.resourceSchedules.length; o++) {
            m = a.resourceSchedules[o].value;
            if (typeof m.specialNonworkingDays !== d)
              for (c = 0; c < m.specialNonworkingDays.length; c++)
                m.specialNonworkingDays[c] = Z(
                  new Date(
                    m.specialNonworkingDays[c].valueOf() -
                      (m.specialNonworkingDays[c].getTimezoneOffset() < 0
                        ? m.specialNonworkingDays[c].getTimezoneOffset()
                        : 0) *
                        60000
                  )
                );
          }
        if (typeof a.scales === d) a.scales = Pb(a);
        if (typeof a.updateScale === d) a.updateScale = 900000;
        if (typeof a.hourWidth === d) a.hourWidth = a.classic ? 2.5 : 5;
        if (typeof a.displayedTime === d) {
          a.displayedTime = a.currentTime;
          setTimeout(function () {
            var c = e.chartContentContainer.scrollLeft - a.hourWidth * 12;
            if (e.chartContentContainer.scrollLeft - c < e.chartContentContainer.clientWidth / 3)
              e.chartContentContainer.scrollLeft = Math.max(0, c);
          }, 0);
        }
        a.previousSchedule = a.schedule;
        if (typeof a.visibleDayStart === d) a.visibleDayStart = 28800000;
        if (typeof a.visibleDayFinish === d) a.visibleDayFinish = 57600000;
        if (a.visibleDayStart >= a.visibleDayFinish) {
          a.visibleWeekStart = 0;
          a.visibleWeekFinish = 86400000;
        }
        if (typeof a.visibleWeekStart === d) a.visibleWeekStart = 0;
        if (typeof a.visibleWeekFinish === d) a.visibleWeekFinish = 6;
        if (a.visibleWeekStart > a.visibleWeekFinish)
          if (a.visibleWeekStart == a.visibleWeekFinish + 1) {
            a.visibleWeekStart = 0;
            a.visibleWeekFinish = 6;
          } else a.visibleWeekFinish = a.visibleWeekStart;
        if (typeof a.workingWeekStart === d) a.workingWeekStart = 1;
        if (typeof a.workingWeekFinish === d) a.workingWeekFinish = 5;
        if (a.workingWeekStart > a.workingWeekFinish)
          if (a.workingWeekStart == a.workingWeekFinish + 1) {
            a.workingWeekStart = 0;
            a.workingWeekFinish = 6;
          } else a.workingWeekFinish = a.workingWeekStart;
        a.workingDayStart = a.visibleDayStart;
        a.workingDayFinish = a.visibleDayFinish;
        if (!a.classic && typeof a.barMargin === d) a.barMargin = 4;
        if (typeof a.barHeight === d)
          a.barHeight = typeof a.barMargin === d ? a.itemHeight / 2 : a.itemHeight - 2 * a.barMargin;
        if (typeof a.barMargin === d) a.barMargin = (a.itemHeight - a.barHeight) / 2;
        if (typeof a.barCornerRadius === d)
          if (a.classic)
            switch (a.theme) {
              default:
                a.barCornerRadius = 0;
                break;
              case 'Aero':
                a.barCornerRadius = 3;
            }
          else a.barCornerRadius = 2;
        if (!a.classic && typeof a.completedBarMargin === d) a.completedBarMargin = 1;
        if (typeof a.completedBarMargin === d) a.completedBarMargin = a.barHeight / 2.5;
        if (typeof a.completedBarHeight === d) a.completedBarHeight = a.barHeight - a.completedBarMargin * 2;
        if (typeof a.completedBarCornerRadius === d) a.completedBarCornerRadius = 0;
        a.completedBarCornerRadius = Math.min(a.completedBarCornerRadius, Math.max(0, a.barCornerRadius - 1));
        if (typeof a.styleDefinitionTemplate === d) a.styleDefinitionTemplate = Qb(e, a);
        if (typeof a.standardBarStyle === d && a.standardBarClass == null)
          if (a.classic)
            switch (a.theme) {
              default:
                a.standardBarStyle =
                  'fill: #8abbed; fill-opacity: 0.8; stroke: #8abbed; stroke-width: 0.65px';
                break;
              case 'ModernBordered':
                a.standardBarStyle = 'fill: #8abbed; fill-opacity: 0.8; stroke: Blue; stroke-width: 0.65px';
                break;
              case 'Aero':
                a.standardBarStyle = 'fill: url(#BlueGradient); fill-opacity: 0.8; stroke: Blue';
            }
          else
            a.standardBarStyle = 'fill: #8dd2ff; fill-opacity: 0.8; stroke: #3b87d9; stroke-width: 0.65px;';
        if (typeof a.standardCompletedBarStyle === d && a.standardCompletedBarClass == null)
          if (a.classic)
            switch (a.theme) {
              default:
                a.standardCompletedBarStyle = 'fill: #3b87d9; stroke: #3b87d9; stroke-width: 0.65px';
                break;
              case 'Aero':
                a.standardCompletedBarStyle = 'fill: #808080; stroke: #202020; stroke-width: 0.65px';
            }
          else a.standardCompletedBarStyle = 'fill: #1ca3ec; stroke: #1ca3ec; stroke-width: 0.5px;';
        if (typeof a.summaryBarStyle === d && a.summaryBarClass == null)
          switch (a.theme) {
            default:
              a.summaryBarStyle = 'fill: #607080; stroke: #607080; stroke-width: 0.65px';
              break;
            case 'ModernBordered':
              a.summaryBarStyle = 'fill: #607080; stroke: #202020; stroke-width: 0.65px';
              break;
            case 'Aero':
              a.summaryBarStyle = 'fill: url(#BlackGradient); stroke: Black';
          }
        if (typeof a.summaryCompletedBarStyle === d && a.summaryCompletedBarClass == null)
          switch (a.theme) {
            default:
              a.summaryCompletedBarStyle = 'fill: #404040; stroke: #404040; stroke-width: 0.65px';
              break;
            case 'Aero':
              a.summaryCompletedBarStyle = 'fill: #a0a0a0; stroke: #303030; stroke-width: 0.65px';
          }
        if (typeof a.collapsedSummaryLineStyle === d && a.collapsedSummaryLineClass == null)
          a.collapsedSummaryLineStyle = 'stroke: #3b87d9; stroke-width: 0.65px; stroke-dasharray: 2 2';
        if (typeof a.milestoneBarStyle === d && a.milestoneBarClass == null)
          if (a.classic)
            switch (a.theme) {
              default:
                a.milestoneBarStyle = 'fill: #607080; stroke: #607080; stroke-width: 0.65px';
                break;
              case 'ModernBordered':
                a.milestoneBarStyle = 'fill: #607080; stroke: #202020; stroke-width: 0.65px';
                break;
              case 'Aero':
                a.milestoneBarStyle = 'fill: url(#BlackGradient); stroke: Black';
            }
          else a.milestoneBarStyle = 'fill: #f8c758; stroke: #3b87d9; stroke-width: 0.65px;';
        if (typeof a.completedBarThumbStyle === d && a.completedBarThumbClass == null)
          a.completedBarThumbStyle =
            'fill: White; stroke: #a0a0a0; stroke-width: 1px; transform: translateY(3px);';
        if (typeof a.baselineBarStyle === d && a.baselineBarClass == null)
          switch (a.theme) {
            default:
              a.baselineBarStyle =
                'fill: none; stroke: #3b87d9; stroke-width: 0.65px; stroke-dasharray: 2, 2';
              break;
            case 'Aero':
              a.baselineBarStyle = 'fill: none; stroke: Blue; stroke-dasharray: 2, 2';
          }
        if (typeof a.dependencyPointerStyle === d && a.dependencyPointerClass == null)
          if (a.classic)
            switch (a.theme) {
              default:
                a.dependencyPointerStyle = 'fill: #3b87d9; stroke: #3b87d9; stroke-width: 0.65px';
                break;
              case 'Aero':
                a.dependencyPointerStyle = 'fill: Blue; stroke: Blue';
            }
          else a.dependencyPointerStyle = 'fill: White; stroke: #a0a0a0; stroke-width: 1px;';
        if (typeof a.dependencyLineStyle === d && a.dependencyLineClass == null)
          if (a.classic)
            switch (a.theme) {
              default:
                a.dependencyLineStyle =
                  'stroke: #3b87d9; stroke-width: 0.65px; fill: none; marker-end: url(#ArrowMarker)';
                break;
              case 'Aero':
                a.dependencyLineStyle =
                  'stroke: Blue; stroke-width: 0.65px; fill: none; marker-end: url(#ArrowMarker)';
            }
          else
            a.dependencyLineStyle =
              'stroke: #3b87d9; stroke-width: 0.65px; fill: none; marker-end: url(#ArrowMarker);';
        if (typeof a.dependencyLineZoneStyle === d && a.dependencyLineZoneClass == null)
          a.dependencyLineZoneStyle = 'stroke: White; stroke-opacity: 0; stroke-width: 4px; fill: none';
        if (typeof a.temporaryDependencyLineStyle === d && a.temporaryDependencyLineClass == null)
          switch (a.theme) {
            default:
              a.temporaryDependencyLineStyle =
                'stroke: #3b87d9; stroke-width: 0.65px; stroke-dasharray: 2, 2; fill: none; marker-end: url(#ArrowMarker)';
              break;
            case 'Aero':
              a.temporaryDependencyLineStyle =
                'stroke: Blue; stroke-width: 0.65px; stroke-dasharray: 2, 2; fill: none; marker-end: url(#ArrowMarker)';
          }
        if (typeof a.assignmentsStyle === d && a.assignmentsClass == null)
          a.assignmentsStyle = 'stroke-width: 0.25px; font-size: ' + (a.classic ? 'x-small' : '12px');
        if (!a.classic)
          a.assignmentsStyle = a.assignmentsStyle + '; transform: translateX(10px) translateY(-1px);';
        if (typeof a.standardTaskTemplate === d) a.standardTaskTemplate = Rb();
        if (typeof a.summaryTaskTemplate === d) a.summaryTaskTemplate = Sb();
        if (typeof a.milestoneTaskTemplate === d) a.milestoneTaskTemplate = Tb();
        if (typeof a.horizontalChartLines !== d) {
          var l = a.internalPreTaskTemplate;
          a.internalPreTaskTemplate = function (c) {
            if (typeof c.scheduleChartItem !== d) return typeof l !== d ? l(c) : null;
            var b = c.ganttChartView.ownerDocument,
              e = b.createElementNS('http://www.w3.org/2000/svg', 'g');
            typeof l !== d && e.appendChild(l(c));
            var f = b.createElementNS('http://www.w3.org/2000/svg', 'line');
            f.setAttribute('x1', 0);
            f.setAttribute('y1', -0.5);
            f.setAttribute('x2', F(a.timelineFinish, a));
            f.setAttribute('y2', -0.5);
            f.setAttribute('style', 'stroke: ' + a.horizontalChartLines + '; stroke-width: 0.5px');
            e.appendChild(f);
            if (c.index == c.ganttChartView.items.length - 1) {
              f = b.createElementNS('http://www.w3.org/2000/svg', 'line');
              f.setAttribute('x1', 0);
              f.setAttribute('y1', a.itemHeight);
              f.setAttribute('x2', F(a.timelineFinish, a));
              f.setAttribute('y2', a.itemHeight);
              f.setAttribute('style', 'stroke: ' + a.horizontalChartLines + '; stroke-width: 0.5px');
              e.appendChild(f);
            }
            return e;
          };
        }
        if (typeof a.isTaskToolTipVisible === d)
          switch (a.target) {
            default:
              a.isTaskToolTipVisible = true;
              break;
            case 'Phone':
              a.isTaskToolTipVisible = false;
          }
        if (typeof a.itemTemplate === d) a.itemTemplate = Ub(a);
        if (typeof a.areTaskAssignmentsVisible === d) a.areTaskAssignmentsVisible = true;
        if (typeof a.assignmentsTemplate === d) a.assignmentsTemplate = Vb();
        if (typeof a.isTaskCompletedEffortVisible === d) a.isTaskCompletedEffortVisible = true;
        if (typeof a.areTaskDependenciesVisible === d) a.areTaskDependenciesVisible = true;
        if (typeof a.dependencyLineTemplate === d) a.dependencyLineTemplate = Wb();
        if (typeof a.isDependencyToolTipVisible === d) a.isDependencyToolTipVisible = a.isTaskToolTipVisible;
        if (typeof a.predecessorItemTemplate === d) a.predecessorItemTemplate = Xb(a);
        if (typeof a.isDraggingTaskStartEndsEnabled === d) a.isDraggingTaskStartEndsEnabled = true;
        if (typeof a.areTaskDependencyConstraintsEnabledWhileDragging === d)
          a.areTaskDependencyConstraintsEnabledWhileDragging = false;
        if (typeof a.isTaskStartReadOnly === d) a.isTaskStartReadOnly = false;
        if (typeof a.isTaskEffortReadOnly === d) a.isTaskEffortReadOnly = false;
        if (typeof a.isTaskCompletionReadOnly === d) a.isTaskCompletionReadOnly = false;
        if (typeof a.areTaskPredecessorsReadOnly === d) a.areTaskPredecessorsReadOnly = false;
        if (typeof a.isBaselineVisible === d) a.isBaselineVisible = true;
        if (typeof a.areStandardTaskLabelsVisible === void 0) a.areStandardTaskLabelsVisible = false;
        if (typeof a.areSummaryTaskLabelsVisible === void 0) a.areSummaryTaskLabelsVisible = false;
        if (typeof a.areMilestoneTaskLabelsVisible === void 0) a.areMilestoneTaskLabelsVisible = false;
        if (typeof a.isSummaryTaskCompletedEffortVisible === d) a.isSummaryTaskCompletedEffortVisible = false;
        var I = null;
        if (a.isIndividualItemNonworkingTimeHighlighted || a.areTaskInterruptionsHighlighted)
          for (c = 0; c < a.scales.length; c++)
            if (a.scales[c].scaleType == 'NonworkingTime') {
              I = a.scales[c];
              break;
            }
        if (I != null && a.isIndividualItemNonworkingTimeHighlighted) {
          var x = a.internalPreTaskTemplate;
          a.internalPreTaskTemplate = function (c) {
            if (typeof c.scheduleChartItem !== d && (c.content || !c.schedule))
              return typeof x !== d ? x(c) : null;
            var b;
            b = C(c);
            if (typeof b === d) b = null;
            else {
              var e = [],
                f,
                g;
              f = Oa(
                a.timelineStart,
                typeof b.workingWeekFinish !== d ? b.workingWeekFinish : a.workingWeekFinish
              );
              for (
                g = sb(f, typeof b.workingWeekStart !== d ? b.workingWeekStart : a.workingWeekStart);
                f < a.timelineFinish;
                f = ra(f), g = ra(g)
              ) {
                e.push({ start: f, finish: g });
                var o = ra(f),
                  m;
                for (dd = g; dd < o; dd = m) {
                  b.workingDayStart > a.visibleDayStart &&
                    e.push({ start: dd, finish: new Date(dd.valueOf() + b.workingDayStart) });
                  m = W(dd);
                  b.workingDayFinish < a.visibleDayFinish &&
                    e.push({ start: new Date(dd.valueOf() + b.workingDayFinish), finish: m });
                }
              }
              if (typeof b.specialNonworkingDays !== d)
                if (typeof b.specialNonworkingDays === 'function') {
                  f = Oa(a.timelineStart, a.workingWeekFinish);
                  for (g = W(f); f < a.timelineFinish; f = W(f), g = W(g))
                    b.specialNonworkingDays(f) && e.push({ start: f, finish: g });
                } else
                  for (o = 0; o < b.specialNonworkingDays.length; o++) {
                    f = b.specialNonworkingDays[o];
                    g = W(f);
                    e.push({ start: f, finish: g });
                  }
              b = e;
            }
            if (b == null || b.length == 0) return typeof x !== d ? x(c) : null;
            e = c.ganttChartView.ownerDocument;
            f = e.createElementNS('http://www.w3.org/2000/svg', 'g');
            for (g = 0; g < b.length; g++) {
              o = b[g];
              m = o.finish;
              o = F(o.start, a);
              m = F(m, a) - o;
              if (!(m <= 0)) {
                var n = e.createElementNS('http://www.w3.org/2000/svg', 'rect');
                n.setAttribute('x', o - 1);
                n.setAttribute('y', 0);
                n.setAttribute('width', m);
                n.setAttribute('height', a.itemHeight);
                n.setAttribute('class', I.highlightingClass);
                n.setAttribute('style', I.highlightingStyle);
                f.appendChild(n);
              }
            }
            typeof x !== d && f.appendChild(x(c));
            return f;
          };
        }
        if (I != null && a.areTaskInterruptionsHighlighted) {
          var h = a.internalExtraTaskTemplate;
          a.internalExtraTaskTemplate = function (c) {
            if (typeof c.scheduleChartItem !== d) return typeof h !== d ? h(c) : null;
            var b;
            if ((c.hasChildren && (typeof c.isSummaryEnabled === d || c.isSummaryEnabled)) || c.isMilestone)
              b = null;
            else {
              var e = C(c);
              b = [];
              var f, g;
              f = Oa(
                Z(c.start),
                typeof e !== d && typeof e.workingWeekFinish !== d ? e.workingWeekFinish : a.workingWeekFinish
              );
              for (
                g = sb(
                  f,
                  typeof e !== d && typeof e.workingWeekStart !== d ? e.workingWeekStart : a.workingWeekStart
                );
                f < c.finish;
                f = ra(f), g = ra(g)
              )
                b.push({ start: f >= c.start ? f : c.start, finish: g <= c.finish ? g : c.finish });
              var o =
                typeof e !== d && typeof e.specialNonworkingDays !== d
                  ? e.specialNonworkingDays
                  : a.specialNonworkingDays;
              if (typeof o !== d)
                if (typeof o === 'function') {
                  f = Oa(
                    Z(c.start),
                    typeof e !== d && typeof e.workingWeekFinish !== d
                      ? e.workingWeekFinish
                      : a.workingWeekFinish
                  );
                  for (g = W(f); f < Z(c.finish); f = W(f), g = W(g))
                    e.specialNonworkingDays(f) && b.push({ start: f, finish: g });
                } else
                  for (e = 0; e < o.length; e++) {
                    f = o[e];
                    g = W(f);
                    f >= c.start && g <= c.finish && b.push({ start: f, finish: g });
                  }
            }
            if (b == null || b.length == 0) return typeof h !== d ? h(c) : null;
            f = c.ganttChartView.ownerDocument;
            g = f.createElementNS('http://www.w3.org/2000/svg', 'g');
            for (o = 0; o < b.length; o++) {
              var e = b[o],
                m = e.finish,
                e = F(e.start, a),
                m = F(m, a) - e;
              if (!(m <= 0)) {
                var n = f.createElementNS('http://www.w3.org/2000/svg', 'rect');
                n.setAttribute('x', e - 1);
                n.setAttribute('y', a.barMargin);
                n.setAttribute('width', m);
                n.setAttribute('height', a.barHeight);
                n.setAttribute('class', I.highlightingClass);
                n.setAttribute('style', I.highlightingStyle);
                g.appendChild(n);
              }
            }
            typeof h !== d && g.appendChild(h(c));
            return g;
          };
        }
        if (typeof a.enableHorizontalScrollingDuringDragging === d)
          a.enableHorizontalScrollingDuringDragging = true;
        if (typeof a.horizontalScrollingDragAreaWidth === d) a.horizontalScrollingDragAreaWidth = 24;
        if (typeof a.horizontalScrollingDragAmount === d) a.horizontalScrollingDragAmount = 20;
        if (typeof a.enableVerticalScrollingDuringDragging === d)
          a.enableVerticalScrollingDuringDragging = true;
        if (typeof a.verticalScrollingDragAreaHeight === d) a.verticalScrollingDragAreaHeight = 24;
        if (typeof a.verticalScrollingDragAmount === d) a.verticalScrollingDragAmount = 20;
      },
      J = function (a, c) {
        for (var e = 0; e < a.length; e++) {
          var b = a[e];
          if (typeof b === 'string') {
            b = { content: b };
            a[e] = b;
          }
          if (typeof b.indentation === 'string') b.indentation = parseInt(b.indentation);
          if (typeof b.isExpanded === 'string') b.isExpanded = b.isExpanded.toLowerCase() == 'true';
          if (typeof b.start === 'string') {
            try {
              b.start = new Date(b.start);
            } catch (f) {
              b.start = new Date(parseInt(b.start));
            }
            if (typeof c.isRelativeToTimezone === d || c.isRelativeToTimezone)
              b.start = new Date(b.start.valueOf() + b.start.getTimezoneOffset() * 60000);
            b.preferredStart = b.start;
          }
          if (typeof b.finish === 'string') {
            try {
              b.finish = new Date(b.finish);
            } catch (g) {
              b.finish = new Date(parseInt(b.finish));
            }
            if (typeof c.isRelativeToTimezone === d || c.isRelativeToTimezone)
              b.finish = new Date(b.finish.valueOf() + b.finish.getTimezoneOffset() * 60000);
          }
          if (typeof b.completedFinish === 'string') {
            try {
              b.completedFinish = new Date(b.completedFinish);
            } catch (q) {
              b.completedFinish = new Date(parseInt(b.completedFinish));
            }
            if (typeof c.isRelativeToTimezone === d || c.isRelativeToTimezone)
              b.completedFinish = new Date(
                b.completedFinish.valueOf() + b.completedFinish.getTimezoneOffset() * 60000
              );
          }
          if (typeof b.isMilestone === 'string') b.isMilestone = b.isMilestone.toLowerCase() == 'true';
          if (typeof b.baselineStart === 'string') {
            try {
              b.baselineStart = new Date(b.baselineStart);
            } catch (o) {
              b.baselineStart = new Date(parseInt(b.baselineStart));
            }
            if (typeof c.isRelativeToTimezone === d || c.isRelativeToTimezone)
              b.baselineStart = new Date(
                b.baselineStart.valueOf() + b.baselineStart.getTimezoneOffset() * 60000
              );
          }
          if (typeof b.baselineFinish === 'string') {
            try {
              b.baselineFinish = new Date(b.baselineFinish);
            } catch (m) {
              b.baselineFinish = new Date(parseInt(b.baselineFinish));
            }
            if (typeof c.isRelativeToTimezone === d || c.isRelativeToTimezone)
              b.baselineFinish = new Date(
                b.baselineFinish.valueOf() + b.baselineFinish.getTimezoneOffset() * 60000
              );
          }
          if (typeof b.minStart === 'string') {
            try {
              b.minStart = new Date(b.minStart);
            } catch (n) {
              b.minStart = new Date(parseInt(b.minStart));
            }
            if (typeof c.isRelativeToTimezone === d || c.isRelativeToTimezone)
              b.minStart = new Date(b.minStart.valueOf() + b.minStart.getTimezoneOffset() * 60000);
          }
          if (typeof b.maxStart === 'string') {
            try {
              b.maxStart = new Date(b.maxStart);
            } catch (l) {
              b.maxStart = new Date(parseInt(b.maxStart));
            }
            if (typeof c.isRelativeToTimezone === d || c.isRelativeToTimezone)
              b.maxStart = new Date(b.maxStart.valueOf() + b.maxStart.getTimezoneOffset() * 60000);
          }
          if (typeof b.minFinish === 'string') {
            try {
              b.minFinish = new Date(b.minFinish);
            } catch (I) {
              b.minFinish = new Date(parseInt(b.minFinish));
            }
            if (typeof c.isRelativeToTimezone === d || c.isRelativeToTimezone)
              b.minFinish = new Date(b.minFinish.valueOf() + b.minFinish.getTimezoneOffset() * 60000);
          }
          if (typeof b.maxFinish === 'string') {
            try {
              b.maxFinish = new Date(b.maxFinish);
            } catch (x) {
              b.maxFinish = new Date(parseInt(b.maxFinish));
            }
            if (typeof c.isRelativeToTimezone === d || c.isRelativeToTimezone)
              b.maxFinish = new Date(b.maxFinish.valueOf() + b.maxFinish.getTimezoneOffset() * 60000);
          }
          if (typeof b.predecessors !== d) {
            if (typeof b.predecessors === 'string') b.predecessors = b.predecessors.split(',');
            for (var h = 0; h < b.predecessors.length; h++) {
              var j = b.predecessors[h];
              if (typeof j === 'string') {
                j = { item: j };
                b.predecessors[h] = j;
              }
              if (typeof j.item === 'string') {
                var t = parseInt(j.item) - 1;
                j.item = t >= 0 && t < a.length ? a[t] : null;
              }
              if (typeof j.lag === 'string') j.lag = parseFloat(j.lag);
            }
          }
          if (
            typeof b.schedule !== d &&
            typeof b.schedule.specialNonworkingDays !== d &&
            typeof c.specialNonworkingDays !== 'function'
          )
            for (h = 0; h < b.schedule.specialNonworkingDays.length; h++)
              b.schedule.specialNonworkingDays[h] = Z(
                new Date(
                  b.schedule.specialNonworkingDays[h].valueOf() -
                    b.schedule.specialNonworkingDays[h].getTimezoneOffset() * 60000
                )
              );
          typeof b.wasGridItemContentLoaded !== d && delete b.wasGridItemContentLoaded;
        }
      },
      U = function (a, c, e) {
        var b;
        if (a.isContentHeightInitialized && a.isContentHeightAuto)
          b = c.length > 0 ? 'auto' : e.itemHeight + 'px';
        else {
          var f = a.clientHeight - e.headerHeight - 2;
          f < 0 && (f = 0);
          b = f + 'px';
          if (f < e.itemHeight) {
            if (!a.isContentHeightInitialized) a.isContentHeightAuto = true;
            b = c.length > 0 ? 'auto' : e.itemHeight + 'px';
          }
          a.isContentHeightInitialized = true;
        }
        return b;
      },
      X = function (a, c) {
        if (typeof c.toggleButtonAreaWidth !== d) return c.toggleButtonAreaWidth;
        var e = 0;
        if (c.isGridVisible)
          for (var b = 0; b < a.length; b++)
            if (
              (typeof a[b].scheduleChartItem === d || a[b].scheduleChartItem == a[b]) &&
              a[b].indentation > 0
            ) {
              e = 16;
              break;
            }
        return (c.toggleButtonAreaWidth = e);
      },
      da = function (a, c) {
        var e = a.createElement('span');
        e.innerHTML = c;
        return e;
      },
      ea = function (a, c) {
        return c == true ? a.createTextNode('\u2713') : a.createTextNode('');
      },
      fa = function (a) {
        var c = a.getFullYear(),
          e = a.getMonth() + 1;
        e < 10 && (e = '0' + e);
        a = a.getDate();
        a < 10 && (a = '0' + a);
        return e + '/' + a + '/' + c;
      },
      na = function (a) {
        var c = fa(a),
          e = a.getHours();
        e < 10 && (e = '0' + e.toString());
        a = a.getMinutes();
        a < 10 && (a = '0' + a.toString());
        return c + ' ' + e + ':' + a;
      },
      h = function (a) {
        return new Date(a.valueOf() + a.getTimezoneOffset() * 60000);
      },
      s = function (a) {
        var c = new Date();
        if (a == null || a == '') return c;
        var e, b, f, d;
        f = a.indexOf(' ');
        if (f >= 0) {
          e = a.substr(0, f);
          d = a.substr(f + 1);
        } else {
          e = a;
          d = '0';
        }
        f = e.indexOf('/');
        if (f >= 0) {
          for (a = e.substr(0, f); a.length > 0 && a.substr(0, 1) == '0'; ) a = a.substr(1);
          a = parseInt(a) - 1;
          e = e.substr(f + 1);
          f = e.indexOf('/');
          if (f >= 0) {
            b = e.substr(0, f);
            for (f = e.substr(f + 1); f.length > 0 && f.substr(0, 1) == '0'; ) f = f.substr(1);
            e = parseInt(f);
          } else {
            b = e;
            e = c.getFullYear();
          }
          for (; b.length > 0 && b.substr(0, 1) == '0'; ) b = b.substr(1);
          b = parseInt(b);
        } else {
          for (; e.length > 0 && e.substr(0, 1) == '0'; ) e = e.substr(1);
          b = parseInt(e);
          a = c.getMonth();
          e = c.getFullYear();
        }
        var q;
        f = d.indexOf(':');
        if (f >= 0) {
          q = d.substr(0, f);
          d = d.substr(f + 1);
        } else {
          q = d;
          d = '0';
        }
        for (; q.length > 1 && q.substr(0, 1) == '0'; ) q = q.substr(1);
        for (f = parseInt(q); d.length > 1 && d.substr(0, 1) == '0'; ) d = d.substr(1);
        d = parseInt(d);
        return isNaN(e) ||
          isNaN(a) ||
          isNaN(b) ||
          e <= 0 ||
          a < 0 ||
          a >= 12 ||
          b < 1 ||
          b > ba[a] + (a != 1 || !(e % 400 == 0 || (e % 100 == 0 ? 0 : e % 4 == 0)) ? 0 : 1)
          ? isNaN(f) || isNaN(d) || f < 0 || f >= 24 || d < 0 || d >= 60
            ? c
            : new Date(c.getFullYear(), c.getMonth(), c.getDate(), f, d, 0, 0)
          : isNaN(f) || isNaN(d) || f < 0 || f >= 24 || d < 0 || d >= 60
          ? new Date(e, a, b, 0, 0, 0, 0)
          : new Date(e, a, b, f, d, 0, 0);
      },
      j = function (a) {
        return new Date(a.valueOf() - a.getTimezoneOffset() * 60000);
      },
      u = function (a, c) {
        var e = a.ownerDocument,
          b = e.createElementNS('http://www.w3.org/2000/svg', 'svg');
        b.setAttribute('style', 'width: 12px; height: 12px; vertical-align: initial');
        b.setAttribute('focusable', 'false');
        e = e.createElementNS('http://www.w3.org/2000/svg', 'rect');
        e.setAttribute('id', 'PART_Border');
        e.setAttribute('width', '12');
        e.setAttribute('height', '12');
        e.setAttribute('style', 'fill: White; fill-opacity: 0');
        b.appendChild(e);
        a.setAttribute('id', 'PART_Button');
        typeof c.toggleButtonClass !== d && a.setAttribute('class', c.toggleButtonClass);
        typeof c.toggleButtonStyle !== d && a.setAttribute('style', c.toggleButtonStyle);
        b.appendChild(a);
        return b;
      },
      k = function (a, c) {
        typeof c !== 'object' && (c = {});
        Y(c);
        var e = [
          { header: '', width: 32, isSelection: true },
          { header: 'Task', width: 140, isTreeView: true },
          { header: 'Start', width: 140 },
          { header: 'Finish', width: 140 },
          { header: 'Milestone', width: 80 },
          { header: 'Completed', width: 80 },
          { header: 'Assignments', width: 200 },
        ];
        e[0].cellTemplate = y(c, e[0], a);
        e[1].cellTemplate = z(c, e[1], a);
        e[1].exportCellTemplate = z(c, e[1], a, false);
        e[2].cellTemplate = Yb(c, e[2]);
        e[2].exportCellTemplate = Yb(c, e[2], false);
        e[3].cellTemplate = Zb(c, e[3]);
        e[3].exportCellTemplate = Zb(c, e[3], false);
        e[4].cellTemplate = L(c, e[4]);
        e[4].exportCellTemplate = L(c, e[4], false);
        e[5].cellTemplate = $b(c, e[5]);
        e[5].exportCellTemplate = $b(c, e[5], false);
        e[6].cellTemplate = ac(c, e[6]);
        e[6].exportCellTemplate = ac(c, e[6], false);
        c.selectionMode != 'Single' &&
          c.selectionMode != 'Extended' &&
          c.selectionMode != 'ExtendedFocus' &&
          e.splice(0, 1);
        return e;
      },
      y = function (a, c, e) {
        return function (b) {
          return !c.isReadOnly ? p(b, a, e) : ea(b.ganttChartView.ownerDocument, b.isSelected);
        };
      },
      p = function (a, c) {
        var e = a.ganttChartView.ownerDocument,
          b;
        if (typeof a.selectionInput === d) {
          b = e.createElement('input');
          a.selectionInput = b;
          b.type = 'checkbox';
          b.setAttribute('style', 'margin: 0px');
        } else b = a.selectionInput;
        if (a.isSelected) {
          b.setAttribute('checked', 'checked');
          if (!b.checked) b.checked = true;
        } else if (b.checked) b.checked = false;
        var f = function () {
          b.checked ? v(a, true, c.selectionMode) : v(a, false, c.selectionMode);
        };
        typeof b.changeEventListener !== d && b.removeEventListener('change', b.changeEventListener, true);
        b.addEventListener('change', f, true);
        b.changeEventListener = f;
        e = function (a) {
          if (a.keyCode == 13) {
            a.preventDefault();
            a.stopPropagation();
            f(a);
          }
        };
        typeof b.keyPressEventListener !== d &&
          b.removeEventListener('keypress', b.keyPressEventListener, true);
        b.addEventListener('keypress', e, true);
        b.keyPressEventListener = e;
        return b;
      },
      v = function (a, c, e) {
        a.isSelected = c;
        w(a, 'isSelected', true, true);
        bc(a);
        if (typeof a.ganttChartView !== d) {
          a.ganttChartView.selectedItem = a;
          if (e != 'Extended' && e != 'ExtendedFocus') a.ganttChartView.selectedItems = [a];
          var b;
          if (e != 'Extended' && e != 'ExtendedFocus')
            for (b = 0; b < a.ganttChartView.items.length; b++) {
              c = a.ganttChartView.items[b];
              if (c != a && c.isSelected) {
                c.isSelected = false;
                w(c, 'isSelected', false, true);
                bc(c);
              }
            }
        }
        if (typeof a.ganttChartView !== d && (e == 'Extended' || e == 'ExtendedFocus')) {
          e = [];
          for (b = 0; b < a.ganttChartView.items.length; b++) {
            c = a.ganttChartView.items[b];
            c.isSelected && e.push(c);
          }
          a.ganttChartView.selectedItems = e;
        }
      },
      D = function (a, c) {
        if (typeof a.ganttChartView !== d) a.ganttChartView.currentItem = a;
        if (c.selectionMode == 'Focus' || c.selectionMode == 'ExtendedFocus') {
          if (typeof a.scheduleChartItem !== d) a = a.scheduleChartItem;
          a.isSelected || v(a, true, c.selectionMode);
        }
      },
      z = function (a, c, e, b) {
        var f = function (a) {
          return da(a.ganttChartView.ownerDocument, a.content);
        };
        return (typeof b === d || b) && !a.isReadOnly && !a.isGridReadOnly && !a.isContentReadOnly
          ? function (b) {
              return !c.isReadOnly && (typeof b.isReadOnly === d || !b.isReadOnly)
                ? A(
                    b,
                    Math.max(
                      0,
                      c.width -
                        b.indentation * b.ganttChartView.settings.indentationLevelWidth -
                        b.ganttChartView.settings.toggleButtonAreaWidth -
                        16
                    ),
                    a
                  )
                : f(b);
            }
          : f;
      },
      A = function (a, c, e) {
        var b = a.ganttChartView.ownerDocument,
          f;
        if (typeof a.contentInput === d) {
          f = b.createElement('input');
          a.contentInput = f;
          f.type = 'text';
          f.addEventListener(
            'focus',
            function () {
              D(a, e);
            },
            false
          );
          var g = function () {
            a.content = f.value;
            w(a, 'content', true, true);
            sa(a);
          };
          typeof f.changeEventListener !== d && f.removeEventListener('change', f.changeEventListener, true);
          f.addEventListener('change', g, true);
          f.changeEventListener = g;
          b = function (a) {
            if (a.keyCode == 13) {
              a.preventDefault();
              a.stopPropagation();
              g(a);
            }
          };
          typeof f.keyPressEventListener !== d &&
            f.removeEventListener('keypress', f.keyPressEventListener, true);
          f.addEventListener('keypress', b, true);
          f.keyPressEventListener = b;
          f.addEventListener(
            'focus',
            function () {
              f.style.backgroundColor = 'White';
            },
            false
          );
          f.addEventListener(
            'blur',
            function () {
              f.style.backgroundColor = 'Transparent';
            },
            false
          );
        } else f = a.contentInput;
        f.value = a.content;
        b = '';
        if (a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled))
          b = '; font-weight: bold';
        f.setAttribute(
          'style',
          'outline: none; background-color: Transparent; width: ' +
            c +
            'px; border-width: 0px; padding: 0px' +
            b +
            (e.classic ? '' : '; font-size: 12px; padding: 1px;')
        );
        return f;
      },
      Yb = function (a, c, e) {
        var b = function (c) {
          return typeof c.start === d ||
            (typeof c.isSummaryEnabled !== d &&
              !c.isSummaryEnabled &&
              typeof c.isBarVisible !== d &&
              !c.isBarVisible)
            ? c.ganttChartView.ownerDocument.createTextNode('')
            : c.ganttChartView.ownerDocument.createTextNode(a.dateTimeFormatter(h(c.start)));
        };
        return (typeof e === d || e) && !a.isReadOnly && !a.isGridReadOnly
          ? function (e) {
              return !c.isReadOnly &&
                (typeof e.isReadOnly === d || !e.isReadOnly) &&
                !(
                  typeof e.isSummaryEnabled !== d &&
                  !e.isSummaryEnabled &&
                  typeof e.isBarVisible !== d &&
                  !e.isBarVisible
                )
                ? Rc(e, Math.max(0, c.width - 16), a)
                : b(e);
            }
          : b;
      },
      Rc = function (a, c, e) {
        var b = a.ganttChartView.ownerDocument,
          f = b.createElement('input');
        a.startInput = f;
        try {
          f.type = e.dateTimePickerType;
        } catch (g) {
          f.type = 'text';
        }
        f.addEventListener(
          'focus',
          function () {
            D(a, e);
          },
          false
        );
        f.addEventListener(
          'focus',
          function () {
            f.style.backgroundColor = 'White';
          },
          false
        );
        f.addEventListener(
          'blur',
          function () {
            f.style.backgroundColor = 'Transparent';
          },
          false
        );
        if (typeof a.start !== d) f.value = e.dateTimeFormatter(h(a.start));
        DlhSoft.Controls.DatePicker &&
          e.dateTimePickerType == 'text' &&
          e.useDatePicker &&
          f.addEventListener(
            'focus',
            function () {
              if (!a.isWaitingToRefreshGridItem) {
                var c = (e.useTimePicker && DlhSoft.Controls.DateTimePicker
                  ? DlhSoft.Controls.DateTimePicker
                  : DlhSoft.Controls.DatePicker
                ).get(f);
                if (!c || !c.isOpen) {
                  var d = 0,
                    g = 0;
                  try {
                    d = f.selectionStart;
                    g = f.selectionEnd;
                  } catch (o) {}
                  c = (e.useTimePicker && DlhSoft.Controls.DateTimePicker
                    ? DlhSoft.Controls.DateTimePicker
                    : DlhSoft.Controls.DatePicker
                  ).initialize(
                    f,
                    void 0,
                    {
                      inputStyle: null,
                      isDropDownButtonVisible: false,
                      defaultTimeOfDay: e.workingDayStart,
                      dateTimeFormatter: e.dateTimeFormatter,
                      dateTimeParser: e.dateTimeParser,
                      popupStyle: 'margin-top: 1px; background-color: White; border: 1px solid ' + e.border,
                      disabledDateSelector: cc(e),
                      disabledTimeSelector: B(e),
                      calendarSelectorLevels: e.calendarSelectorLevels,
                      months: e.months,
                      daysOfWeek: db(e.daysOfWeek),
                      weekStart: e.weekStartDay,
                    },
                    'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
                  );
                  c.openDropDown();
                  setTimeout(function () {
                    try {
                      f.selectionStart = d;
                      f.selectionEnd = g;
                    } catch (a) {}
                  }, 100);
                  navigator.userAgent.match(/(Android)|(IPad)|(IPhone)/i) == null
                    ? setTimeout(function () {
                        try {
                          f.focus();
                        } catch (a) {}
                      }, 100)
                    : b.createEvent &&
                      setTimeout(function () {
                        var a = b.createEvent('MouseEvents');
                        a.initEvent('blur', true, false);
                        f.dispatchEvent(a);
                      });
                }
              }
            },
            true
          );
        var q = '';
        if (a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled))
          q = '; font-weight: bold';
        f.setAttribute(
          'style',
          'outline: none; background-color: Transparent; width: ' +
            c +
            'px; border-width: 0px; padding: 0px' +
            q +
            (e.classic ? '' : '; font-size: 12px; padding: 1px;')
        );
        a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled)
          ? f.setAttribute('disabled', 'true')
          : f.removeAttribute('disabled');
        var o = function () {
          if (!a.hasChildren || !(typeof a.isSummaryEnabled === d || a.isSummaryEnabled)) {
            var c = a.completedFinish > a.start,
              b,
              g;
            if (e.isTaskEffortPreservedWhenStartChangesInGrid) {
              b = ka(a, e);
              g = Da(a, e);
            }
            var o = a.start.valueOf(),
              q = a.finish.valueOf(),
              h = a.completedFinish.valueOf(),
              k = e.dateTimeParser(f.value);
            if (k == null || isNaN(k)) k = new Date();
            k = R(j(k), e, true, a.isMilestone, C(a));
            if (!e.validateStartFinish || k <= a.finish || a.isMilestone) {
              a.start = k;
              a.preferredStart = a.start;
              if (a.isMilestone) a.finish = a.start;
              a.start.valueOf() != o && w(a, 'start', true, true);
              if (a.finish < a.start) {
                a.finish = a.start;
                a.finish.valueOf() != q && w(a, 'finish', false, true);
              }
              if (a.completedFinish < a.start || !c) {
                a.completedFinish = a.start;
                a.completedFinish.valueOf() != h && w(a, 'completedFinish', false, true);
              } else if (a.completedFinish > a.finish) {
                a.completedFinish = a.finish;
                a.completedFinish.valueOf() != h && w(a, 'completedFinish', false, true);
              }
              if (typeof a.completedInput !== d) {
                c = a.completedInput;
                typeof c.changeEventListener !== d &&
                  c.removeEventListener('change', c.changeEventListener, true);
                delete a.completedInput;
              }
              if (e.isTaskEffortPreservedWhenStartChangesInGrid) {
                eb(a, b, e);
                fb(a, g, e);
              }
            }
          }
          $(a);
        };
        typeof f.changeEventListener !== d && f.removeEventListener('change', f.changeEventListener, true);
        f.addEventListener('change', o, true);
        f.changeEventListener = o;
        c = function (a) {
          if (a.keyCode == 13) {
            a.preventDefault();
            a.stopPropagation();
            o(a);
          }
        };
        typeof f.keyPressEventListener !== d &&
          f.removeEventListener('keypress', f.keyPressEventListener, true);
        f.addEventListener('keypress', c, true);
        f.keyPressEventListener = c;
        return f;
      },
      Zb = function (a, c, e) {
        var b = function (c) {
          return typeof c.finish === d ||
            (typeof c.isMilestone !== d &&
              c.isMilestone == true &&
              typeof c.start !== d &&
              c.finish.valueOf() == c.start.valueOf()) ||
            (typeof c.isSummaryEnabled !== d &&
              !c.isSummaryEnabled &&
              typeof c.isBarVisible !== d &&
              !c.isBarVisible)
            ? c.ganttChartView.ownerDocument.createTextNode('')
            : c.ganttChartView.ownerDocument.createTextNode(a.dateTimeFormatter(h(c.finish)));
        };
        return (typeof e === d || e) && !a.isReadOnly && !a.isGridReadOnly
          ? function (e) {
              return !c.isReadOnly &&
                (typeof e.isReadOnly === d || !e.isReadOnly) &&
                !(
                  typeof e.isSummaryEnabled !== d &&
                  !e.isSummaryEnabled &&
                  typeof e.isBarVisible !== d &&
                  !e.isBarVisible
                )
                ? ta(e, Math.max(0, c.width - 16), a)
                : b(e);
            }
          : b;
      },
      ta = function (a, c, e) {
        var b = a.ganttChartView.ownerDocument,
          f = b.createElement('input');
        a.finishInput = f;
        try {
          f.type = e.dateTimePickerType;
        } catch (g) {
          f.type = 'text';
        }
        f.addEventListener(
          'focus',
          function () {
            D(a, e);
          },
          false
        );
        f.addEventListener(
          'focus',
          function () {
            f.style.backgroundColor = 'White';
          },
          false
        );
        f.addEventListener(
          'blur',
          function () {
            f.style.backgroundColor = 'Transparent';
          },
          false
        );
        if (typeof a.finish !== d) f.value = e.dateTimeFormatter(h(a.finish));
        DlhSoft.Controls.DatePicker &&
          e.dateTimePickerType == 'text' &&
          e.useDatePicker &&
          f.addEventListener(
            'focus',
            function () {
              if (!a.isWaitingToRefreshGridItem) {
                var c = (e.useTimePicker && DlhSoft.Controls.DateTimePicker
                  ? DlhSoft.Controls.DateTimePicker
                  : DlhSoft.Controls.DatePicker
                ).get(f);
                if (!c || !c.isOpen) {
                  var d = 0,
                    g = 0;
                  try {
                    d = f.selectionStart;
                    g = f.selectionEnd;
                  } catch (o) {}
                  c = (e.useTimePicker && DlhSoft.Controls.DateTimePicker
                    ? DlhSoft.Controls.DateTimePicker
                    : DlhSoft.Controls.DatePicker
                  ).initialize(
                    f,
                    void 0,
                    {
                      inputStyle: null,
                      isDropDownButtonVisible: false,
                      defaultTimeOfDay: e.workingDayFinish,
                      dateTimeFormatter: e.dateTimeFormatter,
                      dateTimeParser: e.dateTimeParser,
                      popupStyle: 'margin-top: 1px; background-color: White; border: 1px solid ' + e.border,
                      disabledDateSelector: cc(e),
                      disabledTimeSelector: N(e, a),
                      calendarSelectorLevels: e.calendarSelectorLevels,
                      months: e.months,
                      daysOfWeek: db(e.daysOfWeek),
                      weekStart: e.weekStartDay,
                    },
                    'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
                  );
                  c.openDropDown();
                  setTimeout(function () {
                    try {
                      f.selectionStart = d;
                      f.selectionEnd = g;
                    } catch (a) {}
                  }, 100);
                  navigator.userAgent.match(/(Android)|(IPad)|(IPhone)/i) == null
                    ? setTimeout(function () {
                        try {
                          f.focus();
                        } catch (a) {}
                      }, 100)
                    : b.createEvent &&
                      setTimeout(function () {
                        var a = b.createEvent('MouseEvents');
                        a.initEvent('blur', true, false);
                        f.dispatchEvent(a);
                      });
                }
              }
            },
            true
          );
        var q = '';
        typeof a.isMilestone !== d && a.isMilestone == true && (q = '; visibility: hidden');
        var o = '';
        if (a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled))
          o = '; font-weight: bold';
        f.setAttribute(
          'style',
          'outline: none; background-color: Transparent; width: ' +
            c +
            'px; border-width: 0px; padding: 0px' +
            q +
            o +
            (e.classic ? '' : '; font-size: 12px; padding: 1px;')
        );
        a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled)
          ? f.setAttribute('disabled', 'true')
          : f.removeAttribute('disabled');
        var m = function () {
          if (!a.hasChildren || !(typeof a.isSummaryEnabled === d || a.isSummaryEnabled)) {
            var c = a.finish.valueOf(),
              b = a.completedFinish.valueOf(),
              g = e.dateTimeParser(f.value);
            if (g == null || isNaN(g)) g = new Date();
            g = j(g);
            f.value.length > 0 && f.value.indexOf(' ') < 0 && oa(g) == 0 && (g = W(g));
            g = R(g, e, a.isMilestone, true, C(a));
            if (!e.validateStartFinish || g >= a.start) {
              a.finish = g;
              if (a.finish < a.start) a.finish = a.start;
              a.finish.valueOf() != c && w(a, 'finish', true, true);
              if (a.completedFinish < a.start) {
                a.completedFinish = a.start;
                a.completedFinish.valueOf() != b && w(a, 'completedFinish', false, true);
              } else if (a.completedFinish > a.finish) {
                a.completedFinish = a.finish;
                a.completedFinish.valueOf() != b && w(a, 'completedFinish', false, true);
              }
              if (typeof a.completedInput !== d) {
                c = a.completedInput;
                typeof c.changeEventListener !== d &&
                  c.removeEventListener('change', c.changeEventListener, true);
                delete a.completedInput;
              }
            }
          }
          $(a);
        };
        typeof f.changeEventListener !== d && f.removeEventListener('change', f.changeEventListener, true);
        f.addEventListener('change', m, true);
        f.changeEventListener = m;
        c = function (a) {
          if (a.keyCode == 13) {
            a.preventDefault();
            a.stopPropagation();
            m(a);
          }
        };
        typeof f.keyPressEventListener !== d &&
          f.removeEventListener('keypress', f.keyPressEventListener, true);
        f.addEventListener('keypress', c, true);
        f.keyPressEventListener = c;
        return f;
      },
      cc = function (a) {
        return function (c) {
          if (c.getDay() < a.workingWeekStart || c.getDay() > a.workingWeekFinish) return true;
          if (a.specialNonworkingDays) {
            if (typeof a.specialNonworkingDays === 'function') return a.specialNonworkingDays(j(c));
            for (var c = j(c).valueOf(), e = 0; e < a.specialNonworkingDays.length; e++)
              if (c == a.specialNonworkingDays[e].valueOf()) return true;
          }
          return false;
        };
      },
      B = function (a) {
        return function (c) {
          return c < a.visibleDayStart || c >= a.visibleDayFinish;
        };
      },
      N = function (a, c) {
        return function (e) {
          return (
            (Z(c.start) < Z(c.finish) ? e <= a.visibleDayStart : e < a.visibleDayStart) ||
            e > a.visibleDayFinish
          );
        };
      },
      db = function (a) {
        if (a) {
          for (var c = [], e = 0; e < a.length; e++) {
            var b = a[e],
              b = b.length > 0 ? b[0].toUpperCase() + (b.length > 1 ? b[1] : '') : '';
            c.push(b);
          }
          return c;
        }
      },
      L = function (a, c, e) {
        var b = function (a) {
          return typeof a.isMilestone === d ||
            (typeof a.isSummaryEnabled !== d &&
              !a.isSummaryEnabled &&
              typeof a.isBarVisible !== d &&
              !a.isBarVisible)
            ? a.ganttChartView.ownerDocument.createTextNode('')
            : ea(a.ganttChartView.ownerDocument, a.isMilestone);
        };
        return (typeof e === d || e) && !a.isReadOnly && !a.isGridReadOnly
          ? function (e) {
              return !c.isReadOnly &&
                (typeof e.isReadOnly === d || !e.isReadOnly) &&
                !(
                  typeof e.isSummaryEnabled !== d &&
                  !e.isSummaryEnabled &&
                  typeof e.isBarVisible !== d &&
                  !e.isBarVisible
                )
                ? Sc(e, a)
                : b(e);
            }
          : b;
      },
      Sc = function (a, c) {
        var e = a.ganttChartView.ownerDocument,
          b;
        if (typeof a.milestoneInput === d) {
          b = e.createElement('input');
          a.milestoneInput = b;
          b.type = 'checkbox';
          b.setAttribute('style', 'margin: 0px; margin-left: 2px; margin-right: 2px');
          b.addEventListener(
            'focus',
            function () {
              D(a, c);
            },
            false
          );
        } else b = a.milestoneInput;
        (!a.hasChildren || !(typeof a.isSummaryEnabled === d || a.isSummaryEnabled)) &&
        typeof a.isMilestone !== d &&
        a.isMilestone
          ? b.setAttribute('checked', 'checked')
          : b.removeAttribute('checked');
        if (!a.hasChildren || !(typeof a.isSummaryEnabled === d || a.isSummaryEnabled)) {
          var f = function () {
            if (b.checked) {
              a.isMilestone = true;
              w(a, 'isMilestone', true, true);
              if (a.finish > a.start) {
                a.finish = a.start;
                w(a, 'finish', false, true);
              }
              if (a.completedFinish > a.start) {
                a.completedFinish = a.start;
                w(a, 'completedFinish', false, true);
              }
            } else {
              a.isMilestone = false;
              w(a, 'isMilestone', true, true);
            }
            $(a);
          };
          typeof b.changeEventListener !== d && b.removeEventListener('change', b.changeEventListener, true);
          b.addEventListener('change', f, true);
          b.changeEventListener = f;
          e = function (a) {
            if (a.keyCode == 13) {
              a.preventDefault();
              a.stopPropagation();
              f(a);
            }
          };
          typeof b.keyPressEventListener !== d &&
            b.removeEventListener('keypress', b.keyPressEventListener, true);
          b.addEventListener('keypress', e, true);
          b.keyPressEventListener = e;
        } else b.setAttribute('style', 'visibility: hidden');
        return b;
      },
      ac = function (a, c, e) {
        var b = function (a) {
          return typeof a.assignmentsContent === d ||
            (typeof a.isSummaryEnabled !== d &&
              !a.isSummaryEnabled &&
              typeof a.isBarVisible !== d &&
              !a.isBarVisible)
            ? a.ganttChartView.ownerDocument.createTextNode('')
            : da(a.ganttChartView.ownerDocument, a.assignmentsContent);
        };
        return (typeof e === d || e) && !a.isReadOnly && !a.isGridReadOnly && !a.isAssignmentsContentReadOnly
          ? function (e) {
              return !c.isReadOnly &&
                (typeof e.isReadOnly === d || !e.isReadOnly) &&
                !(
                  typeof e.isSummaryEnabled !== d &&
                  !e.isSummaryEnabled &&
                  typeof e.isBarVisible !== d &&
                  !e.isBarVisible
                )
                ? Tc(e, Math.max(0, c.width - 16), a)
                : b(e);
            }
          : b;
      },
      Tc = function (a, c, e) {
        var b = a.ganttChartView,
          f = b.ownerDocument,
          g;
        if (typeof a.assignmentsContentInput === d) {
          g = f.createElement('input');
          a.assignmentsContentInput = g;
          g.type = 'text';
          g.addEventListener(
            'focus',
            function () {
              D(a, e);
            },
            false
          );
          var q = function () {
            a.assignmentsContent = g.value;
            w(a, 'assignmentsContent', true, true);
            if (!a.hasChildren && a.hasFixedEffort) {
              a.fixedEffort = ka(a, e);
              a.fixedEffortAssignments = la(a);
            }
            ua(a);
            var c = null;
            DlhSoft.Controls.MultiSelectorComboBox && (c = DlhSoft.Controls.MultiSelectorComboBox.get(g));
            if (c != null && c.availableChoices.length > 0)
              var b = setInterval(function () {
                if (!c.isOpen && f.activeElement != a.assignmentsContentInput) {
                  clearInterval(b);
                  $(a);
                }
              }, 100);
            else $(a);
          };
          typeof g.changeEventListener !== d && g.removeEventListener('change', g.changeEventListener, true);
          g.addEventListener(
            'change',
            function (a) {
              g.dontAutoFocus = true;
              q(a);
            },
            true
          );
          g.changeEventListener = q;
          var o = function (a) {
            if (a.keyCode == 13) {
              a.preventDefault();
              a.stopPropagation();
              typeof g.dontAutoFocus !== d && delete g.dontAutoFocus;
              q(a);
            }
          };
          typeof g.keyPressEventListener !== d &&
            g.removeEventListener('keypress', g.keyPressEventListener, true);
          g.addEventListener('keypress', o, true);
          g.keyPressEventListener = o;
          g.addEventListener(
            'focus',
            function () {
              g.style.backgroundColor = 'White';
            },
            false
          );
          g.addEventListener(
            'blur',
            function () {
              g.style.backgroundColor = 'Transparent';
            },
            false
          );
        } else g = a.assignmentsContentInput;
        if (typeof a.assignmentsContent !== d) g.value = a.assignmentsContent;
        DlhSoft.Controls.MultiSelectorComboBox &&
          e.useResourceSelector &&
          g.addEventListener(
            'focus',
            function () {
              if (!a.isWaitingToRefreshGridItem) {
                var c = DlhSoft.Controls.MultiSelectorComboBox.get(g);
                if (!c || (!c.isOpen && c.availableChoices.length > 0)) {
                  c = e.assignableResources;
                  if (typeof c === d) {
                    c = Ta(b.items);
                    e.assignableResources = c;
                  }
                  var o = 0,
                    l = 0;
                  try {
                    o = g.selectionStart;
                    l = g.selectionEnd;
                  } catch (q) {}
                  c = DlhSoft.Controls.MultiSelectorComboBox.initialize(
                    g,
                    c,
                    void 0,
                    {
                      inputStyle: null,
                      autoAppendAvailableChoices: e.autoAppendAssignableResources,
                      isDropDownButtonVisible: false,
                      popupStyle:
                        'margin-top: 1px; background-color: White; color: Black; border: 1px solid ' +
                        e.border +
                        '; font-size: small; max-height: 188px; overflow-y: auto',
                    },
                    'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
                  );
                  c.openDropDown();
                  setTimeout(function () {
                    try {
                      g.selectionStart = o;
                      g.selectionEnd = l;
                    } catch (a) {}
                  }, 100);
                  navigator.userAgent.match(/(Android)|(IPad)|(IPhone)/i) == null
                    ? setTimeout(function () {
                        try {
                          g.focus();
                        } catch (a) {}
                      }, 100)
                    : f.createEvent &&
                      setTimeout(function () {
                        var a = f.createEvent('MouseEvents');
                        a.initEvent('blur', true, false);
                        g.dispatchEvent(a);
                      });
                }
              }
            },
            true
          );
        o = '';
        if (a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled))
          o = '; font-weight: bold';
        g.setAttribute(
          'style',
          'outline: none; background-color: Transparent; width: ' +
            c +
            'px; border-width: 0px; padding: 0px' +
            o +
            (e.classic ? '' : '; font-size: 12px; padding: 1px;')
        );
        return g;
      },
      $b = function (a, c, e) {
        var b = function (a) {
          return typeof a.start === d ||
            typeof a.finish === d ||
            a.finish.valueOf() == a.start.valueOf() ||
            (typeof a.isSummaryEnabled !== d &&
              !a.isSummaryEnabled &&
              typeof a.isBarVisible !== d &&
              !a.isBarVisible)
            ? a.ganttChartView.ownerDocument.createTextNode('')
            : ea(a.ganttChartView.ownerDocument, a.ganttChartView.isItemCompleted(a));
        };
        return (typeof e === d || e) && !a.isReadOnly && !a.isGridReadOnly
          ? function (e) {
              return !c.isReadOnly &&
                (typeof e.isReadOnly === d || !e.isReadOnly) &&
                (!e.hasChildren || !(typeof e.isSummaryEnabled === d || e.isSummaryEnabled)) &&
                !(
                  typeof e.isSummaryEnabled !== d &&
                  !e.isSummaryEnabled &&
                  typeof e.isBarVisible !== d &&
                  !e.isBarVisible
                )
                ? Uc(e, a)
                : b(e);
            }
          : b;
      },
      Uc = function (a, c) {
        var e = a.ganttChartView.ownerDocument,
          b;
        if (typeof a.completedInput === d) {
          b = e.createElement('input');
          a.completedInput = b;
          b.type = 'checkbox';
          b.setAttribute('style', 'margin: 0px; margin-left: 2px; margin-right: 2px');
          b.addEventListener(
            'focus',
            function () {
              D(a, c);
            },
            false
          );
        } else b = a.completedInput;
        a.isSetAsCompleted ||
        ((!a.hasChildren || !(typeof a.isSummaryEnabled === d || a.isSummaryEnabled)) &&
          a.completedFinish >= a.finish &&
          a.finish > a.start &&
          (typeof a.isMilestone === d || !a.isMilestone))
          ? b.setAttribute('checked', 'checked')
          : b.removeAttribute('checked');
        a.hasChildren &&
          (typeof a.isSummaryEnabled === d || a.isSummaryEnabled) &&
          b.setAttribute('style', 'visibility: hidden');
        var f = function () {
          if (b.checked) {
            if (a.completedFinish < a.finish) {
              a.completedFinish = a.finish;
              w(a, 'completedFinish', true, true);
            }
            if (a.isMilestone || a.finish.valueOf() <= a.start.valueOf()) {
              a.isSetAsCompleted = true;
              w(a, 'isSetAsCompleted', true, true);
            }
          } else {
            if (a.completedFinish > a.start) {
              a.completedFinish = a.start;
              w(a, 'completedFinish', true, true);
            }
            if (a.isMilestone || a.finish.valueOf() <= a.start.valueOf()) {
              a.isSetAsCompleted = false;
              w(a, 'isSetAsCompleted', true, true);
            }
          }
          $(a);
        };
        typeof b.changeEventListener !== d && b.removeEventListener('change', b.changeEventListener, true);
        b.addEventListener('change', f, true);
        b.changeEventListener = f;
        e = function (a) {
          if (a.keyCode == 13) {
            a.preventDefault();
            a.stopPropagation();
            f(a);
          }
        };
        typeof b.keyPressEventListener !== d &&
          b.removeEventListener('keypress', b.keyPressEventListener, true);
        b.addEventListener('keypress', e, true);
        b.keyPressEventListener = e;
        return b;
      },
      pb = function (a) {
        for (var c = 0, e = 0; e < a.length; e++) c = c + a[e].width;
        return c;
      },
      Kc = function (a, c, e) {
        for (var b = X(c.items, e), f = 0; f < a.length; f++) {
          var g = a[f];
          g.ganttChartView = c;
          if (typeof g.width === d) g.width = 100;
          if (typeof g.minWidth === d)
            g.minWidth = Math.min(g.width, e.minColumnWidth + (g.isTreeView ? b : 0));
          if (typeof g.maxWidth === d) g.maxWidth = e.maxColumnWidth;
          if (typeof g.cellTemplate === d)
            g.cellTemplate = function (a) {
              return da(a.ganttChartView.ownerDocument, a.content);
            };
          if (e.verticalGridLines || e.verticalGridHeaderLines) {
            if (g.minWidth < 4) g.minWidth = 4;
            if (g.width < g.minWidth) g.width = g.minWidth;
          }
        }
      },
      Nc = function (a, c, e, b) {
        var f = a.ownerDocument,
          g = f.createElement('div');
        typeof e.columnHeaderClass !== d && g.setAttribute('class', e.columnHeaderClass);
        typeof e.columnHeaderStyle !== d && g.setAttribute('style', e.columnHeaderStyle);
        for (var q = 0; q < c.length; q++) g.appendChild(Vc(f, c[q], e, b));
        a.appendChild(g);
      },
      Vc = function (a, c, e, b) {
        var f = a.createElement('div');
        f.setAttribute(
          'style',
          'overflow-y: hidden; vertical-align: middle; display: table-cell; -wekbit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; height: ' +
            e.headerHeight +
            'px'
        );
        var g = a.createElement('div'),
          q = c.width >= 4 ? 2 : c.width / 2;
        g.setAttribute(
          'style',
          'padding-left: ' +
            q +
            'px; padding-right: ' +
            q +
            'px; overflow-x: hidden; -wekbit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; width: ' +
            c.width +
            'px'
        );
        q = a.createElement('div');
        typeof c.headerClass !== d && q.setAttribute('class', c.headerClass);
        typeof c.headerStyle !== d && q.setAttribute('style', c.headerStyle);
        q.appendChild(da(a, c.header));
        g.appendChild(q);
        f.appendChild(g);
        if (
          e.verticalGridLines ||
          e.verticalGridHeaderLines ||
          (c.width >= 1 &&
            (typeof e.allowUserToResizeColumns === d || e.allowUserToResizeColumns) &&
            (typeof c.allowUserToResize === d || c.allowUserToResize))
        ) {
          f.isGripperVisible = false;
          var b = c.ganttChartView,
            o = function (a) {
              f.isGripperVisible = a;
              f.style.cursor = a ? (e.classic ? 'e-resize' : 'ew-resize') : 'default';
              f.style.borderRight = a
                ? 'solid 1px ' + e.border
                : e.verticalGridLines || e.verticalGridHeaderLines
                ? 'solid 1px ' + (e.verticalGridHeaderLines ? e.verticalGridHeaderLines : e.verticalGridLines)
                : '';
              g.style.width = c.width - (f.style.borderRight ? 1 : 0) + 'px';
            };
          (e.verticalGridLines || e.verticalGridHeaderLines) && o();
          if (
            c.width >= 1 &&
            (typeof e.allowUserToResizeColumns === d || e.allowUserToResizeColumns) &&
            (typeof c.allowUserToResize === d || c.allowUserToResize)
          ) {
            var m = e.splitterWidth;
            f.addEventListener(
              'mouseover',
              function (a) {
                if (
                  !DlhSoft.Controls.GanttChartView.isGripperDragging &&
                  !(f.isGripperDragging || (typeof a.offsetX === d && a.currentTarget != f))
                )
                  o(
                    (typeof a.offsetX !== d ? a.offsetX : a.layerX - a.currentTarget.offsetLeft) >=
                      c.width - m
                  );
              },
              true
            );
            f.addEventListener(
              'mousemove',
              function (a) {
                if (
                  !DlhSoft.Controls.GanttChartView.isGripperDragging &&
                  !(f.isGripperDragging || (typeof a.offsetX === d && a.currentTarget != f))
                )
                  o(
                    (typeof a.offsetX !== d ? a.offsetX : a.layerX - a.currentTarget.offsetLeft) >=
                      c.width - m
                  );
              },
              true
            );
            g.addEventListener(
              'mouseover',
              function (a) {
                !DlhSoft.Controls.GanttChartView.isGripperDragging &&
                  !f.isGripperDragging &&
                  !(typeof a.offsetX !== d || a.currentTarget != g) &&
                  o(a.layerX - a.currentTarget.offsetLeft >= c.width - m);
              },
              false
            );
            g.addEventListener(
              'mousemove',
              r(b, g, 'mousemove', function (a) {
                !DlhSoft.Controls.GanttChartView.isGripperDragging &&
                  !f.isGripperDragging &&
                  !(typeof a.offsetX !== d || a.currentTarget != g) &&
                  o(a.layerX - a.currentTarget.offsetLeft >= c.width - m);
              })
            );
            f.addEventListener(
              'mousedown',
              r(
                b,
                f,
                'mousedown',
                function (a) {
                  if (
                    !(
                      a.button != 0 ||
                      !f.isGripperVisible ||
                      DlhSoft.Controls.GanttChartView.isGripperDragging
                    )
                  ) {
                    DlhSoft.Controls.GanttChartView.isGripperDragging = true;
                    f.isGripperDragging = true;
                    f.initialGripperDraggingX = a.clientX;
                    f.initialColumnWidth = c.width;
                  }
                },
                true
              ),
              true
            );
            a.addEventListener(
              'mousemove',
              r(
                b,
                a,
                'mousemove',
                function (a) {
                  if (f.isGripperDragging) {
                    c.width = Math.max(
                      c.minWidth,
                      f.initialColumnWidth + (a.clientX - f.initialGripperDraggingX)
                    );
                    if (typeof c.maxWidth !== d && c.width > c.maxWidth) c.width = c.maxWidth;
                    g.style.width = c.width - 1 + 'px';
                    typeof e.columnWidthChangeHandler !== d &&
                      setTimeout(function () {
                        e.columnWidthChangeHandler(c, c.width);
                      }, 0);
                    if (!b.isWaitingToRefreshColumns) {
                      b.isWaitingToRefreshColumns = true;
                      setTimeout(function () {
                        var a = pb(e.columns);
                        b.gridHeader.style.width = a + 'px';
                        b.gridContent.style.width = a + 'px';
                        delete b.isWaitingToRefreshColumns;
                        b.refreshGridItems();
                      }, 0);
                    }
                  }
                },
                true
              ),
              true
            );
            a.addEventListener(
              'mouseup',
              r(
                b,
                a,
                'mouseup',
                function () {
                  if (f.isGripperDragging) {
                    delete f.isGripperDragging;
                    delete f.initialGripperDraggingX;
                    delete f.initialColumnWidth;
                    o(false);
                    delete DlhSoft.Controls.GanttChartView.isGripperDragging;
                  }
                },
                true
              ),
              true
            );
            f.addEventListener(
              'mouseout',
              r(b, f, 'mouseout', function () {
                f.isGripperDragging || o(false);
              })
            );
          }
        }
        return f;
      },
      ha = function (a, c) {
        for (var e = -1, b = null, f = null, g = [], q = 0; q < a.length; q++) {
          var o = a[q];
          if (typeof o.scheduleChartItem === d) {
            if (typeof o.isSelected === d) o.isSelected = false;
            if (o.isSelected) {
              f == null && (f = o);
              g.push(o);
            }
            if (typeof o.indentation === d) o.indentation = 0;
            if (q == 0 && o.indentation != 0) {
              o.indentation = 0;
              w(o, 'indentation', false, true);
            }
            if (typeof o.isExpanded === d) o.isExpanded = true;
            if (b != null) {
              var m = b.indentation + 1;
              if (o.indentation > m) {
                o.indentation = m;
                w(o, 'indentation', false, true);
              }
              b.hasChildren = o.indentation > b.indentation;
              if (b.hasChildren && typeof b.isMilestone !== d && b.isMilestone) {
                b.isMilestone = false;
                w(b, 'isMilestone', false, true);
              }
            }
          }
          o.isVisible = e < 0 || o.indentation <= e;
          if (typeof o.scheduleChartItem === d) {
            if (o.isVisible && !o.isExpanded) e = o.indentation;
            o.isExpanded && o.indentation == e && (e = -1);
            b = o;
          }
        }
        if (b != null) b.hasChildren = false;
        c.selectedItem = f;
        c.selectedItems = g;
      },
      Sa = function (a, c, e) {
        e.isTimingInformationInitialized = false;
        e.isBasicTimingInformationInitialized = false;
        var b = Z(c.currentTime),
          f = [],
          g,
          q,
          o;
        for (q = 0; q < a.length; q++) {
          g = a[q];
          g.index = q;
          var m = null;
          for (f.length > 0 && (m = f[f.length - 1]); m != null && g.indentation <= m.indentation; ) {
            f.pop();
            m = f[f.length - 1];
          }
          g.parent = m;
          g.children = [];
          m != null && m.children.push(g);
          if (!g.hasChildren || !(typeof g.isSummaryEnabled === d || g.isSummaryEnabled)) {
            o = g.start;
            var n = g.finish,
              l = g.completedFinish;
            if (typeof g.start === d) {
              g.start = b;
              g.preferredStart = g.start;
            }
            if (typeof g.finish === d)
              g.finish = typeof g.isMilestone === d || !g.isMilestone ? W(b) : g.start;
            if (typeof g.isSetAsCompleted === d)
              g.isSetAsCompleted =
                typeof g.completedFinish !== d && g.completedFinish.valueOf() == g.finish.valueOf();
            if (typeof g.completedFinish === d) g.completedFinish = g.start;
            if (typeof g.isRelativeToTimezone === d || g.isRelativeToTimezone) {
              g.start = new Date(g.start.valueOf() - g.start.getTimezoneOffset() * 60000);
              g.preferredStart = g.start;
              g.finish = new Date(g.finish.valueOf() - g.finish.getTimezoneOffset() * 60000);
              g.completedFinish =
                typeof g.completedFinish === 'number'
                  ? S(g.start, g.completedFinish * M(g.start, g.finish, c, C(g)), c, C(g))
                  : new Date(g.completedFinish.valueOf() - g.completedFinish.getTimezoneOffset() * 60000);
              if (typeof g.baselineStart !== d)
                g.baselineStart = new Date(
                  g.baselineStart.valueOf() - g.baselineStart.getTimezoneOffset() * 60000
                );
              if (typeof g.baselineFinish !== d)
                g.baselineFinish = new Date(
                  g.baselineFinish.valueOf() - g.baselineFinish.getTimezoneOffset() * 60000
                );
              if (typeof g.minStart !== d)
                g.minStart = new Date(g.minStart.valueOf() - g.minStart.getTimezoneOffset() * 60000);
              if (typeof g.maxStart !== d)
                g.maxStart = new Date(g.maxStart.valueOf() - g.maxStart.getTimezoneOffset() * 60000);
              if (typeof g.minFinish !== d)
                g.minFinish = new Date(g.minFinish.valueOf() - g.minFinish.getTimezoneOffset() * 60000);
              if (typeof g.maxFinish !== d)
                g.maxFinish = new Date(g.maxFinish.valueOf() - g.maxFinish.getTimezoneOffset() * 60000);
              g.isRelativeToTimezone = false;
            } else if (typeof g.completedFinish === 'number')
              g.completedFinish = S(g.start, g.completedFinish * M(g.start, g.finish, c, C(g)), c, C(g));
            if (typeof g.minStart !== d && g.start < g.minStart) g.start = g.minStart;
            else if (typeof g.maxStart !== d && g.start > g.maxStart) g.start = g.maxStart;
            if (typeof g.maxFinish !== d && g.finish > g.maxFinish) g.finish = g.maxFinish;
            else if (typeof g.minFinish !== d && g.finish < g.minFinish) g.finish = g.minFinish;
            if (g.finish < g.start) g.finish = g.start;
            if (g.completedFinish < g.start) g.completedFinish = g.start;
            else if (g.completedFinish > g.finish) g.completedFinish = g.finish;
            if (typeof g.loadChartItem === d)
              g.start = R(g.start, c, true, typeof g.isMilestone !== d && g.isMilestone, C(g));
            typeof g.dependsOf !== d && delete g.dependsOf;
            g.preferredStart = g.start;
            if (typeof g.loadChartItem === d) {
              g.finish = R(g.finish, c, typeof g.isMilestone !== d && g.isMilestone, true, C(g));
              g.completedFinish = R(
                g.completedFinish,
                c,
                typeof g.isMilestone !== d && g.isMilestone,
                true,
                C(g)
              );
            }
            if (g.finish < g.start) g.finish = g.start;
            if (g.completedFinish < g.start) g.completedFinish = g.start;
            if (g.completedFinish > g.finish) g.completedFinish = g.finish;
            (typeof o === d || g.start.valueOf() != o.valueOf()) && w(g, 'start', false, true);
            (typeof n === d || g.finish.valueOf() != n.valueOf()) && w(g, 'finish', false, true);
            (typeof l === d || g.completedFinish.valueOf() != l.valueOf()) &&
              w(g, 'completedFinish', false, true);
          }
          for (o = 0; o < f.length; o++) {
            n = f[o];
            if (typeof n.isSummaryEnabled === d || n.isSummaryEnabled) {
              if (typeof n.start === d || n.start > g.start) {
                n.start = g.start;
                w(n, 'start', false, true);
              }
              if (typeof n.finish === d || n.finish < g.finish) {
                n.finish = g.finish;
                w(n, 'finish', false, true);
              }
              if (typeof n.completedFinish === d || n.completedFinish.valueOf() != n.start.valueOf()) {
                n.completedFinish = n.start;
                w(n, 'completedFinish', false, true);
              }
            }
          }
          (m == null || g.indentation > m.indentation) && f.push(g);
        }
        e.isBasicTimingInformationInitialized = true;
        for (q = a.length; q-- > 0; ) {
          g = a[q];
          g.hasChildren && (typeof g.isSummaryEnabled === d || g.isSummaryEnabled) && gb(g);
        }
        if (c.areTaskDependencyConstraintsEnabled)
          for (q = 0; q < a.length; q++) {
            g = a[q];
            typeof g.predecessors !== d &&
              g.predecessors != null &&
              g.predecessors.length > 0 &&
              Ea(g, a, c, e);
          }
        else if (c.alwaysHandleInvalidDependencies)
          for (q = 0; q < a.length; q++) {
            g = a[q];
            typeof g.predecessors !== d &&
              g.predecessors != null &&
              g.predecessors.length > 0 &&
              Ea(g, a, c, e, true);
          }
        for (q = 0; q < a.length; q++) {
          g = a[q];
          if (!g.hasChildren && g.hasFixedEffort) {
            g.fixedEffort = ka(g, c);
            g.fixedEffortAssignments = la(g);
          }
        }
        e.isTimingInformationInitialized = true;
      },
      gb = function (a, c) {
        if (!(a.children.length <= 0 || (typeof a.isSummaryEnabled !== d && !a.isSummaryEnabled))) {
          typeof c === d && (c = true);
          var e = a.start,
            b = a.finish,
            f = a.completedFinish;
          delete a.start;
          delete a.finish;
          delete a.completedFinish;
          for (var g = 0; g <= 1; g++) {
            for (var q = 0; q < a.children.length; q++) {
              var o = a.children[q];
              if (
                !(typeof o.isParentSummarizationEnabled !== d && !o.isParentSummarizationEnabled && g < 1)
              ) {
                if (typeof a.start === d || a.start > o.start) a.start = o.start;
                if (typeof a.finish === d || a.finish < o.finish) a.finish = o.finish;
              }
            }
            if (typeof a.start !== d && typeof a.finish !== d) break;
          }
          a.completedFinish = a.start;
          (typeof e === d || a.start.valueOf() != e.valueOf()) && w(a, 'start', false, c);
          (typeof b === d || a.finish.valueOf() != b.valueOf()) && w(a, 'finish', false, c);
          (typeof f === d || a.completedFinish.valueOf() != f.valueOf()) && w(a, 'completedFinish', false, c);
        }
      },
      ub = function (a, c) {
        return a == c || tb(a, c) || tb(c, a);
      },
      tb = function (a, c) {
        var e = a.parent;
        return e == null
          ? false
          : (typeof e.isSummaryEnabled === d || e.isSummaryEnabled) && e == c
          ? true
          : tb(e, c);
      },
      Fa = function (a, c) {
        if (typeof a.predecessors === d) return false;
        for (var e = 0; e < a.predecessors.length; e++) if (a.predecessors[e].item == c) return true;
        return false;
      },
      Z = function (a) {
        return new Date(Math.floor(a.valueOf() / 86400000) * 86400000);
      },
      oa = function (a) {
        return a.valueOf() - Z(a).valueOf();
      },
      pa = function (a) {
        a = Math.floor((a.valueOf() - 259200000) / 86400000) % 7;
        a < 0 && (a = a + 7);
        return a;
      },
      Ca = function (a, c) {
        Z(a);
        return new Date(
          Math.floor((a.valueOf() - 259200000) / 604800000) * 604800000 + 259200000 + c * 86400000
        );
      },
      rb = function (a, c) {
        var e = Ca(a, c);
        return Math.abs(e.valueOf() - a.valueOf()) <= 3600000 ? e : new Date(e.valueOf() + 604800000);
      },
      hb = function (a) {
        return (a.hourWidth * (a.visibleDayFinish - a.visibleDayStart)) / 3600000;
      },
      F = function (a, c) {
        var e = hb(c),
          b = hb(c) * (c.visibleWeekFinish - c.visibleWeekStart + 1),
          f = Ca(a, c.weekStartDay),
          f =
            Math.floor((f - c.timelineStart) / 604800000) * b -
            Math.max(0, c.weekStartDay - c.visibleWeekStart) * e,
          b = pa(a),
          f =
            b <= c.visibleWeekStart
              ? f + 0
              : b > c.visibleWeekFinish
              ? f + (c.visibleWeekFinish - c.visibleWeekStart + 1) * e
              : f + (b - c.visibleWeekStart) * e,
          e = oa(a);
        b >= c.visibleWeekStart &&
          b <= c.visibleWeekFinish &&
          (f =
            e <= c.visibleDayStart
              ? f + 0
              : e >= c.visibleDayFinish
              ? f + ((c.visibleDayFinish - c.visibleDayStart) / 3600000) * c.hourWidth
              : f + ((e - c.visibleDayStart) / 3600000) * c.hourWidth);
        return f;
      },
      Na = function (a, c) {
        for (
          var e = c.timelineStart.valueOf(),
            b = hb(c),
            f = hb(c) * (c.visibleWeekFinish - c.visibleWeekStart + 1);
          a > f;

        ) {
          e = e + 604800000;
          a = a - f;
        }
        for (e = e + Math.max(0, c.visibleWeekStart - c.weekStartDay) * 86400000; a > b; ) {
          e = e + 86400000;
          a = a - b;
        }
        e = e + c.visibleDayStart;
        e = e + (a / c.hourWidth) * 3600000;
        return new Date(e);
      },
      Pb = function (a) {
        switch (a.theme) {
          default:
            return [
              {
                scaleType: 'NonworkingTime',
                isHeaderVisible: false,
                isHighlightingVisible: true,
                highlightingStyle: 'stroke-width: 0; fill: #f4f4f4; fill-opacity: 0.65',
              },
              { scaleType: 'Weeks', headerTextFormat: 'Date', headerStyle: 'padding: 2.25px' },
              {
                scaleType: 'Days',
                headerTextFormat: 'DayOfWeekAbbreviation',
                headerStyle: 'padding: 2.25px',
              },
              {
                scaleType: 'CurrentTime',
                isHeaderVisible: false,
                isSeparatorVisible: true,
                separatorStyle: 'stroke: #8bbf8a; stroke-width: 0.5px',
              },
            ];
          case 'ModernBordered':
            return [
              {
                scaleType: 'NonworkingTime',
                isHeaderVisible: false,
                isHighlightingVisible: true,
                highlightingStyle: 'stroke-width: 0; fill: #f4f4f4; fill-opacity: 0.65',
              },
              {
                scaleType: 'Weeks',
                headerTextFormat: 'Date',
                headerStyle: 'padding: 2.25px; border-right: solid 1px White; border-bottom: solid 1px White',
                isSeparatorVisible: true,
                separatorStyle: 'stroke: #c8bfe7; stroke-width: 0.5px',
              },
              {
                scaleType: 'Days',
                headerTextFormat: 'DayOfWeekAbbreviation',
                headerStyle: 'padding: 2.25px; border-right: solid 1px White',
              },
              {
                scaleType: 'CurrentTime',
                isHeaderVisible: false,
                isSeparatorVisible: true,
                separatorStyle: 'stroke: #8bbf8a; stroke-width: 1px',
              },
            ];
          case 'Aero':
            return [
              {
                scaleType: 'NonworkingTime',
                isHeaderVisible: false,
                isHighlightingVisible: true,
                highlightingStyle: 'stroke-width: 0; fill: #f4f4f4; fill-opacity: 0.65',
              },
              {
                scaleType: 'Weeks',
                headerTextFormat: 'Date',
                headerStyle:
                  'padding: 2.25px; border-right: solid 1px #c8bfe7; border-bottom: solid 1px #c8bfe7',
                isSeparatorVisible: true,
                separatorStyle: 'stroke: #c8bfe7; stroke-width: 0.5px',
              },
              {
                scaleType: 'Days',
                headerTextFormat: 'DayOfWeekAbbreviation',
                headerStyle: 'padding: 2.25px; border-right: solid 1px #c8bfe7',
              },
              {
                scaleType: 'CurrentTime',
                isHeaderVisible: false,
                isSeparatorVisible: true,
                separatorStyle: 'stroke: Black; stroke-width: 1px',
              },
            ];
        }
      },
      va = function (a, c) {
        return new Date(Math.floor((a - 259200000).valueOf() / c.updateScale) * c.updateScale + 259200000);
      },
      qb = function (a, c, e, b, f) {
        typeof f === d && (f = false);
        var g = a.ownerDocument,
          q = 0,
          o,
          m;
        for (o = 0; o < e.length; o++) {
          m = e[o];
          (typeof m.isHeaderVisible === d || m.isHeaderVisible) && q++;
        }
        var n = b.headerHeight;
        q > 0 && (n = b.headerHeight / q);
        for (o = 0; o < e.length; o++) {
          m = e[o];
          if (!f || !(m.scaleType != 'CurrentTime' && m.scaleType != 'FutureTime')) {
            q = Wc(m, b);
            if (typeof q !== d) {
              q.length == 0 && q.push({ start: b.timelineStart, finish: b.timelineFinish });
              var l = n;
              if (typeof m.headerHeight !== d) l = m.headerHeight;
              for (var I = b.timelineStart, x = 0; x < q.length; x++) {
                var h = q[x],
                  j = h.start,
                  t = h.finish;
                if (
                  m.scaleType != 'CurrentTime' &&
                  m.scaleType != 'FutureTime' &&
                  m.scaleType != 'NonworkingTime'
                ) {
                  if (j > I && (typeof m.isHeaderVisible === d || m.isHeaderVisible)) j = I;
                  j < I && (j = I);
                  if (j < b.timelineStart) j = b.timelineStart;
                  if (t > b.timelineFinish) t = b.timelineFinish;
                  t < j && (t = j);
                  if (
                    x == q.length - 1 &&
                    t < b.timelineFinish &&
                    (typeof m.isHeaderVisible === d || m.isHeaderVisible)
                  )
                    t = b.timelineFinish;
                  I = t;
                }
                var k = F(j, b),
                  E = F(t, b),
                  p = E - k;
                if (!(p <= 0)) {
                  if (typeof m.isHeaderVisible === d || m.isHeaderVisible) {
                    var s = Xc(m, h, b),
                      u = g.createElement('div');
                    u.setAttribute(
                      'style',
                      'float: left; overflow: hidden; width: ' + p + 'px; height: ' + l + 'px'
                    );
                    var Q = g.createElement('div');
                    Q.setAttribute('class', m.headerClass);
                    Q.setAttribute('style', m.headerStyle);
                    Q.appendChild(da(g, s));
                    u.appendChild(Q);
                    a.appendChild(u);
                  }
                  h.start = j;
                  h.finish = t;
                  h = false;
                  if (typeof m.isHighlightingVisible !== d) h = m.isHighlightingVisible;
                  if (h) {
                    h = g.createElementNS('http://www.w3.org/2000/svg', 'rect');
                    h.setAttribute('x', k - 1);
                    h.setAttribute('y', 0);
                    h.setAttribute('width', p);
                    h.setAttribute('height', 0);
                    h.setAttribute('class', m.highlightingClass);
                    h.setAttribute('style', m.highlightingStyle);
                    h.tag =
                      'Scale-Highlighting' +
                      (m.scaleType != 'CurrentTime' && m.scaleType != 'FutureTime' ? '' : '-CurrentTime');
                    c.appendChild(h);
                  }
                  k = false;
                  if (typeof m.isSeparatorVisible !== d) k = m.isSeparatorVisible;
                  if (k) {
                    k = g.createElementNS('http://www.w3.org/2000/svg', 'line');
                    k.setAttribute('x1', E - 0.75);
                    k.setAttribute('y1', 0);
                    k.setAttribute('x2', E - 0.75);
                    k.setAttribute('y2', 0);
                    k.setAttribute('class', m.separatorClass);
                    k.setAttribute('style', m.separatorStyle);
                    k.tag =
                      'Scale-Separator' +
                      (m.scaleType != 'CurrentTime' && m.scaleType != 'FutureTime' ? '' : '-CurrentTime');
                    c.appendChild(k);
                  }
                }
              }
            }
          }
        }
      },
      Wc = function (a, c) {
        var e = 'Custom',
          b,
          f;
        if (typeof a.scaleType !== d) e = a.scaleType;
        switch (e) {
          case 'CurrentTime':
            return [{ start: c.timelineStart, finish: c.currentTime }];
          case 'FutureTime':
            return [{ start: c.currentTime, finish: c.timelineFinish }];
          case 'Years':
            e = [];
            b = new Date(c.timelineStart.valueOf());
            f = b.getTimezoneOffset();
            b.setMonth(0, 1);
            var g = b.getTimezoneOffset();
            for (
              b = new Date(b.valueOf() - (g - f) * 60000 - (g > 0 ? 86400000 : 0));
              (f = Yc(b)), b < c.timelineFinish;
              b = f
            )
              e.push({ start: b, finish: f });
            return e;
          case 'Months':
            e = [];
            b = new Date(c.timelineStart.valueOf());
            f = b.getTimezoneOffset();
            b.setDate(1);
            g = b.getTimezoneOffset();
            for (
              b = new Date(b.valueOf() - (g - f) * 60000 - (g > 0 ? 86400000 : 0));
              (f = Zc(b)), b < c.timelineFinish;
              b = f
            )
              e.push({ start: b, finish: f });
            return e;
          case 'Weeks':
            e = [];
            b = c.timelineStart;
            f = c.weekStartDay;
            for (g = c.visibleWeekStart; pa(b) != f; ) b = Ua(b);
            for (; pa(b) < g; ) b = W(b);
            for (b = new Date(b.valueOf()); (f = ra(b)), b < c.timelineFinish; b = f)
              e.push({ start: b, finish: f });
            return e;
          case 'Days':
            e = [];
            for (b = c.timelineStart; (f = W(b)), b < c.timelineFinish; b = f)
              e.push({ start: b, finish: f });
            return e;
          case 'Hours':
            e = [];
            for (
              b = new Date(Z(c.timelineStart).valueOf() + c.visibleDayStart);
              (f = $c(new Date(b.valueOf() + 3600000), c.visibleDayFinish, c.visibleDayStart)),
                b < c.timelineFinish;
              b = f
            )
              e.push({ start: b, finish: f });
            return e;
          case 'NonworkingTime':
            e = [];
            b = Oa(c.timelineStart, c.workingWeekFinish);
            for (f = sb(b, c.workingWeekStart); b < c.timelineFinish; b = ra(b), f = ra(f))
              e.push({ start: b, finish: f });
            if (typeof c.specialNonworkingDays !== d)
              if (typeof c.specialNonworkingDays === 'function') {
                b = Oa(c.timelineStart, c.workingWeekFinish);
                for (f = W(b); b < c.timelineFinish; b = W(b), f = W(f))
                  c.specialNonworkingDays(b) && e.push({ start: b, finish: f });
              } else
                for (g = 0; g < c.specialNonworkingDays.length; g++) {
                  b = c.specialNonworkingDays[g];
                  f = W(b);
                  e.push({ start: b, finish: f });
                }
            return e;
          default:
            return a.intervals;
        }
      },
      $c = function (a, c, e) {
        if (oa(a) > c) {
          a = W(a);
          return new Date(Z(a).valueOf() + e);
        }
        return new Date(a.valueOf());
      },
      W = function (a) {
        return new Date(a.valueOf() + 86400000);
      },
      Ua = function (a) {
        return new Date(a.valueOf() - 86400000);
      },
      ra = function (a) {
        return new Date(a.valueOf() + 604800000);
      },
      Zc = function (a) {
        var c = a.getTimezoneOffset(),
          a = new Date(a.valueOf() + (c > 0 ? 86400000 : 0)),
          e = a.getMonth() + 1;
        if (e >= 12) {
          e = 0;
          a.setFullYear(a.getFullYear() + 1);
        }
        a.setMonth(e, 1);
        e = a.getTimezoneOffset();
        return new Date(a.valueOf() - (e - c) * 60000 - (e > 0 ? 86400000 : 0));
      },
      Yc = function (a) {
        var c = a.getTimezoneOffset(),
          a = new Date(a.valueOf() + (c > 0 ? 86400000 : 0));
        a.setFullYear(a.getFullYear() + 1);
        a.setMonth(0, 1);
        var e = a.getTimezoneOffset();
        return new Date(a.valueOf() - (e - c) * 60000 - (e > 0 ? 86400000 : 0));
      },
      dc = function (a, c) {
        if (typeof c === d) return false;
        if (typeof c === 'function') return c(a);
        for (var e = a.valueOf(), b = 0; b < c.length; b++) if (c[b].valueOf() == e) return true;
        return false;
      },
      Oa = function (a, c) {
        for (; pa(a) != c; ) a = Ua(a);
        return W(a);
      },
      sb = function (a, c) {
        for (; pa(a) != c; ) a = W(a);
        return new Date(a.valueOf());
      },
      Xc = function (a, c, e) {
        var b = 'Date';
        if (typeof a.headerTextFormat !== d) b = a.headerTextFormat;
        a = c.start;
        if (a < e.timelineStart) a = e.timelineStart;
        if (typeof b === 'function') return b(a);
        switch (b) {
          case 'Localized':
            return a.toLocaleString();
          case 'DateTime':
            return e.dateTimeFormatter(h(a));
          case 'Date':
            return e.dateFormatter(h(a));
          case 'Hour':
            a = new Date(a.valueOf() + a.getTimezoneOffset() * 60000);
            return (a.getHours() < 10 ? '0' : '') + a.getHours();
          case 'DayOfWeek':
            return e.daysOfWeek[pa(a)];
          case 'DayOfWeekAbbreviation':
            c = e.daysOfWeek[pa(a)];
            return c.length > 0 ? c[0].toUpperCase() : '';
          case 'Day':
            a = new Date(a.valueOf() + a.getTimezoneOffset() * 60000);
            return (a.getDate() < 10 ? '0' : '') + a.getDate();
          case 'Month':
            return e.months[new Date(a.valueOf() + (a.getTimezoneOffset() + 720) * 60000).getMonth()];
          case 'MonthAbbreviation':
            c = e.months[new Date(a.valueOf() + (a.getTimezoneOffset() + 720) * 60000).getMonth()];
            return c.length <= 0
              ? ''
              : c[0].toUpperCase() + (c.length > 1 ? c.substr(1, Math.min(3, c.length) - 1) : '');
          case 'Year':
            return new Date(a.valueOf() + (a.getTimezoneOffset() + 720) * 60000).getFullYear();
          case 'MonthYear':
            return (
              e.months[new Date(a.valueOf() + (a.getTimezoneOffset() + 720) * 60000).getMonth()] +
              ' ' +
              new Date(a.valueOf() + (a.getTimezoneOffset() + 720) * 60000).getFullYear()
            );
          default:
            return typeof c.headerText !== d ? c.headerText : a.toString();
        }
      },
      Oc = function (a, c, e) {
        for (var b = 0, f = 0; f < c.length; f++) {
          var g = c[f],
            q = b;
          typeof g.displayRowIndex !== d && (q = g.displayRowIndex * e.itemHeight);
          a.appendChild(ec(g, q, e));
          g.isVisible && !(typeof g.isHidden !== d && g.isHidden) && (b = Math.max(b, q + e.itemHeight));
        }
      },
      Pc = function (a, c, e, b, f, g, q, o) {
        for (var m = 0, n = 0; n < b.length; n++) {
          var l = b[n];
          q.isGridVisible && typeof l.displayRowIndex === d && a.appendChild(fc(l, b, f, c, g, q, o));
          var h = m;
          typeof l.displayRowIndex !== d && (h = l.displayRowIndex * q.itemHeight);
          e.appendChild(vb(l, h, q));
          l.isVisible && !(typeof l.isHidden !== d && l.isHidden) && (m = Math.max(m, h + q.itemHeight));
          l.itemTop = h;
        }
        za(c, m);
        setTimeout(function () {
          Va(b, c, q);
        }, 0);
      },
      Aa = function (a, c) {
        for (var e = 0, b = 0, f = -1, g = 0; g < a.length; g++) {
          var q = a[g];
          if (
            (typeof q.scheduleChartItem === d || q.scheduleChartItem == q) &&
            q.isVisible &&
            !(typeof q.isHidden !== d && q.isHidden)
          )
            if (typeof q.displayRowIndex === d && b > f) {
              e = e + c.itemHeight;
              f = b++;
            } else {
              e = Math.max(e, q.itemTop + c.itemHeight);
              f = q.displayRowIndex;
            }
        }
        return e;
      },
      Ga = function (a, c, e) {
        if (a.isPart) return Ga(a.ganttChartItem, c, e);
        var b;
        if (typeof a.displayRowIndex !== d) {
          b = a.displayRowIndex * e.itemHeight;
          return (a.itemTop = b);
        }
        for (var f = (b = 0); f < c.length; f++) {
          var g = c[f];
          if (g == a) break;
          g.isVisible &&
            !(typeof g.isHidden !== d && g.isHidden) &&
            typeof g.displayRowIndex === d &&
            (b = b + e.itemHeight);
        }
        return (a.itemTop = b);
      },
      Va = function (a, c, e) {
        if (
          !(
            typeof e.alternativeItemClass === d &&
            typeof e.alternativeItemStyle === d &&
            typeof e.alternativeChartItemClass === d &&
            typeof e.alternativeChartItemStyle === d
          )
        ) {
          for (var b = c.chartAreaAlternativeRows; b.childNodes.length > 0; ) b.removeChild(b.childNodes[0]);
          var f,
            g = 0,
            q = false;
          b.count = g;
          for (f = 0; f < a.length; f++) {
            var o = a[f];
            if (o.isVisible && typeof o.alternativeContentContainer !== d) {
              var m = o.alternativeContentContainer;
              if (g % 2 == 1) {
                typeof e.alternativeItemClass !== d && m.setAttribute('class', e.alternativeItemClass);
                typeof e.alternativeItemStyle !== d && m.setAttribute('style', e.alternativeItemStyle);
                if (typeof e.alternativeChartItemClass !== d || typeof e.alternativeChartItemStyle !== d)
                  if (!e.isVirtualizing || o.isVirtuallyVisible || !q) {
                    q = true;
                    if (typeof b.count === d || g > b.count) {
                      o = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                      o.setAttribute('x', 0);
                      o.setAttribute('y', g * e.itemHeight);
                      o.setAttribute('width', c.clientWidth);
                      o.setAttribute('height', e.itemHeight);
                      typeof e.alternativeChartItemClass !== d &&
                        o.setAttribute('class', e.alternativeChartItemClass);
                      typeof e.alternativeChartItemStyle !== d &&
                        o.setAttribute('style', e.alternativeChartItemStyle);
                      o.index = g;
                      b.appendChild(o);
                    }
                  }
                b.count = g;
              } else {
                m.setAttribute('class', '');
                m.setAttribute('style', '');
              }
              g++;
            }
          }
          for (f = b.childNodes.length; f-- > 0; ) {
            a = b.childNodes[f];
            a.index > b.count && b.removeChild(a);
          }
        }
      },
      fc = function (a, c, e, b, f, g, q) {
        var o = a.ganttChartView.ownerDocument,
          m = o.createElement('div');
        typeof g.itemClass !== d && m.setAttribute('class', g.itemClass);
        typeof g.itemStyle !== d && m.setAttribute('style', g.itemStyle);
        m.style.display = a.isVisible ? 'block' : 'none';
        var n = o.createElement('div');
        a.alternativeContentContainer = n;
        var l = o.createElement('div');
        try {
          l.addEventListener(
            'mousedown',
            r(q, l, 'mousedown', function (c) {
              c.target != a.selectionInput && D(a, g);
            })
          );
          l.addEventListener(
            'mouseup',
            r(q, l, 'mouseup', function (c) {
              c.target != a.selectionInput && D(a, g);
            })
          );
        } catch (h) {}
        if (a.isSelected) {
          typeof g.selectedItemClass !== d && l.setAttribute('class', g.selectedItemClass);
          typeof g.selectedItemStyle !== d && l.setAttribute('style', g.selectedItemStyle);
        }
        var x = o.createElement('div');
        typeof a['class'] !== d && x.setAttribute('class', a['class']);
        typeof a.style !== d && x.setAttribute('style', a.style);
        var G = o.createElement('div');
        if (a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled)) {
          typeof g.summaryItemClass !== d && G.setAttribute('class', g.summaryItemClass);
          typeof g.summaryItemStyle !== d && G.setAttribute('style', g.summaryItemStyle);
        } else if (a.isMilestone) {
          typeof g.milestoneItemClass !== d && G.setAttribute('class', g.milestoneItemClass);
          typeof g.milestoneItemStyle !== d && G.setAttribute('style', g.milestoneItemStyle);
        } else {
          typeof g.standardItemClass !== d && G.setAttribute('class', g.standardItemClass);
          typeof g.standardItemStyle !== d && G.setAttribute('style', g.standardItemStyle);
        }
        o = o.createElement('div');
        a.gridItem = o;
        a.gridItemContent = G;
        a.gridItemSelectionContainer = l;
        var j = g.itemHeight;
        if (!a.isVisible || (typeof a.isHidden !== d && a.isHidden)) j = 0;
        o.setAttribute('style', 'overflow: hidden; height: ' + j + 'px');
        Ha(o, a, c, e, b, f, g);
        G.appendChild(o);
        x.appendChild(G);
        l.appendChild(x);
        n.appendChild(l);
        m.appendChild(n);
        a.gridItemContainer = m;
        (typeof g.isGridRowClickTimeScrollingEnabled === d || g.isGridRowClickTimeScrollingEnabled) &&
          m.addEventListener(
            'mouseup',
            r(
              q,
              m,
              'mouseup',
              function () {
                var c;
                if (typeof a.ganttChartItems === d) c = a.start;
                else {
                  c = null;
                  for (var e = 0; e < a.ganttChartItems.length; e++)
                    if (c == null || a.ganttChartItems[e].start < c) c = a.ganttChartItems[e].start;
                  c = c == null ? a.start : c;
                }
                c = F(c, g);
                e = c - g.hourWidth * 8;
                c - e >= b.container.clientWidth / 2.5 && (e = c);
                b.container.scrollLeft = Math.max(0, e);
              },
              true
            ),
            true
          );
        return m;
      },
      Ha = function (a, c, e, b, f, g, q) {
        var o = c.ganttChartView.ownerDocument;
        if (!q.isVirtualizing || (typeof c.isVirtuallyVisible !== d && c.isVirtuallyVisible)) {
          var m = function () {
            if (typeof c.gridItemSelectionContainer !== d) {
              var m = c.gridItemSelectionContainer;
              typeof q.selectedItemClass !== d &&
                m.setAttribute('class', c.isSelected ? q.selectedItemClass : null);
              typeof q.selectedItemStyle !== d &&
                m.setAttribute('style', c.isSelected ? q.selectedItemStyle : null);
            }
            if (typeof c.gridItemContent !== d) {
              m = c.gridItemContent;
              m.setAttribute('class', '');
              m.setAttribute('style', '');
              if (c.hasChildren && (typeof c.isSummaryEnabled === d || c.isSummaryEnabled)) {
                typeof q.summaryItemClass !== d && m.setAttribute('class', q.summaryItemClass);
                typeof q.summaryItemStyle !== d && m.setAttribute('style', q.summaryItemStyle);
              } else if (c.isMilestone) {
                typeof q.milestoneItemClass !== d && m.setAttribute('class', q.milestoneItemClass);
                typeof q.milestoneItemStyle !== d && m.setAttribute('style', q.milestoneItemStyle);
              } else {
                typeof q.standardItemClass !== d && m.setAttribute('class', q.standardItemClass);
                typeof q.standardItemStyle !== d && m.setAttribute('style', q.standardItemStyle);
              }
            }
            var m = [],
              l = null;
            try {
              l = o.activeElement;
            } catch (h) {}
            for (; l != null && l != c.gridItem; ) {
              m.push(l);
              l = l.parentNode;
            }
            for (var x = -1, l = a.childNodes.length; l-- > 0; )
              if (m.indexOf(a.childNodes[l]) >= 0) {
                x = l;
                break;
              }
            for (l = a.childNodes.length; l-- > 0; ) a.removeChild(a.childNodes[l]);
            for (l = 0; l < b.length; l++) a.appendChild(gc(c, e, b[l], f, g, q));
            typeof c.ganttChartView.draggingItem === d &&
              x >= 0 &&
              setTimeout(function () {
                try {
                  for (
                    var c = a.childNodes[x];
                    c.nodeName != 'input' &&
                    c.nodeName != 'textarea' &&
                    c.nodeName != 'label' &&
                    c.nodeName != 'select' &&
                    c.nodeName != 'button' &&
                    c.childNodes.length > 0;

                  )
                    c = c.childNodes[0];
                  c && c.dontAutoFocus
                    ? delete c.dontAutoFocus
                    : setTimeout(function () {
                        try {
                          c.focus();
                        } catch (a) {}
                      }, 0);
                } catch (e) {}
              }, 0);
          };
          if (c.wasGridItemContentLoaded) setTimeout(m, 0);
          else {
            m();
            c.wasGridItemContentLoaded = true;
          }
        }
      },
      Wa = function (a) {
        if (typeof a.isWaitingToRefreshGridItem === d) {
          a.isWaitingToRefreshGridItem = true;
          setTimeout(function () {
            typeof a.gridItem !== d &&
              Ha(
                a.gridItem,
                a,
                a.ganttChartView.items,
                a.ganttChartView.settings.columns,
                a.ganttChartView.chartContent,
                a.ganttChartView.settings.toggleButtonAreaWidth,
                a.ganttChartView.settings
              );
            delete a.isWaitingToRefreshGridItem;
          }, 0);
        }
      },
      ad = function (a, c, e, b, f, g, q) {
        var o = c.ganttChartView.ownerDocument;
        (!q.isVirtualizing || (typeof c.isVirtuallyVisible !== d && c.isVirtuallyVisible)) &&
          setTimeout(function () {
            if (typeof c.gridItemSelectionContainer !== d) {
              var m = c.gridItemSelectionContainer;
              typeof q.selectedItemClass !== d &&
                m.setAttribute('class', c.isSelected ? q.selectedItemClass : null);
              typeof q.selectedItemStyle !== d &&
                m.setAttribute('style', c.isSelected ? q.selectedItemStyle : null);
            }
            var m = [],
              n = null;
            try {
              n = o.activeElement;
            } catch (l) {}
            for (; n != null && n != c.gridItem; ) {
              m.push(n);
              n = n.parentNode;
            }
            for (var h = -1, n = a.childNodes.length; n-- > 0; )
              if (a.childNodes[n].isSelection && m.indexOf(a.childNodes[n]) >= 0) {
                h = n;
                break;
              }
            for (n = a.childNodes.length; n-- > 0; )
              a.childNodes[n].isSelection && a.removeChild(a.childNodes[n]);
            for (n = 0; n < b.length; n++)
              b[n].isSelection &&
                n < a.childNodes.length &&
                a.insertBefore(gc(c, e, b[n], f, g, q), a.childNodes[n]);
            typeof c.ganttChartView.draggingItem === d &&
              h >= 0 &&
              setTimeout(function () {
                try {
                  for (
                    var c = a.childNodes[h];
                    c.nodeName != 'input' &&
                    c.nodeName != 'textarea' &&
                    c.nodeName != 'label' &&
                    c.nodeName != 'select' &&
                    c.nodeName != 'button' &&
                    c.childNodes.length > 0;

                  )
                    c = c.childNodes[0];
                  c && c.dontAutoFocus
                    ? delete c.dontAutoFocus
                    : setTimeout(function () {
                        try {
                          c.focus();
                        } catch (a) {}
                      }, 0);
                } catch (e) {}
              }, 0);
          }, 0);
      },
      bc = function (a) {
        typeof a.gridItem !== d &&
          ad(
            a.gridItem,
            a,
            a.ganttChartView.items,
            a.ganttChartView.settings.columns,
            a.ganttChartView.chartContent,
            a.ganttChartView.settings.toggleButtonAreaWidth,
            a.ganttChartView.settings
          );
      },
      gc = function (a, c, e, b, f, g) {
        var q = a.ganttChartView.ownerDocument,
          o = q.createElement('div'),
          m = 0;
        e.isTreeView == true && (m = g.indentationLevelWidth * a.indentation);
        o.setAttribute(
          'style',
          'overflow: hidden; vertical-align: middle; display: table-cell; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; margin: 0px; padding: 0px; width: ' +
            e.width +
            'px; height: ' +
            g.itemHeight +
            'px; padding-left: ' +
            m +
            'px'
        );
        var n = q.createElement('div');
        typeof e.cellClass !== d
          ? n.setAttribute('class', e.cellClass)
          : typeof g.cellClass !== d && n.setAttribute('class', g.cellClass);
        typeof e.cellStyle !== d
          ? n.setAttribute('style', e.cellStyle)
          : typeof g.cellStyle !== d && n.setAttribute('style', g.cellStyle);
        var l = q.createElement('div');
        l.setAttribute(
          'style',
          'white-space: nowrap; overflow: hidden; margin: 0px; padding: 0px; width: ' +
            (e.width - m - 16) +
            'px'
        );
        if (e.isTreeView == true) {
          m = q.createElement('div');
          m.setAttribute('style', 'display: inline-block; width: ' + f + 'px');
          if (a.hasChildren) {
            f = q.createElement('div');
            f.setAttribute(
              'style',
              'cursor: default; padding-left: 1px; font-size: 12px; display: inline-block'
            );
            q = g.collapsedToggleButtonTemplate;
            if (a.isExpanded) q = g.expandedToggleButtonTemplate;
            q = q();
            f.appendChild(q);
            hc(f, a, c, q, b, null, null, g);
            m.appendChild(f);
          }
          l.appendChild(m);
        }
        a = e.cellTemplate(a);
        l.appendChild(a);
        n.appendChild(l);
        o.appendChild(n);
        o.isSelection = e.isSelection;
        return o;
      },
      Nb = function (a, c) {
        return function () {
          var e = a.ownerDocument.createElementNS('http://www.w3.org/2000/svg', 'polygon');
          e.setAttribute('points', '3.5,2.5 3.5,11.5 10.5,6.5');
          return u(e, c);
        };
      },
      Ob = function (a, c) {
        return function () {
          var e = a.ownerDocument.createElementNS('http://www.w3.org/2000/svg', 'polygon');
          e.setAttribute('points', '2.5,3.5 11.5,3.5 6.5,10.5');
          return u(e, c);
        };
      },
      hc = function (a, c, e, b, f, g, q, o) {
        if (typeof g === d || g == null) g = b.querySelector('#PART_Button');
        g != null && ic(g, g, o, c.ganttChartView);
        if (typeof q === d || q == null) q = b.querySelector('#PART_Border');
        q != null && ic(q, g, o, c.ganttChartView);
        e = { toggleButton: a, item: c, items: e, content: b, chartContent: f, settings: o };
        if (g != null) {
          g.tag = e;
          (typeof o.isExport === d || !o.isExport) &&
            g.addEventListener('mouseup', r(c.ganttChartView, g, 'mouseup', wb, true), true);
        }
        if (q != null) {
          q.tag = e;
          (typeof o.isExport === d || !o.isExport) &&
            q.addEventListener('mouseup', r(c.ganttChartView, q, 'mouseup', wb, true), true);
        }
        a.tag = e;
        g == null &&
          q == null &&
          (typeof o.isExport === d || !o.isExport) &&
          a.addEventListener('mouseup', r(c.ganttChartView, a, 'mouseup', wb, true), true);
        b.addEventListener(
          'keypress',
          r(
            c.ganttChartView,
            b,
            'keypress',
            function (a) {
              if (a.keyCode == 32) {
                a.preventDefault();
                a.stopPropagation();
                ma(c, !c.isExpanded, true);
              }
            },
            true
          ),
          true
        );
        c.toggleButton = a;
      },
      ic = function (a, c, e, b) {
        if (!(typeof e.isExport !== d && e.isExport)) {
          a.addEventListener(
            'mouseover',
            r(
              b,
              a,
              'mouseover',
              function () {
                typeof e.toggleButtonHoveringClass !== d &&
                  c.setAttribute('class', e.toggleButtonHoveringClass);
                typeof e.toggleButtonHoveringStyle !== d &&
                  c.setAttribute('style', e.toggleButtonHoveringStyle);
              },
              true
            ),
            true
          );
          a.addEventListener(
            'mouseout',
            r(
              b,
              a,
              'mouseout',
              function () {
                c.setAttribute('class', e.toggleButtonClass);
                c.setAttribute('style', e.toggleButtonStyle);
              },
              true
            ),
            true
          );
        }
      },
      wb = function (a) {
        a.stopPropagation();
        a = a.target.tag.item;
        ma(a, !a.isExpanded, true);
      },
      ma = function (a, c, e, b) {
        if (a.isExpanded != c) {
          a.isExpanded = c;
          (typeof b === d || b) && w(a, 'isExpanded', e, true);
          jc(a);
        }
      },
      jc = function (a, c) {
        if (typeof a.toggleButton === d)
          (typeof c === d || c) &&
            setTimeout(function () {
              jc(a, false);
            }, 0);
        else {
          var e = a.toggleButton,
            b = e.tag,
            f = b.settings,
            g = f.expandedToggleButtonTemplate,
            q = f.collapsedToggleButtonTemplate;
          a.isExpanded && (q = g);
          var g = q(),
            q = g.querySelector('#PART_Button'),
            o = g.querySelector('#PART_Border');
          e.replaceChild(g, b.content);
          b.content = g;
          var m = b.items,
            b = b.chartContent;
          hc(e, a, m, g, b, q, o, f);
          a.isVisible && !(typeof a.isHidden !== d && a.isHidden) && bd(m, a, b, f);
          ua(a);
          Va(m, b, f);
          e = a.scheduleChartView;
          if (typeof e !== d)
            for (f = a.scheduleChartIndex; f < e.scheduleChartItems.length; f++) {
              b = e.scheduleChartItems[f];
              if (typeof b.ganttChartItems !== d)
                for (g = 0; g < b.ganttChartItems.length; g++) {
                  q = b.ganttChartItems[g];
                  q.displayRowIndex = b.itemTop / e.settings.itemHeight;
                  e.refreshChartItem(q);
                }
            }
        }
      },
      bd = function (a, c, e, b) {
        var f = -1,
          g = false,
          q = false,
          o = false,
          m = 0,
          n,
          l,
          h,
          x = [];
        for (l = 0; l < a.length; l++) {
          h = a[l];
          if (h == c) {
            g = h.isExpanded;
            f = h.indentation;
          } else h.indentation <= f && (o = true);
          var j = h.chartItem,
            k = h.chartPredecessorItems;
          if (q && !o) {
            if (h.scheduleChartItem && h.scheduleChartItem != h) n = h.scheduleChartItem.isVisible;
            else if (g) {
              n = true;
              for (var t = h.parent; t != null; ) {
                if (!t.isVisible || !t.isExpanded) {
                  n = false;
                  break;
                }
                t = t.parent;
              }
            } else n = false;
            if (h.isVisible != n) {
              h.isVisible = g;
              w(h, 'isVisible', false, true);
              n = b.itemHeight;
              if (!h.isVisible || (typeof h.isHidden !== d && h.isHidden)) n = 0;
              if (typeof h.gridItem !== d) h.gridItem.style.height = n + 'px';
              if (typeof h.gridItemContainer !== d)
                h.gridItemContainer.style.display = n > 0 ? 'block' : 'none';
              n = 'visible';
              if (!h.isVisible || (typeof h.isHidden !== d && h.isHidden)) n = 'hidden';
              j.setAttribute('style', 'visibility: ' + n);
              k.setAttribute('style', 'visibility: ' + n);
            }
          }
          if (typeof h.displayRowIndex === d) {
            j.setAttribute('transform', 'translate(0, ' + m + ')');
            k.setAttribute('transform', 'translate(0, ' + m + ')');
            Ia(h, m);
          }
          h == c && (q = true);
          n = b.itemHeight;
          if (!h.isVisible || (typeof h.isHidden !== d && h.isHidden)) n = 0;
          if (h.itemTop != m) h.itemTop = m;
          typeof h.displayRowIndex === d && (m = m + n);
          if (h.predecessors && h.predecessors.length > 0 && x.indexOf(h) < 0) {
            x.push(h);
            for (j = 0; j < h.predecessors.length; j++) {
              k = h.predecessors[j].item;
              x.indexOf(k) < 0 && x.push(k);
            }
          }
        }
        za(e, m);
        wa(a, c);
        if (b.areTaskDependenciesVisible) for (l = 0; l < x.length; l++) wa(a, x[l]);
        Ba(a, e.container, b);
      },
      Ia = function (a, c) {
        typeof a.parts === d ||
          a.parts.length <= 0 ||
          setTimeout(function () {
            for (var e = 0; e < a.parts.length; e++) {
              var b = a.parts[e];
              b.itemTop = c;
              if (typeof b.chartItem !== d) {
                b.chartItem.setAttribute('transform', 'translate(0, ' + c + ')');
                b.chartPredecessorItems.setAttribute('transform', 'translate(0, ' + c + ')');
                b.chartItem.style.visibility = a.chartItem.style.visibility;
                b.chartPredecessorItems.style.visibility = a.chartItem.style.visibility;
                if (typeof b.isInternallyHidden !== d) {
                  delete b.isInternallyHidden;
                  b.chartItem.style.display = 'inline';
                }
              }
            }
          }, 0);
      },
      za = function (a, c) {
        if (c < a.availableHeight) c = a.availableHeight;
        a.style.height = c + 'px';
        var e = a.chartArea;
        e.style.height = c + 'px';
        for (var b = 0; b < e.childNodes.length; b++) {
          var f = e.childNodes[b];
          if (typeof f.tag !== d)
            switch (f.tag) {
              case 'Scale-Highlighting':
              case 'Scale-Highlighting-CurrentTime':
                f.setAttribute('height', c);
                break;
              case 'Scale-Separator':
              case 'Scale-Separator-CurrentTime':
                f.setAttribute('y2', c);
            }
        }
      },
      wa = function (a, c) {
        var e = [],
          b,
          f,
          g,
          q;
        if (typeof c.successors === d) {
          c.successors = [];
          for (f = 0; f < a.length; f++) {
            b = a[f];
            if (!(b == c || typeof b.predecessors === d || b.predecessors.length == 0))
              for (g = 0; g < b.predecessors.length; g++)
                if (b.predecessors[g].item == c) {
                  c.successors.push(b);
                  break;
                }
          }
        }
        for (f = 0; f < c.successors.length; f++) {
          var o = c.successors[f];
          if (typeof o.predecessors !== d && o.predecessors.length > 0)
            for (g = 0; g < o.predecessors.length; g++) {
              var m = o.predecessors[g].item;
              if (typeof m !== d) {
                b = true;
                for (q = 0; q < e; q++)
                  if (m == e[q]) {
                    b = false;
                    break;
                  }
                if (b) {
                  ia(m.chartPredecessorItems, m, m.ganttChartView.settings);
                  ja(m.chartItem, m, m.ganttChartView.settings);
                  e.push(m);
                }
              }
            }
          if (typeof o.predecessors !== d && o.predecessors.length > 0) {
            b = true;
            for (q = 0; q < e; q++)
              if (typeof o !== d && o == e[q]) {
                b = false;
                break;
              }
            if (b) {
              ia(o.chartPredecessorItems, o, o.ganttChartView.settings);
              ja(o.chartItem, o, o.ganttChartView.settings);
              e.push(o);
            }
          }
        }
      },
      ec = function (a, c, e) {
        var b = a.ganttChartView.ownerDocument.createElementNS('http://www.w3.org/2000/svg', 'g');
        a.chartPredecessorItems = b;
        var f = 'visible';
        if (!a.isVisible || (typeof a.isHidden !== d && a.isHidden)) f = 'hidden';
        b.setAttribute(
          'style',
          'visibility: ' + f + '; -ms-touch-action: pinch-zoom; touch-action: pinch-zoom'
        );
        b.setAttribute('transform', 'translate(0, ' + c + ')');
        if (typeof a.isInternallyHidden !== d && a.isInternallyHidden) b.style.display = 'none';
        ia(b, a, e);
        return b;
      },
      vb = function (a, c, e) {
        var b = a.ganttChartView.ownerDocument.createElementNS('http://www.w3.org/2000/svg', 'g');
        a.chartItem = b;
        var f = 'visible';
        if (!a.isVisible || (typeof a.isHidden !== d && a.isHidden)) f = 'hidden';
        b.setAttribute(
          'style',
          'visibility: ' + f + '; -ms-touch-action: pinch-zoom; touch-action: pinch-zoom'
        );
        b.setAttribute('transform', 'translate(0, ' + c + ')');
        if (typeof a.isInternallyHidden !== d && a.isInternallyHidden) b.style.display = 'none';
        Ia(a, c);
        ja(b, a, e);
        try {
          b.addEventListener(
            'mousedown',
            r(a.ganttChartView, b, 'mousedown', function () {
              D(a, e);
            })
          );
          b.addEventListener(
            'mouseup',
            r(a.ganttChartView, b, 'mouseup', function () {
              D(a, e);
            })
          );
          b.addEventListener(
            'touchstart',
            r(a.ganttChartView, b, 'touchstart', function () {
              D(a, e);
            })
          );
          b.addEventListener(
            'touchend',
            r(a.ganttChartView, b, 'touchend', function () {
              D(a, e);
            })
          );
        } catch (g) {}
        return b;
      },
      ia = function (a, c, e) {
        for (var b = a.childNodes.length; b-- > 0; ) a.removeChild(a.childNodes[b]);
        if (c.predecessors) for (b = 0; b < c.predecessors.length; b++) cd(b, a, c, c.predecessors[b], e);
      },
      cd = function (a, c, e, b, f) {
        if (
          !(
            (f.isVirtualizing && (typeof e.isVirtuallyVisible === d || !e.isVirtuallyVisible)) ||
            (typeof e.isBarVisible !== d && !e.isBarVisible)
          )
        )
          if (
            f.areTaskDependenciesVisible &&
            !(
              (typeof e.ganttChartView === d || e.ganttChartView.items.indexOf(b.item)) < 0 ||
              !b.item.isVisible ||
              (typeof b.item.isHidden !== d && b.item.isHidden) ||
              (typeof b.item.isBarVisible !== d && !b.item.isBarVisible)
            )
          ) {
            var g = f.dependencyLineTemplate(e, b);
            g.predecessorIndex = a;
            (function () {
              g.addEventListener(
                'click',
                r(e.ganttChartView, g, 'click', function (a) {
                  f.dependencyLineClickHandler &&
                    f.dependencyLineClickHandler(e.predecessors[this.predecessorIndex], e, a);
                })
              );
              g.addEventListener(
                'dblclick',
                r(e.ganttChartView, g, 'dblclick', function (a) {
                  f.dependencyLineDoubleClickHandler &&
                    f.dependencyLineDoubleClickHandler(e.predecessors[this.predecessorIndex], e, a);
                })
              );
            })(b);
            typeof f.predecessorItemContextMenuHandler !== d
              ? g.addEventListener(
                  'contextmenu',
                  r(e.ganttChartView, g, 'contextmenu', function (a) {
                    f.predecessorItemContextMenuHandler(a, e.predecessors[this.predecessorIndex], e);
                  })
                )
              : !f.isReadOnly &&
                !f.isChartReadOnly &&
                !f.areTaskPredecessorsReadOnly &&
                (typeof e.isReadOnly === d || !e.isReadOnly) &&
                g.addEventListener(
                  'contextmenu',
                  r(e.ganttChartView, g, 'contextmenu', function (a) {
                    if (confirm('Are you sure you want to remove the predecessor?')) {
                      for (var c = [], b = 0; b < e.predecessors.length; b++)
                        if (b != this.predecessorIndex) {
                          var d = e.predecessors[b];
                          delete d.item.successors;
                          c.push(d);
                        }
                      e.predecessors = c;
                      w(e, 'predecessors', true, true);
                      ia(e.chartPredecessorItems, e, f);
                      ja(e.chartItem, e, f);
                      wa(e.ganttChartView.items, e);
                    }
                    a.preventDefault();
                  })
                );
            c.appendChild(g);
            if (
              f.isDependencyToolTipVisible &&
              (!f.useInlineToolTips || !f.useInlineToolTipsForDependencies)
            ) {
              a = f.predecessorItemTemplate;
              if (typeof b.template !== d) a = b.template;
              g.appendChild(a(e, b));
            }
          }
      },
      ja = function (a, c, e) {
        for (var b = a.childNodes.length; b-- > 0; ) a.removeChild(a.childNodes[b]);
        if (
          !(
            (e.isVirtualizing && (typeof c.isVirtuallyVisible === d || !c.isVirtuallyVisible)) ||
            (typeof c.isBarVisible !== d && !c.isBarVisible)
          )
        ) {
          b = e.standardTaskTemplate;
          if (typeof c.standardTaskTemplate !== d) b = c.standardTaskTemplate;
          if (c.hasChildren && (typeof c.isSummaryEnabled === d || c.isSummaryEnabled)) {
            b = e.summaryTaskTemplate;
            if (typeof c.summaryTaskTemplate !== d) b = c.summaryTaskTemplate;
          } else if (c.isMilestone) {
            b = e.milestoneTaskTemplate;
            if (typeof c.milestoneTaskTemplate !== d) b = c.milestoneTaskTemplate;
          }
          if (typeof c.taskTemplate !== d) b = c.taskTemplate;
          if (typeof e.internalPreTaskTemplate !== d) {
            var f = e.internalPreTaskTemplate(c);
            f != null && a.appendChild(f);
          }
          b = b(c);
          b.addEventListener(
            'contextmenu',
            r(c.ganttChartView, b, 'contextmenu', function (a) {
              typeof e.itemContextMenuHandler !== d ? e.itemContextMenuHandler(a, c) : a.preventDefault();
            })
          );
          a.appendChild(b);
          if (typeof e.internalExtraTaskTemplate !== d) {
            f = e.internalExtraTaskTemplate(c);
            f != null && a.appendChild(f);
          }
          if (typeof e.extraTaskTemplate !== d) {
            f = e.extraTaskTemplate(c);
            f != null && a.appendChild(f);
          }
          if (e.areTaskAssignmentsVisible) {
            a = e.assignmentsTemplate(c);
            b.appendChild(a);
          }
          if (e.isTaskToolTipVisible && !e.useInlineToolTips) {
            a = e.itemTemplate;
            if (typeof c.template !== d) a = c.template;
            b.appendChild(a(c));
          }
        }
      },
      Xa = function (a) {
        for (var c = 0; c < a.length; c++) Pa(a[c]);
      },
      ua = function (a) {
        if (
          (!a.ganttChartView.settings.isVirtualizing ||
            (typeof a.isVirtuallyVisible !== d && a.isVirtuallyVisible)) &&
          typeof a.isWaitingToRefreshChartItem === d
        ) {
          a.isWaitingToRefreshChartItem = true;
          setTimeout(function () {
            ia(a.chartPredecessorItems, a, a.ganttChartView.settings);
            ja(a.chartItem, a, a.ganttChartView.settings);
            var c = Ga(a, a.ganttChartView.items, a.ganttChartView.settings);
            a.chartItem.setAttribute('transform', 'translate(0, ' + c + ')');
            a.chartPredecessorItems.setAttribute('transform', 'translate(0, ' + c + ')');
            Ia(a, c);
            delete a.isWaitingToRefreshChartItem;
          }, 0);
        }
      },
      sa = function (a) {
        if (
          !a.ganttChartView.settings.isVirtualizing ||
          (typeof a.isVirtuallyVisible !== d && a.isVirtuallyVisible)
        )
          if (typeof a.isWaitingToRefreshGridItem !== d || typeof a.isWaitingToRefreshChartItem !== d) {
            typeof a.isWaitingToRefreshGridItem === d && Wa(a);
            typeof a.isWaitingToRefreshChartItem === d && ua(a);
          } else {
            a.isWaitingToRefreshGridItem = true;
            a.isWaitingToRefreshChartItem = true;
            setTimeout(function () {
              typeof a.gridItem !== d &&
                Ha(
                  a.gridItem,
                  a,
                  a.ganttChartView.items,
                  a.ganttChartView.settings.columns,
                  a.ganttChartView.chartContent,
                  a.ganttChartView.settings.toggleButtonAreaWidth,
                  a.ganttChartView.settings
                );
              ia(a.chartPredecessorItems, a, a.ganttChartView.settings);
              ja(a.chartItem, a, a.ganttChartView.settings);
              var c = Ga(a, a.ganttChartView.items, a.ganttChartView.settings);
              a.chartItem.setAttribute('transform', 'translate(0, ' + c + ')');
              a.chartPredecessorItems.setAttribute('transform', 'translate(0, ' + c + ')');
              Ia(a, c);
              delete a.isWaitingToRefreshGridItem;
              delete a.isWaitingToRefreshChartItem;
            }, 0);
          }
      },
      xa = function (a) {
        if (
          (!a.ganttChartView.settings.isVirtualizing ||
            (typeof a.isVirtuallyVisible !== d && a.isVirtuallyVisible)) &&
          typeof a.isWaitingToRefreshPredecessorItems === d
        ) {
          a.isWaitingToRefreshPredecessorItems = true;
          setTimeout(function () {
            wa(a.ganttChartView.items, a);
            delete a.isWaitingToRefreshPredecessorItems;
          }, 0);
        }
      },
      Pa = function (a) {
        if (
          !a.ganttChartView.settings.isVirtualizing ||
          (typeof a.isVirtuallyVisible !== d && a.isVirtuallyVisible)
        )
          if (
            typeof a.isWaitingToRefreshGridItem !== d ||
            typeof a.isWaitingToRefreshChartItem !== d ||
            typeof a.isWaitingToRefreshPredecessorItems !== d
          ) {
            typeof a.isWaitingToRefreshGridItem === d && Wa(a);
            typeof a.isWaitingToRefreshChartItem === d && ua(a);
            typeof a.isWaitingToRefreshPredecessorItems === d && xa(a);
          } else {
            a.isWaitingToRefreshGridItem = true;
            a.isWaitingToRefreshChartItem = true;
            a.isWaitingToRefreshPredecessorItems = true;
            setTimeout(function () {
              typeof a.gridItem !== d &&
                Ha(
                  a.gridItem,
                  a,
                  a.ganttChartView.items,
                  a.ganttChartView.settings.columns,
                  a.ganttChartView.chartContent,
                  a.ganttChartView.settings.toggleButtonAreaWidth,
                  a.ganttChartView.settings
                );
              ia(a.chartPredecessorItems, a, a.ganttChartView.settings);
              ja(a.chartItem, a, a.ganttChartView.settings);
              var c = Ga(a, a.ganttChartView.items, a.ganttChartView.settings);
              a.chartItem.setAttribute('transform', 'translate(0, ' + c + ')');
              a.chartPredecessorItems.setAttribute('transform', 'translate(0, ' + c + ')');
              Ia(a, c);
              wa(a.ganttChartView.items, a);
              delete a.isWaitingToRefreshGridItem;
              delete a.isWaitingToRefreshChartItem;
              delete a.isWaitingToRefreshPredecessorItems;
            }, 0);
          }
      },
      $ = function (a) {
        Pa(a);
        for (a = a.parent; a != null; ) {
          gb(a);
          Pa(a);
          a = a.parent;
        }
      },
      Ya = function (a, c, e, b) {
        J(c, b);
        ha(c, e);
        Sa(c, b, e);
        e = a.index - 1;
        e >= 0 && $(c[e]);
        $(a);
        e = a.index + 1;
        e < c.length && $(c[e]);
      },
      ib = function (a, c) {
        var e = c.toggleButtonAreaWidth;
        delete c.toggleButtonAreaWidth;
        X(a, c);
        return c.toggleButtonAreaWidth != e;
      },
      kc = function (a, c, e, b, f) {
        e.initializedItems.push(c);
        c.ganttChartView = e;
        for (c.isVirtuallyVisible = true; a > 0 && b[a - 1].isPart; ) a--;
        for (var g = 0; g < b.length; g++)
          if (b[g].parts && b[g].parts.indexOf(c) >= 0) {
            c.isPart = true;
            c.ganttChartItem = b[g];
            break;
          }
        var q = a < b.length ? b[a] : null;
        if (q == null || q.isPart) {
          q = a > 0 ? b[a - 1] : null;
          q = q != null ? q.indentation + 1 : 0;
          if (c.indentation > q) c.indentation = q;
        } else if (
          typeof c.indentation === d ||
          c.indentation < q.indentation - 1 ||
          c.indentation > q.indentation
        )
          c.indentation = q.indentation;
        J([c], f);
        b.splice(a, 0, c);
        ha(b, e);
        if (typeof c.parts !== d) {
          if (typeof c.isGroup === d) {
            c.isGroup = true;
            c.isSummaryEnabled = false;
          }
          J(c.parts, f);
          if (c.isGroup || typeof c.isBarVisible === d) c.isBarVisible = false;
          for (g = 0; g < c.parts.length; g++) {
            var o = c.parts[g];
            o.ganttChartView = c.ganttChartView;
            o.ganttChartItem = c;
            o.isPart = true;
            o.isVirtuallyVisible = true;
            if (c.isGroup || typeof o.indentation === d) o.indentation = 0;
            if (c.isGroup || typeof o.displayRowIndex === d) o.displayRowIndex = -1;
            o.isInternallyHidden = true;
            b.indexOf(o) >= 0 || b.splice(b.length, 0, o);
          }
        }
        Sa(b, f, e);
        var m;
        if (f.isGridVisible && !c.isPart && typeof c.displayRowIndex === d) {
          g = null;
          if (a < b.length - 1) {
            q = b[a + 1];
            if (!q.isPart) {
              m = q.itemTop;
              if (typeof q.gridItemContainer !== d) g = q.gridItemContainer;
            }
          }
          e.gridContent.insertBefore(fc(c, b, f.columns, e.chartContent, f.toggleButtonAreaWidth, f, e), g);
        }
        typeof m === d && (m = Aa(b, f) - f.itemHeight);
        q = m;
        typeof c.displayRowIndex !== d && (q = c.displayRowIndex * f.itemHeight);
        var n = e.chartContent.chartArea;
        n.appendChild(vb(c, q, f));
        c.isVisible && !(typeof c.isHidden !== d && c.isHidden) && (m = Math.max(m, q + f.itemHeight));
        c.itemTop = q;
        if (typeof c.parts !== d)
          for (g = 0; g < c.parts.length; g++) {
            o = c.parts[g];
            n.appendChild(vb(o, q, f));
          }
        n.appendChild(ec(c, q, f));
        if (typeof c.displayRowIndex === d)
          for (g = a + 1; g < b.length; g++) {
            o = b[g];
            q = m;
            typeof o.displayRowIndex !== d && (q = o.displayRowIndex * f.itemHeight);
            o.isVisible && !(typeof o.isHidden !== d && o.isHidden) && (m = Math.max(m, q + f.itemHeight));
            o.itemTop = q;
            ua(o);
            wa(b, o);
          }
        for (g = a + 1; g < b.length; g++) Wa(b[g]);
        if (e.isContentHeightInitialized && e.isContentHeightAuto) {
          a = U(e, b, f);
          e.gridContentContainer.style.height = a;
          e.chartContentContainer.style.height = a;
        }
        za(e.chartContent, Aa(b, f));
        Va(b, e.chartContent, f);
        ib(b, f) && Xa(b);
        Ya(c, b, e, f);
        Ba(b, e.chartContentContainer, f);
      },
      lc = function (a, c, e, b) {
        if (typeof a.parts !== d) for (var f = 0; f < a.parts.length; f++) lc(a.parts[f], c, e, b);
        var f = a.index,
          g = null,
          q = false;
        if (f > 0) {
          for (g = e[f - 1]; g.parent != null; ) g = g.parent;
          q = g.isExpanded;
          a.hasChildren && ma(g, a.isExpanded, false, true);
          g.indentation >= a.indentation && (g = null);
        } else e[0].isExpanded || ma(e[0], true, false, true);
        var o = f + 1 < e.length ? e[f + 1] : null;
        o != null && !o.isExpanded && o.indentation > a.indentation && ma(o, true, false, true);
        var m = o != null ? o.indentation : null,
          n,
          l,
          h;
        for (l = 0; l < c.items.length; l++) {
          n = c.items[l];
          if (n.predecessors != null)
            for (h = n.predecessors.length; h-- > 0; )
              if (n.predecessors[h].item == a) {
                n.predecessors.splice(h, 1);
                w(n, 'predecessors', false, true);
                sa(n);
                xa(n);
              }
        }
        var x = a.itemTop;
        e.splice(f, 1);
        ha(e, c);
        Sa(e, b, c);
        b.isGridVisible &&
          typeof a.displayRowIndex === d &&
          typeof a.gridItemContainer !== d &&
          c.gridContent.removeChild(a.gridItemContainer);
        l = c.chartContent.chartArea;
        typeof a.chartItem !== d && l.removeChild(a.chartItem);
        o = f < e.length ? e[f] : null;
        if (o != null && o.indentation < m) {
          w(o, 'indentation', false, true);
          o.predecessors = [];
          w(o, 'predecessors', false, true);
          for (l = 0; l < e.length; l++) {
            n = e[l];
            if (!(n == o || typeof n.predecessors === d || n.predecessors.length == 0))
              for (h = 0; h < n.predecessors.length; h++)
                if (n.predecessors[h].item == o) {
                  n.predecessors.splice(h--, 1);
                  w(n, 'predecessors', false, true);
                  sa(n);
                  xa(n);
                }
          }
        }
        g != null && ma(g, q, false, true);
        for (l = f; l < e.length; l++) {
          a = e[l];
          actualItemTop = x;
          typeof a.displayRowIndex !== d && (actualItemTop = a.displayRowIndex * b.itemHeight);
          a.isVisible &&
            !(typeof a.isHidden !== d && a.isHidden) &&
            (x = Math.max(x, actualItemTop + b.itemHeight));
          a.itemTop = actualItemTop;
          sa(a);
        }
        za(c.chartContent, Aa(e, b));
        Va(e, c.chartContent, b);
        ib(e, b) && Xa(e);
        f >= e.length && (f = e.length - 1);
        if (f >= 0) {
          for (l = 0; l < f; l++) ua(e[l]);
          Ya(e[f], e, c, b);
        }
        Ba(e, c.chartContentContainer, b);
      },
      Qb = function (a, c) {
        return function (e) {
          typeof e === d && (e = a);
          var b = e.ownerDocument,
            e = b.createElementNS('http://www.w3.org/2000/svg', 'defs'),
            f = b.createElementNS('http://www.w3.org/2000/svg', 'linearGradient');
          f.setAttribute('id', 'BlueGradient');
          f.setAttribute('x1', '0%');
          f.setAttribute('y1', '0%');
          f.setAttribute('x2', '0%');
          f.setAttribute('y2', '100%');
          var g = b.createElementNS('http://www.w3.org/2000/svg', 'stop');
          g.setAttribute('offset', '0%');
          g.setAttribute('style', 'stop-color: White');
          var q = b.createElementNS('http://www.w3.org/2000/svg', 'stop');
          q.setAttribute('offset', '25%');
          q.setAttribute('style', 'stop-color: LightBlue');
          var o = b.createElementNS('http://www.w3.org/2000/svg', 'stop');
          o.setAttribute('offset', '100%');
          o.setAttribute('style', 'stop-color: Blue');
          f.appendChild(g);
          f.appendChild(q);
          f.appendChild(o);
          e.appendChild(f);
          f = b.createElementNS('http://www.w3.org/2000/svg', 'linearGradient');
          f.setAttribute('id', 'BlackGradient');
          f.setAttribute('x1', '0%');
          f.setAttribute('y1', '0%');
          f.setAttribute('x2', '0%');
          f.setAttribute('y2', '100%');
          g = b.createElementNS('http://www.w3.org/2000/svg', 'stop');
          g.setAttribute('offset', '0%');
          g.setAttribute('style', 'stop-color: Black');
          q = b.createElementNS('http://www.w3.org/2000/svg', 'stop');
          q.setAttribute('offset', '10%');
          q.setAttribute('style', 'stop-color: White');
          o = b.createElementNS('http://www.w3.org/2000/svg', 'stop');
          o.setAttribute('offset', '20%');
          o.setAttribute('style', 'stop-color: Gray');
          var m = b.createElementNS('http://www.w3.org/2000/svg', 'stop');
          m.setAttribute('offset', '60%');
          m.setAttribute('style', 'stop-color: Black');
          f.appendChild(g);
          f.appendChild(q);
          f.appendChild(o);
          f.appendChild(m);
          e.appendChild(f);
          f = b.createElementNS('http://www.w3.org/2000/svg', 'marker');
          f.setAttribute('id', 'ArrowMarker');
          f.setAttribute('viewBox', '0 0 10 10');
          f.setAttribute('refX', '0');
          f.setAttribute('refY', '5');
          f.setAttribute('markerUnits', 'strokeWidth');
          f.setAttribute('markerWidth', (4.5 * (c.arrowSize ? c.arrowSize : 1)).toString());
          f.setAttribute('markerHeight', (3.5 * (c.arrowSize ? c.arrowSize : 1)).toString());
          f.setAttribute('orient', 'auto');
          b = b.createElementNS('http://www.w3.org/2000/svg', 'path');
          switch (c.theme) {
            default:
              b.setAttribute('fill', '#3b87d9');
              break;
            case 'Aero':
              b.setAttribute('fill', 'Blue');
          }
          b.setAttribute('d', 'M 0 0 L 10 5 L 0 10 z');
          b.setAttribute('fill', c.arrowFill ? c.arrowFill : c.theme == 'Aero' ? 'Blue' : '#3b87d9');
          f.appendChild(b);
          e.appendChild(f);
          return e;
        };
      },
      Ub = function (a) {
        return function (c) {
          var e = c.ganttChartView,
            b = c.ganttChartView.ownerDocument,
            f = b.createElementNS('http://www.w3.org/2000/svg', 'title');
          f.appendChild(da(b, c.content));
          if (typeof a.areToolTipsSimplified === d || !a.areToolTipsSimplified)
            if (typeof c.loadChartView === d) {
              if (typeof c.scheduleChartView === d && c.parent) {
                f.appendChild(b.createTextNode('\n'));
                f.appendChild(b.createTextNode('Parent: ' + c.parent.content));
              }
              if (typeof c.scheduleChartView !== d && c.scheduleChartItem) {
                f.appendChild(b.createTextNode('\n'));
                f.appendChild(b.createTextNode('Row: ' + c.scheduleChartItem.content));
              }
              if (c.hasChildren) {
                f.appendChild(b.createTextNode('\n'));
                f.appendChild(b.createTextNode('Children: ' + c.children.length));
              }
              f.appendChild(b.createTextNode('\n'));
              f.appendChild(
                b.createTextNode((!c.isMilestone ? 'Start: ' : '') + a.dateTimeFormatter(h(c.start)))
              );
              if (!c.isMilestone) {
                f.appendChild(b.createTextNode('\n'));
                f.appendChild(b.createTextNode('Finish: ' + a.dateTimeFormatter(h(c.finish))));
              }
              if (a.areTaskAssignmentsVisible && c.assignmentsContent) {
                f.appendChild(b.createTextNode('\n'));
                f.appendChild(b.createTextNode('Assignments: ' + c.assignmentsContent));
              }
              if (!c.isMilestone) {
                f.appendChild(b.createTextNode('\n'));
                f.appendChild(b.createTextNode('Effort: ' + e.getItemTotalEffort(c) / 3600000 + 'h'));
                if (a.isTaskCompletedEffortVisible) {
                  e = e.getItemCompletion(c);
                  if (!isNaN(e)) {
                    f.appendChild(b.createTextNode('\n'));
                    f.appendChild(
                      b.createTextNode(
                        'Completed: ' +
                          Math.round(e * 100 * (a.integralCompletionPercents ? 1 : 100)) /
                            (a.integralCompletionPercents ? 1 : 100) +
                          '%'
                      )
                    );
                  }
                }
              }
              if (a.areTaskDependenciesVisible && c.predecessors && c.predecessors.length > 0) {
                f.appendChild(b.createTextNode('\n'));
                for (var e = '', g = 0; g < c.predecessors.length; g++) {
                  var q = c.predecessors[g];
                  if (q.item) {
                    e.length > 0 && (e = e + ', ');
                    e = e + q.item.content;
                    q.dependencyType && (e = e + (' (' + mc(q.dependencyType) + ')'));
                  }
                }
                f.appendChild(b.createTextNode('Predecessors: ' + e));
              }
            } else if (!isNaN(c.units)) {
              f.appendChild(b.createTextNode('\n'));
              f.appendChild(b.createTextNode('Allocation: ' + Math.round(c.units * 10000) / 100 + '%'));
            }
          return f;
        };
      },
      Vb = function (a) {
        return function (c) {
          var e = c.ganttChartView,
            b = typeof a !== d ? a : e.settings;
          if (b.areResourceImagesVisibleAsAssignments) {
            var f = e.chartContent.childNodes[0].childNodes[0];
            if (!e.chartContent.hasClipPathForResourceImagesBeenInitialized) {
              var g = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath');
              g.setAttribute('id', 'resourceImageClipPath');
              g.setAttribute('clipPathUnits', 'objectBoundingBox');
              var q = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
              q.setAttribute('cx', 0.5);
              q.setAttribute('cy', 0.5);
              q.setAttribute('r', 0.5);
              g.appendChild(q);
              f.appendChild(g);
              e.chartContent.hasClipPathForResourceImagesBeenInitialized = true;
            }
            var o = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            o.setAttribute('id', 'assignmentsGroup');
            var m = F(c.finish, b),
              m =
                m + (b.classic || c.hasChildren || c.isMilestone ? 0 : 12 - Math.min(12, m - F(c.start, b)));
            if (c.isMilestone || (c.hasChildren && (typeof c.isSummaryEnabled === d || c.isSummaryEnabled)))
              m = m + b.barHeight / 2;
            if (
              !c.isMilestone &&
              (!c.hasChildren || !(typeof c.isSummaryEnabled === d || c.isSummaryEnabled))
            )
              m = m + (b.itemHeight - 10);
            c.isMilestone && (m = m + 3);
            var n = 0;
            la(c).forEach(function (a) {
              var f = a.key,
                g;
              if (typeof b.resourceImageUrls !== d) {
                var q = aa(b.resourceImageUrls, f);
                if (q >= 0) g = b.resourceImageUrls[q].value;
              }
              if (g && b.itemHeight > 4) {
                var q = b.itemHeight - 4,
                  h = document.createElementNS('http://www.w3.org/2000/svg', 'image');
                h.setAttribute('x', m + 28 * n);
                h.setAttribute('y', 2);
                h.setAttribute('width', q + 'px');
                h.setAttribute('height', q + 'px');
                if (c.isExported && g.indexOf('://') != 0) {
                  q =
                    g.indexOf('/') != 0
                      ? window.location.pathname.substr(0, window.location.pathname.lastIndexOf('/') + 1)
                      : '';
                  g = window.location.protocol + '//' + window.location.host + q + g;
                }
                h.setAttribute('href', g);
                h.setAttribute('clip-path', 'url(#resourceImageClipPath)');
                o.appendChild(h);
                var t = a.value != 1 ? ' ' + Math.round(a.value * 10000) / 100 + '%' : '';
                if (DlhSoft.Controls.ToolTip && b.useInlineToolTips) {
                  h.addEventListener(
                    'mouseover',
                    r(e, h, 'mouseover', function (a) {
                      if (a.buttons != 1) {
                        if ((a = e.toolTip)) {
                          a.disable();
                          a.hide();
                        }
                        (a = DlhSoft.Controls.ToolTip.get(h)) ||
                          (a = DlhSoft.Controls.ToolTip.initialize(
                            void 0,
                            h,
                            {
                              duration: NaN,
                              containerStyle:
                                'cursor: default; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; border: 1px solid ' +
                                b.border +
                                '; background-color: White; color: Black; font-family: ' +
                                (b.classic ? 'Arial' : 'system-ui, Arial') +
                                '; font-size: 12px; padding: 4px; margin-top: 1px',
                            },
                            'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
                          ));
                        a.setContent(f + t);
                        a.show();
                        e.resourceToolTip = a;
                      }
                    })
                  );
                  h.addEventListener(
                    'mouseout',
                    r(e, h, 'mouseout', function () {
                      var a = e.resourceToolTip;
                      a && a.hide();
                    })
                  );
                } else {
                  a = document.createElementNS('http://www.w3.org/2000/svg', 'title');
                  a.appendChild(document.createTextNode(f + t));
                  h.appendChild(a);
                }
              }
              n++;
            });
            return o;
          }
          f = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          m = F(c.finish, b);
          m = m + (b.classic || c.hasChildren || c.isMilestone ? 0 : 12 - Math.min(12, m - F(c.start, b)));
          if (c.isMilestone || (c.hasChildren && (typeof c.isSummaryEnabled === d || c.isSummaryEnabled)))
            m = m + b.barHeight / 2;
          f.setAttribute('x', m + 7);
          f.setAttribute('y', b.barMargin + b.barHeight / 2);
          f.setAttribute('dominant-baseline', 'central');
          g = b.target != 'Phone' ? c.assignmentsContent : c.content;
          typeof g === d && (g = '');
          f.appendChild(document.createTextNode(g));
          typeof b.assignmentsClass !== d
            ? f.setAttribute('class', b.assignmentsClass)
            : typeof b.assignmentsStyle !== d && f.setAttribute('style', b.assignmentsStyle);
          return f;
        };
      },
      xb = function (a) {
        var c = a.ganttChartView.ownerDocument;
        if (typeof a.chartItemArea === d)
          a.chartItemArea = c.createElementNS('http://www.w3.org/2000/svg', 'g');
        for (c = a.chartItemArea.childNodes.length; c-- > 0; )
          a.chartItemArea.removeChild(a.chartItemArea.childNodes[c]);
        return a.chartItemArea;
      },
      yb = function (a, c, e, b, f) {
        var d = a.ganttChartView,
          q = d.settings,
          d = d.ownerDocument,
          o = q.barMargin,
          m = q.barHeight,
          n = q.barHeight,
          q = c.childNodes.length - 6,
          l = b != null ? b - e - 4 - (a.hasChildren ? 4 : 0) : 200,
          b = d.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        b.setAttribute('width', l);
        b.setAttribute('height', n);
        if (a.isMilestone) {
          b.setAttribute('x', e - 200 - m);
          b.setAttribute('y', o);
          b.setAttribute(
            'style',
            'line-height: ' +
              n +
              'px; text-overflow: ellipsis; white-space: nowrap; text-align: right; font-size: ' +
              (m / 2 + 1) +
              'px; color: ' +
              f
          );
        } else if (a.hasChildren) {
          b.setAttribute('x', e + 4);
          b.setAttribute('y', o);
          b.setAttribute(
            'style',
            'line-height: ' +
              (n * 2) / 3 +
              'px; text-overflow: ellipsis; white-space: nowrap; font-size: ' +
              m / 2 +
              'px; color: ' +
              f
          );
        } else {
          b.setAttribute('x', e + 2);
          b.setAttribute('y', o);
          b.setAttribute(
            'style',
            'line-height: ' +
              n +
              'px; text-overflow: ellipsis; white-space: nowrap; font-size: ' +
              (m / 2 + 1) +
              'px; color: ' +
              f
          );
        }
        e = d.createElement('div');
        a = d.createTextNode(a.label);
        e.appendChild(a);
        b.appendChild(e);
        c.insertBefore(b, c.childNodes[q]);
        return e;
      },
      Rb = function (a, c, e) {
        return function (b) {
          var f = typeof c !== d ? c : b.ganttChartView,
            g = typeof e !== d ? e : f.settings,
            q = typeof a !== d ? a : f.items,
            o = f.ownerDocument,
            m = xb(b);
          if (g.isBaselineVisible && typeof b.baselineStart !== d && typeof b.baselineFinish !== d) {
            var n = F(b.baselineStart, g),
              l = Math.max(F(b.baselineFinish, g), n + (g.classic ? 4 : 12)),
              h = o.createElementNS('http://www.w3.org/2000/svg', 'rect');
            h.setAttribute('x', n);
            h.setAttribute('y', g.barMargin / 2);
            h.setAttribute('width', Math.max(0, l - n - 1));
            h.setAttribute('height', g.barHeight);
            h.setAttribute('rx', g.barCornerRadius);
            h.setAttribute('ry', g.barCornerRadius);
            n = g.baselineBarClass;
            if (typeof b.baselineBarClass !== d) n = b.baselineBarClass;
            if (typeof n !== d) h.setAttribute('class', n);
            else {
              n = g.baselineBarStyle;
              if (typeof b.baselineBarStyle !== d) n = b.baselineBarStyle;
              typeof n !== d && h.setAttribute('style', n);
            }
            m.appendChild(h);
          }
          var h = F(b.start, g),
            n = Math.max(F(b.finish, g), h + (g.classic ? 4 : 12)),
            l =
              g.percentBasedCompletionBar && b.finish.valueOf() != b.start.valueOf()
                ? h + f.getItemCompletion(b) * (n - h)
                : F(b.completedFinish, g),
            x = o.createElementNS('http://www.w3.org/2000/svg', 'rect');
          x.setAttribute('x', h);
          x.setAttribute('y', g.barMargin);
          x.setAttribute('width', Math.max(0, n - h - 1));
          x.setAttribute('height', g.barHeight);
          x.setAttribute('rx', g.barCornerRadius);
          x.setAttribute('ry', g.barCornerRadius);
          var j = g.standardBarClass;
          if (typeof b.standardBarClass !== d) j = b.standardBarClass;
          if (typeof b.barClass !== d) j = b.barClass;
          if (typeof j !== d) x.setAttribute('class', j);
          else {
            j = g.standardBarStyle;
            if (typeof b.standardBarStyle !== d) j = b.standardBarStyle;
            if (typeof b.barStyle !== d) j = b.barStyle;
            typeof j !== d && x.setAttribute('style', j);
          }
          if (g.isTaskToolTipVisible && DlhSoft.Controls.ToolTip && g.useInlineToolTips) {
            m.addEventListener(
              'mouseover',
              r(f, m, 'mouseover', function (a) {
                if (a.buttons != 1) {
                  if ((a = f.toolTip)) {
                    a.disable();
                    a.hide();
                  }
                  (a = DlhSoft.Controls.ToolTip.get(x)) ||
                    (a = DlhSoft.Controls.ToolTip.initialize(
                      void 0,
                      x,
                      {
                        duration: NaN,
                        containerStyle:
                          'cursor: default; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; border: 1px solid ' +
                          g.border +
                          '; background-color: White; color: Black; font-family: ' +
                          (g.classic ? 'Arial' : 'system-ui, Arial') +
                          '; font-size: 12px; padding: 4px; margin-top: 1px',
                      },
                      'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
                    ));
                  a.setContent(g.itemTemplate(b).innerHTML.replaceAll('\n', '<br/>'));
                  a.show();
                  f.taskToolTip = a;
                }
              })
            );
            m.addEventListener(
              'mouseout',
              r(f, m, 'mouseout', function () {
                var a = f.taskToolTip;
                a.disable();
                a.hide();
              })
            );
          }
          m.appendChild(x);
          if (g.isTaskCompletedEffortVisible) {
            j = o.createElementNS('http://www.w3.org/2000/svg', 'rect');
            j.setAttribute('x', h + 0.65);
            j.setAttribute('y', g.barMargin + g.completedBarMargin - 0.65);
            j.setAttribute('width', Math.max(0, l - h - 1 - 1.3));
            j.setAttribute('height', g.completedBarHeight + 1.3);
            j.setAttribute('rx', g.completedBarCornerRadius);
            j.setAttribute('ry', g.completedBarCornerRadius);
            var k = g.standardCompletedBarClass;
            if (typeof b.standardCompletedBarClass !== d) k = b.standardCompletedBarClass;
            if (typeof b.completedBarClass !== d) k = b.completedBarClass;
            if (typeof k !== d) j.setAttribute('class', k);
            else {
              k = g.standardCompletedBarStyle;
              if (typeof b.standardCompletedBarStyle !== d) k = b.standardCompletedBarStyle;
              if (typeof b.completedBarStyle !== d) k = b.completedBarStyle;
              typeof k !== d && j.setAttribute('style', k);
            }
            m.appendChild(j);
          }
          if (g.areStandardTaskLabelsVisible && b.label) {
            j = yb(
              b,
              m,
              h,
              n,
              b.labelColor ? b.labelColor : g.standardLabelColor ? g.standardLabelColor : 'black'
            );
            k = g.standardLabelClass;
            if (typeof b.labelClass !== d) k = b.labelClass;
            if (typeof k !== d) j.setAttribute('class', k);
            else {
              k = g.standardLabelStyle;
              if (typeof b.labelStyle !== d) k = b.labelStyle;
              typeof k !== d && j.setAttribute('style', k);
            }
          }
          if (
            !g.isReadOnly &&
            !g.isChartReadOnly &&
            (typeof b.isReadOnly === d || !b.isReadOnly) &&
            (typeof b.isBarReadOnly === d || !b.isBarReadOnly)
          ) {
            j = o.createElementNS('http://www.w3.org/2000/svg', 'rect');
            j.setAttribute('x', h);
            j.setAttribute('y', g.barMargin);
            j.setAttribute('width', Math.max(0, n - h - 1));
            j.setAttribute('height', g.barHeight);
            j.setAttribute('style', 'fill: White; fill-opacity: 0; cursor: pointer');
            g.isTaskStartReadOnly || m.appendChild(j);
            var t,
              p,
              k = o.createElementNS('http://www.w3.org/2000/svg', 'rect');
            k.setAttribute('x', h - 4);
            k.setAttribute('y', g.barMargin);
            k.setAttribute('width', g.classic ? 4 : 8);
            k.setAttribute('height', g.barHeight);
            k.setAttribute(
              'style',
              'fill: White; fill-opacity: 0; cursor: ' + (g.classic ? 'w-resize' : 'ew-resize')
            );
            g.isDraggingTaskStartEndsEnabled &&
              !g.isTaskStartReadOnly &&
              g.interaction != 'TouchEnabled' &&
              m.appendChild(k);
            t = o.createElementNS('http://www.w3.org/2000/svg', 'rect');
            t.setAttribute('x', n - 4);
            t.setAttribute('y', g.barMargin);
            t.setAttribute('width', 8);
            t.setAttribute('height', g.barHeight);
            t.setAttribute(
              'style',
              'fill: White; fill-opacity: 0; cursor: ' + (g.classic ? 'e-resize' : 'ew-resize')
            );
            !g.isTaskEffortReadOnly && g.interaction != 'TouchEnabled' && m.appendChild(t);
            if (g.classic) {
              p = o.createElementNS('http://www.w3.org/2000/svg', 'rect');
              p.setAttribute('x', l - 2);
              p.setAttribute('y', g.barMargin);
              p.setAttribute('width', 6);
              p.setAttribute('height', g.barHeight);
              p.setAttribute('style', 'fill: White; fill-opacity: 0; cursor: ew-resize');
            } else {
              p = o.createElementNS('http://www.w3.org/2000/svg', 'g');
              var E = o.createElementNS('http://www.w3.org/2000/svg', 'polygon');
              p.appendChild(E);
              var s = Math.max(l - 1 - 10, h),
                u = Math.min(l - 1 + 10, n - 1);
              E.setAttribute(
                'points',
                s +
                  ',' +
                  (g.itemHeight - g.barMargin) +
                  ' ' +
                  u +
                  ',' +
                  (g.itemHeight - g.barMargin) +
                  ' ' +
                  (l - 1 < h + 10 ? h : l - 1 > n - 1 - 10 ? n - 1 : Math.min(Math.max(l - 1, h), n - 2)) +
                  ',' +
                  (g.itemHeight - g.barMargin - g.barHeight / 2)
              );
              E.setAttribute('style', 'fill: White; fill-opacity: 0; cursor: ew-resize');
              E = o.createElementNS('http://www.w3.org/2000/svg', 'rect');
              p.appendChild(E);
              s = Math.max(h, l - 10);
              u = Math.min(20, n - l);
              if (u < 20) {
                s = s - (10 - u);
                u = 20;
              }
              E.setAttribute('x', s);
              E.setAttribute('y', g.itemHeight / 2);
              E.setAttribute('width', u);
              E.setAttribute('height', g.itemHeight / 2);
              E.setAttribute('style', 'fill: White; fill-opacity: 0;');
            }
            var s =
                typeof g.areToolTipsSimplified === d || !g.areToolTipsSimplified
                  ? f.getItemCompletion(b)
                  : NaN,
              Ma = !isNaN(s)
                ? Math.round(s * 100 * (g.integralCompletionPercents ? 1 : 100)) /
                    (g.integralCompletionPercents ? 1 : 100) +
                  '%'
                : '';
            if (
              !g.isTaskCompletionReadOnly &&
              g.isTaskCompletedEffortVisible &&
              g.interaction != 'TouchEnabled'
            ) {
              m.appendChild(p);
              if (Ma && DlhSoft.Controls.ToolTip && g.useInlineToolTips) {
                p.addEventListener(
                  'mouseover',
                  r(f, p, 'mouseover', function (a) {
                    if (!(a.buttons == 1 && f.dragType != 'CompletedFinish')) {
                      if ((a = f.toolTip)) {
                        a.disable();
                        a.hide();
                      }
                      (a = DlhSoft.Controls.ToolTip.get(p)) ||
                        (a = DlhSoft.Controls.ToolTip.initialize(
                          void 0,
                          p,
                          {
                            duration: NaN,
                            containerStyle:
                              'cursor: default; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; border: 1px solid ' +
                              g.border +
                              '; background-color: White; color: Black; font-family: ' +
                              (g.classic ? 'Arial' : 'system-ui, Arial') +
                              '; font-size: 12px; padding: 4px; margin-top: 1px',
                          },
                          'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
                        ));
                      a.setContent(Ma);
                      a.show();
                      f.taskCompletionToolTip = a;
                    }
                  })
                );
                p.addEventListener(
                  'mouseout',
                  r(f, p, 'mouseout', function () {
                    var a = f.taskCompletionToolTip;
                    a.disable();
                    a.hide();
                  })
                );
              } else {
                s = o.createElementNS('http://www.w3.org/2000/svg', 'title');
                s.appendChild(da(o, Ma));
                p.appendChild(s);
              }
            }
            zb(j, k, t, p, b, h, n, l, q, f, g);
            if (g.areTaskDependenciesVisible && !g.areTaskPredecessorsReadOnly && !b.isPart) {
              l = null;
              if (typeof g.allowCreatingStartDependencies === d || g.allowCreatingStartDependencies) {
                // l = o.createElementNS('http://www.w3.org/2000/svg', 'circle');
                // l.setAttribute('cx', h - (g.classic ? 0 : 8));
                // l.setAttribute('cy', g.barMargin + g.barHeight / 2);
                // l.setAttribute('r', g.barHeight / (g.classic ? 4 : 3));
                // l.setAttribute('style', 'fill: White; fill-opacity: 0; cursor: pointer');
                // m.appendChild(l);
              }
              o = o.createElementNS('http://www.w3.org/2000/svg', 'circle');
              o.setAttribute('cx', n - 2 + (g.classic ? 0 : 9));
              o.setAttribute('cy', g.barMargin + g.barHeight / 2);
              o.setAttribute('r', g.barHeight / (g.classic ? 4 : 3));
              o.setAttribute('style', 'fill: White; fill-opacity: 0; cursor: pointer;');
              m.appendChild(o);
              jb(o, l, m, b, g.barMargin + g.barHeight / 2, n - 2, h, q, f, g);
            }
          }
          return m;
        };
      },
      Sb = function (a, c, e) {
        return function (b) {
          var f = typeof c !== d ? c : b.ganttChartView,
            g = typeof e !== d ? e : f.settings,
            q = typeof a !== d ? a : f.items,
            o = f.ownerDocument,
            m = xb(b),
            n = F(b.start, g),
            l = F(b.finish, g),
            h = (g.barHeight * 2.15) / 3,
            x = o.createElementNS('http://www.w3.org/2000/svg', 'rect');
          x.setAttribute('x', n);
          x.setAttribute('y', g.barMargin);
          x.setAttribute('width', Math.max(0, l - n - 1));
          x.setAttribute('height', h);
          var j = g.summaryBarClass;
          if (typeof b.summaryBarClass !== d) j = b.summaryBarClass;
          if (typeof b.barClass !== d) j = b.barClass;
          if (typeof j !== d) x.setAttribute('class', j);
          else {
            var k = g.summaryBarStyle;
            if (typeof b.summaryBarStyle !== d) k = b.summaryBarStyle;
            if (typeof b.barStyle !== d) k = b.barStyle;
            typeof k !== d && x.setAttribute('style', k);
          }
          m.appendChild(x);
          if (g.isTaskToolTipVisible && DlhSoft.Controls.ToolTip && g.useInlineToolTips) {
            m.addEventListener(
              'mouseover',
              r(f, m, 'mouseover', function (a) {
                if (a.buttons != 1) {
                  if ((a = f.toolTip)) {
                    a.disable();
                    a.hide();
                  }
                  (a = DlhSoft.Controls.ToolTip.get(x)) ||
                    (a = DlhSoft.Controls.ToolTip.initialize(
                      void 0,
                      x,
                      {
                        duration: NaN,
                        containerStyle:
                          'cursor: default; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; border: 1px solid ' +
                          g.border +
                          '; background-color: White; color: Black; font-family: ' +
                          (g.classic ? 'Arial' : 'system-ui, Arial') +
                          '; font-size: 12px; padding: 4px; margin-top: 1px',
                      },
                      'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
                    ));
                  a.setContent(g.itemTemplate(b).innerHTML.replaceAll('\n', '<br/>'));
                  a.show();
                  f.taskToolTip = a;
                }
              })
            );
            m.addEventListener(
              'mouseout',
              r(f, x, 'mouseout', function () {
                var a = f.taskToolTip;
                a.disable();
                a.hide();
              })
            );
          }
          if (g.isTaskCompletedEffortVisible && g.isSummaryTaskCompletedEffortVisible) {
            var t = o.createElementNS('http://www.w3.org/2000/svg', 'rect');
            t.setAttribute('x', n);
            t.setAttribute('y', g.barMargin + (g.completedBarMargin * 2.15) / 3);
            t.setAttribute('width', Math.max(0, l - n - 1) * f.getItemCompletion(b));
            t.setAttribute('height', (g.completedBarHeight * 2.15) / 3);
            t.setAttribute('rx', g.completedBarCornerRadius);
            t.setAttribute('ry', g.completedBarCornerRadius);
            var p = g.summaryCompletedBarClass;
            if (typeof b.summaryCompletedBarClass !== d) p = b.summaryCompletedBarClass;
            if (typeof b.completedBarClass !== d) p = b.completedBarClass;
            if (typeof p !== d) t.setAttribute('class', p);
            else {
              p = g.summaryCompletedBarStyle;
              if (typeof b.summaryCompletedBarStyle !== d) p = b.summaryCompletedBarStyle;
              if (typeof b.completedBarStyle !== d) p = b.completedBarStyle;
              typeof p !== d && t.setAttribute('style', p);
            }
            m.appendChild(t);
          }
          if (!b.isExpanded) {
            t = o.createElementNS('http://www.w3.org/2000/svg', 'line');
            t.setAttribute('x1', n);
            t.setAttribute('y1', g.barMargin + h + 2.5);
            t.setAttribute('x2', l - 1);
            t.setAttribute('y2', g.barMargin + h + 2.5);
            p = g.collapsedSummaryLineClass;
            if (typeof b.collapsedSummaryLineClass !== d) p = b.collapsedSummaryLineClass;
            if (typeof p !== d) t.setAttribute('class', p);
            else {
              p = g.collapsedSummaryLineStyle;
              if (typeof b.collapsedSummaryLineStyle !== d) p = b.collapsedSummaryLineStyle;
              typeof p !== d && t.setAttribute('style', p);
            }
            m.appendChild(t);
          }
          var p = o.createElementNS('http://www.w3.org/2000/svg', 'polygon'),
            t = g.barMargin - 0.25,
            E = h + 1.5,
            s = (g.barHeight * 3) / 4,
            u = g.barHeight / 4,
            h = n - 1 - g.barHeight / 3;
          p.setAttribute(
            'points',
            h +
              ',' +
              t +
              ' ' +
              h +
              ',' +
              (t + E) +
              ' ' +
              (h + s / 2) +
              ',' +
              (t + E + u) +
              ' ' +
              (h + s) +
              ',' +
              (t + E) +
              ' ' +
              (h + s) +
              ',' +
              t
          );
          typeof j !== d && p.setAttribute('class', j);
          typeof k !== d && p.setAttribute('style', k);
          m.appendChild(p);
          p = o.createElementNS('http://www.w3.org/2000/svg', 'polygon');
          h = l + g.barHeight / 3;
          p.setAttribute(
            'points',
            h +
              ',' +
              t +
              ' ' +
              h +
              ',' +
              (t + E) +
              ' ' +
              (h - s / 2) +
              ',' +
              (t + E + u) +
              ' ' +
              (h - s) +
              ',' +
              (t + E) +
              ' ' +
              (h - s) +
              ',' +
              t
          );
          typeof j !== d && p.setAttribute('class', j);
          typeof k !== d && p.setAttribute('style', k);
          m.appendChild(p);
          if (g.areSummaryTaskLabelsVisible && b.label) {
            j = yb(
              b,
              m,
              n,
              l,
              b.labelColor ? b.labelColor : g.summaryLabelColor ? g.summaryLabelColor : 'white'
            );
            k = g.summaryLabelClass;
            if (typeof b.labelClass !== d) k = b.labelClass;
            if (typeof k !== d) j.setAttribute('class', k);
            else {
              k = g.summaryLabelStyle;
              if (typeof b.labelStyle !== d) k = b.labelStyle;
              typeof k !== d && j.setAttribute('style', k);
            }
          }
          if (
            !g.isReadOnly &&
            !g.isChartReadOnly &&
            (typeof b.isReadOnly === d || !b.isReadOnly) &&
            (typeof b.isBarReadOnly === d || !b.isBarReadOnly) &&
            g.areTaskDependenciesVisible &&
            !g.areTaskPredecessorsReadOnly &&
            !b.isPart
          ) {
            j = null;
            if (typeof g.allowCreatingStartDependencies === d || g.allowCreatingStartDependencies) {
              // j = o.createElementNS('http://www.w3.org/2000/svg', 'circle');
              // j.setAttribute('cx', n - 0.5);
              // j.setAttribute('cy', g.barMargin + g.barHeight / 2);
              // j.setAttribute('r', g.barHeight / (g.classic ? 4 : 3));
              // j.setAttribute('style', 'fill: White; fill-opacity: 0; cursor: pointer');
              // m.appendChild(j);
            }
            o = o.createElementNS('http://www.w3.org/2000/svg', 'circle');
            o.setAttribute('cx', l - 0.5);
            o.setAttribute('cy', g.barMargin + g.barHeight / 2);
            o.setAttribute('r', g.barHeight / (g.classic ? 4 : 3));
            o.setAttribute('style', 'fill: White; fill-opacity: 0; cursor: pointer');
            m.appendChild(o);
            jb(o, j, m, b, g.barMargin + g.barHeight / 2, l - 1.5, n, q, f, g);
          }
          return m;
        };
      },
      Tb = function (a, c, e) {
        return function (b) {
          var f = typeof c !== d ? c : b.ganttChartView,
            g = typeof e !== d ? e : f.settings,
            q = typeof a !== d ? a : f.items,
            o = f.ownerDocument,
            m = xb(b);
          if (g.isBaselineVisible && typeof b.baselineStart !== d) {
            var n = F(b.baselineStart, g),
              l = o.createElementNS('http://www.w3.org/2000/svg', 'polygon'),
              h = g.barMargin - 1,
              x = g.barHeight + 1;
            l.setAttribute(
              'points',
              n +
                ',' +
                h +
                ' ' +
                (n - x / 2) +
                ',' +
                (h + x / 2) +
                ' ' +
                n +
                ',' +
                (h + x) +
                ' ' +
                (n + x / 2) +
                ',' +
                (h + x / 2)
            );
            n = g.baselineBarClass;
            if (typeof b.baselineBarClass !== d) n = b.baselineBarClass;
            if (typeof n !== d) l.setAttribute('class', n);
            else {
              var j = g.baselineBarStyle;
              if (typeof b.baselineBarStyle !== d) j = b.baselineBarStyle;
            }
            l.setAttribute('style', j);
            m.appendChild(l);
          }
          var n = F(b.start, g),
            k = o.createElementNS('http://www.w3.org/2000/svg', 'polygon'),
            l = n - 1,
            h = g.barMargin,
            j = g.barHeight + 1;
          k.setAttribute(
            'points',
            l +
              ',' +
              h +
              ' ' +
              (l - j / 2) +
              ',' +
              (h + j / 2) +
              ' ' +
              l +
              ',' +
              (h + j) +
              ' ' +
              (l + j / 2) +
              ',' +
              (h + j / 2)
          );
          h = g.milestoneBarClass;
          if (typeof b.milestoneBarClass !== d) h = b.milestoneBarClass;
          if (typeof b.barClass !== d) h = b.barClass;
          if (typeof h !== d) k.setAttribute('class', h);
          else {
            h = g.milestoneBarStyle;
            if (typeof b.milestoneBarStyle !== d) h = b.milestoneBarStyle;
            if (typeof b.barStyle !== d) h = b.barStyle;
            typeof h !== d && k.setAttribute('style', h);
          }
          m.appendChild(k);
          if (g.isTaskToolTipVisible && DlhSoft.Controls.ToolTip && g.useInlineToolTips) {
            m.addEventListener(
              'mouseover',
              r(f, m, 'mouseover', function (a) {
                if (a.buttons != 1) {
                  if ((a = f.toolTip)) {
                    a.disable();
                    a.hide();
                  }
                  (a = DlhSoft.Controls.ToolTip.get(k)) ||
                    (a = DlhSoft.Controls.ToolTip.initialize(
                      void 0,
                      k,
                      {
                        duration: NaN,
                        containerStyle:
                          'cursor: default; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; border: 1px solid ' +
                          g.border +
                          '; background-color: White; color: Black; font-family: ' +
                          (g.classic ? 'Arial' : 'system-ui, Arial') +
                          '; font-size: 12px; padding: 4px; margin-top: 1px',
                      },
                      'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
                    ));
                  a.setContent(g.itemTemplate(b).innerHTML.replaceAll('\n', '<br/>'));
                  a.show();
                  f.taskToolTip = a;
                }
              })
            );
            m.addEventListener(
              'mouseout',
              r(f, m, 'mouseout', function () {
                var a = f.taskToolTip;
                a.disable();
                a.hide();
              })
            );
          }
          if (g.areMilestoneTaskLabelsVisible && b.label) {
            n = yb(
              b,
              m,
              n,
              null,
              b.labelColor ? b.labelColor : g.milestoneLabelColor ? g.milestoneLabelColor : 'black'
            );
            h = g.milestoneLabelClass;
            if (typeof b.labelClass !== d) h = b.labelClass;
            if (typeof h !== d) n.setAttribute('class', h);
            else {
              h = g.milestoneLabelStyle;
              if (typeof b.labelStyle !== d) h = b.labelStyle;
              typeof h !== d && n.setAttribute('style', h);
            }
          }
          if (
            !g.isReadOnly &&
            !g.isChartReadOnly &&
            (typeof b.isReadOnly === d || !b.isReadOnly) &&
            (typeof b.isBarReadOnly === d || !b.isBarReadOnly)
          ) {
            n = o.createElementNS('http://www.w3.org/2000/svg', 'rect');
            n.setAttribute('x', l - j / 2);
            n.setAttribute('y', g.barMargin);
            n.setAttribute('width', j);
            n.setAttribute('height', j);
            n.setAttribute('style', 'fill: White; fill-opacity: 0; cursor: pointer');
            m.appendChild(n);
            zb(n, null, null, null, b, l, l, l, q, f, g);
            if (g.areTaskDependenciesVisible && !g.areTaskPredecessorsReadOnly && !b.isPart) {
              o = o.createElementNS('http://www.w3.org/2000/svg', 'circle');
              o.setAttribute('cx', l);
              o.setAttribute('cy', g.barMargin + g.barHeight / 2 + (g.classic ? 0 : 0.5));
              o.setAttribute('r', g.barHeight / (g.classic ? 4 : 3));
              o.setAttribute('style', 'fill: White; fill-opacity: 0; cursor: pointer');
              m.appendChild(o);
              jb(o, null, m, b, g.barMargin + g.barHeight / 2, l, l, q, f, g);
            }
          }
          return m;
        };
      },
      Wb = function (a, c) {
        return function (e, b) {
          var f = e.ganttChartView,
            g = typeof c !== d ? c : f.settings,
            h = typeof a !== d ? a : f.items,
            o = f.ownerDocument,
            m = o.createElementNS('http://www.w3.org/2000/svg', 'g'),
            h = ed(e, b, h, g),
            n = o.createElementNS('http://www.w3.org/2000/svg', 'path');
          n.setAttribute('d', h);
          typeof g.dependencyLineZoneClass !== d && n.setAttribute('class', g.dependencyLineZoneClass);
          typeof g.dependencyLineZoneStyle !== d && n.setAttribute('style', g.dependencyLineZoneStyle);
          m.appendChild(n);
          var l = o.createElementNS('http://www.w3.org/2000/svg', 'path');
          l.setAttribute('d', h);
          o = g.dependencyLineClass;
          if (typeof b.dependencyLineClass !== d) o = b.dependencyLineClass;
          if (typeof o !== d) l.setAttribute('class', o);
          else {
            o = g.dependencyLineStyle;
            if (typeof b.dependencyLineStyle !== d) o = b.dependencyLineStyle;
            typeof o !== d && l.setAttribute('style', o);
          }
          m.appendChild(l);
          if (
            g.isDependencyToolTipVisible &&
            DlhSoft.Controls.ToolTip &&
            g.useInlineToolTips &&
            g.useInlineToolTipsForDependencies
          ) {
            m.addEventListener(
              'mouseover',
              r(f, m, 'mouseover', function (a) {
                var c = f.dependencyToolTip;
                if (c) {
                  c.disable();
                  c.hide();
                }
                (c = DlhSoft.Controls.ToolTip.get(l)) ||
                  (c = DlhSoft.Controls.ToolTip.initialize(
                    void 0,
                    l,
                    {
                      duration: NaN,
                      containerStyle:
                        'cursor: default; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; border: 1px solid ' +
                        g.border +
                        '; background-color: White; color: Black; font-family: ' +
                        (g.classic ? 'Arial' : 'system-ui, Arial') +
                        '; font-size: 12px; padding: 4px; margin-top: 1px',
                    },
                    'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
                  ));
                c.setContent(g.predecessorItemTemplate(e, b).innerHTML.replaceAll('\n', '<br/>'));
                c.show();
                var d = m.getBoundingClientRect();
                c.setHorizontalPosition(c.x + a.clientX - d.left);
                c.setVerticalPosition(c.y + a.clientY - d.bottom + 10);
                f.dependencyToolTip = c;
              })
            );
            m.addEventListener(
              'mouseout',
              r(f, m, 'mouseout', function () {
                var a = f.dependencyToolTip;
                a.disable();
                a.hide();
              })
            );
          }
          return m;
        };
      },
      Xb = function (a) {
        return function (c, e) {
          var b = c.ganttChartView.ownerDocument,
            f = b.createElementNS('http://www.w3.org/2000/svg', 'title');
          f.appendChild(da(b, e.item.content + ' - ' + c.content));
          if (typeof a.areToolTipsSimplified === d || !a.areToolTipsSimplified) {
            if (e.dependencyType) {
              f.appendChild(b.createTextNode('\n'));
              f.appendChild(da(b, 'Type: ' + mc(e.dependencyType)));
            }
            if (e.lag) {
              f.appendChild(b.createTextNode('\n'));
              f.appendChild(da(b, 'Lag: ' + e.lag / 3600000 + 'h'));
            }
          }
          return f;
        };
      },
      mc = function (a) {
        switch (a) {
          case 'StartStart':
          case 'SS':
            return 'SS';
          case 'FinishFinish':
          case 'FF':
            return 'FF';
          case 'StartFinish':
          case 'SF':
            return 'SF';
          default:
            return 'FS';
        }
      },
      ed = function (a, c, e, b) {
        var f = Ga(c.item, e, b),
          g = Ga(a, e, b),
          e = 'M ',
          h,
          o,
          m = b.itemHeight,
          n = m / 2,
          l = m / 3.5,
          j = 2,
          x = false,
          k;
        if (
          f == g &&
          (typeof c.dependencyType === d ||
            c.dependencyType == 'FinishStart' ||
            c.dependencyType == 'FS' ||
            c.dependencyType == 'StartFinish' ||
            c.dependencyType == 'SF')
        ) {
          if (
            typeof c.dependencyType === d ||
            c.dependencyType == 'FinishStart' ||
            c.dependencyType == 'FS'
          ) {
            h = F(c.item.finish, b);
            c.item.isMilestone && (h = h + (b.barHeight / 3 + 0.25));
            o = F(a.start, b);
            a.isMilestone && (o = o - (b.barHeight / 3 + 0.25));
          } else {
            h = F(c.item.start, b);
            c.item.isMilestone && (h = h - (b.barHeight / 3 + 0.25));
            o = F(a.finish, b);
            a.isMilestone && (o = o + (b.barHeight / 3 + 0.25));
          }
          f = f - g + 0.5;
          e = e + (h + ' ' + (f + n) + ' L ' + (o + (o > h ? -1 : 1) * (m / 5 - 1)) + ' ' + (f + n));
        } else {
          if (
            typeof c.dependencyType !== d &&
            (c.dependencyType == 'StartStart' ||
              c.dependencyType == 'SS' ||
              c.dependencyType == 'StartFinish' ||
              c.dependencyType == 'SF')
          ) {
            h = F(c.item.start, b);
            c.item.hasChildren && (typeof c.item.isSummaryEnabled === d || c.item.isSummaryEnabled)
              ? (h = h - (b.barHeight / 3 + 0.25))
              : c.item.isMilestone && (h = h - m / 4);
            o = h - l;
          } else {
            h = F(c.item.finish, b) - 1;
            if (c.item.hasChildren && (typeof c.item.isSummaryEnabled === d || c.item.isSummaryEnabled))
              h = h + (b.barHeight / 3 + 0.25);
            else if (c.item.isMilestone) h = h + m / 4;
            else {
              k = F(c.item.start, b) + (b.classic ? 4 : 12) - 1;
              h < k && (h = k);
            }
            o = h + l;
          }
          f = f - g + 0.5;
          e = e + (h + ' ' + (f + n) + ' L ' + o + ' ' + (f + n));
          if (
            typeof c.dependencyType !== d &&
            (c.dependencyType == 'FinishFinish' ||
              c.dependencyType == 'FF' ||
              c.dependencyType == 'StartFinish' ||
              c.dependencyType == 'SF')
          ) {
            h = F(a.finish, b) - 1;
            if (
              typeof c.dependencyType !== d &&
              (c.dependencyType == 'FinishFinish' || c.dependencyType == 'FF')
            ) {
              x = true;
              if (a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled))
                h = h + (b.barHeight / 3 + 1);
              else if (a.isMilestone) h = h + (m / 4 + 1);
              else {
                k = F(a.start, b) + (b.classic ? 4 : 12) - 1;
                h < k && (h = k);
              }
              g = h + l;
            } else if (a.isMilestone) g = h;
            else {
              k = F(a.start, b) + (b.classic ? 4 : 12);
              h < k && (h = k);
              g = h - 2.5;
            }
          } else {
            h = F(a.start, b);
            if (
              typeof c.dependencyType !== d &&
              (c.dependencyType == 'StartStart' || c.dependencyType == 'SS')
            ) {
              x = true;
              a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled)
                ? (h = h - (b.barHeight / 3 + 1))
                : a.isMilestone && (h = h - (m / 4 + 1));
              g = h - l;
            } else if (a.isMilestone) g = h - 1;
            else {
              g = h + 1.5;
              k = F(a.finish, b);
              g < o && k - o > l * 2 && a.start >= c.item.finish && (g = o);
            }
          }
          if (
            typeof c.dependencyType !== d &&
            (c.dependencyType == 'StartStart' ||
              c.dependencyType == 'SS' ||
              c.dependencyType == 'StartFinish' ||
              c.dependencyType == 'SF')
          ) {
            if (g > o) {
              f = f - l / 6;
              f = f <= 0 ? f + n : f - n;
              e = e + (' ' + o + ' ' + (f + n));
            }
          } else if (g < o) {
            f = f - l / 6;
            f = f <= 0 ? f + n : f - n;
            e = e + (' ' + o + ' ' + (f + n));
          }
          e = e + (' ' + g + ' ' + (f + n));
          f = x ? n + 0.5 : f <= 0 ? b.barMargin - 1 - j : m - b.barMargin + 1 + j;
          if (x) {
            g > h - j && (j = -j);
            h = h - j;
          }
          e = e + (' ' + g + ' ' + f);
          x && (e = e + (' ' + h + ' ' + f));
        }
        return e;
      },
      w = function (a, c, e, b) {
        if (typeof a.ganttChartView !== d) {
          var f = a.ganttChartView,
            g = f.settings;
          if (c == 'start' || c == 'minStart' || c == 'maxStart') {
            if (typeof a.minStart !== d && a.start < a.minStart) {
              a.start = a.minStart;
              if (typeof a.loadChartItem === d)
                a.start = R(a.start, g, true, typeof a.isMilestone !== d && a.isMilestone, C(a));
              if (e) a.preferredStart = a.start;
            } else if (typeof a.maxStart !== d && a.start > a.maxStart) {
              a.start = a.maxStart;
              if (typeof a.loadChartItem === d)
                a.start = R(a.start, g, true, typeof a.isMilestone !== d && a.isMilestone, C(a));
              if (e) a.preferredStart = a.start;
            }
            if (a.finish < a.start) {
              a.finish = a.start;
              w(a, 'finish', false, true);
            }
            if (a.completedFinish < a.start) {
              a.completedFinish = a.start;
              w(a, 'completedFinish', false, true);
            } else if (a.completedFinish > a.finish) {
              a.completedFinish = a.finish;
              w(a, 'completedFinish', false, true);
            }
          } else if (c == 'finish' || c == 'minFinish' || c == 'maxFinish') {
            if (typeof a.maxFinish !== d && a.finish > a.maxFinish) {
              a.finish = a.maxFinish;
              if (typeof a.loadChartItem === d)
                a.finish = R(a.finish, g, typeof a.isMilestone !== d && a.isMilestone, true, C(a));
            } else if (typeof a.minFinish !== d && a.finish < a.minFinish) {
              a.finish = a.minFinish;
              if (typeof a.loadChartItem === d)
                a.finish = R(a.finish, g, typeof a.isMilestone !== d && a.isMilestone, true, C(a));
            }
            if (a.finish < a.start) {
              a.finish = a.start;
              w(a, 'finish', false, true);
            }
            if (a.completedFinish < a.start) {
              a.completedFinish = a.start;
              w(a, 'completedFinish', false, true);
            } else if (a.completedFinish > a.finish) {
              a.completedFinish = a.finish;
              w(a, 'completedFinish', false, true);
            }
          }
          if (f.isTimingInformationInitialized) {
            var h = f.items,
              o,
              m;
            if (c == 'indentation' || c == 'predecessors')
              for (o = 0; o < h.length; o++) {
                m = h[o];
                typeof m.dependsOf !== d && delete m.dependsOf;
              }
            if (c == 'predecessors' && g.alwaysHandleInvalidDependencies) {
              m = a;
              typeof m.predecessors !== d && m.predecessors.length > 0 && Ea(m, h, g, f, true);
            } else if (
              (c == 'predecessors' || c == 'start' || c == 'finish') &&
              typeof a.isDuringPropertyChangeTaskDependencyConstraintsEnsuring === d &&
              g.areTaskDependencyConstraintsEnabled &&
              (g.areTaskDependencyConstraintsEnabledWhileDragging || typeof f.isDuringTimeDragOperation === d)
            ) {
              a.isDuringPropertyChangeTaskDependencyConstraintsEnsuring = true;
              for (m = a.parent; m != null; ) {
                typeof m.predecessors !== d && m.predecessors.length > 0 && Ea(m, h, g, f);
                m = m.parent;
              }
              m = a;
              for (
                typeof m.predecessors !== d && m.predecessors.length > 0 ? Ea(m, h, g, f) : Ab(m, g);
                m != null;

              ) {
                if (typeof m.successors === d) {
                  m.successors = [];
                  for (o = 0; o < h.length; o++) {
                    var n = h[o];
                    if (!(n == m || typeof n.predecessors === d || n.predecessors.length == 0))
                      for (var l = 0; l < n.predecessors.length; l++)
                        if (n.predecessors[l].item == m) {
                          m.successors.push(n);
                          break;
                        }
                  }
                }
                for (o = 0; o < m.successors.length; o++) Ea(m.successors[o], h, g, f);
                m = m.parent;
              }
              delete a.isDuringPropertyChangeTaskDependencyConstraintsEnsuring;
            }
            a.isPart && a.ganttChartItem && w(a.ganttChartItem, 'parts', false, true);
          }
          if (
            c == 'assignmentsContent' &&
            !a.hasChildren &&
            a.hasFixedEffort &&
            typeof a.fixedEffortAssignments !== d &&
            a.fixedEffortUpdatesFinish
          ) {
            n = m = 0;
            h = f.getItemAssignments(a);
            for (o = 0; o < h.length; o++) m = m + h[o].value;
            for (o = 0; o < a.fixedEffortAssignments.length; o++) n = n + a.fixedEffortAssignments[o].value;
            m <= 0 && (m = 1);
            n <= 0 && (n = 1);
            f.setItemEffort(a, (f.getItemEffort(a) * n) / m);
          }
          if (
            (c == 'start' || c == 'finish') &&
            !a.hasChildren &&
            a.hasFixedEffort &&
            typeof a.fixedEffort !== d &&
            !a.fixedEffortUpdatesFinish
          ) {
            m = f.getItemEffort(a) / a.fixedEffort;
            h = '';
            for (o = 0; o < a.fixedEffortAssignments.length; o++) {
              n = a.fixedEffortAssignments[o];
              h.length > 0 && (h = h + ', ');
              l = Math.floor((n.value / m) * 1000000) / 1000000;
              h = h + (n.key + (l != 1 ? ' [' + l * 100 + '%]' : ''));
            }
            f.setItemAssignmentsContent(a, h);
          }
          g.itemPropertyChangeHandler(a, c, e, b);
          c == 'isExpanded'
            ? typeof a.ganttChartView !== d &&
              typeof a.ganttChartView.settings.itemExpansionChangeHandler !== d &&
              a.ganttChartView.settings.itemExpansionChangeHandler(a, a.isExpanded)
            : c == 'isSelected' &&
              typeof a.ganttChartView !== d &&
              typeof a.ganttChartView.settings.itemSelectionChangeHandler !== d &&
              a.ganttChartView.settings.itemSelectionChangeHandler(a, a.isSelected, e);
          typeof a.scheduleChartView !== d &&
            c == 'content' &&
            a.scheduleChartView.refreshScheduleChartItem(a);
        }
      },
      Ab = function (a, c) {
        if (typeof a.isAwaitingPreferredStartUpdates === d)
          if (a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled))
            for (var e = 0; e < a.children.length; e++) Ab(a.children[e], c);
          else if (typeof a.preferredStart !== d && a.preferredStart < a.start) {
            a.isAwaitingPreferredStartUpdates = true;
            var b = a.start.valueOf(),
              f = a.finish.valueOf(),
              g = a.completedFinish.valueOf();
            effort = M(a.start, a.finish, c, C(a));
            completedEffort = M(a.start, a.completedFinish, c, C(a));
            a.start = a.preferredStart;
            a.finish = S(a.start, effort, c, C(a));
            a.completedFinish = S(a.start, completedEffort, c, C(a));
            a.start.valueOf() != b && w(a, 'start', false, false);
            a.finish.valueOf() != f && w(a, 'finish', false, false);
            a.completedFinish.valueOf() != g && w(a, 'completedFinish', false, false);
            setTimeout(function () {
              a.start.valueOf() != b && w(a, 'start', false, true);
              a.finish.valueOf() != f && w(a, 'finish', false, true);
              a.completedFinish.valueOf() != g && w(a, 'completedFinish', false, true);
              (a.start.valueOf() != b || a.finish.valueOf() != f || a.completedFinish.valueOf() != g) && $(a);
              delete a.isAwaitingPreferredStartUpdates;
            }, 1);
          }
      },
      Ea = function (a, c, e, b, f) {
        if (f) {
          if (b.isBasicTimingInformationInitialized) {
            for (var f = [], g, h = false, b = 0; b < a.predecessors.length; b++) {
              g = a.predecessors[b];
              if (typeof g.item === d || c.indexOf(g.item) < 0 || Ja(g.item, a)) {
                e.invalidPredecessorDetectionHandler && e.invalidPredecessorDetectionHandler(g, a, g.item);
                h = true;
              } else f.push(g);
            }
            a.predecessors = f;
            if (h) {
              w(a, 'predecessors', false, true);
              typeof a.ganttChartView !== d && Pa(a);
            }
          }
        } else if (
          b.isBasicTimingInformationInitialized &&
          (typeof a.areDependencyConstraintsEnabled === d || a.areDependencyConstraintsEnabled)
        ) {
          Ab(a, e);
          f = [];
          h = false;
          for (b = 0; b < a.predecessors.length; b++) {
            g = a.predecessors[b];
            if (typeof g.item === d || c.indexOf(g.item) < 0 || Ja(g.item, a)) {
              e.invalidPredecessorDetectionHandler && e.invalidPredecessorDetectionHandler(g, a, g.item);
              h = true;
            } else f.push(g);
          }
          a.predecessors = f;
          if (h) {
            w(a, 'predecessors', false, true);
            typeof a.ganttChartView !== d && Pa(a);
          }
          for (b = 0; b < a.predecessors.length; b++) {
            g = a.predecessors[b];
            nc(a, g, c, e);
          }
        }
      },
      nc = function (a, c, e, b) {
        if (a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled))
          for (var f = 0; f < a.children.length; f++) nc(a.children[f], c, e, b);
        else if (
          !(
            (typeof b.areDependencyConstraintsAppliedOnMilestones !== d &&
              !b.areDependencyConstraintsAppliedOnMilestones &&
              a.isMilestone) ||
            (typeof b.areDependencyConstraintsAppliedOnStartedTasks !== d &&
              !b.areDependencyConstraintsAppliedOnStartedTasks &&
              Qa(a))
          )
        ) {
          var g = c.item,
            h,
            e = false;
          if (
            typeof c.dependencyType === d ||
            c.dependencyType == '' ||
            c.dependencyType == 'FinishStart' ||
            c.dependencyType == 'FS'
          ) {
            g = g.finish;
            typeof c.lag !== d && c.lag != 0 && (g = S(g, c.lag, b, C(a)));
            if (a.start < g) {
              if (
                !a.isMilestone &&
                (!a.hasChildren || !(typeof a.isSummaryEnabled === d || a.isSummaryEnabled))
              ) {
                f = M(a.start, a.finish, b, C(a));
                h = M(a.start, a.completedFinish, b, C(a));
              }
              if (!a.hasChildren || !(typeof a.isSummaryEnabled === d || a.isSummaryEnabled)) {
                var o = a.start.valueOf(),
                  c = a.finish.valueOf(),
                  m = a.completedFinish.valueOf();
                a.start = R(va(g, b), b, true, a.isMilestone, C(a));
                a.start.valueOf() != o && w(a, 'start', false, true);
                if (a.isMilestone) {
                  a.finish = a.start;
                  a.finish.valueOf() != c && w(a, 'finish', false, true);
                  a.completedFinish = a.start;
                } else {
                  a.finish = S(a.start, f, b, C(a));
                  a.finish.valueOf() != c && w(a, 'finish', false, true);
                  a.completedFinish = S(a.start, h, b, C(a));
                }
                a.completedFinish.valueOf() != m && w(a, 'completedFinish', false, true);
                e = true;
              }
            }
          } else if (c.dependencyType == 'StartStart' || c.dependencyType == 'SS') {
            g = g.start;
            typeof c.lag !== d && c.lag != 0 && (g = S(g, c.lag, b, C(a)));
            if (a.start < g) {
              if (
                !a.isMilestone &&
                (!a.hasChildren || !(typeof a.isSummaryEnabled === d || a.isSummaryEnabled))
              ) {
                f = M(a.start, a.finish, b, C(a));
                h = M(a.start, a.completedFinish, b, C(a));
              }
              if (!a.hasChildren || !(typeof a.isSummaryEnabled === d || a.isSummaryEnabled)) {
                o = a.start.valueOf();
                c = a.finish.valueOf();
                m = a.completedFinish.valueOf();
                a.start = R(va(g, b), b, true, a.isMilestone, C(a));
                a.start.valueOf() != o && w(a, 'start', false, true);
                if (a.isMilestone) {
                  a.finish = a.start;
                  a.finish.valueOf() != c && w(a, 'finish', false, true);
                  a.completedFinish = a.start;
                } else {
                  a.finish = S(a.start, f, b, C(a));
                  a.finish.valueOf() != c && w(a, 'finish', false, true);
                  a.completedFinish = S(a.start, h, b, C(a));
                }
                a.completedFinish.valueOf() != m && w(a, 'completedFinish', false, true);
                e = true;
              }
            }
          } else if (c.dependencyType == 'FinishFinish' || c.dependencyType == 'FF') {
            g = g.finish;
            typeof c.lag !== d && c.lag != 0 && (g = S(g, c.lag, b, C(a)));
            if (
              a.finish > g &&
              (!a.hasChildren || !(typeof a.isSummaryEnabled === d || a.isSummaryEnabled))
            ) {
              c = a.finish.valueOf();
              m = a.completedFinish.valueOf();
              a.finish = R(va(g, b), b, a.isMilestone, true, C(a));
              if (a.finish < a.start) a.finish = a.start;
              a.finish.valueOf() != c && w(a, 'finish', false, true);
              if (a.isMilestone) {
                if (a.completedFinish != a.start) {
                  a.completedFinish = a.start;
                  a.completedFinish.valueOf() != m && w(a, 'completedFinish', false, true);
                }
              } else if (a.completedFinish > a.finish) {
                a.completedFinish = a.finish;
                a.completedFinish.valueOf() != m && w(a, 'completedFinish', false, true);
              }
              e = true;
            }
          } else if (c.dependencyType == 'StartFinish' || c.dependencyType == 'SF') {
            g = g.start;
            typeof c.lag !== d && c.lag != 0 && (g = S(g, c.lag, b, C(a)));
            if (
              a.finish > g &&
              (!a.hasChildren || !(typeof a.isSummaryEnabled === d || a.isSummaryEnabled))
            ) {
              c = a.finish.valueOf();
              m = a.completedFinish.valueOf();
              a.finish = R(va(g, b), b, a.isMilestone, true, C(a));
              if (a.finish < a.start) a.finish = a.start;
              a.finish.valueOf() != c && w(a, 'finish', false, true);
              if (a.isMilestone) {
                if (a.completedFinish != a.start) {
                  a.completedFinish = a.start;
                  a.completedFinish.valueOf() != m && w(a, 'completedFinish', false, true);
                }
              } else if (a.completedFinish > a.finish) {
                a.completedFinish = a.finish;
                a.completedFinish.valueOf() != m && w(a, 'completedFinish', false, true);
              }
              if (a.start.valueOf() != o || a.finish.valueOf() != c || a.completedFinish.valueOf() != m)
                e = true;
            }
          }
          if (
            e &&
            !(
              typeof a.ganttChartView === d || typeof a.isAwaitingEnsureItemDependencyConstraintsUpdates !== d
            )
          )
            if (typeof a.ganttChartView !== d && !a.ganttChartView.isTimingInformationInitialized) $(a);
            else {
              a.isAwaitingEnsureItemDependencyConstraintsUpdates = true;
              setTimeout(function () {
                $(a);
                delete a.isAwaitingEnsureItemDependencyConstraintsUpdates;
              }, 0);
            }
        }
      },
      Ja = function (a, c) {
        if (typeof a.dependsOf !== d && typeof a.dependsOf[c.index] !== d) return a.dependsOf[c.index];
        if (typeof a.dependsOf === d) a.dependsOf = {};
        if (a == c) return true;
        var e = oc(a);
        if (e.indexOf(c) >= 0) return (a.dependsOf[c.index] = true);
        var b = oc(c);
        if (b.indexOf(a) >= 0) return (a.dependsOf[c.index] = true);
        var f = Bb(a),
          g,
          h;
        for (g = 0; g < f.length; g++) {
          h = f[g];
          if (h == c || h == a || e.indexOf(h) >= 0 || b.indexOf(h) >= 0 || Ja(h, c))
            return (a.dependsOf[c.index] = true);
        }
        for (f = 0; f < e.length; f++) {
          var o = Bb(e[f]);
          for (g = 0; g < o.length; g++) {
            h = o[g];
            if (h == c || h == a || e.indexOf(h) >= 0 || b.indexOf(h) >= 0 || Ja(h, c))
              return (a.dependsOf[c.index] = true);
          }
        }
        e = a.children;
        for (g = 0; g < e.length; g++) {
          b = e[g];
          if (typeof b.predecessors !== d && b.predecessors.length > 0 && Ja(b, c))
            return (a.dependsOf[c.index] = true);
        }
        return (a.dependsOf[c.index] = false);
      },
      oc = function (a) {
        for (var c = []; typeof a.parent !== d && a.parent != null; ) {
          (typeof a.isSummaryEnabled === d || a.isSummaryEnabled) && c.push(a.parent);
          a = a.parent;
        }
        return c;
      },
      Bb = function (a) {
        var c = [];
        if (typeof a.predecessors !== d && a.predecessors != null)
          for (var e = 0; e < a.predecessors.length; e++)
            typeof a.predecessors[e].item !== d && c.push(a.predecessors[e].item);
        return c;
      },
      Lc = function (a, c) {
        for (var e = 0; e < a.length; e++) {
          var b = a[e],
            d = c == null || c(b);
          b.isHidden = !d;
          if (d)
            for (b = b.parent; b != null; ) {
              b.isHidden = false;
              b = b.parent;
            }
        }
      },
      pc = function (a, c, e) {
        return R(a, c, true, true, e);
      },
      ya = function (a, c, e) {
        return R(a, c, true, false, e);
      },
      R = function (a, c, e, b, f) {
        var g = typeof f !== d && typeof f.workingDayStart !== d ? f.workingDayStart : c.visibleDayStart,
          h = typeof f !== d && typeof f.workingDayFinish !== d ? f.workingDayFinish : c.visibleDayFinish,
          o = typeof f !== d && typeof f.workingWeekStart !== d ? f.workingWeekStart : c.workingWeekStart,
          m = typeof f !== d && typeof f.workingWeekFinish !== d ? f.workingWeekFinish : c.workingWeekFinish,
          c =
            typeof f !== d && typeof f.specialNonworkingDays !== d
              ? f.specialNonworkingDays
              : c.specialNonworkingDays,
          f = Z(a),
          a = oa(a);
        if (a < g || (!e && a == g))
          if (e) a = g;
          else {
            f = Ua(f);
            a = h;
          }
        if (a > h || (!b && a == h))
          if (b) a = h;
          else {
            f = W(f);
            a = g;
          }
        for (b = pa(f); b < o || b > m || dc(f, c); ) {
          if (e) {
            b++;
            f = W(f);
            a = g;
          } else {
            b--;
            f = Ua(f);
            a = h;
          }
          for (; b < 0; ) b = b + 7;
          for (; b >= 7; ) b = b - 7;
        }
        return new Date(f.valueOf() + a);
      },
      M = function (a, c, e, b) {
        if (c.valueOf() < a.valueOf()) return -M(c, a, e, b);
        if (c.valueOf() == a.valueOf()) return 0;
        var f = typeof b !== d && typeof b.workingDayStart !== d ? b.workingDayStart : e.visibleDayStart,
          g = typeof b !== d && typeof b.workingDayFinish !== d ? b.workingDayFinish : e.visibleDayFinish,
          h = typeof b !== d && typeof b.workingWeekStart !== d ? b.workingWeekStart : e.workingWeekStart,
          o = typeof b !== d && typeof b.workingWeekFinish !== d ? b.workingWeekFinish : e.workingWeekFinish,
          b =
            typeof b !== d && typeof b.specialNonworkingDays !== d
              ? b.specialNonworkingDays
              : e.specialNonworkingDays,
          e = 0,
          m = Z(a),
          a = oa(a);
        if (a < f) a = f;
        else if (a >= g) {
          m = W(m);
          a = f;
        }
        for (var n = Z(c); m < n; ) {
          var l = pa(m);
          l >= h && l <= o && !dc(m, b) && (e = e + (g - a));
          m = W(m);
          a = f;
        }
        c = oa(c);
        c < f ? (c = f) : c > g && (c = g);
        c > a && (e = e + (c - a));
        return e;
      },
      S = function (a, c, e, b) {
        if (c < 0) return Za(-c, a, e, b);
        a = R(a, e, true, false, b);
        if (c == 0) return a;
        var f = typeof b !== d && typeof b.workingDayStart !== d ? b.workingDayStart : e.visibleDayStart,
          g = typeof b !== d && typeof b.workingDayFinish !== d ? b.workingDayFinish : e.visibleDayFinish,
          h = oa(a);
        if (h + c < g) return new Date(a.valueOf() + c);
        h = g - h;
        a = new Date(a.valueOf() + h);
        for (c = c - h; c > 0; ) {
          a = oa(a) > 0 ? R(W(Z(a)), e, true, false, b) : R(Z(a), e, true, false, b);
          if (f + c < g) return new Date(a.valueOf() + c);
          h = g - f;
          a = new Date(a.valueOf() + h);
          c = c - h;
        }
        return new Date(a.valueOf());
      },
      Za = function (a, c, e, b) {
        if (a < 0) return S(c, -a, e, b);
        c = R(c, e, false, true, b);
        if (a == 0) return c;
        var f = typeof b !== d && typeof b.workingDayStart !== d ? b.workingDayStart : e.visibleDayStart,
          g = typeof b !== d && typeof b.workingDayFinish !== d ? b.workingDayFinish : e.visibleDayFinish,
          h = oa(c);
        if (h - a > f) return new Date(c.valueOf() - a);
        h = h - f;
        c = new Date(c.valueOf() - h);
        for (a = a - h; a > 0; ) {
          c = oa(c) < 86400000 ? R(Z(c), e, false, true, b) : R(Ua(Z(c)), e, false, true, b);
          if (g - a > f) return new Date(c.valueOf() - a);
          h = g - f;
          c = new Date(c.valueOf() - h);
          a = a - h;
        }
        return new Date(c.valueOf());
      },
      kb = function (a, c, e, b, d) {
        return M(a, c, b, d) / M(a, e, b, d);
      },
      Cb = function (a, c, e, b, d) {
        return S(a, c * M(a, e, b, d), b, d);
      },
      $a = function (a) {
        for (var c = 0, a = la(a), e = 0; e < a.length; e++) c = c + a[e].value;
        c == 0 && (c = 1);
        return c;
      },
      Db = function (a) {
        if (a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled)) {
          for (var c = 0, a = a.children, e = 0; e < a.length; e++) c = c + Db(a[e]);
          return c;
        }
        return a.ganttChartView.getItemEffort(a) * $a(a);
      },
      Eb = function (a) {
        if (a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled)) {
          for (var c = 0, a = a.children, e = 0; e < a.length; e++) c = c + Eb(a[e]);
          return c;
        }
        return a.ganttChartView.getItemCompletedEffort(a) * $a(a);
      },
      C = function (a) {
        if (a.schedule) return a.schedule;
        if (a.scheduleChartItem) return a.scheduleChartItem.schedule;
        var c = la(a);
        if (c) {
          for (var e = -Infinity, b, d = 0; d < c.length; d++) {
            var g = c[d];
            if (g.value > e) {
              b = g.key;
              e = g.value;
            }
          }
          if ((a = a.ganttChartView) && a.settings && a.settings.resourceSchedules) {
            b = aa(a.settings.resourceSchedules, b);
            return b < 0 || !a.settings.resourceSchedules[b] ? void 0 : a.settings.resourceSchedules[b].value;
          }
        }
      },
      zb = function (a, c, e, b, f, g, q, o, m, n, l) {
        function j(c, e) {
          n.isDuringTimeDragOperation = true;
          n.draggingItem = f;
          n.dragType = 'Start';
          n.style.cursor = a.style.cursor;
          n.draggingInitialX = c.clientX;
          n.draggingInitialStartPosition = g;
          n.draggingInitialFinishPosition = q;
          n.draggingInitialCompletedFinishPosition = o;
          if (DlhSoft.Controls.ToolTip && l.useUpdatingToolTips && !e) {
            (x = DlhSoft.Controls.ToolTip.get(a)) ||
              (x = DlhSoft.Controls.ToolTip.initialize(
                void 0,
                a,
                {
                  duration: NaN,
                  containerStyle:
                    'cursor: default; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; border: 1px solid ' +
                    l.border +
                    '; background-color: White; color: Black; font-family: ' +
                    (l.classic ? 'Arial' : 'system-ui, Arial') +
                    '; font-size: 12px; padding: 4px; margin-top: 1px',
                },
                'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
              ));
            x.setContent(l.dateTimeFormatter(h(f.start)) + ' \u2013 ' + l.dateTimeFormatter(h(f.finish)));
            x.show();
            x.originalX = x.x;
            n.toolTip = x;
          }
        }
        var x;
        a.addEventListener(
          'mousedown',
          r(
            n,
            a,
            'mousedown',
            function (a) {
              if (a.button == 0) {
                j(a);
                a.preventDefault();
              }
            },
            true
          ),
          true
        );
        a.addEventListener(
          'touchstart',
          r(
            n,
            a,
            'touchstart',
            function (a) {
              j(a.touches[0], true);
              a.preventDefault();
              a.target.addEventListener('touchmove', r(n, a.target, 'touchmove', f.touchMoveHandler));
              a.target.addEventListener('touchend', r(n, a.target, 'touchend', f.touchEndHandler));
            },
            true
          ),
          true
        );
        a.addEventListener(
          'touchend',
          r(
            n,
            a,
            'touchend',
            function () {
              T(a, 'mouseover');
              T(a, 'mousedown');
              setTimeout(function () {
                T(a, 'mouseout');
              }, 2000);
            },
            true
          ),
          true
        );
        if (c != null) {
          var k = function (e, b) {
            n.isDuringTimeDragOperation = true;
            n.draggingItem = f;
            n.dragType = 'StartOnly';
            n.style.cursor = a.style.cursor;
            n.draggingInitialX = e.clientX;
            n.draggingInitialStartPosition = g;
            if (DlhSoft.Controls.ToolTip && l.useUpdatingToolTips && !b) {
              (x = DlhSoft.Controls.ToolTip.get(c)) ||
                (x = DlhSoft.Controls.ToolTip.initialize(
                  void 0,
                  c,
                  {
                    duration: NaN,
                    containerStyle:
                      'cursor: default; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; border: 1px solid ' +
                      l.border +
                      '; background-color: White; color: Black; font-family: ' +
                      (l.classic ? 'Arial' : 'system-ui, Arial') +
                      '; font-size: 12px; padding: 4px; margin-top: 1px',
                  },
                  'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
                ));
              x.setContent(l.dateTimeFormatter(h(f.start)) + ' \u2013');
              x.show();
              x.setHorizontalPosition(x.x + 4);
              x.originalX = x.x;
              n.toolTip = x;
            }
          };
          c.addEventListener(
            'mousedown',
            r(
              n,
              c,
              'mousedown',
              function (a) {
                if (a.button == 0) {
                  k(a);
                  a.preventDefault();
                }
              },
              true
            ),
            true
          );
          c.addEventListener(
            'touchstart',
            r(
              n,
              c,
              'touchstart',
              function (a) {
                k(a.touches[0], true);
                a.preventDefault();
                a.target.addEventListener('touchmove', r(n, a.target, 'touchmove', f.touchMoveHandler));
                a.target.addEventListener('touchend', r(n, a.target, 'touchend', f.touchEndHandler));
              },
              true
            ),
            true
          );
        }
        if (e != null) {
          var p = function (a, c) {
            n.isDuringTimeDragOperation = true;
            n.draggingItem = f;
            n.dragType = 'Finish';
            n.style.cursor = e.style.cursor;
            n.draggingInitialX = a.clientX;
            n.draggingInitialFinishPosition = q;
            if (DlhSoft.Controls.ToolTip && l.useUpdatingToolTips && !c) {
              (x = DlhSoft.Controls.ToolTip.get(e)) ||
                (x = DlhSoft.Controls.ToolTip.initialize(
                  void 0,
                  e,
                  {
                    duration: NaN,
                    containerStyle:
                      'cursor: default; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; border: 1px solid ' +
                      l.border +
                      '; background-color: White; color: Black; font-family: ' +
                      (l.classic ? 'Arial' : 'system-ui, Arial') +
                      '; font-size: 12px; padding: 4px; margin-top: 1px',
                  },
                  'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
                ));
              x.setContent('\u2013 ' + l.dateTimeFormatter(h(f.finish)));
              x.show();
              x.setHorizontalPosition(x.x - x.contentContainer.clientWidth);
              x.originalX = x.x;
              n.toolTip = x;
            }
          };
          e.addEventListener(
            'mousedown',
            r(
              n,
              e,
              'mousedown',
              function (a) {
                if (a.button == 0) {
                  p(a);
                  a.preventDefault();
                }
              },
              true
            ),
            true
          );
          e.addEventListener(
            'touchstart',
            r(
              n,
              e,
              'touchstart',
              function (a) {
                p(a.touches[0], true);
                a.preventDefault();
                a.target.addEventListener('touchmove', r(n, a.target, 'touchmove', f.touchMoveHandler));
                a.target.addEventListener('touchend', r(n, a.target, 'touchend', f.touchEndHandler));
              },
              true
            ),
            true
          );
        }
        if (b != null) {
          var t = function (a, c) {
            n.isDuringTimeDragOperation = true;
            n.draggingItem = f;
            n.dragType = 'CompletedFinish';
            n.style.cursor = b.style.cursor;
            n.draggingInitialX = a.clientX;
            n.draggingInitialCompletedFinishPosition = o;
            if (DlhSoft.Controls.ToolTip && l.useUpdatingToolTips && !c) {
              (x = DlhSoft.Controls.ToolTip.get(b)) ||
                (x = DlhSoft.Controls.ToolTip.initialize(
                  void 0,
                  b,
                  {
                    duration: NaN,
                    containerStyle:
                      'cursor: default; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; border: 1px solid ' +
                      l.border +
                      '; background-color: White; color: Black; font-family: ' +
                      (l.classic ? 'Arial' : 'system-ui, Arial') +
                      '; font-size: 12px; padding: 4px; margin-top: 1px',
                  },
                  'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
                ));
              var e = kb(f.start, f.completedFinish, f.finish, l, C(f));
              if (!isNaN(e)) {
                x.setContent(
                  Math.round(e * 100 * (l.integralCompletionPercents ? 1 : 100)) /
                    (l.integralCompletionPercents ? 1 : 100) +
                    '%'
                );
                x.show();
                x.setHorizontalPosition(x.x + 2);
                x.originalX = x.x;
                n.toolTip = x;
              }
            }
          };
          if (!l.classic) {
            var s = function () {
                if (typeof n.temporaryHoveringCompletedFinishThumb !== d) {
                  var a = n.temporaryHoveringCompletedFinishThumb;
                  delete n.temporaryHoveringCompletedFinishThumb;
                  if (
                    typeof a.childNodes[0].originalClass === d &&
                    typeof a.childNodes[0].originalStyle === d
                  )
                    return;
                  a.childNodes[0].setAttribute('class', a.childNodes[0].originalClass);
                  a.childNodes[0].setAttribute('style', a.childNodes[0].originalStyle);
                  delete a.childNodes[0].originalClass;
                  delete a.childNodes[0].originalStyle;
                }
                if (!(n.draggingItem || f.finish.valueOf() == f.start.valueOf())) {
                  f.temporaryThumb = b;
                  n.temporaryHoveringCompletedFinishThumb = b;
                  b.childNodes[0].originalClass = b.childNodes[0].getAttribute('class');
                  b.childNodes[0].originalStyle = b.childNodes[0].getAttribute('style');
                  typeof l.completedBarThumbClass !== d &&
                    b.childNodes[0].setAttribute('class', l.completedBarThumbClass);
                  (typeof l.completedBarThumbClass !== d || typeof l.completedBarThumbStyle !== d) &&
                    b.childNodes[0].setAttribute('style', l.completedBarThumbStyle);
                  b.style.cursor = 'ew-resize';
                }
              },
              E = function () {
                if (typeof n.temporaryHoveringCompletedFinishThumb !== d) {
                  var a = n.temporaryHoveringCompletedFinishThumb;
                  if (a == f.temporaryThumb) {
                    delete n.temporaryHoveringCompletedFinishThumb;
                    if (
                      !(
                        typeof a.childNodes[0].originalClass === d &&
                        typeof a.childNodes[0].originalStyle === d
                      )
                    ) {
                      a.childNodes[0].setAttribute('class', a.childNodes[0].originalClass);
                      a.childNodes[0].setAttribute('style', a.childNodes[0].originalStyle);
                      delete a.childNodes[0].originalClass;
                      delete a.childNodes[0].originalStyle;
                    }
                  }
                }
              };
            a.addEventListener('mouseover', r(n, a, 'mouseover', s, true), true);
            b.addEventListener('mouseover', r(n, b, 'mouseover', s, true), true);
            a.addEventListener('mouseout', r(n, a, 'mouseout', E, true), true);
            b.addEventListener('mouseout', r(n, b, 'mouseout', E, true), true);
          }
          b.addEventListener(
            'mousedown',
            r(
              n,
              b,
              'mousedown',
              function (a) {
                if (a.button == 0) {
                  t(a);
                  a.preventDefault();
                }
              },
              true
            ),
            true
          );
          b.addEventListener(
            'touchstart',
            r(
              n,
              b,
              'touchstart',
              function (a) {
                t(a.touches[0], true);
                a.preventDefault();
                a.target.addEventListener('touchmove', r(n, a.target, 'touchmove', f.touchMoveHandler));
                a.target.addEventListener('touchend', r(n, a.target, 'touchend', f.touchEndHandler));
              },
              true
            ),
            true
          );
        }
        if (typeof n.draggableItems === d) n.draggableItems = [];
        s = false;
        for (E = 0; E < n.draggableItems.length; E++)
          if (n.draggableItems[E] == f) {
            s = true;
            break;
          }
        if (!s) {
          var u = function (a, c) {
            if (
              !(
                typeof n.draggingItem === d ||
                n.draggingItem != f ||
                (n.dragType != 'Start' &&
                  n.dragType != 'StartOnly' &&
                  n.dragType != 'Finish' &&
                  n.dragType != 'CompletedFinish')
              )
            ) {
              var e = a.clientX - n.draggingInitialX;
              qc(a.clientX, n);
              delete n.draggingItem;
              n.draggingInitialStartPosition + e < 0 && (e = -n.draggingInitialStartPosition);
              if (n.dragType == 'Start' || n.dragType == 'StartOnly') {
                var b, g;
                n.dragType != 'StartOnly' && (b = M(f.start, f.finish, l, C(f)));
                g = M(f.start, f.completedFinish, l, C(f));
                e = R(va(Na(n.draggingInitialStartPosition + e, l), l), l, true, f.isMilestone, C(f));
                if (n.dragType == 'StartOnly' && e > f.finish) e = f.finish;
                f.start = e;
                f.preferredStart = f.start;
                w(f, 'start', true, false);
                if (n.dragType != 'StartOnly') {
                  if (f.isMilestone) {
                    f.finish = f.start;
                    w(f, 'finish', false, false);
                    f.completedFinish = f.start;
                  } else {
                    f.finish = S(f.start, b, l, C(f));
                    w(f, 'finish', false, false);
                    f.completedFinish = S(f.start, g, l, C(f));
                  }
                  w(f, 'completedFinish', false, false);
                  if (DlhSoft.Controls.ToolTip && l.useUpdatingToolTips && !c) {
                    x = n.toolTip;
                    x.setContent(
                      l.dateTimeFormatter(h(f.start)) + ' \u2013 ' + l.dateTimeFormatter(h(f.finish))
                    );
                    x.setHorizontalPosition(x.originalX + (F(f.start, l) - n.draggingInitialStartPosition));
                  }
                } else {
                  if (f.finish < f.start) {
                    f.finish = f.start;
                    w(f, 'finish', false, false);
                  }
                  if (f.completedFinish < f.start || g <= 0) {
                    f.completedFinish = f.start;
                    w(f, 'completedFinish', false, false);
                  }
                  if (DlhSoft.Controls.ToolTip && l.useUpdatingToolTips && !c) {
                    x = n.toolTip;
                    x.setContent(l.dateTimeFormatter(h(f.start)) + ' \u2013');
                    x.setHorizontalPosition(x.originalX + (F(f.start, l) - n.draggingInitialStartPosition));
                  }
                }
                ab(f, m, n.chartContent, l);
              } else if (n.dragType == 'Finish') {
                e = R(va(Na(n.draggingInitialFinishPosition + e, l), l), l, f.isMilestone, true, C(f));
                if (e < f.start) e = f.start;
                if (e.valueOf() != f.finish.valueOf()) {
                  f.finish = e;
                  w(f, 'finish', true, false);
                }
                if (f.completedFinish > e) {
                  f.completedFinish = e;
                  w(f, 'completedFinish', false, false);
                }
                if (DlhSoft.Controls.ToolTip && l.useUpdatingToolTips && !c) {
                  x = n.toolTip;
                  x.setContent('\u2013 ' + l.dateTimeFormatter(h(f.finish)));
                  x.setHorizontalPosition(x.originalX + (F(f.finish, l) - n.draggingInitialFinishPosition));
                }
                ab(f, m, n.chartContent, l);
              } else if (n.dragType == 'CompletedFinish') {
                if (l.percentBasedCompletionBar) {
                  if (f.start.valueOf() != f.finish.valueOf()) {
                    b = F(f.start, l);
                    g = Math.max(F(f.finish, l), b + (l.classic ? 4 : 12));
                    e = Math.max(
                      0,
                      Math.min(1, (n.draggingInitialCompletedFinishPosition + e - b) / (g - b))
                    );
                    e = Cb(f.start, e, f.finish, l);
                    if (e.valueOf() != f.completedFinish.valueOf()) {
                      f.completedFinish = e;
                      w(f, 'completedFinish', true, false);
                    }
                  }
                } else {
                  e = R(
                    va(Na(n.draggingInitialCompletedFinishPosition + e, l), l),
                    l,
                    f.isMilestone,
                    true,
                    C(f)
                  );
                  if (e < f.start) e = f.start;
                  if (e > f.finish) e = f.finish;
                  if (e.valueOf() != f.completedFinish.valueOf()) {
                    f.completedFinish = e;
                    w(f, 'completedFinish', true, false);
                  }
                }
                if (DlhSoft.Controls.ToolTip && l.useUpdatingToolTips && !c) {
                  x = n.toolTip;
                  e = kb(f.start, f.completedFinish, f.finish, l, C(f));
                  if (!isNaN(e)) {
                    x.setContent(
                      Math.round(e * 100 * (l.integralCompletionPercents ? 1 : 100)) /
                        (l.integralCompletionPercents ? 1 : 100) +
                        '%'
                    );
                    x.setHorizontalPosition(
                      x.originalX + (F(f.completedFinish, l) - n.draggingInitialCompletedFinishPosition)
                    );
                  }
                }
                ab(f, m, n.chartContent, l);
              }
              n.draggingItem = f;
              n.draggingPerformed = true;
            }
          };
          n.addEventListener(
            'mousemove',
            r(
              n,
              n,
              'mousemove',
              function (a) {
                u(a);
              },
              true
            ),
            true
          );
          n.addEventListener(
            'touchmove',
            r(
              n,
              n,
              'touchmove',
              function (a) {
                u(a.touches[0], true);
              },
              true
            ),
            true
          );
          f.touchMoveHandler = function (a) {
            u(a.touches[0], true);
          };
          var v = function () {
            if (
              !(
                typeof n.draggingItem === d ||
                n.draggingItem != f ||
                (n.dragType != 'Start' &&
                  n.dragType != 'StartOnly' &&
                  n.dragType != 'Finish' &&
                  n.dragType != 'CompletedFinish')
              )
            ) {
              delete n.isDuringTimeDragOperation;
              var a = n.draggingItem;
              n.style.cursor = 'default';
              if (n.draggingPerformed) {
                if (n.dragType == 'Start' || n.dragType == 'StartOnly') {
                  w(a, 'start', true, true);
                  w(a, 'finish', false, true);
                  w(a, 'completedFinish', false, true);
                } else if (n.dragType == 'Finish') {
                  w(a, 'finish', true, true);
                  w(a, 'completedFinish', false, true);
                } else n.dragType == 'CompletedFinish' && w(a, 'completedFinish', true, true);
                delete n.draggingPerformed;
              }
              delete n.draggingItem;
            }
          };
          document.addEventListener(
            'mouseup',
            r(
              n,
              document,
              'mouseup',
              function (a) {
                a.button == 0 && v();
              },
              true
            ),
            true
          );
          document.addEventListener(
            'touchend',
            r(
              n,
              document,
              'touchend',
              function () {
                v();
              },
              true
            ),
            true
          );
          f.touchEndHandler = function () {
            v();
          };
          n.draggableItems.push(f);
        }
      },
      jb = function (a, c, e, b, f, g, h, o, m, n) {
        function l(c, e) {
          delete m.cancelDrag;
          m.draggingItem = b;
          m.dragType = 'Dependency';
          m.dragDependencyType = 'Finish';
          m.style.cursor = a.style.cursor;
          m.draggingInitialX = c.clientX;
          m.draggingInitialY = c.clientY;
          m.draggingInitialRightPosition = g;
          m.draggingInitialTopPosition = f;
          m.draggingInitialFinishPosition = g;
          m.draggingInitialThumbPosition = f;
          if (DlhSoft.Controls.ToolTip && n.useUpdatingToolTips && !e) {
            (toolTip = DlhSoft.Controls.ToolTip.get(a)) ||
              (toolTip = DlhSoft.Controls.ToolTip.initialize(
                void 0,
                a,
                {
                  duration: NaN,
                  containerStyle:
                    'cursor: default; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; border: 1px solid ' +
                    n.border +
                    '; background-color: White; color: Black; font-family: ' +
                    (n.classic ? 'Arial' : 'system-ui, Arial') +
                    '; font-size: 12px; padding: 4px; margin-top: 1px',
                },
                'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
              ));
            toolTip.setContent(b.content + ' \u2013');
            toolTip.show();
            toolTip.setPosition(toolTip.x + 16, toolTip.y - 8);
            toolTip.originalX = toolTip.x;
            toolTip.originalY = toolTip.y;
            m.toolTip = toolTip;
          }
        }
        var j = b.ganttChartView.ownerDocument;
        if (
          m.temporaryHoveringThumb &&
          (m.temporaryHoveringThumb.originalClass || m.temporaryHoveringThumb.originalStyle)
        ) {
          m.temporaryHoveringThumb.setAttribute('class', m.temporaryHoveringThumb.originalClass);
          m.temporaryHoveringThumb.setAttribute('style', m.temporaryHoveringThumb.originalStyle);
          delete m.temporaryHoveringThumb.originalClass;
          delete m.temporaryHoveringThumb.originalStyle;
        }
        if (a != null) {
          a.addEventListener(
            'mousedown',
            r(
              m,
              a,
              'mousedown',
              function (a) {
                if (a.button == 0) {
                  l(a);
                  a.preventDefault();
                }
              },
              true
            ),
            true
          );
          a.addEventListener(
            'touchstart',
            r(
              m,
              a,
              'touchstart',
              function (a) {
                l(a.touches[0], true);
                a.preventDefault();
              },
              true
            ),
            true
          );
        }
        if (c != null) {
          var x = function (e, d) {
            delete m.cancelDrag;
            m.draggingItem = b;
            m.dragType = 'Dependency';
            m.dragDependencyType = 'Start';
            m.style.cursor = a.style.cursor;
            m.draggingInitialX = e.clientX;
            m.draggingInitialY = e.clientY;
            m.draggingInitialRightPosition = h;
            m.draggingInitialTopPosition = f;
            m.draggingInitialFinishPosition = h;
            m.draggingInitialThumbPosition = f;
            e.preventDefault();
            if (DlhSoft.Controls.ToolTip && n.useUpdatingToolTips && !d) {
              (toolTip = DlhSoft.Controls.ToolTip.get(c)) ||
                (toolTip = DlhSoft.Controls.ToolTip.initialize(
                  void 0,
                  c,
                  {
                    duration: NaN,
                    containerStyle:
                      'cursor: default; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; border: 1px solid ' +
                      n.border +
                      '; background-color: White; color: Black; font-family: ' +
                      (n.classic ? 'Arial' : 'system-ui, Arial') +
                      '; font-size: 12px; padding: 4px; margin-top: 1px',
                  },
                  'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
                ));
              toolTip.setContent(b.content + ' \u2013');
              toolTip.show();
              toolTip.setPosition(toolTip.x + 16, toolTip.y - 8);
              toolTip.originalX = toolTip.x;
              toolTip.originalY = toolTip.y;
              m.toolTip = toolTip;
            }
          };
          c.addEventListener(
            'mousedown',
            r(
              m,
              c,
              'mousedown',
              function (a) {
                if (a.button == 0) {
                  x(a);
                  a.preventDefault();
                }
              },
              true
            ),
            true
          );
          c.addEventListener(
            'touchstart',
            r(
              m,
              c,
              'touchstart',
              function (a) {
                x(a.touches[0], true);
                a.preventDefault();
              },
              true
            ),
            true
          );
        }
        a != null &&
          a.addEventListener(
            'mouseover',
            r(
              m,
              a,
              'mouseover',
              function () {
                if (n.classic && typeof m.temporaryHoveringThumb !== d) {
                  var c = m.temporaryHoveringThumb;
                  delete m.temporaryHoveringThumb;
                  if (typeof c.originalClass === d && typeof c.originalStyle === d) return;
                  c.setAttribute('class', c.originalClass);
                  c.setAttribute('style', c.originalStyle);
                  delete c.originalClass;
                  delete c.originalStyle;
                }
                if (!(typeof m.temporaryDependencyLine !== d || a.originalStyle)) {
                  a.mouseOver = true;
                  m.temporaryHoveringThumb = a;
                  a.originalClass = a.getAttribute('class');
                  a.originalStyle = a.getAttribute('style');
                  typeof n.dependencyPointerClass !== d && a.setAttribute('class', n.dependencyPointerClass);
                  (typeof n.dependencyPointerClass !== d || typeof n.dependencyPointerStyle !== d) &&
                    a.setAttribute('style', n.dependencyPointerStyle);
                  a.style.cursor = 'pointer';
                  setTimeout(function () {
                    T(a, 'mouseout');
                  }, 4000);
                }
              },
              true
            ),
            true
          );
        c != null &&
          c.addEventListener(
            'mouseover',
            r(
              m,
              c,
              'mouseover',
              function () {
                if (n.classic && typeof m.temporaryHoveringThumb !== d) {
                  var a = m.temporaryHoveringThumb;
                  delete m.temporaryHoveringThumb;
                  if (typeof a.originalClass === d && typeof a.originalStyle === d) return;
                  a.setAttribute('class', a.originalClass);
                  a.setAttribute('style', a.originalStyle);
                  delete a.originalClass;
                  delete a.originalStyle;
                }
                if (!(typeof m.temporaryDependencyLine !== d || c.originalStyle)) {
                  c.mouseOver = true;
                  m.temporaryHoveringThumb = c;
                  c.originalClass = c.getAttribute('class');
                  c.originalStyle = c.getAttribute('style');
                  typeof n.dependencyPointerClass !== d && c.setAttribute('class', n.dependencyPointerClass);
                  (typeof n.dependencyPointerClass !== d || typeof n.dependencyPointerStyle !== d) &&
                    c.setAttribute('style', n.dependencyPointerStyle);
                  c.style.cursor = 'pointer';
                  setTimeout(function () {
                    T(c, 'mouseout');
                  }, 4000);
                }
              },
              true
            ),
            true
          );
        a != null &&
          a.addEventListener(
            'mouseout',
            r(
              m,
              a,
              'mouseout',
              function () {
                delete a.mouseOver;
                setTimeout(
                  function () {
                    if (!((typeof a.originalClass === d && typeof a.originalStyle === d) || a.mouseOver)) {
                      a.setAttribute('class', a.originalClass);
                      a.setAttribute('style', a.originalStyle);
                      delete a.originalClass;
                      delete a.originalStyle;
                    }
                  },
                  n.classic ? 250 : 0
                );
              },
              true
            ),
            true
          );
        c != null &&
          c.addEventListener(
            'mouseout',
            r(
              m,
              c,
              'mouseout',
              function () {
                delete c.mouseOver;
                setTimeout(
                  function () {
                    if (!((typeof c.originalClass === d && typeof c.originalStyle === d) || c.mouseOver)) {
                      c.setAttribute('class', c.originalClass);
                      c.setAttribute('style', c.originalStyle);
                      delete c.originalClass;
                      delete c.originalStyle;
                    }
                  },
                  n.classic ? 250 : 0
                );
              },
              true
            ),
            true
          );
        a != null &&
          a.addEventListener(
            'touchend',
            r(
              m,
              a,
              'touchend',
              function () {
                T(a, 'mouseover');
                setTimeout(function () {
                  T(a, 'mouseout');
                }, 2000);
              },
              true
            ),
            true
          );
        c != null &&
          c.addEventListener(
            'touchend',
            r(
              m,
              c,
              'touchend',
              function () {
                T(c, 'mouseover');
                setTimeout(function () {
                  T(c, 'mouseout');
                }, 2000);
              },
              true
            ),
            true
          );
        if (typeof m.draggableDependencyItems === d) m.draggableDependencyItems = [];
        for (var k = false, p = 0; p < m.draggableDependencyItems.length; p++)
          if (m.draggableDependencyItems[p] == b) {
            k = true;
            break;
          }
        if (!k) {
          var t = function (a, c) {
            if (!(typeof m.draggingItem === d || m.draggingItem != b || m.dragType != 'Dependency')) {
              var f = a.clientX - m.draggingInitialX,
                g = a.clientY - m.draggingInitialY;
              qc(a.clientX, m);
              rc(a.clientY, m);
              delete m.draggingItem;
              m.draggingInitialFinishPosition + f < 0 && (f = -m.draggingInitialFinishPosition);
              if (typeof m.temporaryInitDependencyLine !== d) {
                try {
                  e.removeChild(m.temporaryInitDependencyLine);
                } catch (l) {}
                delete m.temporaryInitDependencyLine;
              }
              if (typeof m.temporaryDependencyLine !== d) {
                try {
                  e.removeChild(m.temporaryDependencyLine);
                } catch (h) {}
                delete m.temporaryDependencyLine;
              }
              if (m.cancelDrag) {
                delete m.cancelDrag;
                delete m.draggingItem;
                m.style.cursor = 'default';
              } else {
                if (!n.classic) {
                  var q = j.createElementNS('http://www.w3.org/2000/svg', 'line');
                  q.setAttribute(
                    'x1',
                    m.draggingInitialRightPosition +
                      (m.dragDependencyType == 'Start' ? -8 : 8) * (b.isMilestone || b.hasChildren ? 2 : 1)
                  );
                  q.setAttribute('y1', m.draggingInitialTopPosition);
                  q.setAttribute('x2', m.draggingInitialRightPosition);
                  q.setAttribute('y2', m.draggingInitialTopPosition);
                  typeof n.temporaryDependencyLineClass !== d &&
                    q.setAttribute('class', n.temporaryDependencyLineClass);
                  typeof n.temporaryDependencyLineStyle !== d &&
                    q.setAttribute('style', n.temporaryDependencyLineStyle);
                  q.style.markerEnd = 'none';
                  m.temporaryInitDependencyLine = q;
                  e.appendChild(q);
                }
                q = j.createElementNS('http://www.w3.org/2000/svg', 'line');
                q.setAttribute(
                  'x1',
                  m.draggingInitialRightPosition +
                    (n.classic
                      ? 0
                      : (m.dragDependencyType == 'Start' ? -8 : 8) * (b.isMilestone || b.hasChildren ? 2 : 1))
                );
                q.setAttribute('y1', m.draggingInitialTopPosition);
                q.setAttribute('x2', m.draggingInitialFinishPosition + f);
                q.setAttribute('y2', m.draggingInitialThumbPosition + g);
                typeof n.temporaryDependencyLineClass !== d &&
                  q.setAttribute('class', n.temporaryDependencyLineClass);
                typeof n.temporaryDependencyLineStyle !== d &&
                  q.setAttribute('style', n.temporaryDependencyLineStyle);
                m.temporaryDependencyLine = q;
                e.appendChild(q);
                m.draggingItem = b;
                if (DlhSoft.Controls.ToolTip && n.useUpdatingToolTips && !c) {
                  toolTip = m.toolTip;
                  for (
                    var x = Math.floor((b.itemTop + m.draggingInitialThumbPosition + g) / n.itemHeight),
                      q = null,
                      k = 0,
                      t = 0;
                    t < o.length;
                    t++
                  ) {
                    var p = o[t];
                    if (
                      p.isVisible &&
                      !(typeof p.isHidden !== d && p.isHidden) &&
                      typeof p.displayRowIndex === d
                    ) {
                      if (k == x) {
                        q = p;
                        break;
                      }
                      k++;
                    }
                  }
                  x = 'Start';
                  if (q != null) {
                    k = m.draggingInitialFinishPosition + f;
                    t = F(q.start, n);
                    p = F(q.finish, n);
                    if (
                      q.isMilestone ||
                      (q.hasChildren && (typeof q.isSummaryEnabled === d || q.isSummaryEnabled))
                    ) {
                      t = t - n.itemHeight / 2;
                      p = p + n.itemHeight / 2;
                    }
                    if (p < t + (n.classic ? 4 : 12)) p = t + (n.classic ? 4 : 12);
                    if (k < t || k > p || ub(q, b) || Fa(q, b) || Fa(b, q)) q = null;
                    if (
                      q != null &&
                      (typeof n.allowCreatingToFinishDependencies === d ||
                        n.allowCreatingToFinishDependencies) &&
                      !q.isMilestone &&
                      k > t + ((p - t) * 3) / 4
                    )
                      x = 'Finish';
                  }
                  if (b.scheduleChartView == null) {
                    toolTip.setContent(
                      b.content +
                        ' \u2013' +
                        (q != null ? ' ' + q.content + (x != 'Finish' ? '' : ' \u2022') : '')
                    );
                    toolTip.setPosition(toolTip.originalX + f, toolTip.originalY + g);
                  }
                }
              }
            }
          };
          m.addEventListener(
            'mousemove',
            r(
              m,
              m,
              'mousemove',
              function (a) {
                t(a);
              },
              true
            ),
            true
          );
          m.addEventListener(
            'touchmove',
            r(
              m,
              m,
              'touchmove',
              function (a) {
                t(a.touches[0], true);
              },
              true
            ),
            true
          );
          var s = function (a) {
            if (!(typeof m.draggingItem === d || m.draggingItem != b || m.dragType != 'Dependency')) {
              if (typeof m.temporaryInitDependencyLine !== d) {
                try {
                  e.removeChild(m.temporaryInitDependencyLine);
                } catch (c) {}
                delete m.temporaryInitDependencyLine;
              }
              if (typeof m.temporaryDependencyLine !== d) {
                try {
                  e.removeChild(m.temporaryDependencyLine);
                } catch (f) {}
                delete m.temporaryDependencyLine;
              }
              for (
                var g = Math.floor(
                    (b.itemTop + m.draggingInitialThumbPosition + (a.clientY - m.draggingInitialY)) /
                      n.itemHeight
                  ),
                  l = null,
                  h = 0,
                  q = 0;
                q < o.length;
                q++
              ) {
                var j = o[q];
                if (
                  j.isVisible &&
                  !(typeof j.isHidden !== d && j.isHidden) &&
                  typeof j.displayRowIndex === d
                ) {
                  if (h == g) {
                    l = j;
                    break;
                  }
                  h++;
                }
              }
              if (l != null) {
                a = m.draggingInitialFinishPosition + (a.clientX - m.draggingInitialX);
                if (l.scheduleChartView)
                  for (q = 0; q < l.ganttChartItems.length; q++) {
                    j = l.ganttChartItems[q];
                    g = m.getChartPosition(j.start);
                    h = m.getChartPosition(j.finish);
                    if (j.isMilestone) {
                      g = g - n.itemHeight / 2;
                      h = h + n.itemHeight / 2;
                    }
                    if (a >= g && a <= h) {
                      l = j;
                      break;
                    }
                  }
                g = F(l.start, n);
                h = F(l.finish, n);
                if (
                  l.isMilestone ||
                  (l.hasChildren && (typeof l.isSummaryEnabled === d || l.isSummaryEnabled))
                ) {
                  g = g - n.itemHeight / 2;
                  h = h + n.itemHeight / 2;
                }
                if (h < g + (n.classic ? 4 : 12)) h = g + (n.classic ? 4 : 12);
                if (a >= g && a <= h) {
                  q =
                    l == null ||
                    (typeof n.allowCreatingToFinishDependencies !== d &&
                      !n.allowCreatingToFinishDependencies) ||
                    l.isMilestone ||
                    a <= g + ((h - g) * 3) / 4
                      ? 'Start'
                      : 'Finish';
                  if (
                    !ub(l, b) &&
                    !Fa(l, b) &&
                    !Fa(b, l) &&
                    (!n.alwaysHandleInvalidDependencies || !Ja(b, l))
                  ) {
                    delete b.successors;
                    if (typeof l.predecessors === d) l.predecessors = [];
                    j = { item: b };
                    if (m.dragDependencyType != 'Start') {
                      if (q == 'Finish') j.dependencyType = 'FF';
                    } else j.dependencyType = q != 'Finish' ? 'SS' : 'SF';
                    l.predecessors.push(j);
                    w(l, 'predecessors', true, true);
                    if (
                      l.start < b.finish &&
                      !n.areTaskDependencyConstraintsEnabled &&
                      !n.areTaskDependencyConstraintsDisabledWhenDropping
                    ) {
                      var t, k;
                      if (
                        !l.isMilestone &&
                        (!l.hasChildren || !(typeof l.isSummaryEnabled === d || l.isSummaryEnabled))
                      ) {
                        t = M(l.start, l.finish, n, C(l));
                        k = M(l.start, l.completedFinish, n, C(l));
                      }
                      if (
                        (!l.hasChildren || !(typeof l.isSummaryEnabled === d || l.isSummaryEnabled)) &&
                        q == 'Start'
                      ) {
                        l.start = R(
                          va(m.dragDependencyType != 'Start' ? b.finish : b.start, n),
                          n,
                          true,
                          l.isMilestone,
                          C(b)
                        );
                        w(l, 'start', false, true);
                        if (l.isMilestone) {
                          l.finish = l.start;
                          w(l, 'finish', false, true);
                          l.completedFinish = l.start;
                        } else {
                          l.finish = S(l.start, t, n, C(l));
                          w(l, 'finish', false, true);
                          l.completedFinish = S(l.start, k, n, C(l));
                        }
                        w(l, 'completedFinish', false, true);
                      }
                    }
                    ab(l, o, m.chartContent, n);
                    ab(b, o, m.chartContent, n);
                  } else if (n.invalidPredecessorDetectionHandler) {
                    j = { item: b };
                    if (m.dragDependencyType != 'Start') {
                      if (q == 'Finish') j.dependencyType = 'FF';
                    } else j.dependencyType = q != 'Finish' ? 'SS' : 'SF';
                    n.invalidPredecessorDetectionHandler(j, l, b);
                  }
                }
              }
              delete m.draggingItem;
              m.style.cursor = 'default';
            }
          };
          j.addEventListener(
            'mouseup',
            r(
              m,
              j,
              'mouseup',
              function (a) {
                a.button == 0 && s(a);
              },
              true
            ),
            true
          );
          j.addEventListener(
            'touchend',
            r(
              m,
              j,
              'touchend',
              function (a) {
                s(a.changedTouches[0]);
              },
              true
            ),
            true
          );
          m.draggableDependencyItems.push(b);
        }
      },
      qc = function (a, c) {
        var e = c.settings;
        if (typeof c.draggingItem !== d && e.enableHorizontalScrollingDuringDragging) {
          var b = new Date();
          if (!c.lastXDraggingTime || !(b > c.lastXDraggingTime && b - c.lastXDraggingTime < 50)) {
            c.lastXDraggingTime = b;
            var b = c,
              f = 0;
            if (b.offsetParent) {
              do {
                f = f + b.offsetLeft;
                b = b.offsetParent;
              } while (b);
            }
            a = a - f;
            if (a < c.gridContentContainer.offsetWidth + e.horizontalScrollingDragAreaWidth) {
              b = c.chartContentContainer.scrollLeft;
              c.chartContentContainer.scrollLeft =
                c.chartContentContainer.scrollLeft - e.horizontalScrollingDragAmount;
              e = b - c.chartContentContainer.scrollLeft;
              if (typeof c.draggingInitialStartPosition !== d)
                c.draggingInitialStartPosition = c.draggingInitialStartPosition - e;
              if (typeof c.draggingInitialFinishPosition !== d)
                c.draggingInitialFinishPosition = c.draggingInitialFinishPosition - e;
              if (typeof c.draggingInitialCompletedFinishPosition !== d)
                c.draggingInitialCompletedFinishPosition = c.draggingInitialCompletedFinishPosition - e;
            } else if (
              a >=
              c.gridContentContainer.offsetWidth +
                c.chartContentContainer.clientWidth -
                e.horizontalScrollingDragAreaWidth
            ) {
              b = c.chartContentContainer.scrollLeft;
              c.chartContentContainer.scrollLeft =
                c.chartContentContainer.scrollLeft + e.horizontalScrollingDragAmount;
              e = c.chartContentContainer.scrollLeft - b;
              if (typeof c.draggingInitialStartPosition !== d)
                c.draggingInitialStartPosition = c.draggingInitialStartPosition + e;
              if (typeof c.draggingInitialFinishPosition !== d)
                c.draggingInitialFinishPosition = c.draggingInitialFinishPosition + e;
              if (typeof c.draggingInitialCompletedFinishPosition !== d)
                c.draggingInitialCompletedFinishPosition = c.draggingInitialCompletedFinishPosition + e;
            }
          }
        }
      },
      fd = function (a) {
        var c = 0;
        if (a.offsetParent) {
          do {
            c = c + a.offsetTop;
            a = a.offsetParent;
          } while (a);
        }
        return c;
      },
      rc = function (a, c) {
        var e = c.settings;
        if (typeof c.draggingItem !== d && e.enableVerticalScrollingDuringDragging) {
          var b = new Date();
          if (!c.lastYDraggingTime || !(b > c.lastYDraggingTime && b - c.lastYDraggingTime < 50)) {
            c.lastYDraggingTime = b;
            var a = a - fd(c),
              f,
              g;
            if (
              a >= c.chartHeaderContainer.clientHeight &&
              a < c.chartHeaderContainer.clientHeight + e.verticalScrollingDragAreaHeight
            ) {
              f = c.chartContentContainer.scrollTop;
              c.chartContentContainer.scrollTop =
                c.chartContentContainer.scrollTop - e.verticalScrollingDragAmount;
              if (typeof c.isDuringVerticalScrolling === d) {
                c.isDuringVerticalScrolling = true;
                setTimeout(function () {
                  g = f - c.chartContentContainer.scrollTop;
                  c.draggingInitialThumbPosition = c.draggingInitialThumbPosition - g;
                  delete c.isDuringVerticalScrolling;
                }, 0);
              }
            } else if (
              a >=
              c.chartHeaderContainer.clientHeight +
                c.chartContentContainer.clientHeight -
                e.verticalScrollingDragAreaHeight
            ) {
              f = c.chartContentContainer.scrollTop;
              c.chartContentContainer.scrollTop =
                c.chartContentContainer.scrollTop + e.verticalScrollingDragAmount;
              if (typeof c.isDuringVerticalScrolling === d) {
                c.isDuringVerticalScrolling = true;
                setTimeout(function () {
                  g = c.chartContentContainer.scrollTop - f;
                  c.draggingInitialThumbPosition = c.draggingInitialThumbPosition + g;
                  delete c.isDuringVerticalScrolling;
                }, 0);
              }
            }
          }
        }
      },
      ab = function (a, c, e, b) {
        ia(a.chartPredecessorItems, a, b);
        ja(a.chartItem, a, b);
        wa(c, a);
        setTimeout(function () {
          if (typeof a.gridItem !== d) {
            if (typeof a.completedInput !== d) {
              var f = a.completedInput;
              typeof f.changeEventListener !== d &&
                f.removeEventListener('change', f.changeEventListener, true);
              delete a.completedInput;
            }
            Ha(a.gridItem, a, c, b.columns, e, b.toggleButtonAreaWidth, b);
          }
          for (f = a.parent; f != null; ) {
            gb(f, false);
            a = f;
            ia(a.chartPredecessorItems, a, b);
            ja(a.chartItem, a, b);
            wa(c, a);
            typeof a.gridItem !== d && Ha(a.gridItem, a, c, b.columns, e, b.toggleButtonAreaWidth, b);
            f = a.parent;
          }
        }, 0);
      },
      sc = function (a) {
        var c = a.itemTop - a.ganttChartView.chartContentContainer.scrollTop;
        if (c < 0) a.ganttChartView.chartContentContainer.scrollTop = a.itemTop;
        else if (
          c >
          a.ganttChartView.chartContentContainer.clientHeight - a.ganttChartView.settings.itemHeight
        )
          a.ganttChartView.chartContentContainer.scrollTop =
            a.itemTop -
            (a.ganttChartView.chartContentContainer.clientHeight - a.ganttChartView.settings.itemHeight);
      },
      Qc = function (a, c, e, b, f, g, h, o, m, n, l, j, k, p) {
        var s, t;
        c.addEventListener(
          'scroll',
          r(
            a,
            c,
            'scroll',
            function () {
              if (h.scrollTop != c.scrollTop && !t) {
                s = true;
                setTimeout(function () {
                  h.scrollTop = c.scrollTop;
                  setTimeout(function () {
                    s = false;
                  }, 100);
                }, 200);
              }
              if (b.scrollLeft != c.scrollLeft) b.scrollLeft = c.scrollLeft;
            },
            true
          ),
          true
        );
        lb(c, e, f, p);
        typeof a.updateGridHeaderTimer !== d && clearInterval(a.updateGridHeaderTimer);
        a.updateGridHeaderTimer = setInterval(function () {
          try {
            lb(c, e, f, p);
          } catch (b) {
            try {
              clearInterval(a.updateGridHeaderTimer);
            } catch (d) {}
          }
        }, 100);
        h.addEventListener(
          'scroll',
          r(
            a,
            h,
            'scroll',
            function () {
              if (c.scrollTop != h.scrollTop && !s) {
                t = true;
                setTimeout(function () {
                  c.scrollTop = h.scrollTop;
                  setTimeout(function () {
                    t = false;
                  }, 100);
                }, 200);
              }
              if (o.scrollLeft != h.scrollLeft) o.scrollLeft = h.scrollLeft;
              Ba(k, h, p);
              Va(k, l, p);
              p.displayedTime = Na(h.scrollLeft, p);
              (typeof a.isDuringScrollToDateTime === d || !a.isDuringScrollToDateTime) &&
                typeof p.displayedTimeChangeHandler !== d &&
                setTimeout(function () {
                  p.displayedTimeChangeHandler(p.displayedTime);
                }, 0);
              typeof a.isDuringScrollToDateTime !== d && delete a.isDuringScrollToDateTime;
            },
            true
          ),
          true
        );
        setTimeout(function () {
          if (o.scrollLeft != h.scrollLeft) o.scrollLeft = h.scrollLeft;
          Ba(k, h, p);
        }, 1);
        lb(h, m, n, p);
        typeof a.updateChartHeaderTimer !== d && clearInterval(a.updateChartHeaderTimer);
        a.updateChartHeaderTimer = setInterval(function () {
          try {
            lb(h, m, n, p);
          } catch (c) {
            try {
              clearInterval(a.updateChartHeaderTimer);
            } catch (e) {}
          }
        }, 100);
        window.addEventListener(
          'mousewheel',
          r(
            a,
            window,
            'mousewheel',
            function () {
              try {
                a.cancelDrag = true;
              } catch (c) {}
            },
            true
          ),
          true
        );
      },
      lb = function (a, c, e, b) {
        setTimeout(function () {
          try {
            var d = c.clientWidth - a.clientWidth;
            d < 0 && (d = 0);
            var g = d + 'px';
            if (e.style.width != g) {
              e.style.width = g;
              if (d > 0) {
                e.style.boxSizing = 'border-box';
                e.style.MozBoxSizing = 'border-box';
                e.style.border = 'solid 1px ' + b.border;
                e.style.borderTop = 'none';
                e.style.borderRight = 'none';
              } else {
                e.style.border = '';
                e.style.borderTop = '';
                e.style.borderRight = '';
                e.style.boxSizing = '';
                e.style.MozBoxSizing = '';
              }
            }
          } catch (h) {}
        }, 0);
      },
      La = function (a, c, e) {
        if (e.isSplitterEnabled) {
          a.style.height = c.clientHeight + 'px';
          a.style.left = Math.max(0, c.offsetWidth - 1) + 'px';
        }
      },
      Ba = function (a, c, e) {
        if (!(typeof c.isInitializing !== d && c.isInitializing) && e.isVirtualizing)
          for (
            var b = c.scrollTop - e.itemHeight, c = b + c.clientHeight + 2 * e.itemHeight, f = 0;
            f < a.length;
            f++
          ) {
            var g = a[f];
            if (!g.isPart)
              if (g.scheduleChartItem && g.scheduleChartItem != g) {
                if (
                  g.itemTop != g.scheduleChartItem.itemTop ||
                  g.isVirtuallyVisible != g.scheduleChartItem.isVirtuallyVisible
                ) {
                  g.itemTop = g.scheduleChartItem.itemTop;
                  g.isVirtuallyVisible = g.scheduleChartItem.isVirtuallyVisible;
                  if (g.isVirtuallyVisible) {
                    ia(g.chartPredecessorItems, g, e);
                    ja(g.chartItem, g, e);
                    g.chartItem.setAttribute('transform', 'translate(0, ' + g.itemTop + ')');
                    g.chartPredecessorItems.setAttribute('transform', 'translate(0, ' + g.itemTop + ')');
                    Ia(g, g.itemTop);
                  }
                }
              } else if (
                typeof g.isVirtuallyVisible === d &&
                (typeof g.isVisible === d || g.isVisible) &&
                !(typeof g.isHidden !== d && g.isHidden) &&
                typeof g.itemTop !== d &&
                g.itemTop >= b &&
                g.itemTop < c
              ) {
                g.isVirtuallyVisible = true;
                w(g, 'isVirtuallyVisible', false, true);
                typeof g.gridItem !== d &&
                  Ha(g.gridItem, g, a, e.columns, g.ganttChartView.chartContent, e.toggleButtonAreaWidth, e);
                ia(g.chartPredecessorItems, g, e);
                ja(g.chartItem, g, e);
                g.chartItem.setAttribute('transform', 'translate(0, ' + g.itemTop + ')');
                g.chartPredecessorItems.setAttribute('transform', 'translate(0, ' + g.itemTop + ')');
                Ia(g, g.itemTop);
              } else
                typeof g.isVirtuallyVisible !== d &&
                  (typeof g.isVisible === d || g.isVisible) &&
                  !(typeof g.isHidden !== d && g.isHidden) &&
                  typeof g.itemTop !== d &&
                  (g.itemTop < b || g.itemTop >= c) &&
                  delete g.isVirtuallyVisible;
          }
      },
      Mc = function (a, c) {
        for (var e = a.childNodes.length; e-- > 0; ) a.removeChild(a.childNodes[e]);
        a.appendChild(c);
      },
      Fb = function (a, c, e, b) {
        var f = b.indexOf(a);
        if (!(f < 0 || c < 0 || c == f || c >= b.length)) {
          b.splice(f, 1);
          b.splice(c, 0, a);
          tc(b);
          K(e);
          typeof e.settings.itemMoveHandler !== d && e.settings.itemMoveHandler(a, f, c);
        }
      },
      mb = function (a, c, e, b, f) {
        if (!(a < 0 || e < 0 || e == a || e > f.length - c)) {
          var g = [],
            h;
          for (h = a; h < a + c; h++) g.push(f[h]);
          f.splice(a, c);
          for (h = 0; h < g.length; h++) f.splice(e + h, 0, g[h]);
          tc(f);
          K(b);
          if (typeof b.settings.itemMoveHandler !== d)
            for (h = 0; h < g.length; h++) b.settings.itemMoveHandler(g[h], a + h, e + h);
        }
      },
      tc = function (a) {
        for (var c = 0, e = 0; e < a.length; e++) {
          var b = a[e];
          if (b.indentation > c) {
            b.indentation = c;
            w(b, 'indentation', false, true);
          }
          c = b.indentation + 1;
        }
      },
      gd = function (a, c, e, b, f, g, h, o, m, n) {
        function l(e) {
          delete b.cancelDrag;
          b.draggingItem = c;
          b.dragType = 'Ordering';
          b.style.cursor = a.style.cursor;
          b.draggingInitialY = e.clientY;
          b.draggingInitialThumbPosition = 0;
          b.resetChartAreaDefinitions();
        }
        a.addEventListener(
          'mousedown',
          r(
            b,
            a,
            'mousedown',
            function (a) {
              if (a.button == 0) {
                l(a);
                a.preventDefault();
              }
            },
            true
          ),
          true
        );
        a.addEventListener(
          'touchstart',
          r(
            b,
            a,
            'touchstart',
            function (a) {
              l(a.touches[0]);
              a.preventDefault();
            },
            true
          ),
          true
        );
        if (typeof b.draggableOrderingItems === d) b.draggableOrderingItems = [];
        for (var j = false, k = 0; k < b.draggableOrderingItems.length; k++)
          if (b.draggableOrderingItems[k] == c) {
            j = true;
            break;
          }
        if (!j) {
          var p = function (a) {
            if (!(typeof b.draggingItem === d || b.draggingItem != c || b.dragType != 'Ordering')) {
              if (typeof b.temporaryHoveredGridItemSelectionContainer !== d) {
                b.temporaryHoveredGridItemSelectionContainer.setAttribute(
                  'class',
                  b.temporaryHoveredGridItemClass
                );
                b.temporaryHoveredGridItemSelectionContainer.setAttribute(
                  'style',
                  b.temporaryHoveredGridItemStyle
                );
                delete b.temporaryHoveredGridItemSelectionContainer;
                delete b.temporaryHoveredGridItemClass;
                delete b.temporaryHoveredGridItemStyle;
              }
              var l = a.clientY - b.draggingInitialY;
              rc(a.clientY, b);
              delete b.draggingItem;
              if (b.cancelDrag) {
                D(c, f);
                delete b.cancelDrag;
                delete b.draggingItem;
                b.style.cursor = 'default';
              } else {
                for (
                  var a = Math.floor((c.itemTop + b.draggingInitialThumbPosition + l) / f.itemHeight),
                    l = null,
                    j = 0,
                    k = 0;
                  k < e.length;
                  k++
                ) {
                  var x = e[k];
                  if (
                    x.isVisible &&
                    !(typeof x.isHidden !== d && x.isHidden) &&
                    typeof x.displayRowIndex === d
                  ) {
                    if (j == a) {
                      l = x;
                      break;
                    }
                    j++;
                  }
                }
                if (l != null && l != c && l.gridItemSelectionContainer != null) {
                  b.temporaryHoveredGridItemSelectionContainer = l.gridItemSelectionContainer;
                  b.temporaryHoveredGridItemClass = l.gridItemSelectionContainer.getAttribute('class');
                  b.temporaryHoveredGridItemStyle = l.gridItemSelectionContainer.getAttribute('style');
                  typeof g === d || g(a, l)
                    ? typeof h !== d
                      ? l.gridItemSelectionContainer.setAttribute('class', h)
                      : typeof o !== d && l.gridItemSelectionContainer.setAttribute('style', o)
                    : typeof m !== d
                    ? l.gridItemSelectionContainer.setAttribute('class', m)
                    : typeof n !== d && l.gridItemSelectionContainer.setAttribute('style', n);
                }
                b.draggingItem = c;
              }
            }
          };
          b.addEventListener(
            'mousemove',
            r(
              b,
              b,
              'mousemove',
              function (a) {
                p(a);
              },
              true
            ),
            true
          );
          b.addEventListener(
            'touchmove',
            r(
              b,
              b,
              'touchmove',
              function (a) {
                p(a.touches[0]);
              },
              true
            ),
            true
          );
          var s = function (a) {
            if (!(typeof b.draggingItem === d || b.draggingItem != c || b.dragType != 'Ordering')) {
              if (typeof b.temporaryHoveredGridItemSelectionContainer !== d) {
                b.temporaryHoveredGridItemSelectionContainer.setAttribute(
                  'class',
                  b.temporaryHoveredGridItemClass
                );
                b.temporaryHoveredGridItemSelectionContainer.setAttribute(
                  'style',
                  b.temporaryHoveredGridItemStyle
                );
                delete b.temporaryHoveredGridItemSelectionContainer;
                delete b.temporaryHoveredGridItemClass;
                delete b.temporaryHoveredGridItemStyle;
              }
              for (
                var a = Math.floor(
                    (c.itemTop + b.draggingInitialThumbPosition + (a.clientY - b.draggingInitialY)) /
                      f.itemHeight
                  ),
                  o = null,
                  m = 0,
                  n = 0;
                n < e.length;
                n++
              ) {
                var l = e[n];
                if (
                  l.isVisible &&
                  !(typeof l.isHidden !== d && l.isHidden) &&
                  typeof l.displayRowIndex === d
                ) {
                  if (m == a) {
                    o = l;
                    break;
                  }
                  m++;
                }
              }
              o != null && (typeof g === d || g(a, o)) && b.moveItemHierarchy(b.draggingItem, o.index);
              setTimeout(function () {
                D(c, f);
                sc(c);
              }, 0);
              b.resetChartAreaDefinitions();
              delete b.draggingItem;
              b.style.cursor = 'default';
            }
          };
          document.addEventListener(
            'mouseup',
            r(
              b,
              document,
              'mouseup',
              function (a) {
                a.button == 0 && s(a);
              },
              true
            ),
            true
          );
          document.addEventListener(
            'touchend',
            r(
              b,
              document,
              'touchend',
              function (a) {
                s(a.changedTouches[0]);
              },
              true
            ),
            true
          );
          b.draggableOrderingItems.push(c);
        }
      },
      hd = function (a, c) {
        for (var e in c)
          (e.indexOf('custom') != 0 && e.indexOf('description') != 0) || (typeof a[e] === d && (a[e] = c[e]));
      },
      uc = function (a, c, e, b, f, g, h, o, m, n, l, j, k, p, s, t) {
        var u,
          v = [],
          y;
        if (typeof b !== d)
          for (u = 0; u < b.length; u++) {
            y = t.columns[b[u]];
            v.push({
              isTreeView: y.isTreeView,
              header: y.header,
              width: y.width,
              headerClass: y.headerClass,
              headerStyle: y.headerStyle,
              cellClass: y.cellClass,
              cellStyle: y.cellStyle,
              cellTemplate: typeof y.exportCellTemplate !== d ? y.exportCellTemplate : y.cellTemplate,
            });
          }
        else
          for (u = 0; u < t.columns.length; u++) {
            y = t.columns[u];
            y.isSelection ||
              v.push({
                isTreeView: y.isTreeView,
                header: y.header,
                width: y.width,
                headerClass: y.headerClass,
                headerStyle: y.headerStyle,
                cellClass: y.cellClass,
                cellStyle: y.cellStyle,
                cellTemplate: typeof y.exportCellTemplate !== d ? y.exportCellTemplate : y.cellTemplate,
              });
          }
        if (typeof e === d) e = t.isGridVisible;
        u = e ? pb(v) + 1 : 0;
        if (typeof f !== d) {
          if (typeof h === d || !h) f = new Date(f.valueOf() - f.getTimezoneOffset() * 60000);
        } else f = t.timelineStart;
        if (typeof g !== d) {
          if (typeof h === d || !h) g = new Date(g.valueOf() - g.getTimezoneOffset() * 60000);
        } else g = t.timelineFinish;
        f = {
          isExport: true,
          isReadOnly: true,
          selectionMode: 'None',
          isVirtualizing: false,
          isGridVisible: e,
          isSplitterEnabled: false,
          gridWidth: u + 'px',
          columns: v,
          allowUserToResizeColumns: false,
          isGridRowClickTimeScrollingEnabled: false,
          isMouseWheelZoomEnabled: false,
          timelineStart: f,
          timelineFinish: g,
          extendTimelineToEntireWeeks: t.extendTimelineToEntireWeeks,
          hourWidth: typeof o !== d ? o : t.hourWidth,
          displayedTime: typeof f !== d ? f : t.timelineStart,
          currentTime: t.currentTime,
          isTaskToolTipVisible: false,
          isDependencyToolTipVisible: false,
          containerClass: t.containerClass,
          containerStyle: t.containerStyle,
          border: t.border,
          theme: t.theme,
          headerBackground: t.headerBackground,
          headerHeight: t.headerHeight,
          itemHeight: t.itemHeight,
          itemClass: t.itemClass,
          itemStyle: t.itemStyle,
          standardItemClass: t.standardItemClass,
          summaryItemClass: t.summaryItemClass,
          milestoneItemClass: t.milestoneItemClass,
          standardItemStyle: t.standardItemStyle,
          summaryItemStyle: t.summaryItemStyle,
          milestoneItemStyle: t.milestoneItemStyle,
          indentationLevelWidth: t.indentationLevelWidth,
          toggleButtonClass: t.toggleButtonClass,
          toggleButtonStyle: t.toggleButtonStyle,
          scales: [],
          visibleWeekStart: t.visibleWeekStart,
          visibleWeekFinish: t.visibleWeekFinish,
          workingWeekStart: t.workingWeekStart,
          workingWeekFinish: t.workingWeekFinish,
          visibleDayStart: t.visibleDayStart,
          visibleDayFinish: t.visibleDayFinish,
          specialNonworkingDays: t.specialNonworkingDays,
          barMargin: t.barMargin,
          barHeight: t.barHeight,
          barCornerRadius: t.barCornerRadius,
          completedBarMargin: t.completedBarMargin,
          completedBarHeight: t.completedBarHeight,
          completedBarCornerRadius: t.completedBarCornerRadius,
          standardBarClass: t.standardBarClass,
          summaryBarClass: t.summaryBarClass,
          milestoneBarClass: t.milestoneBarClass,
          standardBarStyle: t.standardBarStyle,
          summaryBarStyle: t.summaryBarStyle,
          milestoneBarStyle: t.milestoneBarStyle,
          standardCompletedBarClass: t.standardCompletedBarClass,
          standardCompletedBarStyle: t.standardCompletedBarStyle,
          summaryCompletedBarClass: t.summaryCompletedBarClass,
          summaryCompletedBarStyle: t.summaryCompletedBarStyle,
          dependencyLineClass: t.dependencyLineClass,
          dependencyLineStyle: t.dependencyLineStyle,
          assignmentsClass: t.assignmentsClass,
          assignmentsStyle: t.assignmentsStyle,
          areTaskAssignmentsVisible: t.areTaskAssignmentsVisible,
          isTaskCompletedEffortVisible: t.isTaskCompletedEffortVisible,
          areTaskDependenciesVisible: t.areTaskDependenciesVisible,
          areDependencyConstraintsAppliedOnStartedTasks: t.areDependencyConstraintsAppliedOnMilestones,
          areDependencyConstraintsAppliedOnMilestones: t.areDependencyConstraintsAppliedOnMilestones,
          isBaselineVisible: t.isBaselineVisible,
          areStandardTaskLabelsVisible: t.areStandardTaskLabelsVisible,
          areSummaryTaskLabelsVisible: t.areSummaryTaskLabelsVisible,
          areMilestoneTaskLabelsVisible: t.areMilestoneTaskLabelsVisible,
          areResourceImagesVisibleAsAssignments: t.areResourceImagesVisibleAsAssignments,
          resourceImageUrls: t.resourceImageUrls,
          standardLabelColor: t.standardLabelColor,
          summaryLabelColor: t.summaryLabelColor,
          milestoneLabelColor: t.milestoneLabelColor,
          standardLabelClass: t.standardLabelClass,
          standardLabelStyle: t.standardLabelStyle,
          summaryLabelClass: t.summaryLabelClass,
          summaryLabelStyle: t.summaryLabelStyle,
          milestoneLabelClass: t.milestoneLabelClass,
          milestoneLabelStyle: t.milestoneLabelStyle,
          arrowSize: t.arrowSize,
          arrowFill: t.arrowFill,
          alternativeItemClass: t.alternativeItemClass,
          alternativeChartItemClass: t.alternativeChartItemClass,
          alternativeItemStyle: t.alternativeItemStyle,
          alternativeChartItemStyle: t.alternativeChartItemStyle,
          gridLines: t.gridLines,
          horizontalGridLines: t.horizontalGridLines,
          verticalGridLines: t.verticalGridLines,
          verticalGridHeaderLines: t.verticalGridHeaderLines,
          horizontalChartLines: t.horizontalChartLines,
          isIndividualItemNonworkingTimeHighlighted: t.isIndividualItemNonworkingTimeHighlighted,
          areTaskInterruptionsHighlighted: t.areTaskInterruptionsHighlighted,
          isBaselineVisible: t.isBaselineVisible,
          taskInitiationCost: t.taskInitiationCost,
          defaultResourceUsageCost: t.defaultResourceUsageCost,
          specificResourceUsageCosts: t.specificResourceUsageCosts,
          defaultResourceHourCost: t.defaultResourceHourCost,
          specificResourceHourCosts: t.specificResourceHourCosts,
          target: t.target,
          months: t.months,
          daysOfWeek: t.daysOfWeek,
          weekStartDay: t.weekStartDay,
          dateFormatter: t.dateFormatter,
          dateTimeFormatter: t.dateTimeFormatter,
          isRelativeToTimezone: t.isRelativeToTimezone,
          standardTaskTemplate: t.standardTaskTemplate,
          summaryTaskTemplate: t.summaryTaskTemplate,
          milestoneTaskTemplate: t.milestoneTaskTemplate,
          assignmentsTemplate: t.assignmentsTemplate,
          dependencyLineTemplate: t.dependencyLineTemplate,
          styleDefinitionTemplate: t.styleDefinitionTemplate,
          classic: t.classic,
        };
        if (f.extendTimelineToEntireWeeks) {
          f.timelineStart = Ca(f.timelineStart, f.weekStartDay);
          f.timelineFinish = rb(f.timelineFinish, f.weekStartDay);
        }
        g = F(f.timelineFinish, f);
        f.chartWidth = g + 'px';
        var w = u + g + 2 + (e ? 1 : 0);
        for (u = 0; u < t.scales.length; u++) {
          e = t.scales[u];
          f.scales.push({
            scaleType: e.scaleType,
            isHeaderVisible: e.isHeaderVisible,
            headerHeight: e.headerHeight,
            headerTextFormat: e.headerTextFormat,
            headerClass: e.headerClass,
            headerStyle: e.headerStyle,
            isHighlightingVisible: e.isHighlightingVisible,
            highlightingClass: e.highlightingClass,
            highlightingStyle: e.highlightingStyle,
            isSeparatorVisible: e.isSeparatorVisible,
            separatorClass: e.separatorClass,
            separatorStyle: e.separatorStyle,
            intervals: e.intervals,
          });
        }
        var r,
          Q,
          D = false;
        if (l != null && typeof l.createElement !== d) r = l;
        else {
          if (l != null && typeof l.focus !== d) Q = l;
          else {
            Q = window.open(
              '',
              l != null ? l : '_blank',
              typeof j !== d && j && (typeof p === d || p)
                ? 'width=800,height=480,location=no,menubar=no,toolbar=no,status=no,scrollbars=yes'
                : ''
            );
            D = true;
          }
          r = Q.document;
          try {
            var z = document.head.getElementsByTagName('link');
            for (u = 0; u < z.length; u++) {
              var B = z[u],
                A = r.adoptNode(B.cloneNode(true));
              A.href = B.href;
              r.head.appendChild(A);
            }
          } catch (L) {}
        }
        r.title = typeof a !== d ? a : 'Exported chart' + (typeof j !== d && j ? ' (printable)' : '');
        typeof m === d && (m = 0);
        typeof n === d && (n = s.length - 1);
        a = [];
        for (u = z = 0; u < s.length; u++) {
          l = s[u];
          if (
            !(
              (typeof l.displayRowIndex !== d && (l.displayRowIndex < m || l.displayRowIndex > n)) ||
              (typeof l.displayRowIndex === d && (z++ < m || z > n + 1))
            )
          ) {
            t = {
              content: l.content,
              label: l.label,
              indentation: l.indentation,
              start: l.start,
              finish: l.finish,
              completedFinish: l.completedFinish,
              isMilestone: l.isMilestone,
              schedule: C(l),
              assignmentsContent: l.assignmentsContent,
              baselineStart: l.baselineStart,
              baselineFinish: l.baselineFinish,
              isBarVisible: l.isBarVisible,
              isRelativeToTimezone: l.isRelativeToTimezone,
              class: l['class'],
              style: l.style,
              barClass: l.barClass,
              standardBarClass: l.standardBarClass,
              standardCompletedBarClass: l.standardCompletedBarClass,
              summaryBarClass: l.summaryBarClass,
              milestoneBarClass: l.milestoneBarClass,
              baselineBarClass: l.baselineBarClass,
              barStyle: l.barStyle,
              standardBarStyle: l.standardBarStyle,
              standardCompletedBarStyle: l.standardCompletedBarStyle,
              summaryBarStyle: l.summaryBarStyle,
              milestoneBarStyle: l.milestoneBarStyle,
              baselineBarStyle: l.baselineBarStyle,
              labelColor: l.labelColor,
              labelClass: l.labelClass,
              labelStyle: l.labelStyle,
              isSummaryEnabled: l.isSummaryEnabled,
              isParentSummarizationEnabled: l.isParentSummarizationEnabled,
              isHidden: l.isHidden,
              isExported: true,
              tag: l,
            };
            if (typeof l.displayRowIndex !== d) t.displayRowIndex = l.displayRowIndex - m;
            hd(t, l);
            a.push(t);
            l.exportItem = t;
          }
        }
        for (u = 0; u < s.length; u++) {
          l = s[u];
          if (!(typeof l.displayRowIndex !== d && (l.displayRowIndex < m || l.displayRowIndex > n)))
            if ((t = l.exportItem) && typeof l.predecessors !== d) {
              t.predecessors = [];
              for (z = 0; z < l.predecessors.length; z++) {
                B = l.predecessors[z];
                (typeof B.item.displayRowIndex !== d &&
                  (B.item.displayRowIndex < m || B.item.displayRowIndex > n)) ||
                  t.predecessors.push({
                    item: B.item.exportItem,
                    dependencyType: B.dependencyType,
                    lag: B.lag,
                    dependencyLineClass: B.dependencyLineClass,
                    dependencyLineStyle: B.dependencyLineStyle,
                  });
              }
            }
        }
        var ta = r.createElement('p');
        ta.innerHTML = typeof c !== d ? c : '';
        r.body.appendChild(ta);
        var N = r.createElement('div');
        N.setAttribute('style', 'width: ' + w + 'px');
        try {
          DlhSoft.Controls.GanttChartView.initialize(
            N,
            a,
            f,
            'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
          );
        } catch (M) {}
        setTimeout(function () {
          D && r.body.setAttribute('style', 'margin: 0px');
          var a = r.createElement('div');
          a.appendChild(N);
          r.body.replaceChild(a, ta);
          if (k) {
            a.setAttribute('style', 'width: ' + N.offsetHeight + 'px; height: ' + w + 'px; overflow: hidden');
            a = Math.round((N.offsetWidth - N.offsetHeight) / 2);
            N.setAttribute(
              'style',
              'width: ' +
                w +
                'px; transform: rotate(90deg) translateX(' +
                a +
                'px) translateY(' +
                a +
                'px); -webkit-transform: rotate(90deg) translateX(' +
                a +
                'px) translateY(' +
                a +
                'px)'
            );
          }
          r.close();
          if (typeof Q !== void 0) {
            Q.focus();
            typeof j !== d &&
              j &&
              setTimeout(function () {
                Q.print();
                (typeof p === d || p) &&
                  setTimeout(function () {
                    Q.close();
                  });
              });
          }
        }, 0);
      },
      vc = function (a) {
        var c = qa;
        if (typeof a === d) return c;
        for (var e = 0; e < a.length; e++) {
          var b = a[e];
          if ((!b.hasChildren || !(typeof b.isSummaryEnabled === d || b.isSummaryEnabled)) && b.start < c)
            c = b.start;
        }
        return new Date(c.valueOf());
      },
      Gb = function (a) {
        var c = ca;
        if (typeof a === d) return c;
        for (var e = 0; e < a.length; e++) {
          var b = a[e];
          if (!b.hasChildren || !(typeof b.isSummaryEnabled === d || b.isSummaryEnabled))
            if (typeof b.isMilestone === d || !b.isMilestone) {
              if (b.finish > c) c = b.finish;
            } else if (b.start > c) c = b.start;
        }
        return new Date(c.valueOf());
      },
      wc = function (a, c) {
        for (var e = 0, b = 0; b < a.length; b++) {
          var d = a[b];
          d.parent == null && (e = e + ka(d, c));
        }
        return e;
      },
      xc = function (a, c) {
        for (var e = 0, b = 0; b < a.length; b++) {
          var d = a[b];
          d.parent == null && (e = e + Da(d, c));
        }
        return e;
      },
      yc = function (a, c) {
        for (var e = [], b = 0; b < c.length; b++) {
          var f = c[b];
          if (!(f == a || typeof f.predecessors === d || f.predecessors.length == 0))
            for (var g = 0; g < f.predecessors.length; g++)
              if (f.predecessors[g].item == a) {
                e.push(f);
                break;
              }
        }
        return e;
      },
      nb = function (a, c) {
        for (var e = [], b = yc(a, c), f = 0; f < b.length; f++) {
          var g = b[f];
          if (!(typeof g.predecessors === d || g.predecessors.length == 0))
            for (var h = 0; h < g.predecessors.length; h++)
              g.predecessors[h].item == a && e.push({ successor: g, predecessor: g.predecessors[h] });
        }
        return e;
      },
      ob = function (a, c, e, b, f) {
        typeof c === d && (c = 0);
        f = f ? f : Gb(e);
        if (a.finish >= f) return true;
        var g;
        if (!a.hasChildren || !(typeof a.isSummaryEnabled === d || a.isSummaryEnabled)) {
          var h = a.successorPredecessors ? a.successorPredecessors : nb(a, e);
          for (g = 0; g < h.length; g++) {
            var o = h[g].successor;
            if (!b.areTaskDependencyConstraintsEnabled) {
              for (var m = a, n = true; m; ) {
                if (Fa(m, o)) {
                  n = false;
                  break;
                }
                m = m.parent;
              }
              if (!n) continue;
            }
            m = h[g].predecessor;
            if (
              (((typeof m.dependencyType === d ||
                m.dependencyType == '' ||
                m.dependencyType == 'FinishStart' ||
                m.dependencyType == 'FS') &&
                M(a.finish, o.start, b, C(a)) <= (m.lag ? m.lag : 0) + c) ||
                ((m.dependencyType == 'StartStart' || m.dependencyType == 'SS') &&
                  M(a.start, o.start, b, C(a)) <= (m.lag ? m.lag : 0) + c)) &&
              ob(o, c, e, b, f)
            )
              return true;
          }
        } else {
          a = a.children;
          for (g = 0; g < a.length; g++) if (ob(a[g], c, e, b, f)) return true;
        }
        return false;
      },
      id = function (a, c, e, b) {
        if (typeof c !== d) {
          c = a.getNetworkDiagramItems(b, void 0, void 0, c);
          a = a.ownerDocument.createElement('div');
          DlhSoft.Controls.Pert.NetworkDiagramView.initialize(
            a,
            c,
            void 0,
            'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
          );
          return a
            .getCriticalItems()
            .filter(function (a) {
              return a.tag;
            })
            .map(function (a) {
              return a.tag;
            });
        }
      },
      Hb = function (a, c, e) {
        for (var b = 0; b < a.length; b++) {
          var f = a[b];
          (!f.hasChildren || !(typeof f.isSummaryEnabled === d || f.isSummaryEnabled)) &&
            typeof f.predecessors !== d &&
            f.predecessors != null &&
            f.predecessors.length > 0 &&
            Ea(f, a, c, e);
        }
      },
      zc = function (a, c, e, b) {
        var c = new Date(c.valueOf() - c.getTimezoneOffset() * 60000),
          c = ya(c, b, C(a)),
          e = ka(a, b),
          d = Da(a, b);
        Ib(a, c);
        eb(a, e, b);
        fb(a, d, b);
        $(a);
      },
      Ac = function (a, c) {
        for (var e = 0, b = la(a), f = 0; f < b.length; f++) {
          var g = b[f],
            h = g.key,
            o;
          typeof c.specificResourceUsageCosts !== d && (o = aa(c.specificResourceUsageCosts, h)) >= 0
            ? (e = e + c.specificResourceUsageCosts[o].value * g.value)
            : typeof c.defaultResourceUsageCost !== d && (e = e + c.defaultResourceUsageCost * g.value);
          if (!a.hasChildren || !(typeof a.isSummaryEnabled === d || a.isSummaryEnabled)) {
            var m = ka(a, c) / 3600000;
            typeof c.specificResourceHourCosts !== d && (o = aa(c.specificResourceHourCosts, h)) >= 0
              ? (e = e + c.specificResourceHourCosts[o].value * m * g.value)
              : typeof c.defaultResourceHourCost !== d && (e = e + c.defaultResourceHourCost * m * g.value);
          }
        }
        return e;
      },
      Kb = function (a, c) {
        var e = typeof c.taskInitiationCost !== d ? c.taskInitiationCost : 0,
          e = e + Ac(a, c);
        if (a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled))
          for (var b = 0; b < a.children.length; b++) e = e + Jb(a.children[b], c);
        return e;
      },
      Jb = function (a, c) {
        return (typeof a.executionCost !== d ? a.executionCost : 0) + Kb(a, c);
      },
      Bc = function (a, c, e, b, f, g, h) {
        if (typeof e !== d) {
          typeof a === d && (a = false);
          typeof c === d && (c = new Date());
          typeof g === d && (g = false);
          typeof h === d && (h = false);
          Hb(e, b, f);
          var f = [],
            o = [],
            m,
            n,
            l,
            j = [];
          for (n = 0; n < e.length; n++) {
            m = e[n];
            if (!m.hasChildren || !(typeof m.isSummaryEnabled === d || m.isSummaryEnabled)) {
              if (h) {
                l = false;
                for (var k = m; k != null; ) {
                  if (typeof k.predecessors !== d && k.predecessors.length > 0) {
                    l = true;
                    break;
                  }
                  k = k.parent;
                }
                if (!l) continue;
              }
              f.push({ key: m, value: jd(m) });
              var k = !g ? la(m) : [],
                p = [];
              for (l = 0; l < k.length; l++) {
                var s = k[l];
                s.value > 0 && p.push(s);
              }
              o.push({ key: m, value: p });
              j.push(m);
            }
          }
          e = [];
          for (g = f.length; g > 0; ) {
            h = [];
            for (n = 0; n < f.length; n++) {
              m = f[n].key;
              l = true;
              k = f[n].value;
              for (p = 0; p < k.length; p++) {
                s = k[p];
                if (aa(f, s) >= 0 && h.indexOf(s) < 0) {
                  l = false;
                  break;
                }
              }
              if (l) {
                p = m;
                l = c;
                k = b;
                if (typeof p.minStart !== d && l < p.minStart) l = p.minStart;
                if (a || !Qa(p))
                  for (; p != null; ) {
                    if (typeof p.predecessors !== d)
                      for (s = 0; s < p.predecessors.length; s++) {
                        var t = p.predecessors[s];
                        if (typeof t !== d) {
                          var u = t.dependencyType;
                          typeof u === d && (u = 'FinishStart');
                          switch (u) {
                            case '':
                            case 'FinishStart':
                            case 'FS':
                              t = S(t.item.finish, typeof t.lag === d ? 0 : t.lag, k, C(t.item));
                              t > l && (l = t);
                              break;
                            case 'StartStart':
                            case 'SS':
                              t = S(t.item.start, typeof t.lag === d ? 0 : t.lag, k, C(t.item));
                              t > l && (l = t);
                          }
                        }
                      }
                    for (p = p.parent; p != null && typeof p.isSummaryEnabled !== d && !p.isSummaryEnabled; )
                      p = p.parent;
                  }
                k = l;
                l = aa(o, m);
                var p = o[l].value,
                  y;
                for (l = 0; l < p.length; l++) {
                  y = p[l].key;
                  s = p[l].value;
                  u = aa(e, y);
                  if (u >= 0) {
                    t = 1;
                    if (typeof b.resourceQuantities !== d) {
                      var v = aa(b.resourceQuantities, y);
                      if (v >= 0) t = b.resourceQuantities[v].value;
                    }
                    previousAllocations = e[u].value;
                    for (u = 0; u < previousAllocations.length; u++) {
                      y = previousAllocations[u].key;
                      if (!(y + s <= t)) {
                        v = previousAllocations[u].value;
                        v > k && (k = v);
                      }
                    }
                  }
                }
                if ((a || !Qa(m)) && (k = ya(k, b, C(m))) != m.start) {
                  l = ka(m, b);
                  s = Da(m, b);
                  Ib(m, k);
                  eb(m, l, b);
                  fb(m, s, b);
                }
                t = m.finish;
                for (l = 0; l < p.length; l++) {
                  y = p[l].key;
                  s = p[l].value;
                  u = aa(e, y);
                  if (u < 0) {
                    v = [];
                    e.push({ key: y, value: v });
                  } else v = e[u].value;
                  for (var r = [{ key: s, value: t }], D, Q, z, u = 0; u < v.length; u++) {
                    y = v[u].key;
                    var B = v[u].value;
                    if (B > k) {
                      Q = y + s;
                      z = B < t ? B : t;
                      D = aa(r, Q);
                      if (D < 0) r.push({ key: Q, value: z });
                      else if (z > r[Q]) r[D].value = z;
                      B < t ? r.push({ key: y + s, value: B }) : r.push({ key: y + s, value: t });
                    }
                  }
                  for (s = 0; s < r.length; s++) {
                    Q = r[s].key;
                    z = r[s].value;
                    D = aa(v, Q);
                    if (D < 0) v.push({ key: Q, value: z });
                    else if (z > v[D].value) v[D].value = z;
                  }
                }
                h.push(m);
              }
            }
            for (n = 0; n < h.length; n++) f.splice(aa(f, h[n]), 1);
            if (f.length == g) break;
            g = f.length;
          }
          for (n = 0; n < j.length; n++) {
            m = j[n];
            w(m, 'start', true, true);
            w(m, 'finish', true, true);
            w(m, 'completedFinish', true, true);
            $(m);
          }
        }
      },
      jd = function (a) {
        for (var c = [], a = Bb(a), e = 0; e < a.length; e++) Cc(a[e], c);
        return c;
      },
      Cc = function (a, c) {
        if (!a.hasChildren || !(typeof a.isSummaryEnabled === d || a.isSummaryEnabled))
          c.indexOf(a) < 0 && c.push(a);
        else for (var e = 0; e < a.children.length; e++) Cc(a.children[e], c);
      },
      Qa = function (a) {
        return !a.isMilestone && a.completedFinish > a.start;
      },
      ka = function (a, c) {
        if (a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled)) {
          for (var e = 0, b = a.children, f = 0; f < b.length; f++) e = e + ka(b[f], c);
          return e;
        }
        return M(a.start, a.finish, c, C(a));
      },
      Da = function (a, c) {
        if (a.hasChildren && (typeof a.isSummaryEnabled === d || a.isSummaryEnabled)) {
          for (var e = 0, b = a.children, f = 0; f < b.length; f++) e = e + Da(b[f], c);
          return e;
        }
        return M(a.start, a.completedFinish, c, C(a));
      },
      eb = function (a, c, e) {
        c = S(a.start, c, e, C(a));
        a.finish = c;
        w(a, 'finish', false, false);
        Dc(a, false);
      },
      fb = function (a, c, e) {
        a.completedFinish = S(a.start, c, e, C(a));
        w(a, 'completedFinish', false, false);
      },
      Ib = function (a, c) {
        a.start = c;
        a.preferredStart = c;
        w(a, 'start', false, false);
        Dc(a, false);
      },
      Dc = function (a, c) {
        for (var e = a.parent; e != null; ) {
          gb(e, c);
          a = e;
          e = a.parent;
        }
      },
      Ec = function (a, c, e) {
        typeof e === d && (e = false);
        var b = a.parent,
          f = a.index;
        if (b == null) {
          for (b = 0; f-- > 0; ) {
            a = c[f];
            a.indentation == 0 && b++;
          }
          return b + (!e ? 1 : 0);
        }
        return Ec(b, c) + '.' + (b.children.indexOf(a) + (!e ? 1 : 0));
      },
      la = function (a) {
        var c;
        a = a.assignmentsContent;
        if (typeof a === d) c = [];
        else {
          for (var a = a.split(','), e = [], b = 0; b < a.length; b++) {
            var f = bb(a[b]),
              g = 1,
              h = f.indexOf('['),
              o = f.lastIndexOf(']');
            if (h >= 0 && o >= 0) {
              o = bb(f.substr(h + 1, o - h - 1));
              f = bb(f.substr(0, h));
              h = o.indexOf('%');
              h >= 0 && (o = bb(o.substr(0, h)));
              try {
                c = parseFloat(o);
                g = c / 100;
              } catch (m) {
                g = 1;
              }
            }
            if (!(f.length <= 0)) {
              h = aa(e, f);
              h < 0 ? e.push({ key: f, value: g }) : (e[h].value = e[h].value + g);
            }
          }
          c = e;
        }
        return c;
      },
      Fc = function (a) {
        for (var c = [], a = la(a), e = 0; e < a.length; e++) c.push(a[e].key);
        return c;
      },
      Ra = function (a, c) {
        if (typeof c === d) return [];
        for (var e = [], b = 0; b < c.length; b++) {
          var f = c[b];
          if (!f.hasChildren || !(typeof f.isSummaryEnabled === d || f.isSummaryEnabled))
            for (var g = la(f), h = 0; h < g.length; h++) {
              var o = g[h];
              if (o.key == a) {
                e.push({ key: f, value: o.value });
                break;
              }
            }
        }
        return e;
      },
      Ta = function (a) {
        if (typeof a === d) return [];
        for (var c = [], e = 0; e < a.length; e++) {
          var b = a[e];
          if (!b.hasChildren || !(typeof b.isSummaryEnabled === d || b.isSummaryEnabled))
            for (var b = Fc(b), f = 0; f < b.length; f++) {
              var g = b[f];
              c.indexOf(g) < 0 && c.push(g);
            }
        }
        return c;
      },
      Gc = function (a, c) {
        for (var e = [], b = Ra(a, c), f = 0; f < b.length; f++) {
          var g = b[f],
            h = g.key;
          if (!h.hasChildren || !(typeof h.isSummaryEnabled === d || h.isSummaryEnabled))
            for (h = [{ start: h.start, finish: h.finish }]; h.length > 0; ) {
              var o;
              o = h[0];
              h.splice(0, 1);
              if (!(o.finish <= o.start))
                if (e.length == 0) e.push({ key: o, value: g.value });
                else {
                  vc(c);
                  for (var m = false, n = 0; n < e.length; n++) {
                    var l = e[n].key,
                      j = e[n].value;
                    if (!(o.start >= l.finish))
                      if (o.finish <= l.start) {
                        for (m = 0; m <= n; m++)
                          if (e[m].key.start >= o.start) {
                            e.splice(m, 0, { key: o, value: g.value });
                            break;
                          }
                        m = true;
                        break;
                      } else if (o.start <= l.start && o.finish >= l.finish) {
                        e[n].value = j + g.value;
                        o.start < l.start && h.push({ start: o.start, finish: l.start });
                        o.finish > l.finish && h.push({ start: l.finish, finish: o.finish });
                        m = true;
                        break;
                      } else if (o.start >= l.start && o.finish <= l.finish) {
                        e.splice(n, 1);
                        o.start > l.start &&
                          e.splice(n++, 0, { key: { start: l.start, finish: o.start }, value: j });
                        e.splice(n++, 0, { key: o, value: j + g.value });
                        o.finish < l.finish &&
                          e.splice(n++, 0, { key: { start: o.finish, finish: l.finish }, value: j });
                        m = true;
                        break;
                      } else if (o.start >= l.start && o.finish >= l.finish) {
                        e.splice(n, 1);
                        o.start > l.start &&
                          e.splice(n++, 0, { key: { start: l.start, finish: o.start }, value: j });
                        e.splice(n++, 0, { key: { start: o.start, finish: l.finish }, value: j + g.value });
                        o.finish > l.finish && h.push({ start: l.finish, finish: o.finish });
                        m = true;
                        break;
                      } else if (o.start <= l.start && o.finish <= l.finish) {
                        e.splice(n, 1);
                        o.start < l.start && h.push({ start: o.start, finish: l.start });
                        e.splice(n++, 0, { key: { start: l.start, finish: o.finish }, value: j + g.value });
                        o.finish < l.finish &&
                          e.splice(n++, 0, { key: { start: o.finish, finish: l.finish }, value: j });
                        m = true;
                        break;
                      }
                  }
                  m || e.push({ key: o, value: g.value });
                }
            }
        }
        return e;
      },
      Lb = function (a, c, e) {
        for (var b = 0; b < a.length; b++) {
          var d = a[b];
          if (d.key == c) {
            c = d.value;
            if (c.indexOf(e) >= 0) return true;
            for (b = 0; b < c.length; b++) if (Lb(a, c[b], e)) return true;
            break;
          }
        }
        return false;
      },
      Ka = function (a) {
        var c = a.content;
        return a.parent == null ? c : c + ' (' + Ka(a.parent) + ')';
      },
      cb = function (a, c) {
        return a == c ? true : a.parent == null ? false : cb(a.parent, c);
      },
      bb = function (a) {
        return a.replace(/^\s*/, '').replace(/\s*$/, '');
      },
      aa = function (a, c) {
        for (var e = 0; e < a.length; e++) if (a[e].key == c) return e;
        return -1;
      },
      Hc = function (a) {
        return typeof a !== d ? new Date(a.valueOf() + a.getTimezoneOffset() * 60000) : a;
      },
      Ic = function (a) {
        return typeof a !== d ? new Date(a.valueOf() - a.getTimezoneOffset() * 60000) : a;
      },
      Jc = function (a, c, e) {
        var b;
        for (b = 0; b < c.length; b++) c[b].ganttChartView = a;
        for (b = 0; b < c.length; b++) {
          var f = c[b];
          if (typeof f.parts !== d) {
            if (typeof f.isGroup === d) {
              f.isGroup = true;
              f.isSummaryEnabled = false;
            }
            J(f.parts, e);
            if (f.isGroup || typeof f.isBarVisible === d) f.isBarVisible = false;
            for (var g = 0; g < f.parts.length; g++) {
              var h = f.parts[g];
              h.ganttChartView = f.ganttChartView;
              h.ganttChartItem = f;
              h.isPart = true;
              h.isVirtuallyVisible = true;
              if (f.isGroup || typeof h.indentation === d) h.indentation = 0;
              if (f.isGroup || typeof h.displayRowIndex === d) h.displayRowIndex = -1;
              h.isInternallyHidden = true;
              c.indexOf(h) >= 0 || c.splice(c.length, 0, h);
            }
          }
        }
        a.items = c;
        a.settings = e;
        a.refresh = function () {
          K(a);
        };
        a.refreshItems = function () {
          Xa(c);
        };
        a.refreshGridItems = function () {
          for (var a = 0; a < c.length; a++) Wa(c[a]);
        };
        a.refreshChartItems = function () {
          for (var a = 0; a < c.length; a++) ua(c[a]);
        };
        a.refreshGridItem = Wa;
        a.refreshChartItem = ua;
        a.refreshItem = sa;
        a.refreshPredecessorItems = xa;
        a.refreshItemGraph = Pa;
        a.refreshItemPath = $;
        a.refreshItemNeighbourhood = function (b) {
          Ya(b, c, a, e);
        };
        a.refreshCurrentTimeLine = function () {
          var b = a.chartHeader,
            d = a.chartContent,
            f = d.chartArea,
            g,
            h = [];
          for (g = f.childNodes.length; g-- > 1; ) {
            var j = f.childNodes[g];
            j.tag != 'Scale-Highlighting-CurrentTime' && j.tag != 'Scale-Separator-CurrentTime' && h.push(j);
            f.removeChild(j);
          }
          qb(b, f, e.scales, e, true);
          for (g = h.length; g-- > 0; ) f.appendChild(h[g]);
          za(d, Aa(c, e));
        };
        a.setCurrentTime = function (c) {
          e.currentTime = c;
          a.refreshCurrentTimeLine();
        };
        a.updateCurrentTime = function () {
          var c = new Date(),
            c = new Date(c.valueOf() - c.getTimezoneOffset() * 60000);
          a.setCurrentTime(c);
        };
        a.getCurrentItem = function () {
          return a.currentItem;
        };
        a.getSelectedItem = function () {
          return a.selectedItem;
        };
        a.getSelectedItems = function () {
          return a.selectedItems;
        };
        a.selectItem = function (a) {
          v(a, true, e.selectionMode);
        };
        a.unselectItem = function (a) {
          v(a, false, e.selectionMode);
        };
        a.expandItem = function (a) {
          ma(a, true, true);
        };
        a.collapseItem = function (a) {
          ma(a, false, true);
        };
        a.scrollToItem = sc;
        a.scrollToBottom = function () {
          a.chartContentContainer.scrollTop = a.chartContent.clientHeight;
        };
        a.scrollToDateTime = function (c) {
          a.isDuringScrollToDateTime = true;
          a.chartContentContainer.scrollLeft = F(c, a.settings);
        };
        a.increaseTimelinePage = function (c) {
          e.timelineStart = new Date(e.timelineStart.valueOf() + c);
          e.timelineFinish = new Date(e.timelineFinish.valueOf() + c);
          K(a);
        };
        a.decreaseTimelinePage = function (c) {
          e.timelineStart = new Date(e.timelineStart.valueOf() - c);
          e.timelineFinish = new Date(e.timelineFinish.valueOf() - c);
          K(a);
        };
        a.setSplitterPosition = function (c) {
          var b = a.gridContainer,
            f = a.chartContainer,
            g = a.splitter,
            c = Math.ceil((c * 1000000) / a.offsetWidth) / 10000;
          typeof b.percent !== d && delete b.percent;
          b.style.width = c + '%';
          f.style.width = 100 - c + '%';
          La(g, b, e);
        };
        a.getChartPosition = function (a) {
          return F(a, e);
        };
        a.getChartWidth = function () {
          return F(e.timelineFinish, e);
        };
        a.getDateTime = function (a) {
          return Na(a, e);
        };
        a.getWorkingTime = function (a) {
          return pc(a, e);
        };
        a.getStartWorkingTime = function (a) {
          return ya(a, e);
        };
        a.getFinishWorkingTime = function (a) {
          return R(a, e, false, true, void 0);
        };
        a.getEffort = function (a, c) {
          return M(a, c, e);
        };
        a.getFinish = function (a, c) {
          return S(a, c, e);
        };
        a.getStart = function (a, c) {
          return Za(a, c, e);
        };
        a.getCompletion = function (a, c, b) {
          return kb(a, c, b, e);
        };
        a.getCompletedFinish = function (a, c, b) {
          return Cb(a, c, b, e);
        };
        a.getItemsHeight = function () {
          return Aa(c, e);
        };
        a.getItemTop = function (a) {
          return Ga(a, c, e);
        };
        a.onItemPropertyChanged = w;
        a.initializeTaskDraggingThumbs = function (c, e, b, d, f, g, h, j) {
          zb(c, e, b, d, f, g, h, j, a.items, a, a.settings);
        };
        a.initializeDependencyDraggingThumbs = function (c, e, b, d, f, g, h) {
          jb(c, e, b, d, f, g, h, a.items, a, a.settings);
        };
        a.insertItem = function (b, d) {
          kc(b, d, a, c, e);
        };
        a.addItem = function (e) {
          a.insertItem(c.length, e);
        };
        a.insertItems = function (c, e) {
          for (var b = 0; b < e.length; b++) a.insertItem(c++, e[b]);
        };
        a.addItems = function (c) {
          for (var e = 0; e < c.length; e++) a.addItem(c[e]);
        };
        a.removeItem = function (b) {
          lc(b, a, c, e);
        };
        a.removeItems = function (c) {
          for (var e = 0; e < c.length; e++) a.removeItem(c[e]);
        };
        a.increaseItemIndentation = function (b) {
          var f = b.index > 0 ? a.items[b.index - 1] : null;
          if (!(f == null || b.indentation > f.indentation)) {
            ma(b, true, false, true);
            b.indentation++;
            w(b, 'indentation', true, true);
            if (b.predecessors && b.predecessors.length > 0) {
              for (var g = false, h = 0; h < b.predecessors.length; h++)
                if (b.predecessors[h].item == f && b.indentation > f.indentation) {
                  b.predecessors.splice(h--, 1);
                  g = true;
                }
              g && w(b, 'predecessors', false, true);
            }
            for (g = 0; g < c.length; g++) {
              h = c[g];
              if (!(h != f || typeof h.predecessors === d || h.predecessors.length == 0))
                for (var j = 0; j < h.predecessors.length; j++)
                  if (h.predecessors[j].item == b && f.indentation > h.predecessors[j].item.indentation) {
                    h.predecessors.splice(j--, 1);
                    w(h, 'predecessors', false, true);
                    sa(h);
                    xa(h);
                  }
            }
            ib(c, e) && Xa(c);
            for (Ya(b, c, a, e); b != null; ) {
              b.isExpanded || ma(b, true, false);
              b = b.parent;
            }
          }
        };
        a.decreaseItemIndentation = function (b) {
          var f = b.index < a.items.length - 1 ? a.items[b.index + 1] : null;
          if (!(b.indentation <= 0 || (f != null && b.indentation < f.indentation))) {
            b.indentation--;
            w(b, 'indentation', true, true);
            if (f && b.predecessors && b.predecessors.length > 0) {
              for (var g = false, h = 0; h < b.predecessors.length; h++)
                if (b.predecessors[h].item == f && b.indentation < f.indentation) {
                  b.predecessors.splice(h--, 1);
                  g = true;
                }
              g && w(b, 'predecessors', false, true);
            }
            for (g = 0; g < c.length; g++) {
              h = c[g];
              if (!(h != f || typeof h.predecessors === d || h.predecessors.length == 0))
                for (var j = 0; j < h.predecessors.length; j++)
                  if (h.predecessors[j].item == b && f.indentation < h.predecessors[j].item.indentation) {
                    h.predecessors.splice(j--, 1);
                    w(h, 'predecessors', false, true);
                    sa(h);
                    xa(h);
                  }
            }
            ib(c, e) && Xa(c);
            for (Ya(b, c, a, e); b != null; ) {
              b.isExpanded || ma(b, true, false);
              b = b.parent;
            }
          }
        };
        a.setItemContent = function (a, c) {
          a.content = c;
          w(a, 'content', true, true);
        };
        a.setItemStart = function (a, c) {
          a.start = ya(c, e, C(a));
          a.preferredStart = a.start;
          w(a, 'start', true, true);
          if (a.completedFinish < a.start) {
            a.completedFinish = a.start;
            w(a, 'completedFinish', false, true);
          }
        };
        a.setItemFinish = function (a, c) {
          var b;
          b = C(a);
          b = R(c, e, false, true, b);
          a.finish = b;
          w(a, 'finish', true, true);
          if (a.completedFinish > a.finish) {
            a.completedFinish = a.finish;
            w(a, 'completedFinish', false, true);
          }
        };
        a.setItemIsMilestone = function (a, c) {
          a.isMilestone = c;
          w(a, 'isMilestone', true, true);
        };
        a.getItemEffort = function (a) {
          return ka(a, e);
        };
        a.setItemEffort = function (a, c) {
          a.finish = S(a.start, c, e, C(a));
          w(a, 'finish', true, true);
          if (a.completedFinish > a.finish) {
            a.completedFinish = a.finish;
            w(a, 'completedFinish', false, true);
          }
        };
        a.getItemTotalEffort = function (a) {
          return Db(a);
        };
        a.setItemTotalEffort = function (a, c) {
          a.ganttChartView.setItemEffort(a, c / $a(a));
        };
        a.setItemHasFixedEffort = function (a, c) {
          a.hasFixedEffort = c;
          if (!a.hasChildren && a.hasFixedEffort) {
            a.fixedEffort = ka(a, e);
            a.fixedEffortAssignments = la(a);
          }
        };
        a.getItemDuration = function (a) {
          return M(a.start, a.finish, e, C(a));
        };
        a.setItemDuration = a.setItemEffort;
        a.getItemCompletedEffort = function (a) {
          return Da(a, e);
        };
        a.setItemCompletedEffort = function (a, c) {
          a.completedFinish = S(a.start, c, e, C(a));
          if (a.completedFinish > a.finish) a.completedFinish = a.finish;
          w(a, 'completedFinish', true, true);
        };
        a.getItemTotalCompletedEffort = function (a) {
          return Eb(a);
        };
        a.setItemTotalCompletedEffort = function (a, c) {
          a.ganttChartView.setItemCompletedEffort(a, c / $a(a));
        };
        a.getItemCompletion = function (c) {
          return a.getItemCompletedEffort(c) / a.getItemEffort(c);
        };
        a.setItemCompletion = function (c, b) {
          a.setItemCompletedEffort(c, b * a.getItemEffort(c));
        };
        a.isItemCompleted = function (c) {
          return (
            a.getItemCompletion(c) >= 1 ||
            ((c.isMilestone || c.finish.valueOf() <= c.start.valueOf()) &&
              typeof c.isSetAsCompleted !== d &&
              c.isSetAsCompleted)
          );
        };
        a.setItemAsCompleted = function (c) {
          if (c.isMilestone || c.finish.valueOf() <= c.start.valueOf()) c.isSetAsCompleted = true;
          a.setItemCompletion(c, 1);
        };
        a.hasItemStarted = function (a) {
          return Qa(a);
        };
        a.setItemAsNotStarted = function (c) {
          if (c.isMilestone || c.finish.valueOf() <= c.start.valueOf()) c.isSetAsCompleted = false;
          a.setItemCompletion(c, 0);
        };
        a.isItemOnSchedule = function (a) {
          var c = new Date(),
            c = new Date(c.valueOf() - c.getTimezoneOffset() * 60000);
          return a.completedFinish >= c;
        };
        a.setItemAssignmentsContent = function (a, c) {
          a.assignmentsContent = c;
          w(a, 'assignmentsContent', true, true);
        };
        a.getItemPredecessorsString = function (a, b) {
          var e = b;
          typeof e === d && (e = false);
          var f = '';
          if (!(typeof a.predecessors === d || a.predecessors.length == 0))
            for (var g = 0; g < a.predecessors.length; g++) {
              var h = a.predecessors[g],
                j = c.indexOf(h.item);
              if (!(j < 0)) {
                f.length > 0 && (f = f + ', ');
                f = f + (j + (!e ? 1 : 0));
                if (
                  typeof h.dependencyType !== d &&
                  h.dependencyType != '' &&
                  h.dependencyType != 'FinishStart' &&
                  h.dependencyType != 'FS'
                )
                  if (h.dependencyType == 'StartStart' || h.dependencyType == 'SS') f = f + 'SS';
                  else if (h.dependencyType == 'FinishFinish' || h.dependencyType == 'FF') f = f + 'FF';
                  else if (h.dependencyType == 'StartFinish' || h.dependencyType == 'SF') f = f + 'SF';
                if (typeof h.lag !== d && h.lag != 0) {
                  h.lag > 0 && (f = f + '+');
                  f = f + h.lag / 3600000;
                }
              }
            }
          return f;
        };
        a.setItemPredecessorsString = function (a, b, e) {
          typeof e === d && (e = false);
          var f = [];
          a.predecessors = f;
          for (var b = b.split(','), g = 0; g < b.length; g++) {
            var h = bb(b[g]);
            if (!(h.length <= 0)) {
              for (var j = 0; j < h.length && h.charCodeAt(j) >= 48 && h.charCodeAt(j) <= 57; ) j++;
              var k = h.substr(0, j),
                k = parseInt(k) - (!e ? 1 : 0);
              if (!isNaN(k) && !(k < 0 || k >= c.length)) {
                k = c[k];
                if (!ub(a, k) && !Fa(a, k) && !Fa(k, a)) {
                  var h = h.substr(j),
                    j = h.indexOf('+'),
                    t = h.indexOf('-'),
                    q = j >= 0 ? j : t >= 0 ? t : -1,
                    t = 0;
                  if (q >= 0) {
                    var p = parseFloat(h.substr(q + 1));
                    isNaN(p) || (t = p * (j >= 0 ? 1 : -1) * 3600000);
                  }
                  h = (q < 0 ? h : h.substr(0, q)).toUpperCase();
                  f.push({ item: k, dependencyType: h, lag: t });
                }
              }
            }
          }
          for (e = 0; e < c.length; e++) delete c[e].successors;
          w(a, 'predecessors', true, true);
        };
        a.getItemIndexString = function (a, c) {
          typeof c === d && (c = false);
          return typeof a.index !== d ? (a.index + (!c ? 1 : 0)).toString() : '';
        };
        a.getItemWbsIndexString = function (a, b) {
          return Ec(a, c, b).toString();
        };
        a.moveRange = function (b, e, d) {
          mb(b, e, d, a, c);
        };
        a.moveItem = function (b, e) {
          Fb(b, e, a, c);
        };
        a.moveItemUp = function (b) {
          var e = c.indexOf(b);
          e <= 0 || Fb(b, e - 1, a, c);
        };
        a.moveItemDown = function (b) {
          var e = c.indexOf(b);
          e < 0 || e >= c.length - 1 || Fb(b, e + 1, a, c);
        };
        a.moveItemHierarchy = function (b, e) {
          for (var d = c.indexOf(b), f = d + 1; f < c.length; f++)
            if (c[f].indentation <= b.indentation) break;
          mb(d, f - d, e, a, c);
        };
        a.moveItemHierarchyUp = function (b) {
          for (var e = c.indexOf(b), d = e + 1; d < c.length; d++)
            if (c[d].indentation <= b.indentation) break;
          for (var f = e; f-- > 0; ) {
            if (c[f].indentation < b.indentation) return;
            if (c[f].indentation == b.indentation) break;
          }
          mb(e, d - e, f, a, c);
        };
        a.moveItemHierarchyDown = function (b) {
          for (var e = c.indexOf(b), d = e + 1; d < c.length; d++)
            if (c[d].indentation <= b.indentation) break;
          for (var d = d - e, f = e + d; f++ < c.length - 1; ) if (c[f].indentation <= b.indentation) break;
          c[f - 1].indentation < b.indentation || mb(e, d, f - d, a, c);
        };
        a.exportContent = function (a, b) {
          typeof a === d && (a = {});
          uc(
            a.title,
            a.preparingMessage,
            a.isGridVisible,
            a.columnIndexes,
            a.timelineStart,
            a.timelineFinish,
            a.isRelativeToTimezone,
            a.hourWidth,
            a.startRowIndex,
            a.endRowIndex,
            b,
            false,
            a.rotate,
            false,
            c,
            e
          );
        };
        a.print = function (a) {
          typeof a === d && (a = {});
          uc(
            a.title,
            a.preparingMessage,
            a.isGridVisible,
            a.columnIndexes,
            a.timelineStart,
            a.timelineFinish,
            a.isRelativeToTimezone,
            a.hourWidth,
            a.startRowIndex,
            a.endRowIndex,
            null,
            true,
            a.rotate,
            a.autoClose,
            c,
            e
          );
        };
        a.getRootItems = function () {
          for (var a = [], b = 0; b < c.length; b++) {
            var e = c[b];
            e.parent == null && a.push(e);
          }
          return a;
        };
        a.getLeafItems = function () {
          for (var a = [], b = 0; b < c.length; b++) {
            var e = c[b];
            e.parent != null && a.push(e);
          }
          return a;
        };
        a.getSummaryItems = function () {
          for (var a = [], b = 0; b < c.length; b++) {
            var e = c[b];
            e.hasChildren && a.push(e);
          }
          return a;
        };
        a.getProjectStart = function () {
          return vc(c);
        };
        a.getProjectFinish = function () {
          return Gb(c);
        };
        a.getProjectEffort = function () {
          return wc(c, e);
        };
        a.getProjectTotalEffort = function () {
          for (var a = 0, b = 0; b < c.length; b++) {
            var e = c[b];
            e.parent == null && (a = a + Db(e));
          }
          return a;
        };
        a.getProjectCompletedEffort = function () {
          return xc(c, e);
        };
        a.getProjectTotalCompletedEffort = function () {
          for (var a = 0, b = 0; b < c.length; b++) {
            var e = c[b];
            e.parent == null && (a = a + Eb(e));
          }
          return a;
        };
        a.getProjectCompletion = function () {
          return xc(c, e) / wc(c, e);
        };
        a.isItemCritical = function (a, b) {
          return ob(a, b, c, e);
        };
        a.getCriticalItems = function (a, b) {
          var f;
          f = a;
          var g = b ? b : c;
          typeof f === d && (f = 0);
          if (typeof g === d) f = void 0;
          else {
            for (var h = Gb(g), j = 0; j < g.length; j++) {
              var k = g[j];
              k.successorPredecessors = nb(k, g);
            }
            for (var q = [], j = 0; j < g.length; j++) {
              k = g[j];
              (!k.hasChildren || !(typeof k.isSummaryEnabled === d || k.isSummaryEnabled)) &&
                ob(k, f, g, e, h) &&
                q.push(k);
            }
            for (j = 0; j < g.length; j++) {
              k = g[j];
              delete k.successorPredecessors;
            }
            f = q;
          }
          return f;
        };
        a.getPertCriticalItems = function (b, d) {
          return id(a, d ? d : c, e, b);
        };
        a.ensureDependencyConstraints = function () {
          Hb(c, e, a);
        };
        a.setupBaseline = function () {
          for (var a = 0; a < c.length; a++) {
            var b = c[a];
            if (!b.hasChildren || !(typeof b.isSummaryEnabled === d || b.isSummaryEnabled)) {
              b.baselineStart = b.start;
              b.isMilestone ? delete b.baselineFinish : (b.baselineFinish = b.finish);
            } else {
              delete b.baselineFinish;
              delete b.baselineStart;
            }
            sa(b);
          }
        };
        a.rescheduleItemToStart = function (a, b) {
          zc(a, b, c, e);
        };
        a.rescheduleItemToFinish = function (a, c) {
          var b = c,
            b = new Date(b.valueOf() - b.getTimezoneOffset() * 60000),
            d = ka(a, e),
            f = Da(a, e),
            b = Za(d, b, e, C(a)),
            b = ya(b, e, C(a));
          Ib(a, b);
          eb(a, d, e);
          fb(a, f, e);
          $(a);
        };
        a.splitRemainingWork = function (b, f, g) {
          if (
            typeof c === d ||
            b.hasChildren ||
            b.isMilestone ||
            !Qa(b) ||
            (!b.isMilestone && b.completedFinish >= b.finish)
          )
            b = null;
          else {
            var h = c.indexOf(b);
            if (h < 0) b = null;
            else {
              f = {
                content: typeof f === d ? b.content : b.content + f,
                indentation: b.indentation,
                isExpanded: b.isExpanded,
                start: b.completedFinish,
                finish: b.finish,
                assignmentsContent: b.assignmentsContent,
                isReadOnly: b.isReadOnly,
                isHidden: b.isHidden,
                isBarVisible: b.isBarVisible,
                isBarReadOnly: b.isBarReadOnly,
                isSummaryEnabled: b.isSummaryEnabled,
                isParentSummarizationEnabled: b.isParentSummarizationEnabled,
                displayRowIndex: b.displayRowIndex,
                class: b['class'],
                style: b.style,
                barClass: b.barClass,
                standardBarClass: b.standardBarClass,
                summaryBarClass: b.summaryBarClass,
                milestoneBarClass: b.milestoneBarClass,
                baselineBarClass: b.baselineBarClass,
                barStyle: b.barStyle,
                standardBarStyle: b.standardBarStyle,
                summaryBarStyle: b.summaryBarStyle,
                milestoneBarStyle: b.milestoneBarStyle,
                baselineBarStyle: b.baselineBarStyle,
                taskTemplate: b.taskTemplate,
                template: b.template,
                tag: b.tag,
              };
              if (typeof g !== d) b.content = b.content + g;
              b.finish = b.completedFinish;
              kc(h + 1, f, a, c, e);
              g = nb(b, c);
              for (h = 0; h < g.length; h++) {
                var j = g[h].predecessor;
                if (
                  typeof j.dependencyType === d ||
                  j.dependencyType == '' ||
                  j.dependencyType == 'FinishStart' ||
                  j.dependencyType == 'FS' ||
                  j.dependencyType == 'FinishFinish' ||
                  j.dependencyType == 'FF'
                )
                  j.item = f;
              }
              f.predecessors = [{ item: b }];
              xa(b);
              xa(f);
              b = f;
            }
          }
          return b;
        };
        a.optimizeWork = function (b, f, g, h) {
          var j;
          typeof b === d && (b = false);
          typeof f === d && (f = false);
          typeof g === d && (g = new Date());
          if (h) Bc(f, g, c, e, a, true, b);
          else {
            g = new Date(g.valueOf() + g.getTimezoneOffset() * 60000);
            j = [];
            for (h = 0; h < c.length; h++) {
              var k = c[h];
              if (!k.hasChildren || !(typeof k.isSummaryEnabled === d || k.isSummaryEnabled))
                if (!(k.start <= g || (!f && Qa(k)))) {
                  if (b) {
                    for (var q = false, p = k; p != null; ) {
                      if (typeof p.predecessors !== d && p.predecessors.length > 0) {
                        q = true;
                        break;
                      }
                      p = p.parent;
                    }
                    if (!q) continue;
                  }
                  zc(k, g, c, e);
                  j.push(k);
                }
            }
            Hb(c, e, a);
            for (h = 0; h < j.length; h++) {
              b = j[h];
              w(b, 'start', true, true);
              w(b, 'finish', true, true);
              w(b, 'completedFinish', true, true);
              $(b);
            }
            j = void 0;
          }
          return j;
        };
        a.levelAllocations = function (c) {
          if (!c) c = a.items;
          c.length || (c = [c]);
          for (var b = 0; b < c.length; b++) {
            var e = c[b],
              d,
              f = '',
              g = la(e);
            if (g && g.length > 0) {
              var h = 0;
              for (d = 0; d < g.length; d++) h = h + g[d].value;
              if (h > 0) {
                for (d = 0; d < g.length; d++) {
                  f.length > 0 && (f = f + ', ');
                  f =
                    f +
                    (g[d].key +
                      (g.length > 1 ? ' [' + Math.round((g[d].value / h) * 10000) / 100 + '%]' : ''));
                }
                e.assignmentsContent = f;
                w(e, 'assignmentsContent', false, true);
                $(e);
              }
            }
          }
        };
        a.levelResources = function (b, d) {
          return Bc(b, d, c, e, a);
        };
        a.getItemSuccessors = function (a) {
          return yc(a, c);
        };
        a.getItemSuccessorPredecessorItems = function (a) {
          return nb(a, c);
        };
        a.getItemAllocationUnits = $a;
        a.getItemAssignments = la;
        a.getItemAssignedResources = Fc;
        a.getResourceAssignments = function (a) {
          return Ra(a, c);
        };
        a.getResourceAssignedItems = function (a) {
          for (var b = [], a = Ra(a, c), e = 0; e < a.length; e++) b.push(a[e].key);
          return b;
        };
        a.getAssignedResources = function () {
          return Ta(c);
        };
        a.getItemAssignmentsCost = function (a) {
          return Ac(a, e);
        };
        a.getItemExtraCost = function (a) {
          return Kb(a, e);
        };
        a.getItemCost = function (a) {
          return Jb(a, e);
        };
        a.setItemCost = function (a, c) {
          a.executionCost = c - Kb(a, e);
          w(a, 'executionCost', true, true);
        };
        a.getResourceCost = function (a) {
          for (var b = 0, a = Ra(a, c), f = 0; f < a.length; f++) {
            var g = a[f],
              h;
            typeof e.specificResourceUsageCosts !== d && (h = aa(e.specificResourceUsageCosts, resource)) >= 0
              ? (b = b + e.specificResourceUsageCosts[h].value * g.value)
              : typeof e.defaultResourceUsageCost !== d && (b = b + e.defaultResourceUsageCost * g.value);
            var j = g.key;
            if (!j.hasChildren || !(typeof j.isSummaryEnabled === d || j.isSummaryEnabled)) {
              j = ka(j, e) / 3600000;
              typeof e.specificResourceHourCosts !== d && (h = aa(e.specificResourceHourCosts, resource)) >= 0
                ? (b = b + e.specificResourceHourCosts[h].value * j * g.value)
                : typeof e.defaultResourceHourCost !== d && (b = b + e.defaultResourceHourCost * j * g.value);
            }
          }
          return b;
        };
        a.getProjectCost = function () {
          for (var a = 0, b = 0; b < c.length; b++) {
            var d = c[b];
            d.parent == null && (a = a + Jb(d, e));
          }
          return a;
        };
        a.getScheduleChartItems = function (a) {
          var b = [],
            a =
              typeof a !== d
                ? typeof a === 'array'
                  ? a
                  : [a]
                : typeof e.assignableResources !== d
                ? e.assignableResources
                : [],
            f,
            g = Ta(c),
            h;
          for (h = 0; h < g.length; h++) {
            f = g[h];
            a.indexOf(f) < 0 && a.push(f);
          }
          for (h = 0; h < a.length; h++) {
            f = a[h];
            g = { tag: f, content: f, ganttChartItems: [] };
            f = Ra(f, c);
            for (var j = 0; j < f.length; j++) {
              var k = f[j],
                q = k.key,
                k = {
                  tag: q,
                  content: q.content,
                  start: q.start,
                  finish: q.finish,
                  completedFinish: q.completedFinish,
                  isMilestone: q.isMilestone,
                  schedule: C(q),
                  assignmentsContent: k.value != 1 ? Math.round(k.value * 10000) / 100 + '%' : '',
                  minStart: q.minStart,
                  maxStart: q.maxStart,
                  minFinish: q.minFinish,
                  maxFinish: q.maxFinish,
                  isHidden: q.isHidden,
                  isBarVisible: q.isBarVisible,
                  isBarReadOnly: q.isBarReadOnly,
                  isReadOnly: q.isReadOnly,
                  isRelativeToTimezone: q.isRelativeToTimezone,
                };
              g.ganttChartItems.push(k);
            }
            b.push(g);
          }
          return b;
        };
        a.getAllocations = function (a) {
          return Gc(a, c);
        };
        a.getLoadChartItems = function (a) {
          var b = [];
          typeof a === d ? (a = Ta(c)) : typeof a !== 'array' && (a = [a]);
          for (var f = 0; f < a.length; f++) {
            for (
              var g = a[f], h = { tag: g, content: g, ganttChartItems: [] }, j = Gc(g, c), k = 0;
              k < j.length;
              k++
            ) {
              var q = j[k],
                t = 1;
              if (typeof e.resourceQuantities !== d) {
                var p = aa(e.resourceQuantities, g);
                if (p >= 0) t = e.resourceQuantities[p].value;
              }
              p = e.maxLoadChartDisplayedResourceQuantity;
              typeof p === d && (p = 100);
              t > p && (t = p);
              h.ganttChartItems.push({
                start: q.key.start,
                finish: q.key.finish,
                units: q.value / t,
                content: Math.round(q.value * 10000) / 100 + '%',
                isRelativeToTimezone: false,
              });
            }
            b.push(h);
          }
          return b;
        };
        a.getFilteredGanttChartItems = function (a) {
          for (var a = [a], b = [], e = 0; e < a.length; e++) {
            var f = a[e],
              g = [];
            if (typeof a === d) a = Ta(c);
            else if (typeof a !== 'array') var h = Ra(f, c);
            for (f = 0; f < h.length; f++) {
              var j = h[f].key;
              if (
                (!j.hasChildren || !(typeof j.isSummaryEnabled === d || j.isSummaryEnabled)) &&
                !(b.indexOf(j) >= 0)
              ) {
                g.push({
                  tag: j,
                  content: j.content,
                  start: j.start,
                  finish: j.finish,
                  completedFinish: j.completedFinish,
                  isMilestone: j.isMilestone,
                  assignmentsContent:
                    a.length > 1
                      ? j.assignmentsContent
                      : h[f].value != 1
                      ? Math.round(h[f].value * 10000) / 100 + '%'
                      : '',
                  minStart: j.minStart,
                  maxStart: j.maxStart,
                  minFinish: j.minFinish,
                  maxFinish: j.maxFinish,
                  isHidden: j.isHidden,
                  isBarVisible: j.isBarVisible,
                  isBarReadOnly: j.isBarReadOnly,
                  isReadOnly: true,
                  isRelativeToTimezone: j.isRelativeToTimezone,
                });
                b.push(j);
              }
            }
          }
          return g;
        };
        a.copyCommonSettings = function (a) {
          if (typeof a !== d) {
            a.target = e.target;
            a.theme = e.theme;
            a.border = e.border;
            a.containerClass = e.containerClass;
            a.containerStyle = e.containerStyle;
            a.isGridVisible = e.isGridVisible;
            a.gridWidth = e.gridWidth;
            a.chartWidth = e.chartWidth;
            a.isSplitterEnabled = e.isSplitterEnabled;
            a.splitterWidth = e.splitterWidth;
            a.splitterBackground = e.splitterBackground;
            a.minGridWidth = e.minGridWidth;
            a.minChartWidth = e.minChartWidth;
            a.headerBackground = e.headerBackground;
            a.headerHeight = e.headerHeight;
            a.indentationLevelWidth = e.indentationLevelWidth;
            a.gridLines = e.gridLines;
            a.horizontalGridLines = e.horizontalGridLines;
            a.verticalGridLines = e.verticalGridLines;
            a.verticalGridHeaderLines = e.verticalGridHeaderLines;
            a.horizontalChartLines = e.horizontalChartLines;
            a.displayedTime = e.displayedTime;
            a.currentTime = e.currentTime;
            a.timelineStart = e.timelineStart;
            a.timelineFinish = e.timelineFinish;
            a.scales = e.scales;
            a.updateScale = e.updateScale;
            a.hourWidth = e.hourWidth;
            a.workingWeekStart = e.workingWeekStart;
            a.workingWeekFinish = e.workingWeekFinish;
            a.visibleDayStart = e.visibleDayStart;
            a.visibleDayFinish = e.visibleDayFinish;
            a.visibleWeekStart = e.visibleWeekStart;
            a.visibleWeekFinish = e.visibleWeekFinish;
            a.specialNonworkingDays = e.specialNonworkingDays;
            a.months = e.months;
            a.daysOfWeek = e.daysOfWeek;
            a.weekStartDay = e.weekStartDay;
            a.dateFormatter = e.dateFormatter;
            a.dateTimeFormatter = e.dateTimeFormatter;
            a.dateTimeParser = e.dateTimeParser;
            a.isRelativeToTimezone = e.isRelativeToTimezone;
            a.classic = e.classic;
          }
        };
        a.getPertChartItems = function (a, b, f, g, h, j, k) {
          var q = a,
            t = b,
            a = f,
            p = h,
            h = j,
            s = k ? k : c;
          if (typeof q === d || q < 0) q = Number.MAX_VALUE;
          typeof t === d && (t = 'Start');
          typeof a === d && (a = 'Finish');
          typeof p === d && (p = ' completed');
          typeof h === d && (h = ' starting');
          for (
            var u = new Date(new Date(10000, 0, 1).valueOf() - 1), k = [], y, v, r, w, j = [], b = 0;
            b < s.length;
            b++
          ) {
            f = s[b];
            f.indentation <= q &&
              (!f.hasChildren ||
                !(
                  typeof f.isSummaryEnabled === d ||
                  f.isSummaryEnabled ||
                  (typeof f.isBarVisible !== d && !f.isBarVisible)
                ) ||
                f.indentation == q) &&
              j.push({ key: f, value: [] });
          }
          for (b = 0; b < j.length; b++) {
            var f = j[b].key,
              D = [f];
            if (
              f.indentation == q &&
              f.hasChildren &&
              (typeof f.isSummaryEnabled === d ||
                f.isSummaryEnabled ||
                (typeof f.isBarVisible !== d && !f.isBarVisible))
            )
              for (y = 0; y < s.length; y++) {
                v = s[y];
                cb(v, f) && D.push(v);
              }
            var z = j[b].value;
            for (y = 0; y < D.length; y++)
              for (var B = D[y]; B != null; ) {
                if (typeof B.predecessors !== d)
                  for (r = 0; r < B.predecessors.length; r++)
                    if (
                      !(
                        typeof B.predecessors[r].item === d ||
                        (typeof B.predecessors[r].dependencyType !== d &&
                          B.predecessors[r].dependencyType != '' &&
                          B.predecessors[r].dependencyType != 'FinishStart' &&
                          B.predecessors[r].dependencyType != 'FS')
                      )
                    ) {
                      for (var A = (w = B.predecessors[r].item); A != null && aa(j, A) < 0; ) A = A.parent;
                      if (A != f) {
                        var F = [];
                        if (A != null) F.push(A);
                        else
                          for (A = 0; A < j.length; A++) {
                            v = j[A].key;
                            cb(v, w) && F.push(v);
                          }
                        for (A = 0; A < F.length; A++) {
                          w = F[A];
                          z.indexOf(w) < 0 && ((q < Number.MAX_VALUE && Lb(j, w, f)) || z.push(w));
                        }
                      }
                    }
                B = B.parent;
              }
          }
          A = 0;
          r = y = null;
          for (b = 0; b < s.length; b++) {
            f = s[b];
            if (y == null || f.start < y) y = f.start;
            if (r == null || f.finish > r) r = f.finish;
          }
          y == null && (y = u);
          t = { content: t, displayedText: (A++).toString(), displayedRowIndex: 0, displayedColumnIndex: 0 };
          k.push(t);
          s = [t];
          q = [];
          for (b = 0; b < j.length; b++) j[b].value.length == 0 && q.push(j[b].key);
          if (q.length > 0) {
            s = [];
            for (b = 0; b < q.length; b++) {
              f = q[b];
              r = t;
              u = (A++).toString();
              if (f.start > y) {
                r = {
                  content: f.content != null ? f.content.toString() + h : null,
                  displayedText: u,
                  predecessors: [],
                };
                k.push(r);
                r.predecessors.push({ item: t, content: g, isEffortVirtual: true });
              }
              u = {
                content: f.content + p,
                displayedText: u + (r != t ? "'" : ''),
                predecessors: [],
                tag: f,
              };
              s.push(u);
              k.push(u);
              u.predecessors.push({
                item: r,
                content: Ka(f),
                displayedText: f.content != null ? f.content.toString() : null,
                effort: M(f.start, f.finish, e, C(f)),
                tag: f,
              });
            }
            do {
              D = [];
              for (b = 0; b < s.length; b++) D.push(s[b]);
              f = false;
              for (b = 0; b < s.length; b++) {
                q = s[b];
                u = q.tag;
                z = [];
                for (y = 0; y < j.length; y++) j[y].value.indexOf(u) >= 0 && z.push(j[y].key);
                if (z.length > 0) {
                  D.splice(D.indexOf(q), 1);
                  for (y = 0; y < z.length; y++) {
                    f = z[y];
                    u = null;
                    for (r = 0; r < k.length; r++)
                      if (k[r].tag == f) {
                        u = k[r];
                        break;
                      }
                    if (u == null) {
                      u = {
                        content: f.content + p,
                        displayedText: (A++).toString(),
                        predecessors: [],
                        tag: f,
                      };
                      D.push(u);
                      k.push(u);
                    }
                    u.predecessors.push({
                      item: q,
                      content: Ka(f),
                      displayedText: f.content != null ? f.content.toString() : null,
                      effort: M(f.start, f.finish, e, C(f)),
                      tag: f,
                    });
                  }
                  f = true;
                }
              }
              s = D;
            } while (f);
          }
          f = { content: a, displayedText: (A++).toString(), predecessors: [], displayedRowIndex: 0 };
          k.push(f);
          f = f.predecessors;
          for (b = 0; b < s.length; b++) f.push({ item: s[b] });
          for (y = 0; y < j.length; y++) {
            f = j[y].key;
            q = null;
            for (r = 0; r < k.length; r++)
              if (k[r].tag == f) {
                q = k[r];
                break;
              }
            if (q != null) {
              b = [];
              if (typeof q.predecessors !== d)
                for (r = 0; r < q.predecessors.length; r++)
                  q.predecessors[r].tag == f && b.push(q.predecessors[r]);
              if (b.length > 1) {
                p = {
                  content: f.content != null ? f.content.toString() + h : null,
                  displayedText: q.displayedText,
                  predecessors: [],
                };
                k.splice(k.indexOf(q), 0, p);
                A = p.predecessors;
                for (r = 0; r < b.length; r++) {
                  a = b[r];
                  q.predecessors.splice(q.predecessors.indexOf(a), 1);
                  A.push({ item: a.item, content: g, isEffortVirtual: true });
                }
                q.predecessors.push({
                  item: p,
                  content: Ka(f),
                  displayedText: f.content != null ? f.content.toString() : null,
                  effort: M(f.start, f.finish, e, C(f)),
                  tag: f,
                });
                q.displayedText = q.displayedText + "'";
              } else if (b.length == 1) {
                a = b[0];
                b = a.item.tag;
                if (b != null && f.start > ya(b.finish, e)) {
                  r = {
                    content: f.content != null ? f.content.toString() + h : null,
                    displayedText: q.displayedText,
                    predecessors: [],
                  };
                  k.splice(k.indexOf(q), 0, r);
                  r.predecessors.push({ item: a.item, content: g, isEffortVirtual: true });
                  q.predecessors.splice(0, q.predecessors.length);
                  q.predecessors.push({
                    item: r,
                    content: Ka(f),
                    displayedText: f.content != null ? f.content.toString() : null,
                    effort: M(f.start, f.finish, e, C(f)),
                    tag: f,
                  });
                  q.displayedText = q.displayedText + "'";
                }
              }
            }
          }
          for (b = 0; b < j.length; b++)
            if (j[b].key.isMilestone) {
              f = j[b].key;
              q = null;
              for (r = 0; r < k.length; r++)
                if (k[r].tag == f) {
                  q = k[r];
                  break;
                }
              if (q != null && typeof q.predecessors !== d && q.predecessors.length == 1) {
                a = q.predecessors[0];
                if (a.item != t && a.tag == f) {
                  q.predecessors.splice(0, 1);
                  u = a.item;
                  for (r = 0; r < u.predecessors.length; r++) {
                    g = u.predecessors[r];
                    a.item.predecessors.splice(a.item.predecessors.indexOf(g), 1);
                    q.predecessors.push(g);
                  }
                  for (r = 0; r < k.length; r++) {
                    g = k[r];
                    if (g.predecessors)
                      for (A = 0; A < g.predecessors.length; A++) {
                        h = g.predecessors[A];
                        if (h.item == u) h.item = q;
                      }
                  }
                  q.content = f.content != null ? f.content.toString() : null;
                  q.displayedText = q.displayedText.replace("'", '');
                  k.splice(k.indexOf(u), 1);
                }
              }
            }
          return k;
        };
        a.getNetworkDiagramItems = function (a, b, f, g) {
          var h = a,
            j = b,
            k = f,
            q = g ? g : c;
          if (typeof h === d || h < 0) h = Number.MAX_VALUE;
          typeof j === d && (j = 'Start');
          typeof k === d && (k = 'Finish');
          for (
            var g = new Date(1900, 0, 1),
              f = new Date(new Date(10000, 0, 1).valueOf() - 1),
              b = [],
              p,
              s,
              u,
              y,
              v,
              r,
              w = [],
              a = 0;
            a < q.length;
            a++
          ) {
            p = q[a];
            p.indentation <= h &&
              (!p.hasChildren ||
                !(
                  typeof p.isSummaryEnabled === d ||
                  p.isSummaryEnabled ||
                  (typeof p.isBarVisible !== d && !p.isBarVisible)
                ) ||
                p.indentation == h) &&
              w.push({ key: p, value: [] });
          }
          for (a = 0; a < w.length; a++) {
            p = w[a].key;
            v = [p];
            if (
              p.indentation == h &&
              p.hasChildren &&
              (typeof p.isSummaryEnabled === d ||
                p.isSummaryEnabled ||
                (typeof p.isBarVisible !== d && !p.isBarVisible))
            )
              for (s = 0; s < q.length; s++) {
                u = q[s];
                cb(u, p) && v.push(u);
              }
            var D = w[a].value;
            for (s = 0; s < v.length; s++)
              for (var z = v[s]; z != null; ) {
                if (typeof z.predecessors !== d)
                  for (y = 0; y < z.predecessors.length; y++)
                    if (
                      !(
                        typeof z.predecessors[y].item === d ||
                        (typeof z.predecessors[y].dependencyType !== d &&
                          z.predecessors[y].dependencyType != '' &&
                          z.predecessors[y].dependencyType != 'FinishStart' &&
                          z.predecessors[y].dependencyType != 'FS')
                      )
                    ) {
                      for (u = r = z.predecessors[y].item; u != null && aa(w, u) < 0; ) u = u.parent;
                      if (u != p) {
                        var B = [];
                        if (u != null) B.push(u);
                        else
                          for (var A = 0; A < w.length; A++) {
                            u = w[A].key;
                            cb(u, r) && B.push(u);
                          }
                        for (r = 0; r < B.length; r++) {
                          u = B[r];
                          D.indexOf(u) < 0 && ((h < Number.MAX_VALUE && Lb(w, u, p)) || D.push(u));
                        }
                      }
                    }
                z = z.parent;
              }
          }
          r = s = null;
          for (a = 0; a < q.length; a++) {
            p = q[a];
            if (s == null || p.start < s) s = p.start;
            if (r == null || p.finish > r) r = p.finish;
          }
          s == null && (s = f);
          r == null && (r = g);
          j = {
            content: j,
            displayedText: j,
            earlyStart: s,
            earlyFinish: s,
            lateStart: f,
            lateFinish: f,
            isMilestone: true,
            effort: 0,
            displayedRowIndex: 0,
            displayedColumnIndex: 0,
          };
          b.push(j);
          h = [j];
          s = [];
          for (a = 0; a < w.length; a++) w[a].value.length == 0 && s.push(w[a].key);
          if (s.length > 0) {
            h = [];
            for (a = 0; a < s.length; a++) {
              p = s[a];
              y = {
                content: Ka(p),
                displayedText: p.content,
                earlyStart: g,
                earlyFinish: g,
                lateStart: f,
                lateFinish: f,
                effort: M(p.start, p.finish, e, C(p)),
                isMilestone: p.isMilestone,
                predecessors: [],
                tag: p,
              };
              h.push(y);
              b.push(y);
              y.earlyStart = ya(j.earlyFinish, e);
              y.earlyFinish = S(y.earlyStart, y.effort, e);
              y.predecessors.push({ item: j, tag: p });
            }
            do {
              D = [];
              for (a = 0; a < h.length; a++) D.push(h[a]);
              y = false;
              for (a = 0; a < h.length; a++) {
                q = h[a];
                p = q.tag;
                z = [];
                for (s = 0; s < w.length; s++) w[s].value.indexOf(p) >= 0 && z.push(w[s].key);
                if (z.length > 0) {
                  D.splice(D.indexOf(q), 1);
                  for (s = 0; s < z.length; s++) {
                    p = z[s];
                    v = null;
                    for (y = 0; y < b.length; y++)
                      if (b[y].tag == p) {
                        v = b[y];
                        break;
                      }
                    if (v == null) {
                      v = {
                        content: Ka(p),
                        displayedText: p.content,
                        earlyStart: g,
                        earlyFinish: g,
                        lateStart: f,
                        lateFinish: f,
                        effort: M(p.start, p.finish, e, C(p)),
                        isMilestone: p.isMilestone,
                        predecessors: [],
                        tag: p,
                      };
                      D.push(v);
                      b.push(v);
                    }
                    y = ya(q.earlyFinish, e);
                    if (y > v.earlyStart) {
                      v.earlyStart = y;
                      v.earlyFinish = S(v.earlyStart, v.effort, e);
                    }
                    v.predecessors.push({ item: q, tag: p });
                  }
                  y = true;
                }
              }
              h = D;
            } while (y);
          }
          k = {
            content: k,
            displayedText: k,
            earlyStart: g,
            earlyFinish: g,
            lateStart: r,
            lateFinish: r,
            isMilestone: true,
            effort: 0,
            predecessors: [],
            displayedRowIndex: 0,
          };
          b.push(k);
          s = k.predecessors;
          for (a = 0; a < h.length; a++) {
            w = h[a];
            y = w.earlyFinish;
            if (y > k.earlyStart) {
              k.earlyStart = y;
              k.earlyFinish = k.earlyStart;
            }
            s.push({ item: w });
          }
          for (h = [k]; h.length > 0; ) {
            w = [];
            for (a = 0; a < h.length; a++) {
              q = h[a];
              if (typeof q.predecessors !== d)
                for (s = 0; s < q.predecessors.length; s++) {
                  r = q.predecessors[s];
                  v = r.item;
                  p = false;
                  for (y = 0; y < w.length; y++)
                    if (w[y] == v) {
                      p = true;
                      break;
                    }
                  p || w.push(v);
                  p = q.lateStart;
                  if (p < v.lateFinish) {
                    v.lateFinish = v.effort > 0 ? R(p, e, false, true, void 0) : p;
                    v.lateStart = Za(v.effort, v.lateFinish, e);
                  }
                }
            }
            h = w;
          }
          if (b.length > 2)
            for (a = 0; a < b.length; a++) {
              p = b[a];
              p.slack = M(p.earlyStart, p.lateStart, e);
            }
          else {
            j.earlyStart = g;
            j.earlyFinish = g;
            j.lateStart = g;
            j.lateFinish = g;
            k.earlyStart = f;
            k.earlyFinish = f;
            k.lateStart = f;
            k.lateFinish = f;
          }
          for (a = 0; a < b.length; a++) b[a].isRelativeToTimezone = false;
          return b;
        };
        a.getOutputDate = Hc;
        a.getInputDate = Ic;
        a.itemDependsOf = function (a, b) {
          return Ja(a, b);
        };
      };
    return {
      initialize: P,
      initializeItems: J,
      refresh: K,
      getDefaultColumns: k,
      getDefaultCollapsedToggleButtonTemplate: Nb,
      getDefaultExpandedToggleButtonTemplate: Ob,
      getDefaultScales: Pb,
      getDefaultStyleDefinitionTemplate: Qb,
      getDefaultStandardTaskTemplate: Rb,
      getDefaultSummaryTaskTemplate: Sb,
      getDefaultMilestoneTaskTemplate: Tb,
      getDefaultItemTemplate: Ub,
      getDefaultAssignmentsTemplate: Vb,
      getDefaultDependencyLineTemplate: Wb,
      getDefaultPredecessorItemTemplate: Xb,
      initializeTaskDraggingThumbs: function (a, c, e, b, f, d, h, j) {
        f.ganttChartView.initializeTaskDraggingThumbs(a, c, e, b, f, d, h, j);
      },
      initializeDependencyDraggingThumb: function (a, c, e, b, f) {
        e.ganttChartView.initializeDependencyDraggingThumb(a, c, e, b, f);
      },
      getWorkingTime: pc,
      getEffort: M,
      getFinish: S,
      getStart: Za,
      getCompletion: kb,
      getCompletedFinish: Cb,
      getBaselineFinishColumnTemplate: function (a, c, e, b, f, g, k, o, m, n) {
        typeof c === d && (c = true);
        typeof e === d && (e = true);
        return function (l) {
          var p = l.ganttChartView;
          return (c
            ? e
              ? DlhSoft.Controls.GanttChartView.dateTimePickerInputColumnTemplateBase
              : DlhSoft.Controls.GanttChartView.datePickerInputColumnTemplateBase
            : DlhSoft.Controls.GanttChartView.dateTimeInputColumnTemplateBase)(
            p.ownerDocument,
            a,
            function () {
              return l.baselineFinish != null ? h(l.baselineFinish) : void 0;
            },
            function (a) {
              a != null ? (l.baselineFinish = j(a)) : delete l.baselineFinish;
              p.onItemPropertyChanged(l, 'baselineFinish', true, true);
              p.refreshItem(l);
            },
            function () {
              return !(
                l.isReadOnly ||
                o ||
                (typeof l.ganttChartView !== d &&
                  typeof l.ganttChartView.settings !== d &&
                  (l.ganttChartView.settings.isReadOnly || l.ganttChartView.settings.isGridReadOnly))
              );
            },
            function () {
              return !(l.isMilestone || (typeof l.isBarVisible !== d && !l.isBarVisible));
            },
            function () {
              return l.hasChildren && (typeof l.isSummaryEnabled === d || l.isSummaryEnabled);
            },
            b ? b : 86400000,
            f,
            g,
            k,
            function (a) {
              return a == null ? '' : p.settings.dateTimeFormatter(a);
            },
            function (a) {
              return a == '' ? null : p.settings.dateTimeParser(a);
            },
            m,
            n
          );
        };
      },
      getMinStartColumnTemplate: function (a, c, e, b, f, g, k, o, m, n) {
        typeof c === d && (c = true);
        typeof e === d && (e = true);
        return function (l) {
          var p = l.ganttChartView;
          return (c
            ? e
              ? DlhSoft.Controls.GanttChartView.dateTimePickerInputColumnTemplateBase
              : DlhSoft.Controls.GanttChartView.datePickerInputColumnTemplateBase
            : DlhSoft.Controls.GanttChartView.dateTimeInputColumnTemplateBase)(
            p.ownerDocument,
            a,
            function () {
              return l.minStart != null ? h(l.minStart) : void 0;
            },
            function (a) {
              a != null ? (l.minStart = j(a)) : delete l.minStart;
              p.onItemPropertyChanged(l, 'minStart', true, true);
              p.refreshItem(l);
            },
            function () {
              return (
                !(
                  l.isReadOnly ||
                  o ||
                  (typeof l.ganttChartView !== d &&
                    typeof l.ganttChartView.settings !== d &&
                    (l.ganttChartView.settings.isReadOnly || l.ganttChartView.settings.isGridReadOnly))
                ) && !(l.hasChildren && (typeof l.isSummaryEnabled === d || l.isSummaryEnabled))
              );
            },
            function () {
              return !(typeof l.isBarVisible !== d && !l.isBarVisible);
            },
            function () {
              return l.hasChildren && (typeof l.isSummaryEnabled === d || l.isSummaryEnabled);
            },
            b,
            f,
            g,
            k,
            function (a) {
              return a == null ? '' : p.settings.dateTimeFormatter(a);
            },
            function (a) {
              return a == '' ? null : p.settings.dateTimeParser(a);
            },
            m,
            n
          );
        };
      },
      getMaxStartColumnTemplate: function (a, c, e, b, f, g, k, o, m, n) {
        typeof c === d && (c = true);
        typeof e === d && (e = true);
        return function (l) {
          var p = l.ganttChartView;
          return (c
            ? e
              ? DlhSoft.Controls.GanttChartView.dateTimePickerInputColumnTemplateBase
              : DlhSoft.Controls.GanttChartView.datePickerInputColumnTemplateBase
            : DlhSoft.Controls.GanttChartView.dateTimeInputColumnTemplateBase)(
            p.ownerDocument,
            a,
            function () {
              return l.maxStart != null ? h(l.maxStart) : void 0;
            },
            function (a) {
              a != null ? (l.maxStart = j(a)) : delete l.maxStart;
              p.onItemPropertyChanged(l, 'maxStart', true, true);
              p.refreshItem(l);
            },
            function () {
              return (
                !(
                  l.isReadOnly ||
                  o ||
                  (typeof l.ganttChartView !== d &&
                    typeof l.ganttChartView.settings !== d &&
                    (l.ganttChartView.settings.isReadOnly || l.ganttChartView.settings.isGridReadOnly))
                ) && !(l.hasChildren && (typeof l.isSummaryEnabled === d || l.isSummaryEnabled))
              );
            },
            function () {
              return !(typeof l.isBarVisible !== d && !l.isBarVisible);
            },
            function () {
              return l.hasChildren && (typeof l.isSummaryEnabled === d || l.isSummaryEnabled);
            },
            b ? b : 86400000,
            f,
            g,
            k,
            function (a) {
              return a == null ? '' : p.settings.dateTimeFormatter(a);
            },
            function (a) {
              return a == '' ? null : p.settings.dateTimeParser(a);
            },
            m,
            n
          );
        };
      },
      getMinFinishColumnTemplate: function (a, c, e, b, f, g, k, o, m, n) {
        typeof c === d && (c = true);
        typeof e === d && (e = true);
        return function (l) {
          var p = l.ganttChartView;
          return (c
            ? e
              ? DlhSoft.Controls.GanttChartView.dateTimePickerInputColumnTemplateBase
              : DlhSoft.Controls.GanttChartView.datePickerInputColumnTemplateBase
            : DlhSoft.Controls.GanttChartView.dateTimeInputColumnTemplateBase)(
            p.ownerDocument,
            a,
            function () {
              return l.minFinish != null ? h(l.minFinish) : void 0;
            },
            function (a) {
              a != null ? (l.minFinish = j(a)) : delete l.minFinish;
              p.onItemPropertyChanged(l, 'minFinish', true, true);
              p.refreshItem(l);
            },
            function () {
              return (
                !(
                  l.isReadOnly ||
                  o ||
                  (typeof l.ganttChartView !== d &&
                    typeof l.ganttChartView.settings !== d &&
                    (l.ganttChartView.settings.isReadOnly || l.ganttChartView.settings.isGridReadOnly))
                ) && !(l.hasChildren && (typeof l.isSummaryEnabled === d || l.isSummaryEnabled))
              );
            },
            function () {
              return !(l.isMilestone || (typeof l.isBarVisible !== d && !l.isBarVisible));
            },
            function () {
              return l.hasChildren && (typeof l.isSummaryEnabled === d || l.isSummaryEnabled);
            },
            b,
            f,
            g,
            k,
            function (a) {
              return a == null ? '' : p.settings.dateTimeFormatter(a);
            },
            function (a) {
              return a == '' ? null : p.settings.dateTimeParser(a);
            },
            m,
            n
          );
        };
      },
      getMaxFinishColumnTemplate: function (a, c, e, b, f, g, k, p, m, n) {
        typeof c === d && (c = true);
        typeof e === d && (e = true);
        return function (l) {
          var s = l.ganttChartView;
          return (c
            ? e
              ? DlhSoft.Controls.GanttChartView.dateTimePickerInputColumnTemplateBase
              : DlhSoft.Controls.GanttChartView.datePickerInputColumnTemplateBase
            : DlhSoft.Controls.GanttChartView.dateTimeInputColumnTemplateBase)(
            s.ownerDocument,
            a,
            function () {
              return l.maxFinish != null ? h(l.maxFinish) : void 0;
            },
            function (a) {
              a != null ? (l.maxFinish = j(a)) : delete l.maxFinish;
              s.onItemPropertyChanged(l, 'maxFinish', true, true);
              s.refreshItem(l);
            },
            function () {
              return (
                !(
                  l.isReadOnly ||
                  p ||
                  (typeof l.ganttChartView !== d &&
                    typeof l.ganttChartView.settings !== d &&
                    (l.ganttChartView.settings.isReadOnly || l.ganttChartView.settings.isGridReadOnly))
                ) && !(l.hasChildren && (typeof l.isSummaryEnabled === d || l.isSummaryEnabled))
              );
            },
            function () {
              return !(l.isMilestone || (typeof l.isBarVisible !== d && !l.isBarVisible));
            },
            function () {
              return l.hasChildren && (typeof l.isSummaryEnabled === d || l.isSummaryEnabled);
            },
            b ? b : 86400000,
            f,
            g,
            k,
            function (a) {
              return a == null ? '' : s.settings.dateTimeFormatter(a);
            },
            function (a) {
              return a == '' ? null : s.settings.dateTimeParser(a);
            },
            m,
            n
          );
        };
      },
      getIconColumnTemplate: function (a, c, e, b, f, g, h, j, m) {
        if (typeof a === 'string')
          var k = a,
            a = function () {
              return k;
            };
        typeof b === d || b == false
          ? (b = function () {
              return false;
            })
          : b == true &&
            (b = function () {
              return true;
            });
        return function (l) {
          var k = l.ganttChartView,
            n = k.ownerDocument.createElement('img');
          n.setAttribute('src', a(l));
          typeof c !== d && n.setAttribute('class', c);
          typeof e !== d && n.setAttribute('style', e);
          if (!k.settings.isReadOnly && !k.settings.isGridReadOnly && b(l)) {
            n.style.cursor = 'move';
            gd(n, l, k.items, k, k.settings, f, g, h, j, m);
          }
          return n;
        };
      },
      getOutputDate: Hc,
      getInputDate: Ic,
    };
  })());
void 0 == DlhSoft.Controls.ScheduleChartView &&
  (DlhSoft.Controls.ScheduleChartView = (function () {
    var d = function (d, s, j, u, k) {
        d.internalEventListeners &&
          d.internalEventListeners.push({ object: s, event: j, handler: u, useCapture: k });
        return u;
      },
      O = function (d, s, j, u) {
        var k = [
          'DlhSoft.Controls',
          'ScheduleChartView',
          'DlhSoft.ProjectData.GanttChart.HTML.Controls',
          '5',
          'validate',
          'Licensing',
        ];
        DlhSoft[k[5]][k[4]](d, k[0], k[1], k[2], k[3], u, j);
        d.isScheduleChartInitializing = true;
        for (var u = s, s = [], y = (k = 0), p = 0; p < u.length; p++) {
          var v = u[p];
          if (!(typeof v.scheduleChartItem !== 'undefined' && v.scheduleChartItem != v)) {
            v.scheduleChartIndex = k++;
            if (!v.isHidden) v.scheduleChartVisibilityIndex = y++;
            v.isBarVisible = false;
            s.push(v);
            if (typeof v.ganttChartItems !== 'undefined')
              for (var r = 0; r < v.ganttChartItems.length; r++) {
                var z = v.ganttChartItems[r];
                z.scheduleChartItem = v;
                if (v.isHidden) z.isHidden = true;
                z.displayRowIndex = v.scheduleChartVisibilityIndex;
                z.indentation = (v.indentation ? v.indentation : 0) + 1;
                s.push(z);
              }
          }
        }
        typeof j !== 'object' && (j = {});
        DlhSoft.Controls.GanttChartView.initializeItems(s, j);
        k = j;
        y = s;
        ba(k);
        if (typeof k.gridWidth === 'undefined') k.gridWidth = '15%';
        if (typeof k.chartWidth === 'undefined') k.chartWidth = k.isGridVisible ? '85%' : '100%';
        if (typeof k.columns === 'undefined') k.columns = ca(y, k);
        if (typeof k.areTaskDependenciesVisible === 'undefined') k.areTaskDependenciesVisible = false;
        if (typeof k.isBaselineVisible === 'undefined') k.isBaselineVisible = false;
        if (typeof k.areStandardTaskLabelsVisible === 'undefined') k.areStandardTaskLabelsVisible = false;
        if (typeof k.areMilestoneTaskLabelsVisible === 'undefined') k.areMilestoneTaskLabelsVisible = false;
        if (typeof k.areAssignmentsReadOnly === 'undefined') k.areAssignmentsReadOnly = false;
        if (typeof k.assignmentThumbStyle === 'undefined')
          switch (k.theme) {
            default:
              k.assignmentThumbStyle =
                'fill: none; stroke: #3b87d9; stroke-width: 0.65px; stroke-dasharray: 2, 2';
              break;
            case 'Aero':
              k.assignmentThumbStyle = 'fill: none; stroke: Blue; stroke-dasharray: 2, 2';
          }
        if (typeof k.temporaryAssignmentThumbStyle === 'undefined')
          switch (k.theme) {
            default:
              k.temporaryAssignmentThumbStyle =
                'fill: none; stroke: #3b87d9; stroke-width: 0.65px; stroke-dasharray: 2, 2';
              break;
            case 'Aero':
              k.temporaryAssignmentThumbStyle =
                'fill: none; stroke: Blue; stroke-width: 0.65px; stroke-dasharray: 2, 2';
          }
        if (typeof k.assignmentThumbTemplate === 'undefined') k.assignmentThumbTemplate = H(y, d, k);
        k.internalExtraTaskTemplate = k.assignmentThumbTemplate;
        fa(d, s, u, j);
        DlhSoft.Controls.GanttChartView.initialize(
          d,
          s,
          j,
          'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
        );
        na(d, s);
        d.isScheduleChartInitializing = false;
        d.isScheduleChartInitialized = true;
        return d;
      },
      V = function (d) {
        O(d, d.scheduleChartItems, d.settings, d.license);
      },
      ba = function (d) {
        if (typeof d.useUpdatingToolTips === 'undefined') d.useUpdatingToolTips = true;
        if (typeof d.target === 'undefined') d.target = 'Standard';
        if (typeof d.theme === 'undefined') d.theme = 'Modern';
        if (typeof d.isGridVisible === 'undefined')
          switch (d.target) {
            default:
              d.isGridVisible = true;
              break;
            case 'Phone':
              d.isGridVisible = false;
          }
        if (typeof d.interaction === 'undefined')
          d.interaction = d.target != 'Phone' ? 'Standard' : 'TouchEnabled';
        if (typeof d.isReadOnly === 'undefined') d.isReadOnly = false;
        if (typeof d.isGridReadOnly === 'undefined') d.isGridReadOnly = false;
        if (typeof d.isContentReadOnly === 'undefined') d.isContentReadOnly = false;
        if (typeof d.selectionMode === 'undefined') d.selectionMode = 'Focus';
        if (typeof d.isVirtualizing === 'undefined') d.isVirtualizing = true;
        if (d.target == 'Phone' && typeof d.areTaskAssignmentsVisible === 'undefined')
          d.areTaskAssignmentsVisible = false;
      },
      ca = function (d, s) {
        typeof s !== 'object' && (s = {});
        ba(s);
        var j = [
          { header: '', width: 32, isSelection: true },
          { header: 'Resource', width: 120, isTreeView: true },
        ];
        j[0].cellTemplate = qa(s, j[0], d);
        j[1].cellTemplate = K(s, j[1], d);
        j[1].exportCellTemplate = K(s, j[1], d, false);
        s.selectionMode != 'Single' &&
          s.selectionMode != 'Extended' &&
          s.selectionMode != 'ExtendedFocus' &&
          j.splice(0, 1);
        return j;
      },
      qa = function (d, s) {
        return function (d) {
          return !s.isReadOnly ? r(d) : getBooleanNode(d.ganttChartView.ownerDocument, d.isSelected);
        };
      },
      r = function (h) {
        var s = h.ganttChartView.ownerDocument,
          j;
        if (typeof h.selectionInput === 'undefined') {
          j = s.createElement('input');
          h.selectionInput = j;
          j.type = 'checkbox';
          j.setAttribute('style', 'margin: 0px');
        } else j = h.selectionInput;
        if (h.isSelected) {
          j.setAttribute('checked', 'checked');
          if (!j.checked) j.checked = true;
        } else if (j.checked) j.checked = false;
        var u = function () {
          j.checked
            ? T(h)
            : typeof h.scheduleChartView !== 'undefined' && h.scheduleChartView.unselectItem(h);
        };
        typeof j.changeEventListener !== 'undefined' &&
          j.removeEventListener('change', j.changeEventListener, true);
        j.addEventListener('change', d(h.ganttChartView, j, 'change', u, true), true);
        j.changeEventListener = u;
        s = function (d) {
          if (d.keyCode == 13) {
            d.preventDefault();
            d.stopPropagation();
            u(d);
          }
        };
        typeof j.keyPressEventListener !== 'undefined' &&
          j.removeEventListener('keypress', j.keyPressEventListener, true);
        j.addEventListener('keypress', d(h.ganttChartView, j, 'keypress', s, true), true);
        j.keyPressEventListener = s;
        return j;
      },
      T = function (d) {
        typeof d.scheduleChartView !== 'undefined' && d.scheduleChartView.selectItem(d);
      },
      P = function (d, s) {
        if (typeof d.scheduleChartView !== 'undefined') d.scheduleChartView.currentItem = d;
        (s.selectionMode == 'Focus' || s.selectionMode == 'ExtendedFocus') && !d.isSelected && T(d);
      },
      K = function (d, s, j, u) {
        var k = function (d) {
          var h = d.content,
            d = d.ganttChartView.ownerDocument.createElement('span');
          d.innerHTML = h;
          return d;
        };
        return (typeof u === 'undefined' || u) && !d.isReadOnly && !d.isGridReadOnly && !d.isContentReadOnly
          ? function (j) {
              return !s.isReadOnly && (typeof j.isReadOnly === 'undefined' || !j.isReadOnly)
                ? Y(
                    j,
                    Math.max(
                      0,
                      s.width -
                        j.indentation * j.ganttChartView.settings.indentationLevelWidth -
                        j.ganttChartView.settings.toggleButtonAreaWidth -
                        16
                    ),
                    d
                  )
                : k(j);
            }
          : k;
      },
      Y = function (h, s, j) {
        var u = h.ganttChartView.ownerDocument,
          k;
        if (typeof h.contentInput === 'undefined') {
          k = u.createElement('input');
          h.contentInput = k;
          k.type = 'text';
          k.addEventListener(
            'focus',
            d(h.ganttChartView, k, 'focus', function () {
              P(h, j);
            })
          );
          var y = function () {
            h.content = k.value;
            h.scheduleChartView.onItemPropertyChanged(h, 'content', true, true);
            h.scheduleChartView.refreshItem(h);
          };
          typeof k.changeEventListener !== 'undefined' &&
            k.removeEventListener('change', k.changeEventListener, true);
          k.addEventListener('change', d(h.ganttChartView, k, 'change', y, true), true);
          k.changeEventListener = y;
          u = function (d) {
            if (d.keyCode == 13) {
              d.preventDefault();
              d.stopPropagation();
              y(d);
            }
          };
          typeof k.keyPressEventListener !== 'undefined' &&
            k.removeEventListener('keypress', k.keyPressEventListener, true);
          k.addEventListener('keypress', d(h.ganttChartView, k, 'keypress', u, true), true);
          k.keyPressEventListener = u;
          k.addEventListener(
            'focus',
            d(h.ganttChartView, k, 'focus', function () {
              k.style.backgroundColor = 'White';
            })
          );
          k.addEventListener(
            'blur',
            d(h.ganttChartView, k, 'blur', function () {
              k.style.backgroundColor = 'Transparent';
            })
          );
        } else k = h.contentInput;
        k.value = h.content;
        u = '';
        if (h.hasChildren && (typeof h.isSummaryEnabled === 'undefined' || h.isSummaryEnabled))
          u = '; font-weight: bold';
        k.setAttribute(
          'style',
          'outline: none; background-color: Transparent; width: ' +
            s +
            'px; border-width: 0px; padding: 0px' +
            u +
            (j.classic ? '' : '; font-size: 12px; padding: 1px;')
        );
        return k;
      },
      H = function (h, s, j) {
        return function (u) {
          var k = u.ganttChartView.ownerDocument,
            y = k.createElementNS('http://www.w3.org/2000/svg', 'g');
          if (
            !j.isReadOnly &&
            !j.isChartReadOnly &&
            (typeof u.isReadOnly === 'undefined' || !u.isReadOnly) &&
            !j.areAssignmentsReadOnly
          ) {
            var p = s.getChartPosition(u.start, j),
              v = Math.max(s.getChartPosition(u.finish, j), p + 4),
              r = k.createElementNS('http://www.w3.org/2000/svg', 'line');
            r.setAttribute('x1', p);
            r.setAttribute('y1', j.barMargin + j.barHeight + 2);
            r.setAttribute('x2', v - 1);
            r.setAttribute('y2', j.barMargin + j.barHeight + 2);
            var z = j.assignmentThumbClass;
            typeof z !== 'undefined' && r.setAttribute('class', z);
            r.setAttribute('style', j.assignmentThumbStyle);
            r.style.visibility = 'hidden';
            y.appendChild(r);
            k = k.createElementNS('http://www.w3.org/2000/svg', 'rect');
            k.setAttribute('x', p);
            k.setAttribute('y', j.barMargin + j.barHeight - 2);
            k.setAttribute('width', Math.max(0, v - p - 1));
            k.setAttribute('height', 7);
            k.setAttribute('style', 'fill: White; fill-opacity: 0; cursor: move');
            k.addEventListener(
              'mouseover',
              d(
                s,
                k,
                'mouseover',
                function () {
                  if (typeof s.draggingItem === 'undefined') r.style.visibility = 'visible';
                },
                true
              ),
              true
            );
            k.addEventListener(
              'mouseout',
              d(
                s,
                k,
                'mouseout',
                function () {
                  r.style.visibility = 'hidden';
                },
                true
              ),
              true
            );
            y.appendChild(k);
            U(k, y, u, p, v, h, s, j);
          }
          return y;
        };
      },
      J = function (d) {
        var s = d.scheduleChartView;
        d.itemTop = d.scheduleChartVisibilityIndex * s.settings.itemHeight;
        s.refreshGridItem(d);
        if (typeof d.ganttChartItems !== 'undefined')
          for (var j = 0; j < d.ganttChartItems.length; j++) {
            var u = d.ganttChartItems[j];
            u.itemTop = d.itemTop;
            s.refreshChartItem(u);
          }
      },
      U = function (h, s, j, u, k, y, p, v) {
        function r(d, y) {
          delete j.assignmentThumbParentNode;
          delete p.cancelDrag;
          p.draggingItem = j;
          p.dragType = 'Assignment';
          p.style.cursor = h.style.cursor;
          p.draggingInitialY = d.clientY;
          p.draggingInitialThumbPosition = v.barMargin;
          s.itemLeft = u;
          s.itemRight = k;
          j.assignmentThumb = s;
          if (DlhSoft.Controls.ToolTip && v.useUpdatingToolTips && !y) {
            (toolTip = DlhSoft.Controls.ToolTip.get(h)) ||
              (toolTip = DlhSoft.Controls.ToolTip.initialize(
                void 0,
                h,
                {
                  duration: NaN,
                  containerStyle:
                    'cursor: default; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; border: 1px solid ' +
                    v.border +
                    '; background-color: White; color: Black; font-family: ' +
                    (v.classic ? 'Arial' : 'system-ui, Arial') +
                    '; font-size: 12px; padding: 4px; margin-top: 1px',
                },
                'DlhSoft.Controls: DlhSoft internal usage only. Customers may purchase standard product usage licenses from http://DlhSoft.com/Purchase.'
              ));
            toolTip.setContent(j.content + ' \u2013');
            toolTip.show();
            toolTip.setPosition(toolTip.x + (k - u) + 4, toolTip.y - v.itemHeight - 1);
            toolTip.originalY = toolTip.y;
            p.toolTip = toolTip;
          }
        }
        var z = j.ganttChartView.ownerDocument;
        h.addEventListener(
          'mousedown',
          d(
            p,
            h,
            'mousedown',
            function (d) {
              if (d.button == 0) {
                r(d);
                d.preventDefault();
              }
            },
            true
          ),
          true
        );
        h.addEventListener(
          'touchstart',
          d(
            p,
            h,
            'touchstart',
            function (d) {
              r(d.touches[0], true);
              d.preventDefault();
            },
            true
          ),
          true
        );
        if (typeof p.draggableAssignmentItems === 'undefined') p.draggableAssignmentItems = [];
        for (var A = false, H = 0; H < p.draggableAssignmentItems.length; H++)
          if (p.draggableAssignmentItems[H] == j) {
            A = true;
            break;
          }
        if (!A) {
          var J = function (d, h) {
            if (
              !(typeof p.draggingItem === 'undefined' || p.draggingItem != j || p.dragType != 'Assignment')
            ) {
              var r = Math.ceil((d.clientY - p.draggingInitialY) / v.itemHeight) * v.itemHeight;
              da(d.clientY, p);
              delete p.draggingItem;
              s = j.assignmentThumb;
              s.parentNode
                ? (j.assignmentThumbParentNode = s.parentNode)
                : j.assignmentThumbParentNode && j.assignmentThumbParentNode.appendChild(s);
              u = s.itemLeft;
              k = s.itemRight;
              if (typeof p.temporaryAssignmentThumb !== 'undefined') {
                try {
                  s.removeChild(p.temporaryAssignmentThumb);
                } catch (D) {}
                delete p.temporaryAssignmentThumb;
              }
              if (p.cancelDrag) {
                delete p.cancelDrag;
                delete p.draggingItem;
                p.style.cursor = 'default';
              } else {
                var A;
                if (v.temporaryAssignmentThumbTemplate)
                  A = v.temporaryAssignmentThumbTemplate(
                    p,
                    u,
                    p.draggingInitialThumbPosition + r,
                    Math.max(v.classic ? 4 : 12, k - u - 1),
                    v.barHeight
                  );
                else {
                  A = z.createElementNS('http://www.w3.org/2000/svg', 'rect');
                  A.setAttribute('x', u);
                  A.setAttribute('y', p.draggingInitialThumbPosition + r);
                  A.setAttribute('width', Math.max(v.classic ? 4 : 12, k - u - 1));
                  A.setAttribute('height', v.barHeight);
                  typeof v.temporaryAssignmentThumbClass !== 'undefined' &&
                    A.setAttribute('class', v.temporaryAssignmentThumbClass);
                  A.setAttribute('style', v.temporaryAssignmentThumbStyle);
                }
                p.temporaryAssignmentThumb = A;
                s.appendChild(A);
                p.draggingItem = j;
                if (DlhSoft.Controls.ToolTip && v.useUpdatingToolTips && !h) {
                  toolTip = p.toolTip;
                  A = Math.floor((j.itemTop + p.draggingInitialThumbPosition + r) / v.itemHeight);
                  var L = null,
                    H = 0,
                    J;
                  for (J = 0; J < y.length; J++) {
                    var K = y[J];
                    if (
                      K.isVisible &&
                      !(typeof K.isHidden !== 'undefined' && K.isHidden) &&
                      typeof K.displayRowIndex === 'undefined'
                    ) {
                      if (H == A) {
                        K.hasChildren || (L = K);
                        break;
                      }
                      H++;
                    }
                  }
                  toolTip.setContent(j.content + ' \u2013' + (L != null ? ' ' + L.content : ''));
                  toolTip.setVerticalPosition(
                    Math.max(X(p) + p.chartHeaderContainer.clientHeight, toolTip.originalY + r)
                  );
                  if (typeof toolTip.originalX === 'undefined') toolTip.originalX = toolTip.x;
                  toolTip.setHorizontalPosition(toolTip.originalX);
                }
              }
            }
          };
          p.addEventListener(
            'mousemove',
            d(
              p,
              p,
              'mousemove',
              function (d) {
                J(d);
              },
              true
            ),
            true
          );
          p.addEventListener(
            'touchmove',
            d(
              p,
              p,
              'touchmove',
              function (d) {
                J(d.touches[0], true);
              },
              true
            ),
            true
          );
          var K = function (d) {
            if (
              !(typeof p.draggingItem === 'undefined' || p.draggingItem != j || p.dragType != 'Assignment')
            ) {
              delete j.assignmentThumbParentNode;
              s = j.assignmentThumb;
              if (typeof p.temporaryAssignmentThumb !== 'undefined') {
                try {
                  s.removeChild(p.temporaryAssignmentThumb);
                } catch (h) {}
                delete p.temporaryAssignmentThumb;
              }
              var d = Math.ceil((d.clientY - p.draggingInitialY) / v.itemHeight) * v.itemHeight,
                d = Math.floor((j.itemTop + p.draggingInitialThumbPosition + d) / v.itemHeight),
                k = null,
                u = 0,
                r;
              for (r = 0; r < y.length; r++) {
                var z = y[r];
                if (
                  z.isVisible &&
                  !(typeof z.isHidden !== 'undefined' && z.isHidden) &&
                  typeof z.displayRowIndex === 'undefined'
                ) {
                  if (u == d) {
                    z.hasChildren || (k = z);
                    break;
                  }
                  u++;
                }
              }
              if (k != null) {
                var u = j.scheduleChartItem,
                  z = u.ganttChartItems,
                  D = [];
                for (r = 0; r < z.length; r++) z[r] != j && D.push(z[r]);
                u.ganttChartItems = D;
                p.onItemPropertyChanged(u, 'ganttChartItems', true, true);
                if (typeof k.ganttChartItems === 'undefined') k.ganttChartItems = [];
                k.ganttChartItems.push(j);
                p.onItemPropertyChanged(k, 'ganttChartItems', true, true);
                j.scheduleChartItem = k;
                p.onItemPropertyChanged(j, 'scheduleChartItem', true, true);
                j.displayRowIndex = d;
                j.isVirtuallyVisible = true;
                p.refreshChartItem(j);
                p.refreshPredecessorItems(j);
                P(k, v);
              }
              delete p.draggingItem;
              p.style.cursor = 'default';
            }
          };
          z.addEventListener(
            'mouseup',
            d(
              p,
              z,
              'mouseup',
              function (d) {
                d.button == 0 && K(d);
              },
              true
            ),
            true
          );
          z.addEventListener(
            'touchend',
            d(
              p,
              z,
              'touchend',
              function (d) {
                K(d.changedTouches[0]);
              },
              true
            ),
            true
          );
          p.draggableAssignmentItems.push(j);
        }
      },
      X = function (d) {
        var s = 0;
        if (d.offsetParent) {
          do {
            s = s + d.offsetTop;
            d = d.offsetParent;
          } while (d);
        }
        return s;
      },
      da = function (d, s) {
        var j = s.settings;
        if (typeof s.draggingItem !== 'undefined' && j.enableVerticalScrollingDuringDragging) {
          var u = new Date();
          if (!s.lastDraggingTime || !(u > s.lastDraggingTime && u - s.lastDraggingTime < 50)) {
            s.lastDraggingTime = u;
            var d = d - X(s),
              k,
              r;
            if (
              d >= s.chartHeaderContainer.clientHeight &&
              d < s.chartHeaderContainer.clientHeight + j.verticalScrollingDragAreaHeight
            ) {
              k = s.chartContentContainer.scrollTop;
              s.chartContentContainer.scrollTop = s.chartContentContainer.scrollTop - j.itemHeight;
              if (typeof s.isDuringVerticalScrolling === 'undefined') {
                s.isDuringVerticalScrolling = true;
                setTimeout(function () {
                  r = k - s.chartContentContainer.scrollTop;
                  s.draggingInitialThumbPosition =
                    s.draggingInitialThumbPosition - Math.ceil(r / j.itemHeight) * j.itemHeight;
                  delete s.isDuringVerticalScrolling;
                }, 0);
              }
            } else if (
              d >=
              s.chartHeaderContainer.clientHeight +
                s.chartContentContainer.clientHeight -
                j.verticalScrollingDragAreaHeight
            ) {
              k = s.chartContentContainer.scrollTop;
              s.chartContentContainer.scrollTop = s.chartContentContainer.scrollTop + j.itemHeight;
              if (typeof s.isDuringVerticalScrolling === 'undefined') {
                s.isDuringVerticalScrolling = true;
                setTimeout(function () {
                  r = s.chartContentContainer.scrollTop - k;
                  s.draggingInitialThumbPosition =
                    s.draggingInitialThumbPosition + Math.ceil(r / j.itemHeight) * j.itemHeight;
                  delete s.isDuringVerticalScrolling;
                }, 0);
              }
            }
          }
        }
      },
      ea = function (d, s, j, u) {
        var k = u.indexOf(d);
        if (!(k < 0 || s < 0 || s == k || s >= u.length)) {
          u.splice(k, 1);
          u.splice(s, 0, d);
          V(j);
          typeof j.settings.itemMoveHandler !== 'undefined' && j.settings.itemMoveHandler(d, k, s);
        }
      },
      fa = function (d, s, j, u) {
        for (var k = 0; k < s.length; k++) s[k].scheduleChartView = d;
        d.scheduleChartItems = j;
        d.settings = u;
        d.refreshScheduleChartItem = J;
        d.initializeAssignmentDraggingThumb = function (j, k, s, u, r) {
          U(j, k, s, u, r, d.items, d, d.settings);
        };
        d.insertScheduleChartItem = function (k, p) {
          var u = k;
          p.scheduleChartView = d;
          p.isVirtuallyVisible = true;
          p.scheduleChartIndex = u;
          var r = 0,
            z;
          for (z = 0; z < u; z++) j[z].isHidden || r++;
          p.scheduleChartVisibilityIndex = r;
          p.isBarVisible = false;
          u = u < j.length ? j[u].index : s.length;
          j.splice(p.scheduleChartIndex, 0, p);
          d.insertItem(u++, p);
          if (typeof p.ganttChartItems !== 'undefined')
            for (z = 0; z < p.ganttChartItems.length; z++) {
              r = p.ganttChartItems[z];
              r.scheduleChartView = d;
              r.scheduleChartItem = p;
              r.isHidden = p.isHidden;
              r.displayRowIndex = p.scheduleChartVisibilityIndex;
              r.indentation = p.indentation;
              d.insertItem(u++, r);
            }
          for (z = p.scheduleChartIndex + 1; z < j.length; z++) {
            u = j[z];
            u.scheduleChartIndex = z;
            typeof u.scheduleChartVisibilityIndex !== 'undefined' && u.scheduleChartVisibilityIndex++;
            if (typeof u.ganttChartItems !== 'undefined')
              for (r = 0; r < u.ganttChartItems.length; r++)
                u.ganttChartItems[r].displayRowIndex = u.scheduleChartVisibilityIndex;
            J(u);
          }
        };
        d.addScheduleChartItem = function (k) {
          d.insertScheduleChartItem(j.length, k);
        };
        d.insertScheduleChartItems = function (j, k) {
          for (var s = 0; s < k.length; s++) d.insertScheduleChartItem(j++, k[s]);
        };
        d.addScheduleChartItems = function (j) {
          for (var k = 0; k < j.length; k++) d.addScheduleChartItem(j[k]);
        };
        d.removeScheduleChartItem = function (k) {
          if (typeof k.ganttChartItems !== 'undefined')
            for (var p = 0; p < k.ganttChartItems.length; p++) d.removeItem(k.ganttChartItems[p]);
          j.splice(k.scheduleChartIndex, 1);
          for (p = k.scheduleChartIndex; p < j.length; p++) {
            var s = j[p];
            s.scheduleChartIndex = p;
            typeof s.scheduleChartVisibilityIndex !== 'undefined' && s.scheduleChartVisibilityIndex--;
            if (typeof s.ganttChartItems !== 'undefined')
              for (var u = 0; u < s.ganttChartItems.length; u++)
                s.ganttChartItems[u].displayRowIndex = s.scheduleChartVisibilityIndex;
            J(s);
          }
          d.removeItem(k);
        };
        d.removeScheduleChartItems = function (j) {
          for (var k = 0; k < j.length; k++) d.removeScheduleChartItem(j[k]);
        };
        d.moveScheduleChartRange = function (k, p, s) {
          if (!(k < 0 || s < 0 || s == k || s > j.length - p)) {
            var u = [],
              r;
            for (r = k; r < k + p; r++) u.push(j[r]);
            j.splice(k, p);
            for (r = 0; r < u.length; r++) j.splice(s + r, 0, u[r]);
            V(d);
            if (typeof d.settings.itemMoveHandler !== 'undefined')
              for (r = 0; r < u.length; r++) d.settings.itemMoveHandler(u[r], k + r, s + r);
          }
        };
        d.moveScheduleChartItem = function (k, p) {
          ea(k, p, d, j);
        };
        d.moveScheduleChartItemUp = function (k) {
          var p = j.indexOf(k);
          p <= 0 || ea(k, p - 1, d, j);
        };
        d.moveScheduleChartItemDown = function (k) {
          var p = j.indexOf(k);
          p < 0 || p >= j.length - 1 || ea(k, p + 1, d, j);
        };
        d.moveGanttChartItem = function (j, k) {
          var s = j.scheduleChartItem,
            u = s.ganttChartItems.indexOf(j);
          s.ganttChartItems.splice(u, 1);
          k.ganttChartItems.push(j);
          j.scheduleChartItem = k;
          j.displayRowIndex = k.scheduleChartVisibilityIndex;
          d.refreshChartItem(j);
        };
      };
    return {
      initialize: O,
      refresh: V,
      getDefaultColumns: ca,
      getDefaultScales: DlhSoft.Controls.GanttChartView.getDefaultScales,
      getDefaultStyleDefinitionTemplate: DlhSoft.Controls.GanttChartView.getDefaultStyleDefinitionTemplate,
      initializeAssignmentDraggingThumb: function (d, s, j, u, k) {
        j.scheduleChartView.initializeAssignmentDraggingThumb(d, s, j, u, k);
      },
      textColumnTemplateBase: DlhSoft.Controls.GanttChartView.textColumnTemplateBase,
      getInputDate: DlhSoft.Controls.GanttChartView.getInputDate,
    };
  })());
void 0 == DlhSoft.Controls.LoadChartView &&
  (DlhSoft.Controls.LoadChartView = (function () {
    return {
      initialize: O,
      refresh: V,
      getDefaultColumns: ca,
      getDefaultScales: DlhSoft.Controls.GanttChartView.getDefaultScales,
      getDefaultAllocationTemplate: U,
      getDefaultItemTemplate: DlhSoft.Controls.GanttChartView.getDefaultItemTemplate,
      getWorkingTime: DlhSoft.Controls.GanttChartView.getWorkingTime,
      textColumnTemplateBase: DlhSoft.Controls.GanttChartView.textColumnTemplateBase,
      textInputColumnTemplateBase: DlhSoft.Controls.GanttChartView.textInputColumnTemplateBase,
      getOutputDate: DlhSoft.Controls.GanttChartView.getOutputDate,
      getInputDate: DlhSoft.Controls.GanttChartView.getInputDate,
    };
  })());
